import { gql } from '@apollo/client';

// 获取用户所有标签
export const GET_BOOKMARK_TAGS = gql`
  query BookmarkTags {
    bookmarkTags {
      name
      count
    }
  }
`;

// 添加书签标签
export const ADD_BOOKMARK_TAGS = gql`
  mutation BookmarkTagsAdd($input: bookmarkTagsAddInput!, $bookmarkId: String!) {
    bookmarkTagsAdd(input: $input, bookmarkId: $bookmarkId) {
      tags
    }
  }
`;
