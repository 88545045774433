import React, { useContext } from 'react';
import { useMutation } from "@apollo/client";
import { styled, useTheme } from '@mui/material/styles';
import { Menu, MenuItem, ListSubheader, ListItemIcon, ListItemText, Box, Divider } from '@mui/material';
import useBookmark from '@pages/DynamicBookmarkList/useBookmark';
import { BookmarkContext } from '@contexts/bookmark/bookmarkReducer';
import { CollectionContext } from '@contexts/collection/collectionReducer';
import * as BookmarkActionTypes from '@contexts/bookmark/BookmarkActionTypes';
import * as CollectionActionTypes from '@contexts/collection/CollectionActionTypes';
import { UPDATE_SORT_BY_COLLECTION } from '@apis/CollectionApi';
import { GET_COLLECTION } from '@apis/CollectionApi';
import { useApolloClient } from '@apollo/client';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import LanguageIcon from '@mui/icons-material/Language';
import CheckIcon from '@mui/icons-material/Check';

const StyledMenu = styled((props) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))();

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  lineHeight: '32px',
  color: theme.palette.grey[600],
  fontWeight: 400,
}));

const StyledListItemIcon = styled(({ checked, ...props }) => (
  <Box {...props}>
    {
      checked && <CheckIcon style={{ marginRight: 0 }} />
    }
  </Box>
))(({ theme }) => ({
  minWidth: '24px',
  display: 'flex',
  alignItems: 'center'
}));

export default (props) => {
  const theme = useTheme();
  const { anchorEl, onClose } = props;

  const { dispatch: bookmarkDispatch } = useContext(BookmarkContext);
  const { state: collectionState, dispatch: collectionDispath } = useContext(CollectionContext);
  const [updateCollectionSortBy] = useMutation(UPDATE_SORT_BY_COLLECTION);

  const open = Boolean(anchorEl);

  const { resetBookmark } = useBookmark();

  const client = useApolloClient();

  const handleUpdateSortBy = async ({ field, direction }) => {
    onClose();

    const sortBy = {
      sortByDirection: direction || collectionState.collection.sortByDirection,
      sortByField: field || collectionState.collection.sortByField,
    }

    await client.writeQuery({
      query: GET_COLLECTION,
      variables: {
        id: collectionState.collection.id,
      },
      data: {
        collection: {
          sortBy,
        }
      }
    })

    collectionDispath({ type: CollectionActionTypes.SET_COLLECTION, data: { ...collectionState.collection, ...sortBy  } });

    resetBookmark();
    bookmarkDispatch({ type: BookmarkActionTypes.RESET_BOOKMARK_LIST });

    await updateCollectionSortBy({
      variables: {
        id: collectionState.collection.id,
        input: sortBy,
      },
    })
  }

  return collectionState.collection ? (
    <StyledMenu
        id="sort-by-action-menu"
        MenuListProps={{
          'aria-labelledby': 'sort-by-action-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        <StyledListSubheader>排序</StyledListSubheader>
        <MenuItem onClick={handleUpdateSortBy.bind(this, { field: 'UPDATED_AT' })}>
          <StyledListItemIcon checked={collectionState.collection.sortByField === 'UPDATED_AT'} />
          <ScheduleOutlinedIcon style={{ marginRight: '8px' }} />按时间
        </MenuItem>
        <MenuItem onClick={handleUpdateSortBy.bind(this, { field: 'TITLE' })}>
          <StyledListItemIcon checked={collectionState.collection.sortByField === 'TITLE'} />
          <SortByAlphaIcon style={{ marginRight: '8px' }} />按标题
        </MenuItem>
        <MenuItem onClick={handleUpdateSortBy.bind(this, { field: 'SITE' })}>
          <StyledListItemIcon checked={collectionState.collection.sortByField === 'SITE'} />
          <LanguageIcon style={{ marginRight: '8px' }} />按站点
        </MenuItem>
        <Divider />
        <StyledListSubheader>方向</StyledListSubheader>
        <MenuItem onClick={handleUpdateSortBy.bind(this, { direction: 'DESC' })}>
          <StyledListItemIcon checked={collectionState.collection.sortByDirection === 'DESC'} />
          {
            collectionState.collection.sortByField === 'UPDATED_AT' ? '新 → 旧' : 'Z → A'
          }
        </MenuItem>
        <MenuItem onClick={handleUpdateSortBy.bind(this, { direction: 'ASC' })}>
          <StyledListItemIcon checked={collectionState.collection.sortByDirection === 'ASC'} />
          {
            collectionState.collection.sortByField === 'UPDATED_AT' ? '旧 → 新' : 'A → Z'
          }
        </MenuItem>
      </StyledMenu>
  ) : null
}
