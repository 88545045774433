import React, { useEffect, useState, useContext } from 'react';
import { Typography, Stack, useMediaQuery, SwipeableDrawer, Box, AppBar, Toolbar, useScrollTrigger } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import arrowDownFill from '@iconify/icons-eva/arrow-down-fill';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
// import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useApolloClient } from "@apollo/client";
// import AccountPopover from '../AccountPopover';
import { GET_COLLECTIONS } from '@apis/CollectionApi';
import { getEmojiAndName } from '@utils/helper';
import CollectionList from '@components/CollectionList';
// import SearchHeader from './SearchHeader';
// import AddBookmarkDrawer from '@components/AddBookmarkDrawer';
import { BookmarkContext } from '@contexts/bookmark/bookmarkReducer';
import { SnackbarContext } from '@contexts/snackbar/snackbarReducer';
import useBookmark from '@pages/DynamicBookmarkList/useBookmark';
// import * as BookmarkActionTypes  from '@contexts/bookmark/BookmarkActionTypes';
// import { SHOW_SNACKBAR } from '@contexts/snackbar/SnackbarActionTypes';
import MoreHorizOutlinedIcon from '@icons/MoreHorizOutlined';
import * as events from '@constants/events';
import SearchBar from '../../../Search/components/SearchBar';

const MOBILE_PAGE_TAB = {
  BOOKMARK: 'BOOKMARK',
  SEARCH: 'SEARCH',
  EXPLORE: 'EXPLORE',
  PROFILE: 'PROFILE',
}

const getPage = path => {
  if (path.startsWith('/explore')) {
    return MOBILE_PAGE_TAB.EXPLORE;
  } else if (path.startsWith('/profile')) {
    return MOBILE_PAGE_TAB.PROFILE;
  }
  return MOBILE_PAGE_TAB.BOOKMARK;
}

const getTitle = ({ collection, path }) => {
  let title = '';
  if (collection) {
    switch (collection) {
      case 'inbox': title = '收集箱'; break;
      case 'all': title = '所有书签'; break;
      case 'starred': title = '已加星标'; break;
      case 'trash': title = '回收站'; break;
    }
  } else {
    const page = getPage(path);
    if (page === MOBILE_PAGE_TAB.EXPLORE) {
      title = '发现';
    } else if (page === MOBILE_PAGE_TAB.PROFILE) {
      title = '我的';
    }
  }

  return title;
}

const isDefaultCollection = collection => ['inbox', 'all', 'trash'].indexOf(collection) > -1;

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const client = useApolloClient();
  const matchDownLg = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const navigate = useNavigate();
  const { collection, keywords } = useParams();
  const [title, setTitle] = useState(getTitle({ collection, path: location.pathname }));
  const [icon, setIcon] = useState();
  const [collections, setCollections] = useState([]);
  const [showCollections, setShowCollections] = useState(false);
  // const [addBookmarkDrawerVisible, setAddBookmarkDrawerVisible] = useState(false);

  // const { resetBookmark } = useBookmark();
  // const { state: bookmarkState, dispatch: bookmarkDispatch } = useContext(BookmarkContext);
  // const { dispatch: snackbarDispatch } = useContext(SnackbarContext);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    // target: window ? window() : undefined,
  });

  // const [getCollections] = useLazyQuery(GET_COLLECTIONS, {
  //   fetchPolicy: 'cache-only',
  // });

  // const redirectTo = keywords => {
  //   let path = isDefaultCollection(collection) ? `/${collection}` : `/collection/${collection}`;
  
  //   if (keywords) {
  //     navigate(`${path}/${keywords}`);
  //   } else {
  //     navigate(path);
  //   }
  // }
  
  const page = getPage(location.pathname);

  useEffect(() => {
    client.watchQuery({
      query: GET_COLLECTIONS,
      fetchPolicy: 'cache-only',
    }).subscribe({
      next: ({ data }) => {
        setCollections(data.collections || []);
      },
      error: err => {
        console.log(err);
      }
    });
  }, []);

  useEffect(() => {
    let pageTitle;
    
    if (collection) {
      if (isDefaultCollection(collection)) {
        setIcon(null);
        pageTitle = getTitle({ collection, path: location.pathname });
      } else {
        for (let { id, name } of collections) {
          if (id === collection) {
            const [emoji, displayName] = getEmojiAndName(name);
            if (emoji) {
              setIcon(emoji);
            } else {
              setIcon(null);
            }
            pageTitle = displayName;
            break;
          }
        }
      }
    } else {
      setIcon(null);
      pageTitle = getTitle({ path: location.pathname });
    }

    if (pageTitle) {
      document.title = pageTitle;
      setTitle(pageTitle);
    }
  }, [collections, collection, location.pathname]);

  const isSearchView = location.pathname.startsWith(`/${collection}/q`);

  const handleShowCollectionList = () => {
    setShowCollections(true);
  }

  useEffect(() => {
    window.addEventListener(events.SHOW_COLLECTION_LIST, handleShowCollectionList);

    return () => {
      window.removeEventListener(events.SHOW_COLLECTION_LIST, handleShowCollectionList);
    }
  }, []);

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        // bgcolor: theme.palette.background.default,
        // boxShadow: 'none',
        // backdropFilter: 'blur(6px)',
        // WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
        // backgroundColor: alpha(theme.palette.background.default, 0.72),
        backgroundColor: theme.palette.background.paper,
        // boxShadow: 'rgba(0, 0, 0, 0.2) 0px -2px 4px 0px, rgba(0, 0, 0, 0.14) 0px -3px 6px 0px, rgba(0, 0, 0, 0.12) 0px -1px 8px 0px;',
        borderBottomWidth: trigger ? '1px' : 0,
        borderBottomColor: alpha(theme.palette.divider, .16),
      }}
    >
      {
        isSearchView ? (
          // <SearchHeader
          //   collection={collection}
          //   value={keywords}
          //   onSearch={value => {
          //     navigate(`/${collection}/q/${value}`, { replace: true });
          //   }}
          // />
          <SearchBar showTitle={false} keywords={keywords} index={false} />
        ) : (
          <Toolbar sx={{ display: 'flex', alignItems: 'center', color: theme.palette.text.primary, pr: 1 }}>
            <Typography
              variant="h4"
              component="h4"
              sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}
              onClick={() => {
                setShowCollections(true);
              }}
            >
              { icon && <span style={{ marginRight: '8px' }}>{icon}</span> }
              {
                title && (
                  <>
                    {title}
                    {
                      page === MOBILE_PAGE_TAB.BOOKMARK && <ExpandMoreIcon fontSize="inherit" sx={{ ml: 0.5 }} />
                    }
                  </>
                )
              }
            </Typography>
            <Stack direction="row" alignItems="center">
              <IconButton
                aria-label="search"
                sx={{ color: theme.palette.text.secondary }}
                size="medium"
                // onClick={() => { setAddBookmarkDrawerVisible(true) }}
              >
                <MoreHorizOutlinedIcon fontSize="inherit" />
              </IconButton>
              {/* {
                collection && (
                  <IconButton
                    aria-label="search"
                    sx={{ color: theme.palette.text.secondary }}
                    onClick={() => { navigate(`/${collection}/q`) }}
                  >
                    <SearchIcon />
                  </IconButton>
                )
              } */}
            </Stack>
            {/* <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}> */}
              {/* <AccountPopover /> */}
            {/* </Stack> */}
            <SwipeableDrawer 
              // container={window}
              anchor="bottom"
              open={showCollections}
              onClose={() => { setShowCollections(false); }}
              onOpen={() => { setShowCollections(true); }}
              // swipeAreaWidth={56}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                '& .MuiPaper-root': {
                  maxHeight: `calc(100% - 48px)`,
                  // overflow: 'visible',
                  borderTopLeftRadius: 12,
                  borderTopRightRadius: 12,
                }
              }}
            >
              <Box sx={{ py: 2 }}>
                <CollectionList
                  onItemClick={({ path }) => {
                    setShowCollections(false);
                    navigate(path, { replace: true });
                  }}
                />
              </Box>
              {/* <NavMenu data={DEFAULT_MENU_DATA} /> */}
            </SwipeableDrawer>
            {/* <AddBookmarkDrawer
              collectionId={collection}
              open={addBookmarkDrawerVisible}
              onOpen={() => { setAddBookmarkDrawerVisible(true);}}
              onClose={() => { setAddBookmarkDrawerVisible(false);}}
              onSuccess={() => {
                setAddBookmarkDrawerVisible(false);
                resetBookmark();
                bookmarkDispatch({ type: BookmarkActionTypes.RESET_BOOKMARK_LIST });
                snackbarDispatch({ type: SHOW_SNACKBAR, data: `已添加书签` });
              }}
            /> */}
          </Toolbar>
        )
      }
    </AppBar>
  )
}

export default Header;
