import React from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';

export default () => {
  const theme = useTheme();
  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (window.matchMedia('(display-mode: standalone)').matches && matchUpSm) ? (
    <hr
      style={{
        margin: 0,
        position: 'fixed',
        background: alpha(theme.palette.divider, .16),
        width: '100%',
        zIndex: 1203,
        height: '1px',
        border: 0,
        top: 0,
      }}
    />
  ) : null
}
