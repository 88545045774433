import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Tab } from '@mui/material';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Collaboration from './components/Collaboration';
import Publish from './components/Publish';

const TABS = {
  COLLABORATION: 'COLLABORATION',
  PUBLISH: 'PUBLISH', 
}

const StyledTabList = styled(TabList)(({ theme }) => ({
  minHeight: '32px',
  '& .MuiTab-root': {
    minHeight: '32px',
    fontSize: theme.typography.h5.fontSize,
    padding: 0,
    justifyContent: 'start',
    minWidth: '64px',
  },
}));

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: 0,
}));

export default (props) => {
  const {
    open,
    onClose,
  } = props;

  const [tab, setTab] = useState(TABS.COLLABORATION);

  const handleCancel = () => {
    onClose();
  }

  useEffect(() => {
    if (!open) {
      setTab(TABS.COLLABORATION);
    }
  }, [open])

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="xs"
      fullWidth
    >
      <TabContext value={tab}>
        <DialogTitle>
          {/* 分享 {collection.name} */}
          <StyledTabList onChange={(evt, val) => { setTab(val); }} aria-label="basic tabs example">
            <Tab label="分享" value={TABS.COLLABORATION} />
            <Tab label="发布" value={TABS.PUBLISH} />
          </StyledTabList>
          <IconButton
            aria-label="close"
            onClick={handleCancel}
            size="small"
            sx={{
              position: 'absolute',
              right: 14,
              top: 9,
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ px: 0 }}>
          <StyledTabPanel value={TABS.COLLABORATION}>
            <Collaboration {...props} />
          </StyledTabPanel>
          <StyledTabPanel value={TABS.PUBLISH}>
            <Publish {...props} />
          </StyledTabPanel>
        </DialogContent>
      </TabContext>
    </Dialog>
  )
}
