import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function FlowerIcon2(props) {
  return (
    <SvgIcon {...props}>
      <path d="M18.7688691,4.13573357 C21.5118652,6.23730797 20.7745006,10.2402752 17.9851979,12.9112608 C17.0516629,13.805197 15.9063825,14.5850004 14.5387746,15.3104853 L14.2513057,15.4575921 L13.8166654,15.6682244 L13.3154894,15.9024221 C13.0518444,16.0240389 12.7712967,16.1514028 12.4859185,16.2798788 L10.129238,17.3336881 L9.62213443,17.5686948 C9.4901823,17.6320855 9.39905422,17.6790949 9.3599751,17.7054131 C8.94059813,17.987847 8.52720271,18.3627625 8.08339339,18.8454398 L7.8252751,19.1339126 L7.54255084,19.4645196 L7.10112609,19.9923863 L6.79219641,20.3478353 C6.50963332,20.6587074 6.30499767,20.830814 6.06023894,20.9415447 L5.95680975,20.9836738 C6.00373752,20.9197851 6.03611361,20.8437782 6.04830605,20.7598563 C6.30376103,19.0015305 6.74638531,17.4836115 7.37376786,16.2052032 C8.55088403,14.0985467 10.3100004,11.8669621 12.6399824,9.53694213 C12.8352429,9.34167839 12.8352403,9.0250959 12.6399766,8.82983535 C12.4447129,8.63457479 12.1281304,8.63457737 11.9328698,8.82984111 C9.54169312,11.2210568 7.72646294,13.5238265 6.48835182,15.7411606 C5.80006971,17.1420676 5.32842362,18.7595129 5.05869545,20.6160826 C5.04879685,20.6842158 5.05313633,20.7511334 5.06946473,20.8138215 C4.95434697,20.7110287 4.85995264,20.5770768 4.79467658,20.4231691 L4.67526093,20.0892202 C3.18477936,15.5446526 6.2986193,6.19187143 10.987417,3.57654208 C13.3721594,2.24637449 16.4173695,2.33410823 18.7688691,4.13573357 Z" />
    </SvgIcon>
  );
}

export default FlowerIcon2;
  