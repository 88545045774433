import React from 'react';
import { Typography, Box, Stack, Button } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';

export default (props) => {
  const theme = useTheme();

  return (
    <Box>
      {/* <Typography
        component="span"
        variant="h3"
        sx={{
          fontWeight: theme.typography.fontWeightMedium,
        }}
      >
        导出
      </Typography> */}
      <Box
        sx={{
          borderRadius: '16px',
          border: `1px solid ${theme.palette.border}`,
          p: 2,
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: theme.typography.fontWeightMedium }}>导出</Typography>
        <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mt: 1 }}>您可以将所有收藏的内容以CSV格式导出。导出完成后，我们将通过电子邮件发送给您下载链接</Typography>
        <Stack direction="row" spacing={2} sx={{ mt: 2.5, alignItems: 'center' }}>
          <Button variant="contained" disabled>导出CSV文件</Button>
          <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mt: 1 }}>导出功能暂未开放</Typography>
        </Stack>
      </Box>
    </Box>
  )
};
