import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import { LayoutContext } from '@contexts/layout/layoutReducer';
import SidebarLeftOutlined from '@icons/SidebarLeftOutlined';
import { TOGGLE_SIDEBAR } from '@contexts/layout/LayoutActionTypes';
import { LAYOUT_SIZE } from '@constants/common';

export default ({ title, showBorder }) => {
  const theme = useTheme();
  const { state: layoutState, dispatch: layoutDispatch } = useContext(LayoutContext);

  return (
    <AppBar
      sx={{
        // backgroundColor: alpha(theme.palette.background.paper, 0.72),
        // boxShadow: `inset 0 -1px 0 ${alpha(theme.palette.divider, .16)}`,
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${showBorder ? theme.palette.divider : 'transparent'}`,
        transition: 'none',
        ...(layoutState.showSidebar && {
          [theme.breakpoints.up('md')]: {
            left: '260px',
          },
        }),
      }}>
      <Toolbar>
        {
          layoutState.size === LAYOUT_SIZE.MEDIUM && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="toggle sidebar"
              sx={{ mr: 2 }}
              size="medium"
              onClick={() => { layoutDispatch({ type: TOGGLE_SIDEBAR }) }}
            >
              <SidebarLeftOutlined size="inherit" sx={{ color: theme.palette.text.secondary }} />
            </IconButton>
          )
        }
        <Typography variant="h4" component="h4" sx={{ color: theme.palette.text.primary, fontWeight: theme.typography.fontWeightMedium}}>
          { title }
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
