import { gql } from "@apollo/client";

// 添加星标
export const ADD_STARRED_BOOKMARK = gql`
  mutation starredBookmarkAdd($bookmarkId: String!) {
    starredBookmarkAdd(bookmarkId: $bookmarkId) {
      id
    }
  }
`;

// 移除星标
export const REMOVE_STARRED_BOOKMARK = gql`
  mutation starredBookmarkRemove($bookmarkId: String!) {
    starredBookmarkRemove(bookmarkId: $bookmarkId) {
      id
    }
  }
`;
