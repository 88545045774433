import React, { useState, useEffect }  from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import { Link, Button, Stack, Typography, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputBase, OutlinedInput, FormControlLabel, FormControl, InputLabel, FormLabel, Box} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
// import { Icon } from '@iconify/react';
// import eyeFill from '@iconify/icons-eva/eye-fill';
// import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// import Cookies from 'js-cookie'
import * as Yup from 'yup';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useMutation,
  gql
} from "@apollo/client";
import InputFormControl from '@components/InputFormControl';
import { getChromeExtensionId } from '@utils/helper';
import { LOGIN } from '@apis/AuthApi';

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(0.5),
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: 16,
  height: 48,
  // color: theme.palette.getContrastText(theme.palette.grey[900]),
  // backgroundColor: theme.palette.grey[900],
  // '&:hover': {
    // backgroundColor: theme.palette.grey[800],
  // },5
}));

const LoginForm = (props) => {
  const {
    onSubmittingStatusChange
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const pageState = location.state || {};

  const [showPassword, setShowPassword] = useState(false);
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [login, { data, loading, error }] = useMutation(LOGIN);
  const [errorMessage, setErrorMessage] = useState();
  const [showForgotPasswordDialog, setShowForgotPasswordDialog] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('请输入正确的电子邮箱地址').required('请输入电子邮箱地址'),
    password: Yup.string().required('请输入密码')
  });

  const formik = useFormik({
    initialValues: {
      email: pageState.email || '',
      password: '',
    },
    validationSchema: LoginSchema,
    validateOnBlur: validateAfterSubmit,
    validateOnChange: validateAfterSubmit,
    onSubmit: (values, { setSubmitting }) => {
      login({
        variables: values,
        onCompleted: ({ login: { token} }) => {
          setErrorMessage(null);
          if (token) {
            localStorage.setItem('token', token);
            // Cookies.set('token', token);
            // navigate('/', { replace: true });

            const { ref } = qs.parse(location.search, { ignoreQueryPrefix: true });

            if (ref === 'chrome_ext') {
              try {
                chrome.runtime.sendMessage(getChromeExtensionId(), { type: 'KP_MSG_LOGIN_SUCCESS' });
              } catch(ex) {}
            }

            window.location.replace('/');
          } else {
            setSubmitting(false);
          }
        },
        onError: (ex) => {
          setErrorMessage(ex.message);
          setSubmitting(false);
        }
      });
    }
  })

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  // const handleShowPassword = () => {
  //   setShowPassword(show => !show);
  // };

  const handleCloseForgotPasswordDialog = () => {
    setShowForgotPasswordDialog(false);
  }

  useEffect(() => {
    onSubmittingStatusChange(isSubmitting);
  }, [isSubmitting]);

  return (
    <>
      {
        pageState.from && (
          <Alert sx={{ mb: 2 }} severity={pageState.status}>{ pageState.message }</Alert>
        )
      }
      {
        errorMessage && (
          <Alert sx={{ mb: 2 }} severity="error">{ errorMessage }</Alert>
        )
      }
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <Stack>
            <Stack spacing={2}>
              <InputFormControl
                label="电子邮件地址"
                type="username"
                placeholder="电子邮件地址"
                autoComplete="username"
                disabled={isSubmitting}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                {...getFieldProps('email')}
              />

              <InputFormControl
                label="密码"
                type={showPassword ? 'text' : 'password'}
                placeholder="密码"
                disabled={isSubmitting}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                {...getFieldProps('password')}
              />
            </Stack>

            <Stack direction="row" sx={{ mt: 1 }}>
              <Link
                variant="subtitle2"
                onClick={() => {
                  setShowForgotPasswordDialog(true);
                }}
              >
                忘记密码？
              </Link>
              <Box sx={{ flex: 1 }}></Box>
            </Stack>

            <StyledLoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              loadingPosition="start"
              sx={{ mt: 4 }}
              onClick={() => {
                setValidateAfterSubmit(true);
              }}
            >
              登录
            </StyledLoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      <Dialog
        open={showForgotPasswordDialog}
        onClose={handleCloseForgotPasswordDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          忘记密码
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            暂不支持找回密码，如需支持，请联系 <Link target="_blank" href="mailto:mycreat@gmail.com">cbq926@gmail.com</Link>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForgotPasswordDialog} autoFocus>
            确定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default LoginForm;
