import React, { useContext, useEffect, useCallback } from "react";
import { Box, IconButton, Stack, Button, CircularProgress, Typography } from "@mui/material";
import { styled, useTheme, alpha } from "@mui/material/styles";
import { BookmarkContext } from "@contexts/bookmark/bookmarkReducer";
import { Link as RouterLink } from "react-router-dom";
import { getFaviconUrl } from "@utils/helper";
import CloseIcon from '@mui/icons-material/Close';
import {
  CLOSE_PREVIEW_WINDOW,
  SET_PREVIEW_TAB,
  OPERATION_UPDATE_BOOKMARK,
} from "@contexts/bookmark/BookmarkActionTypes";
import BookmarkSummary from "../BookmarkSummary";
import BookmarkNote from "../BookmarkNote";
import BookmarkActionBar from "../BookmarkActionBar";

const TABS = {
  SUMMARY: 0,
  NOTE: 1,
}

const StyledTabButton = styled(props => <Button component="div" {...props} />)(({ theme, selected }) => ({
  color: theme.palette.text.primary,
  backgroundColor: 'transparent',
  height: '32px',
  fontWeight: 400,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  transition: theme.transitions.create(['background-color']),
  ...(selected && {
    backgroundColor: theme.palette.action.selected,
    fontWeight: 700,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    }
  }),
}));

const StyledBookmarkIcon = styled("div")(({ theme }) => ({
  width: "20px",
  backgroundPosition: "0 center",
  backgroundSize: "20px auto",
  backgroundRepeat: "no-repeat",
  height: "20px",
}));

export default () => {
  const theme = useTheme();
  const { state: bookmarkState, dispatch: bookmarkDispatch } = useContext(BookmarkContext);

  const bookmarkData =
    bookmarkState.bookmarkList.edges[bookmarkState.selectIndex];

  if (!bookmarkData) return;

  const handleTabButtonClick = (value) => {
    bookmarkDispatch({ type: SET_PREVIEW_TAB, data: value });
  };

  const tabValue = bookmarkState.previewTab;

  // useEffect(() => {
  //   console.log('[debug] PreviewMobile: ', bookmarkData, bookmarkState.selectIndex, bookmarkState.bookmarkList.edges.length);
  // }, [bookmarkData, bookmarkState.selectIndex, bookmarkState.bookmarkList.edges])

  // 设置卡片主题
  const setSummaryPalette = (node) => {
    const PALETTE_COLORS = {
      '--kp-color-primary': theme.palette.primary.main,
      '--kp-color-primary-hover': theme.palette.primary.dark,
      '--kp-color-text-primary': theme.palette.text.primary,
      '--kp-color-text-secondary': theme.palette.text.secondary,
      '--kp-color-border': theme.palette.border,
      '--kp-color-background-paper': theme.palette.background.paper,
      '--kp-color-background-neutral': theme.palette.background.neutral,
    }

    for (let i in PALETTE_COLORS) {
      node.style.setProperty(i, PALETTE_COLORS[i]);
    }
  }

  const handleSummaryElementRef = useCallback((node) => {
    if (!node) return;
    setSummaryPalette(node);
  }, [theme.palette.mode]);

  useEffect(() => {
    if (!bookmarkData) return;
    if (bookmarkData.card && !bookmarkData.extra && !bookmarkData.meta?.extraObj) {
      bookmarkDispatch({ type: OPERATION_UPDATE_BOOKMARK, data: { id: bookmarkData.id } })
    }
  }, [bookmarkData]);
  
  return (
    <>
      <Box
        component="header"
        sx={{
          display: 'flex',
          backgroundColor: theme.palette.background.paper,
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          height: '56px',
          alignItems: 'center',
          px: 1,
          borderBottom: `1px solid ${theme.palette.divider}`,
          flexShrink: 0,
        }}
      >
        <RouterLink to={`/all/q/${encodeURIComponent(`site:${new URL(bookmarkData.url).host}`)}`} onClick={() => { bookmarkDispatch({ type: CLOSE_PREVIEW_WINDOW, data: { record: false } }); }}>
          <IconButton>
            <StyledBookmarkIcon
              sx={{
                backgroundImage: `url(${getFaviconUrl(bookmarkData.srcUrl ?? bookmarkData.url, bookmarkData.faviconUrl)})`,
              }}
            />
          </IconButton>
        </RouterLink>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ ml: 2 }}>
          <StyledTabButton selected={tabValue === TABS.SUMMARY} onClick={handleTabButtonClick.bind(this, TABS.SUMMARY)}>摘要</StyledTabButton>
          <StyledTabButton selected={tabValue === TABS.NOTE} onClick={handleTabButtonClick.bind(this, TABS.NOTE)}>备注</StyledTabButton>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
          <IconButton
            aria-label="close"
            onClick={() => bookmarkDispatch({ type: CLOSE_PREVIEW_WINDOW, data: { record: false } })}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
      </Box>
      <Box sx={{ p: 2, backgroundColor: theme.palette.background.paper, overflow: 'auto',}}>
        <Box ref={handleSummaryElementRef} display={tabValue === 0 ? 'block' : 'none'}>
          {
            bookmarkData.inProgress && (
              <Stack alignItems="center" justifyContent="center" spacing={1} direction="row" sx={{ pt: 1, pb: 2 }}>
                <CircularProgress size={16} />
                <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>解析中...</Typography>
              </Stack>
            )
          }
          <BookmarkSummary data={bookmarkData} />
        </Box>
        <Box display={tabValue === 1 ? 'block' : 'none'}>
          <BookmarkNote data={bookmarkData} />
        </Box>
      </Box>
      <BookmarkActionBar />
    </>
  );
};
