import React, { useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Menu, Box} from '@mui/material';
import { UserContext } from '@contexts/user/userReducer';
import { useApolloClient } from '@apollo/client';
// import { LOGOUT } from '@apis/AuthApi';
import AvatarAction from '@components/AvatarAction';

const StyledMenu = styled((props) => (
  <Menu
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '16px'
  }
}));

export default (props) => {
  const theme = useTheme();
  const client = useApolloClient();
  const { anchorEl, onClose } = props;

  const open = Boolean(anchorEl);
  const { state: { user }, dispatch: userDispatch } = useContext(UserContext);

  // const handleLogout = async () => {
  //   const { data } = await client.mutate({
  //     mutation: LOGOUT,
  //   })
  //   if (data.logout) {
  //     localStorage.removeItem('token');
  //     location.replace('/login');
  //   }
  // }
  
  return (
    <StyledMenu
      id="profile-action-menu"
      MenuListProps={{
        'aria-labelledby': 'profile-action-menu-button',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      {
        user && (
          <Box sx={{ px: 1, py: 0.5 }}>
            <AvatarAction />
          </Box>
        )
      }
      {/* <Tooltip title="暂未开放">
        <MenuItem onClick={() => {}} disableRipple>
          编辑个人资料
        </MenuItem>
      </Tooltip>
      <MenuItem onClick={handleLogout} disableRipple>
        退出登录
      </MenuItem> */}
    </StyledMenu>
  )
}
