import React from 'react';
import userInitialState from './userInitialState';
import * as UserActionTypes from './UserActionTypes';
import { LOC_COLLAPSE_SIDEBAR_COLLECTION, LOC_COLLAPSE_SIDEBAR_TAGS } from '@constants/localStorage';

export const UserReducer = (state = userInitialState, action) => {
  switch(action.type) {
    case UserActionTypes.SET_USER:
      return {
        ...state,
        user: action.data,
      };
    case UserActionTypes.SET_USER_SETTINGS:
      return {
        ...state,
        settings: action.data,
      };
    case UserActionTypes.SET_USER_AUTH:
      return {
        ...state,
        auth: action.data,
      };
    case UserActionTypes.SET_BOOKMARK_TAGS:
      return {
        ...state,
        bookmarkTags: action.data,
      }
    case UserActionTypes.OPERATION_FETCH_BOOKMARK_TAGS:
      return {
        ...state,
        operation: {
          type: UserActionTypes.OPERATION_FETCH_BOOKMARK_TAGS,
          params: action.data,
        },
      }
    case UserActionTypes.TOGGLE_SIDEBAR_COLLECTION_COLLAPSE: {
      let sidebarCollectionCollapsed = !state.sidebarCollectionCollapsed;
      
      if (sidebarCollectionCollapsed) {
        localStorage[LOC_COLLAPSE_SIDEBAR_COLLECTION] = true;
      } else {
        delete localStorage[LOC_COLLAPSE_SIDEBAR_COLLECTION];
      }

      return {
        ...state,
        sidebarCollectionCollapsed,
      }
    }
    case UserActionTypes.TOGGLE_SIDEBAR_TAGS_COLLAPSE: {
      let sidebarTagsCollapsed = !state.sidebarTagsCollapsed;

      if (sidebarTagsCollapsed) {
        localStorage[LOC_COLLAPSE_SIDEBAR_TAGS] = true;
      } else {
        delete localStorage[LOC_COLLAPSE_SIDEBAR_TAGS];
      }

      return {
        ...state,
        sidebarTagsCollapsed,
      }
    }
    default:
      return state;
  }
}

export const UserContext = React.createContext(userInitialState);
