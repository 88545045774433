import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function CompassFilled(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,15.7538848 19.9128836,19.138348 16.6504655,20.8549782 C15.2282029,21.6033491 13.6418258,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M16.2132444,8.46466815 L10.8759643,10.6587152 L7.78675556,15.5353319 L13.1248783,13.3405778 L16.2132444,8.46466815 Z" />
    </SvgIcon>
  );
}

export default CompassFilled;
