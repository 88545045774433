import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import qs from 'qs';
import { AppBar, Toolbar, Typography, IconButton, useMediaQuery, Box, Stack, Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { useApolloClient, useMutation } from '@apollo/client';
import { getEmojiAndName } from '@utils/helper';
import { SnackbarContext } from '@contexts/snackbar/snackbarReducer';
import { SHOW_SNACKBAR } from '@contexts/snackbar/SnackbarActionTypes';
import { LayoutContext } from '@contexts/layout/layoutReducer';
import { TOGGLE_SIDEBAR } from '@contexts/layout/LayoutActionTypes';
import { BookmarkContext } from '@contexts/bookmark/bookmarkReducer';
import * as BookmarkActionTypes  from '@contexts/bookmark/BookmarkActionTypes';
import { CollectionContext } from '@contexts/collection/collectionReducer';
import AddBookmarkDialog from '@components/AddBookmarkDialog';
import useBookmark from '@pages/DynamicBookmarkList/useBookmark';
import SidebarLeftOutlined from '@icons/SidebarLeftOutlined';
import SidebarRightOutlined from '@icons/SidebarRightOutlined';
import KeycapTooltip from '@components/KeycapTooltip';
import { HOTKEYS_TOGGLE_LEFT_SIDEBAR, HOTKEYS_TOGGLE_RIGHT_SIDEBAR } from '@constants/hotkeys';
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import * as collectionRole from '@constants/collectionRole';
import * as events from '@constants/events';
import Search from './components/Search';
import TitleActionMenu from './components/TitleActionMenu';
import SortByActionMenu from './components/SortByActionMenu';
import ViewActionMenu from './components/ViewActionMenu';
import BaseButton from '@base/Button';
import AddCircleOutlinedIcon from '@new-icons/AddCircleOutlined';
import { FOLLOW_COLLECTION } from '@apis/CollectionApi';
import { CollectionListContext } from '@contexts/collectionList/collectionListReducer';
import * as CollectionListActionTypes from '@contexts/collectionList/CollectionListActionTypes';
import * as CollectionActionTypes from '@contexts/collection/CollectionActionTypes';
import { useHotkeys } from 'react-hotkeys-hook';
import { HOTKEYS_CREATE_BOOKMARK } from '@constants/hotkeys';
import { LAYOUT_SIZE } from '@constants/common';

const StyledAppBar = styled(AppBar)(({ theme, leftSidebarOpen, rightSidebarOpen }) => ({
  // boxShadow: `inset 0 -1px 0 ${alpha(theme.palette.divider, .16)}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  // backdropFilter: 'blur(6px)',
  // WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.paper, 0.72),
  // [theme.breakpoints.up('sm')]: {
  //   zIndex: 1201,
  // },
  left: 0,
  ...(leftSidebarOpen && {
    [theme.breakpoints.up('md')]: {
      left: '260px',
    },
  }),
  width: `calc(100% - ${(leftSidebarOpen && useMediaQuery(theme.breakpoints.up('md'))) ? 260 : 0}px - ${rightSidebarOpen ? 360 : 0}px)`,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  // alignItems: 'flex-start',
  // paddingTop: theme.spacing(1),
  // paddingBottom: theme.spacing(2),
  // // Override media queries injected by theme.mixins.toolbar
  // '@media all': {
  //   minHeight: 128,
  // },
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const StyledDropdownButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  paddingLeft: '10px',
  paddingRight: '10px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  }
}));

const StyledTitle = styled(Button)(({ theme, showTitleActionMenu }) => ({
  paddingTop: '5px',
  paddingBottom: '5px',
  paddingRight: '16px',
  paddingLeft: showTitleActionMenu ? '16px': 0,
  transition: theme.transitions.create(['padding-left', 'background-color']),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    paddingLeft: '16px',
  }
}));

// const PAGE = {
//   BOOKMARK: 'BOOKMARK',
//   SHARE: 'SHARE',
//   SETTINGS: 'SETTINGS',
// }

// const getPage = path => {
//   if (path.startsWith('/share')) {
//     return PAGE.SHARE;
//   } else if (path.startsWith('/settings')) {
//     return PAGE.SETTINGS;
//   }
//   return PAGE.BOOKMARK;
// }

const isDefaultCollection = collectionId => ['inbox', 'all', 'trash', 'starred'].indexOf(collectionId) > -1;

// const getCollectionByPath = (pathname) => {
//   if (pathname.startsWith('/collection')) {
//     return pathname.replace('/collection/', '');
//   }
  
//   return pathname.slice(1);
// }

const getSortByFieldName = (field) => {
  switch (field) {
    case 'UPDATED_AT': return '时间';
    case 'TITLE': return '标题';
    case 'SITE': return '网站';
    default: return '';
  }
}

const getSortByFieldIcon = (field) => {
  switch (field) {
    case 'UPDATED_AT': return <ScheduleOutlinedIcon />;
    case 'TITLE': return <SortByAlphaIcon />;
    case 'SITE': return <LanguageIcon />;
    default: return null;
  }
}

const getViewName = (view) => {
  switch (view) {
    case 'LIST': return '列表';
    case 'CARDS': return '卡片';
    default: return '';
  }
}

const getViewIcon = (view) => {
  switch (view) {
    case 'LIST': return <ReorderOutlinedIcon />;
    case 'CARDS': return <GridViewOutlinedIcon />;
    default: return null;
  }
}

export default ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { collection: collectionId, keywords } = useParams();
  const { scope } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const client = useApolloClient();

  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);
  const { state: layoutState, dispatch: layoutDispatch } = useContext(LayoutContext);
  const { state: bookmarkState, dispatch: bookmarkDispatch } = useContext(BookmarkContext);
  const { state: collectionState, dispatch: collectionDispatch } = useContext(CollectionContext);
  const { state: collectionListState, dispatch: collectionListDispatch } = useContext(CollectionListContext);

  const [title, setTitle] = useState();
  const [icon, setIcon] = useState();
  // const [collections, setCollections] = useState([]);
  const [addDialogShow, setAddDialogShow] = useState(false);
  const [titleActionMenuAanchorEl, setTitleActionMenuAanchorEl] = useState(null);
  const [sortByActionMenuAanchorEl, setSortByActionMenuAanchorEl] = useState(null);
  const [viewActionMenuAanchorEl, setViewActionMenuAanchorEl] = useState(null);
  const [followCollection] = useMutation(FOLLOW_COLLECTION);

  // const [getCollections] = useLazyQuery(GET_COLLECTIONS, {
  //   fetchPolicy: 'cache-only',
  // });

  const handleSearch = (keywords, isScopeAll) => {
    let path = isDefaultCollection(collectionId) ? `/${collectionId}` : `/collection/${collectionId}`;
  
    if (keywords) {
      navigate(`${path}/q/${keywords}${isScopeAll ? '?scope=all' : ''}`);
    } else {
      navigate(path);
    }
  }

  const { resetBookmark } = useBookmark();

  const handleFollowCollection = async (collectionId) => {
    await followCollection({
      variables: {
        collectionId,
      },
      onCompleted: ({ collectionFollow: { collection } }) => {
        collectionListDispatch({ type: CollectionListActionTypes.OPERATION_FETCH_COLLECTION, data: { force: true } });
        collectionDispatch({
          type: CollectionActionTypes.UPDATE_COLLECTION,
          data: {
            role: collection?.role,
          }
        });
      },
      onError: ({ message }) => {
      }
    })
  }

  useEffect(() => {
    if (!collectionState.collection) return;

    const collectionId = collectionState.collection.id;
    let pageTitle;
    if (isDefaultCollection(collectionId)) {
      setIcon(null);
      pageTitle = collectionState.collection.name;
    } else {
      const [emoji, displayName] = getEmojiAndName(collectionState.collection.name);
      if (emoji) {
        setIcon(emoji);
      } else {
        setIcon(null);
      }
      pageTitle = displayName;
    }

    if (pageTitle) {
      document.title = pageTitle;
      setTitle(pageTitle);
    }
  }, [collectionState.collection]);

  const Title = ({ sx }) => (
    <Typography variant="h4" component="h4" sx={{ color: theme.palette.text.primary, fontWeight: theme.typography.fontWeightMedium, ...sx }}>
      { icon && <span style={{ marginRight: '8px' }}>{icon}</span> }
      { title }
    </Typography>
  )
  
  const showAddButton = collectionState.collection && (['all', 'inbox'].indexOf(collectionState.collection.id) > -1
    || [collectionRole.ADMIN, collectionRole.COLLABORATOR].indexOf(collectionState.collection.role) > -1)

  useHotkeys([HOTKEYS_CREATE_BOOKMARK], () => {
    setTimeout(() => {
      setAddDialogShow(true);
    }, 100)
  }, []);
  
  return (
    <StyledAppBar
      theme={theme}
      leftSidebarOpen={layoutState.showSidebar}
      rightSidebarOpen={bookmarkState.showPreviewWindow}
      elevation={0}
    >
      <StyledToolbar>
        <Stack direction="row" sx={{ height: '48px', display: 'flex', alignItems: 'center', width: '100%' }}>
          {
            (layoutState.size === LAYOUT_SIZE.MEDIUM || !layoutState.showSidebar) && (
              <KeycapTooltip text="收起侧边栏" hotkeys={HOTKEYS_TOGGLE_LEFT_SIDEBAR}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="toggle sidebar"
                  sx={{ mr: 2 }}
                  size="medium"
                  onClick={() => { layoutDispatch({ type: TOGGLE_SIDEBAR }) }}
                >
                  <SidebarLeftOutlined size="inherit" sx={{ color: theme.palette.text.secondary }} />
                </IconButton>
              </KeycapTooltip>
            )
          }
          <Stack direction="row" sx={{ flex: 1 }}>
            <Search
              title={title}
              collection={collectionId}
              keywords={keywords}
              scope={scope}
              onSearch={handleSearch}
            />
          </Stack>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            {
              showAddButton ? (
                <KeycapTooltip text="添加书签" hotkeys={HOTKEYS_CREATE_BOOKMARK}>
                  <Button
                    type="primary"
                    startIcon={(<AddCircleIcon />)}
                    sx={{
                      height: '32.5px',
                      // color: theme.palette.text.secondary,
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                      }
                    }}
                    onClick={() => { setAddDialogShow(true); }}
                  >
                    添加书签
                  </Button>
                </KeycapTooltip>
              ) : null
            }
            {
              !bookmarkState.showPreviewWindow && (
                <KeycapTooltip text="展开预览窗口" hotkeys={HOTKEYS_TOGGLE_RIGHT_SIDEBAR}>
                  <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="toggle detail window"
                    size="medium"
                    onClick={() => { bookmarkDispatch({ type: BookmarkActionTypes.TOGGLE_PREVIEW_WINDOW }) }}
                  >
                    <SidebarRightOutlined fontSize="inherit" sx={{ color: theme.palette.text.secondary, fontSize: theme.typography.h3.fontSize }} />
                  </IconButton>
                </KeycapTooltip>
              )
            }
          </Stack>
        </Stack>
        <Box sx={{ height: '44px', marginBottom: '4px', display: 'flex', alignItems: 'center', width: '100%' }}>
          {
            keywords ? (
              <Typography
                variant="h4"
                component="h4"
                sx={{
                  color: theme.palette.text.primary,
                  fontWeight: theme.typography.fontWeightMedium,
                  padding: '5px 16px',
                }}
              >
                {
                  scope === 'all'
                    ? '所有书签'
                    : (
                      <>
                        { icon && <span style={{ marginRight: '8px' }}>{icon}</span> }
                        { title }
                      </>
                    )
                }
                <span style={{ marginLeft: '8px' }}>搜索结果</span>
              </Typography>
            ) : ((!isDefaultCollection(collectionId) && collectionState.collection?.role && collectionState.collection?.role !== collectionRole.GUEST) ? (
              <StyledTitle
                endIcon={(title ? <ArrowDropDownIcon sx={{ color: theme.palette.text.secondary }} /> : null)}
                showTitleActionMenu={!!titleActionMenuAanchorEl}
                onClick={(evt) => {
                  setTitleActionMenuAanchorEl(evt.currentTarget);
                }}
              >
                <Title />
              </StyledTitle>
            ) : (
              <Title sx={{ paddingTop: '5px', paddingBottom: '5px', paddingRight: '16px' }} />
            ))
          }
          {
            collectionState.collection?.loaded && collectionState.collection?.role === collectionRole.GUEST && (
              <BaseButton size="sm" color="primary" startIcon={<AddCircleOutlinedIcon />} onClick={handleFollowCollection.bind(this, collectionState.collection.id)}>添加到我的收藏夹</BaseButton>
            )
          }
          <Box sx={{ flexGrow: 1 }} />
          {
            collectionState.collection ? (
              <Stack direction="row" spacing={2}>
                {
                  collectionState.collection && collectionState.collection.id !== 'trash' && collectionState.collection.id !== 'starred' && collectionState.collection.sortByField ? (
                    <StyledDropdownButton
                      startIcon={ getSortByFieldIcon(collectionState.collection.sortByField) }
                      size="small"
                      onClick={(evt) => {
                        setSortByActionMenuAanchorEl(evt.currentTarget);
                      }}
                    >
                      按{ getSortByFieldName(collectionState.collection.sortByField) } { collectionState.collection.sortByDirection === 'DESC' ? '↓' : '↑' }
                    </StyledDropdownButton>
                  ) : null
                }
                {
                  collectionState.collection.view ? (
                    <StyledDropdownButton
                      startIcon={ getViewIcon(collectionState.collection.view) }
                      size="small"
                      onClick={(evt) => {
                        setViewActionMenuAanchorEl(evt.currentTarget);
                      }}
                    >
                      { getViewName(collectionState.collection.view) }
                    </StyledDropdownButton>
                  ) : null
                }
                {/* <IconButton
                  // edge={ bookmarkState.showPreviewWindow ? 'end' : false }
                  color="inherit"
                  aria-label="add bookmark"
                  onClick={() => { setAddDialogShow(true) }}
                >
                  <AddCircleOutlinedIcon sx={{ color: theme.palette.text.secondary }} />
                </IconButton> */}
                <AddBookmarkDialog
                  collectionId={collectionId}
                  open={addDialogShow}
                  onClose={() => { setAddDialogShow(false); }}
                  onSuccess={(bookmark) => {
                    setAddDialogShow(false);
                    window.dispatchEvent(new CustomEvent(events.ADD_BOOKMARK, {
                      detail: bookmark
                    }));
                    // bookmarkDispatch({ type: BookmarkActionTypes.ADD_BOOKMARK, data: bookmark });
                    // resetBookmark();
                    // bookmarkDispatch({ type: BookmarkActionTypes.RESET_BOOKMARK_LIST });
                    snackbarDispatch({ type: SHOW_SNACKBAR, data: `已添加书签` });
                  }}
                />
              </Stack>
            ) : null
          }
        </Box>
      </StyledToolbar>
      <TitleActionMenu
        anchorEl={titleActionMenuAanchorEl}
        onClose={() => { setTitleActionMenuAanchorEl(null); }}
      />
      <SortByActionMenu
        anchorEl={sortByActionMenuAanchorEl}
        onClose={() => { setSortByActionMenuAanchorEl(null); }}
      />
      <ViewActionMenu
        anchorEl={viewActionMenuAanchorEl}
        onClose={() => { setViewActionMenuAanchorEl(null); }}
      />
    </StyledAppBar>
  )
}
