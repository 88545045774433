import React from 'react';
import update from 'immutability-helper';
import { produce } from 'immer';
import collectionListInitialState from './collectionListInitialState';
import * as CollectionListActionTypes from './CollectionListActionTypes';

let snapshotState;

export const CollectionListReducer = (state = collectionListInitialState, action) => {
  switch(action.type) {
    case CollectionListActionTypes.SHOW_ACTION_MENU:
      return {
        ...state,
        menu: action.data.menu,
        element: action.data.element,
        id: action.data.id,
        name: action.data.name,
      };
    case CollectionListActionTypes.HIDE_ACTION_MENU:
      return {
        ...state,
        menu: [],
        element: null,
        name: '',
      }
    case CollectionListActionTypes.OPERATION_FETCH_COLLECTION:
      return {
        ...state,
        operation: {
          type: CollectionListActionTypes.OPERATION_FETCH_COLLECTION,
          params: action.data,
        },
      }
    case CollectionListActionTypes.SET_COLLECTION_LIST:
      return {
        ...state,
        collectionList: action.data,
      }
    case CollectionListActionTypes.SET_EXPAND_COLLECTION:
      return {
        ...state,
        expandCollection: action.data,
      }
    case CollectionListActionTypes.ADD_EXPAND_COLLECTION:
      return {
        ...state,
        expandCollection: Array.from(new Set(state.expandCollection.concat(action.data))),
      }
    case CollectionListActionTypes.DELETE_EXPAND_COLLECTION:
      const expandCollectionSet = new Set(state.expandCollection);
      expandCollectionSet.delete(action.data);
      return {
        ...state,
        expandCollection: Array.from(expandCollectionSet),
      }
    case CollectionListActionTypes.SET_PINNED_COLLECTION_LIST:
      return {
        ...state,
        pinnedCollectionList: action.data,
      }
    case CollectionListActionTypes.OPERATION_REMOVE_PINNED_COLLECTION:
      return {
        ...state,
        operation: {
          type: CollectionListActionTypes.OPERATION_REMOVE_PINNED_COLLECTION,
          params: action.data,
        },
      }
    case CollectionListActionTypes.OPERATION_CREATE_COLLECTION:
      return {
        ...state,
        operation: {
          type: CollectionListActionTypes.OPERATION_CREATE_COLLECTION,
        },
      }
    case CollectionListActionTypes.OPERATION_UPDATE_COLLECTION:
      return {
        ...state,
        operation: {
          type: CollectionListActionTypes.OPERATION_UPDATE_COLLECTION,
          params: action.data,
        },
      }
    case CollectionListActionTypes.OPERATION_DELETE_COLLECTION:
      return {
        ...state,
        operation: {
          type: CollectionListActionTypes.OPERATION_DELETE_COLLECTION,
          params: action.data,
        },
      }
    case CollectionListActionTypes.OPERATION_SHARE_COLLECTION:
      return {
        ...state,
        operation: {
          type: CollectionListActionTypes.OPERATION_SHARE_COLLECTION,
          params: action.data,
        },
      }
    case CollectionListActionTypes.OPERATION_QUIT_COLLECTION:
      return {
        ...state,
        operation: {
          type: CollectionListActionTypes.OPERATION_QUIT_COLLECTION,
          params: action.data,
        },
      }
    case CollectionListActionTypes.UPDATE_COLLECTION_COUNT: {
      snapshotState = { ...state };

      return {
        ...state,
        collectionList: produce(state.collectionList, draftState => {
          for (let i of action.data) {
            for (let j of draftState) {
              if (i.id === j.id) {
                if (i.updateField === 'count') {
                  j.count += i.value;
                }
                break;
              }
            }
          }
        }),
        pinnedCollectionList: produce(state.pinnedCollectionList, draftState => {
          for (let i of action.data) {
            for (let j of draftState) {
              if (i.id === j.collection.id) {
                if (i.updateField === 'count') {
                  j.collection.count += i.value;
                }
                break;
              }
            }
          }
        }),
      }
    }
    case CollectionListActionTypes.ROLLBACK_OPERATION: {
      if (snapshotState) {
        const tmp = { ...snapshotState };
        snapshotState = null;
        return tmp;
      }
    }
    default:
      return state;
  }
}

export const CollectionListContext = React.createContext(collectionListInitialState);
