import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function SearchOutlined(props) {
  return (
    <SvgIcon {...props}>
      <path d="M18.5303301,17.4696699 L21.5303301,20.4696699 C21.8232233,20.7625631 21.8232233,21.2374369 21.5303301,21.5303301 C21.2374369,21.8232233 20.7625631,21.8232233 20.4696699,21.5303301 L17.4696699,18.5303301 C17.1767767,18.2374369 17.1767767,17.7625631 17.4696699,17.4696699 C17.7625631,17.1767767 18.2374369,17.1767767 18.5303301,17.4696699 Z M11,2.25 C15.8324916,2.25 19.75,6.16750844 19.75,11 C19.75,15.8324916 15.8324916,19.75 11,19.75 C6.16750844,19.75 2.25,15.8324916 2.25,11 C2.25,6.16750844 6.16750844,2.25 11,2.25 Z M11,3.75 C6.99593556,3.75 3.75,6.99593556 3.75,11 C3.75,15.0040644 6.99593556,18.25 11,18.25 C15.0040644,18.25 18.25,15.0040644 18.25,11 C18.25,6.99593556 15.0040644,3.75 11,3.75 Z" fill-rule="nonzero" />
    </SvgIcon>
  );
}

export default SearchOutlined;
