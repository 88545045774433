import React, { useState, useContext, useEffect } from 'react';
import classnames from 'classnames';
import { NavLink as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { produce } from 'immer';
import { Box, Typography, IconButton, Stack, Divider } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTheme, styled } from '@mui/material/styles';
import { useHotkeys } from 'react-hotkeys-hook';
import MoreHorizOutlinedIcon from '@icons/MoreHorizOutlined';
import BookmarksOutlined from '@icons/BookmarksOutlined';
import InboxOutlined from '@icons/InboxOutlined';
import StarOutlinedIcon from '@icons/StarOutlined';
import BookmarkOutlinedIcon from '@icons/BookmarkOutlined';
import PinOutlinedIcon from '@icons/PinOutlined';
import UnPinOutlinedIcon from '@icons/UnPinOutlined';
import AddCircleOutlinedIcon from '@icons/AddCircleOutlined';
import plusOutline from '@iconify/icons-eva/plus-outline';
import { OPERATION_FETCH_COLLECTION, SET_EXPAND_COLLECTION, ADD_EXPAND_COLLECTION, DELETE_EXPAND_COLLECTION, OPERATION_REMOVE_PINNED_COLLECTION, OPERATION_CREATE_COLLECTION } from '@contexts/collectionList/CollectionListActionTypes';
import { CollectionListContext } from '@contexts/collectionList/collectionListReducer';
import { ACTION_ADD_SUB_COLLECTION, ACTION_DELETE_COLLECTION, ACTION_UPDATE_COLLECTION, ACTION_SHARE_COLLECTION, ACTION_PIN_COLLECTION, ACTION_QUIT_COLLECTION } from '@contexts/collectionList/CollectionListConstants';
import { HOTKEYS_INBOX, HOTKEYS_ALL, HOTKEYS_TRASH, HOTKEYS_PINNED_1, HOTKEYS_PINNED_2, HOTKEYS_PINNED_3 } from '@constants/hotkeys';
import * as collectionRole from '@constants/collectionRole';
import CompassOutlinedIcon from '@icons/CompassOutlined';
import TrashOutlinedIcon from '@icons/TrashOutlined';
import { GET_BOOKMARK_TAGS } from '@apis/BookmarkTagApi';
import TagIcon from '@mui/icons-material/Tag';
import { UserContext } from '@contexts/user/userReducer';
import { OPERATION_FETCH_BOOKMARK_TAGS, TOGGLE_SIDEBAR_COLLECTION_COLLAPSE, TOGGLE_SIDEBAR_TAGS_COLLAPSE } from '@contexts/user/UserActionTypes';
import { LOC_EXPAND_BOOKMARK_TAGS } from '@constants/localStorage';

import NavMenu from './NavMenu';

const LOC_EXPAND_COLLECTION = '_kp_expand_collection';

const DEFAULT_MENU_DATA = {
  children: [
    {
      id: 'inbox',
      name: '收集箱',
      path: '/inbox',
      icon: InboxOutlined,
      hotkey: HOTKEYS_INBOX,
      canDrop: true,
    },
    {
      id: 'all',
      name: '所有书签',
      path: '/all',
      icon: BookmarksOutlined,
      hotkey: HOTKEYS_ALL,
      canDrop: false,
    },
    {
      id: 'starred',
      name: '已加星标',
      path: '/starred',
      icon: StarOutlinedIcon,
      hotkey: HOTKEYS_TRASH,
      canDrop: true,
    },
    // {
    //   id: 'trash',
    //   name: '回收站',
    //   path: '/trash',
    //   icon: TrashOutlinedIcon,
    //   hotkey: HOTKEYS_TRASH,
    // },
  ]
};

const getBookmarkAction = (role) => ([{
  icon: MoreHorizOutlinedIcon,
  menu: [
    [
      (role === collectionRole.ADMIN) ? (
        {
          name: '创建子收藏夹',
          // icon: BookmarkOutlinedIcon,
          action: ACTION_ADD_SUB_COLLECTION,
        }
      ) : null,
      {
        name: '修改名称',
        // icon: BookmarkOutlinedIcon,
        action: ACTION_UPDATE_COLLECTION,
      },
      (role !== collectionRole.VIEWER) ? (
        {
          name: '分享',
          // icon: BookmarkOutlinedIcon,
          action: ACTION_SHARE_COLLECTION,
        }
      ) : null,
      {
        name: '添加到快捷访问',
        // icon: PinOutlinedIcon,
        action: ACTION_PIN_COLLECTION,
      }
    ].filter(m => m), [
      {
        name: '删除',
        role: 'destructive',
        // icon: TrashOutlinedIcon,
        action: (role === collectionRole.VIEWER || role === collectionRole.COLLABORATOR)
          ? ACTION_QUIT_COLLECTION
          : ACTION_DELETE_COLLECTION,
      }
    ]
  ]
}]);

const getExpandMenusCache = () => {
  try {
    return JSON.parse(localStorage[LOC_EXPAND_COLLECTION]);
  } catch(ex) {
    return [];
  }
}

// const addExpandMenusCache = (ids) => {
//   const menus = getExpandMenusCache();
//   localStorage[LOC_EXPAND_COLLECTION] = JSON.stringify(Array.from(new Set(menus.concat(ids))));
// }

// const removeExpandMenusCache = (id) => {
//   try {
//     const menus = new Set(JSON.parse(localStorage[LOC_EXPAND_COLLECTION]));
//     menus.delete(id);
//     localStorage[LOC_EXPAND_COLLECTION] = JSON.stringify(Array.from(menus));
//   } catch (ex) {
//     localStorage[LOC_EXPAND_COLLECTION] = '[]';
//   }
// }

const StyledCollectionHeader = styled(Stack)(({ theme }) => ({
  '& .MuiButtonBase-root': {
    visibility: 'hidden',
  },
  '&:hover .MuiButtonBase-root': {
    visibility: 'visible',
  },
}));

const StyledRefreshIcon = styled(RefreshIcon)(({ spinning }) => {
  return spinning ? {
    animation: 'spin .8s linear infinite',
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  } : {}
});

const expandBookmarkTags = Array.from(new Set(JSON.parse(localStorage[LOC_EXPAND_BOOKMARK_TAGS] || '[]')));

export default ({ onItemClick }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [collectionMapData, setCollectionMapData] = useState();
  const [collectionData, setCollectionData] = useState();
  const [pinnedCollectionData, setPinnedCollectionData] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [refreshingTags, setRefreshingTags] = useState(false);
  const [bookmarkTagData, setBookmarkTagData] = useState();

  const { state: collectionListState, dispatch: collectionListDispatch } = useContext(CollectionListContext);
  const { state: userState, dispatch: userDispatch } = useContext(UserContext);

  const handleRefresh = () => {
    setRefreshing(true);
    collectionListDispatch({ type: OPERATION_FETCH_COLLECTION, data: { force: true } });
    setTimeout(() => setRefreshing(false), 800);
  }

  const fetchBookmarkTags = () => {
    setRefreshingTags(true);
    userDispatch({ type: OPERATION_FETCH_BOOKMARK_TAGS });
    setTimeout(() => setRefreshingTags(false), 800);
  }

  useHotkeys([HOTKEYS_INBOX, HOTKEYS_ALL, HOTKEYS_TRASH, HOTKEYS_PINNED_1, HOTKEYS_PINNED_2, HOTKEYS_PINNED_3], (_, handler) => {
    for (let { hotkey, path } of pinnedCollectionData.children) {
      if (hotkey === handler.keys[0]) {
        navigate(path);
        break;
      }
    }
  }, [pinnedCollectionData]);

  useEffect(() => {
    collectionListDispatch({ type: OPERATION_FETCH_COLLECTION, data: {} });
    collectionListDispatch({ type: SET_EXPAND_COLLECTION, data: getExpandMenusCache() });

    fetchBookmarkTags();
  }, []);

  useEffect(() => {
    if (!collectionListState.collectionList) return;

    const collectionMap = {};
    collectionListState.collectionList.forEach(({ id, name, parentId, publish, count, role }) => {
        collectionMap[id] = {
          id,
          name,
          parentId,
          publish,
          path: `/collection/${id}`,
          icon: BookmarkOutlinedIcon,
          action: getBookmarkAction(role),
          count,
          canDrop: role !== collectionRole.VIEWER, // 是否能拖入书签
          canSupportNest: role === collectionRole.ADMIN, // 是否能支持子收藏夹
        };
      });

      const expandMenus = new Set(collectionListState.expandCollection);

      // 展开父级收藏夹
      for (let i in collectionMap) {
        const node = collectionMap[i];
        if (expandMenus.has(node.id)) {
          expandMenus.delete(node.id);
          node.open = true;
        }
        // 设置当前访问的URL，默认展开收藏夹（若式从快捷访问打开，则不展开）
        if (location.pathname === node.path && location.pathname.startsWith('/collection') && !location.search.includes('pinned')) {
          const parentIds = [];
          let p = collectionMap[node.parentId];
          while (p) {
            parentIds.push(p.id);
            p.open = true;
            p = collectionMap[p.parentId];
          }
          // addExpandMenusCache(parentIds);
        }
      }

      // const unuseExpandMenus = Array.from(expandMenus);
      // for (let i of unuseExpandMenus) {
      //   removeExpandMenusCache(i);
      // }

      setCollectionMapData(collectionMap);
  }, [collectionListState.collectionList]);

  useEffect(() => {
    if (!collectionListState.pinnedCollectionList) {
      setPinnedCollectionData(DEFAULT_MENU_DATA);
      return;
    }

    setPinnedCollectionData(produce(DEFAULT_MENU_DATA, draftState => {
      collectionListState.pinnedCollectionList.map(({ collection: { id, name, count, role } }, idx) => {
        if (['inbox', 'all', 'trash', 'starred'].includes(id)) {
          for (let i of draftState.children) {
            if (i.id === id) {
              i.count = count;
              break;
            }
          }
        } else {
          draftState.children.push({
            id,
            name,
            path: `/collection/${id}?pinned`,
            icon: BookmarkOutlinedIcon,
            hotkey: (1 + idx).toString(),
            actionIcon: UnPinOutlinedIcon,
            action: OPERATION_REMOVE_PINNED_COLLECTION,
            count,
            canDrop: role !== collectionRole.VIEWER,
          });
        }
      })
    }));
  }, [collectionListState.pinnedCollectionList]);

  useEffect(() => {
    if (!collectionMapData) return;

    const expandCollection = collectionListState.expandCollection;

    const usedMenus = [];

    setCollectionMapData(produce(collectionMapData, draftState => {
      for (let i in draftState) {
        if (expandCollection.includes(i)) {
          usedMenus.push(i);
          draftState[i].open = true;
        } else {
          draftState[i].open = false;
        }
      }
    }));

    localStorage[LOC_EXPAND_COLLECTION] = JSON.stringify(usedMenus);
  }, [collectionListState.expandCollection]);
  
  useEffect(() => {
    if (collectionMapData) {
      const collectionList = [];

      const data = produce(collectionMapData, draftState => {
        for (let i in draftState) {
          const node = draftState[i];
          const parentNode = draftState[node.parentId];
          if (parentNode) {
            (parentNode.children || (parentNode.children = [])).push(node);
          }
        }
      });

      for (let i in data) {
        if (!data[i].parentId) {
          collectionList.push(data[i]);
        }
      }
      
      setCollectionData(collectionList);
    }
  }, [collectionMapData]);

  useEffect(() => {
    if (userState.bookmarkTags) {
      // by Claude: https://claude.ai/chat/ae0a94a8-8a6b-4e2c-8618-087ea8621808
      const map = {};
      const result = [];

      // 记录所有书签，包括临时创建的父级书签
      const allBookmarkTags = [];

      userState.bookmarkTags.forEach(item => {
        const parts = item.name.split('/');
        let currentPath = '';
        parts.forEach((part, index) => {
          currentPath += (index > 0 ? '/' : '') + part;
          if (!map[currentPath]) {
            map[currentPath] = {
              id: currentPath,
              name: part,
              count: -1,  // 初始化为 -1
              path: `/all/q/${encodeURIComponent(`tag:${currentPath}`)}`,
              icon: TagIcon,
              open: expandBookmarkTags.includes(currentPath),
              children: []
            };

            allBookmarkTags.push(currentPath);
          }
          if (currentPath === item.name) {
            map[currentPath].count = item.count;  // 只有在 list 中的项才设置实际的 count
          }
        });
      });

      Object.values(map).forEach(node => {
        const lastSlashIndex = node.id.lastIndexOf('/');
        if (lastSlashIndex === -1) {
          // 这是顶级节点
          result.push(node);
        } else {
          const parentId = node.id.substring(0, lastSlashIndex);
          const parent = map[parentId];
          if (parent) {
            parent.children.push(node);
          }
        }
      });
      
      setBookmarkTagData(result);

      // 检查本地存储的标签中是否有已经删除的标签，若有重置本底存储
      const deletedBookmarkTags = expandBookmarkTags.filter(item => !allBookmarkTags.includes(item));
      if (deletedBookmarkTags.length > 0) {
        const expandBookmarkTagsSet = new Set(expandBookmarkTags);
        for (let i of deletedBookmarkTags) {
          expandBookmarkTagsSet.delete(i);
        }
        localStorage[LOC_EXPAND_BOOKMARK_TAGS] = JSON.stringify(Array.from(expandBookmarkTagsSet));
      }
    }
  }, [userState.bookmarkTags]);
  
  return (
    <Box sx={{ px: 1.5, flex: 1 }}>
      <NavMenu onItemClick={onItemClick} data={pinnedCollectionData} />
      <NavMenu
        sortable={true}
        collapsed={userState.sidebarCollectionCollapsed}
        onCollapsePanel={() => {
          userDispatch({ type: TOGGLE_SIDEBAR_COLLECTION_COLLAPSE });
        }}
        data={{
          id: 'collection',
          name: (
            <StyledCollectionHeader direction="row" alignItems="center" spacing={.5}>
              <Typography
                display="block"
                sx={{
                  color: theme.palette.grey[600],
                  fontSize: theme.typography.caption.fontSize,
                }}
              >
                收藏夹
              </Typography>
              <IconButton
                color="refresh"
                aria-label="refresh"
                size="small"
                sx={{ fontSize: '.875rem' }}
                onClick={handleRefresh}
              >
                <StyledRefreshIcon
                  spinning={refreshing}
                  sx={{
                    fontSize: 'inherit',
                    color: theme.palette.grey[600],
                    opacity: .8
                  }}
                />
              </IconButton>
            </StyledCollectionHeader>
          ),
          action: [
            {
              name: '创建收藏夹',
              icon: AddCircleOutlinedIcon,
              action: function() {
                collectionListDispatch({ type: OPERATION_CREATE_COLLECTION, data: {} });
              },
              collapse: false,
            },
          ],
          children: collectionData,
        }}
        onItemClick={onItemClick}
        onExpand={(id) => {
          const openState = collectionMapData[id]?.open;
          // setCollectionMapData(produce(collectionMapData, draftState => {
          //   draftState[id].open = !openState;
          // }));
          if (openState) {
            collectionListDispatch({ type: DELETE_EXPAND_COLLECTION, data: id });
            // removeExpandMenusCache(id);
          } else {
            collectionListDispatch({ type: ADD_EXPAND_COLLECTION, data: id });
            // addExpandMenusCache(id);
          }
        }}
      />
      {
        userState.bookmarkTags && userState.bookmarkTags.length > 0 && bookmarkTagData && (
          <NavMenu
            type='tag'
            collapsed={userState.sidebarTagsCollapsed}
            onCollapsePanel={() => { userDispatch({ type: TOGGLE_SIDEBAR_TAGS_COLLAPSE }); }}
            data={{
              id: 'tags',
              name: (
                <StyledCollectionHeader direction="row" alignItems="center" spacing={.5}>
                  <Typography
                    display="block"
                    sx={{
                      color: theme.palette.grey[600],
                      fontSize: theme.typography.caption.fontSize,
                    }}
                  >
                    标签
                  </Typography>
                  <IconButton
                    color="refresh"
                    aria-label="refresh"
                    size="small"
                    sx={{ fontSize: '.875rem' }}
                    onClick={fetchBookmarkTags}
                  >
                    <StyledRefreshIcon
                      spinning={refreshingTags}
                      sx={{
                        fontSize: 'inherit',
                        color: theme.palette.grey[600],
                        opacity: .8
                      }}
                    />
                  </IconButton>
                </StyledCollectionHeader>
              ),
              children: bookmarkTagData,
            }}
            onExpand={(id) => {
              setBookmarkTagData(produce(bookmarkTagData, draftState => {
                function findBookmarkTagByName(data, id) {
                  for (const item of data) {
                    if (item.id === id) {
                      return item;
                    }
                    if (item.children) {
                      const result = findBookmarkTagByName(item.children, id);
                      if (result !== null) {
                        return result;
                      }
                    }
                  }
                  return null;
                }

                const ret = findBookmarkTagByName(draftState, id);

                ret.open = !ret.open;

                // 将记录存储到localStorage
                const expandBookmarkTagsLoc = new Set(JSON.parse(localStorage[LOC_EXPAND_BOOKMARK_TAGS] || '[]'));

                if (ret.open) {
                  expandBookmarkTagsLoc.add(id);
                } else {
                  expandBookmarkTagsLoc.delete(id);
                }
                
                localStorage[LOC_EXPAND_BOOKMARK_TAGS] = JSON.stringify(Array.from(expandBookmarkTagsLoc));
              }))
            }}
          />
        )
      }
      <Divider sx={{ mb: 2 }} />
      <NavMenu
        onItemClick={onItemClick}
        data={{
          children: [
            // {
            //   id: 'explore',
            //   name: '发现',
            //   path: '/explore',
            //   icon: CompassOutlinedIcon,
            // },
            {
              id: 'trash',
              name: '回收站',
              path: '/trash',
              icon: TrashOutlinedIcon,
              canDrop: true,
            },
          ]
        }}
      />
    </Box>
  )
}
