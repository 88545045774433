import React, { useContext, useEffect, useState } from 'react';
import { Typography, Box, Stack, Button, Divider, FormControl, FormHelperText, InputBase } from '@mui/material';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { UserContext } from '@contexts/user/userReducer';
import AvatarAction from '@components/AvatarAction';
import { useMutation, useLazyQuery } from '@apollo/client';
import { UPDATE_USER } from '@apis/UserApi';
import { AUTH } from '@apis/AuthApi';
import * as UserActionTypes from '@contexts/user/UserActionTypes';
import { SnackbarContext } from '@contexts/snackbar/snackbarReducer';
import { SHOW_SNACKBAR } from '@contexts/snackbar/SnackbarActionTypes';
import UploadPasswordDialog from './components/UpdatePasswordDialog';

const StyledOutlinedInput = styled(InputBase)(({ theme, error }) => ({
  '& .MuiInputBase-input': {
    borderRadius: theme.spacing(1),
    position: 'relative',
    fontSize: 14,
    padding: '4px 12px',
    height: 24,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    border: '1px solid',
    borderColor: error ? theme.palette.error.main : theme.palette.border,
    '&:focus': {
      boxShadow: `${alpha(error ? theme.palette.error.main : theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
    },
  },
}));

const FormItem = (props) => {
  const {
    label,
    children,
    edge,
  } = props;

  const theme = useTheme();

  return (
    <Stack sx={{ px: 2 }}>
      <Stack direction="row" sx={{ alignItems: 'center', mb: 2, height: 34 }}>
        <Stack sx={{ flex: 1 }}>
          <Typography
            component="label"
            variant="body2"
            sx={{
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            { label }
          </Typography>
        </Stack>
        <Box>
          { children }
        </Box>
      </Stack>
      {
        edge !== 'end' && <Divider />
      }
    </Stack>
  )
}

const FormItemInput = (props) => {
  const {
    label,
    type,
    placeholder,
    autoComplete,
    error,
    helperText,
    edge,
    ...otherProps
  } = props;

  const theme = useTheme();

  return (
    <Stack sx={{ px: 2 }}>
      <Stack direction="row" sx={{ alignItems: 'center', mb: 2 }}>
        <Stack sx={{ flex: 1 }}>
          <Typography
            component="label"
            variant="body2"
            sx={{
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            { label }
          </Typography>
          {helperText && (
          <FormHelperText error={error} sx={{ fontSize: 14, ml: 0 }}>
            { helperText }
          </FormHelperText>
        )}
        </Stack>
        <Box>
          <FormControl fullWidth>
            <StyledOutlinedInput
              fullWidth
              autoComplete={autoComplete}
              type={type}
              placeholder={placeholder}
              error={error}
              {...otherProps}
            />
          </FormControl>
        </Box>
      </Stack>
      {
        edge !== 'end' && <Divider />
      }
    </Stack>
  )
}

export default (props) => {
  const theme = useTheme();
  const { state: { user, auth }, dispatch: userDispatch } = useContext(UserContext);

  if (!user) return;

  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);
  const [currentNickname, setCurrentNickname] = useState(user.nickname);
  const [updatePasswordDialogShow, setUpdatePasswordDialogShow] = useState(false);
  
  const [updateUser] = useMutation(UPDATE_USER);
  const [getAuth] = useLazyQuery(AUTH, { fetchPolicy: 'network-only' });

  const formik = useFormik({
    initialValues: {
      nickname: user.nickname,
    },
    validationSchema: Yup.object().shape({
      nickname: Yup.string().required('请输入昵称'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (!values.nickname || values.nickname === currentNickname) return;

      const { data: { userUpdate } } = await updateUser({
        variables: {
          user: {
            nickname: values.nickname,
          }
        }
      });

      setCurrentNickname(userUpdate.nickname);

      userDispatch({
        type: UserActionTypes.SET_USER,
        data: Object.assign({}, user, userUpdate),
      });

      snackbarDispatch({ type: SHOW_SNACKBAR, data: '昵称已更新' });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, handleChange, handleBlur } = formik;

  const handleNicknameBlur = async (evt) => {
    const { value } = evt.target;
    handleBlur(evt, 'nickname');
    handleSubmit();
  }

  const hasPassword = (auth || []).find(item => item.type === null); // 已经设置过密码

  useEffect(() => {
    resetForm();
    formik.setValues({
      nickname: user.nickname,
    })
  }, []);

  return (
    <Box>
      {/* <Box>
        <Typography
          component="span"
          variant="h3"
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
          }}
        >
          账号
        </Typography>
      </Box> */}
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <Stack
            component="section"
            spacing={2}
            sx={{
              borderRadius: '16px',
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box sx={{ backgroundColor: theme.palette.background.neutral, p: 2, borderTopLeftRadius: '16px', borderTopRightRadius: '16px', }}>
              <AvatarAction showBorder={false} />
            </Box>

            <FormItem label="电子邮件">
              <Typography variant="body2">
                { user.email }
              </Typography>
            </FormItem>

            <FormItem label="密码">
              {
                Array.isArray(auth) ? (
                  <Button onClick={() => { setUpdatePasswordDialogShow(true); }}>
                    <Typography variant="body2">
                      {
                        hasPassword ? '修改' : '创建'
                      }
                    </Typography>
                  </Button>
                ) : null
              }
            </FormItem>

            <FormItemInput
              edge="end"
              label="昵称"
              type="nickname"
              placeholder="昵称"
              autoComplete="nickname"
              error={Boolean(touched.nickname && errors.nickname)}
              helperText={touched.nickname && errors.nickname}
              value={values.nickname}
              name="nickname"
              onChange={handleChange}
              onBlur={handleNicknameBlur}
            />
          </Stack>
        </Form>
      </FormikProvider>
      <UploadPasswordDialog
        open={updatePasswordDialogShow}
        hasPassword={hasPassword}
        onClose={() => { setUpdatePasswordDialogShow(false); }}
        onSuccess={() => {
          setUpdatePasswordDialogShow(false);
          snackbarDispatch({ type: SHOW_SNACKBAR, data: `密码${hasPassword ? '修改' : '创建'}成功` });
          
          // 若没有密码，创建完密码后，刷新授权信息
          if (!hasPassword) {
            getAuth({
              onCompleted: ({ auth }) => {
                userDispatch({
                  type: UserActionTypes.SET_USER_AUTH,
                  data: auth,
                });
              }
            })
          }
        }}
      />
    </Box>
  )
};
