import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SearchBar from './components/SearchBar';

export default () => {
  const theme = useTheme();
  const [searchBarHeight, setSearchBarHeight] = useState(96);

  return (
    <Stack>
      <SearchBar
        onHeightChange={(val) => {
          setSearchBarHeight(val);
        }}
      />
      <Stack sx={{ px: 2, paddingTop: `${searchBarHeight + 8}px`, transition: theme.transitions.create('padding-top') }}>
        <Typography variant="h5" component="h5" sx={{ color: 'text.primary' }}>最近搜索</Typography>
      </Stack>
    </Stack>
  );
};
