import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function FlowerIcon3(props) {
  return (
    <SvgIcon {...props}>
      <path d="M11.9771414,1.27137399 C8.44785229,1.27137399 5.85641241,4.06804339 5.24272701,7.90979329 C4.61630029,11.8313055 6.67229864,17.7178088 9.47797611,21.0496542 C10.3982164,22.1424735 11.3680273,22.771374 11.9988894,22.771374 C12.6237958,22.771374 13.564646,22.146549 14.4878101,21.0494447 C17.3006798,17.706581 19.3760508,11.8237394 18.7587752,7.91121798 C18.1534625,4.0745225 15.5194892,1.27137399 11.9771414,1.27137399 Z M11.9771414,2.77137399 C14.7030239,2.77137399 16.7776464,4.97924573 17.2771021,8.14498045 C17.8201125,11.5867812 15.8927763,17.0500035 13.3400757,20.0836777 C12.6741675,20.8750547 12.077397,21.271374 11.9988894,21.271374 C11.9057089,21.271374 11.2887854,20.871313 10.6253577,20.0834681 C8.08123298,17.0622263 6.17262962,11.5977277 6.72394773,8.14640514 C7.23117284,4.97111044 9.26948483,2.77137399 11.9771414,2.77137399 Z" />
    </SvgIcon>
  );
}

export default FlowerIcon3;
  