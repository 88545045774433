import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
// import * as SnackbarActionTypes from '@contexts/snackbar/SnackbarActionTypes';
// import { SnackbarContext } from '@contexts/snackbar/snackbarReducer';
import { ADD_COLLECTION, GET_COLLECTIONS } from '@apis/CollectionApi';
import InputFormControl from '../InputFormControl';

// const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
//   fontSize: theme.typography.h4.fontSize,
// }));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  // paddingBottom: 0,
}));

export default (props) => {
  const {
    open,
    onClose,
    onSuccess,
    parentId,
  } = props;

  // const navigate = useNavigate();
  // const { dispatch: snackbarDispatch } = useContext(SnackbarContext);
  const [addCollection] = useMutation(ADD_COLLECTION);
  // const [getCollections] = useLazyQuery(GET_COLLECTIONS, { fetchPolicy: 'network-only' });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    // validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required('请输入名称').max(100, '名称不能超过100个字'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const { data } = await addCollection({
        variables: {
          ...values,
          parentId,
        },
      });
      // snackbarDispatch({ type: SnackbarActionTypes.SHOW_SNACKBAR, data: `已创建 ${name}` });
      // navigate(`/collection/${id}`); // 创建新收藏夹不自动跳转
      // getCollections();  // 重新拉取一遍收藏夹
      // onClose();
      setSubmitting(false);
      onSuccess(data.collectionCreate);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleCancel = () => {
    onClose();
  }

  useEffect(() => {
    resetForm();
  }, [open]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="xs"
      fullWidth
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>
            创建收藏夹
            <IconButton
              aria-label="close"
              onClick={handleCancel}
              size="small"
              sx={{
                position: 'absolute',
                right: 14,
                top: 15,
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <StyledDialogContent>
            <InputFormControl
              fullWidth
              autoFocus
              margin="dense"
              type="text"
              // label="名称"
              placeholder="名称"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </StyledDialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>取消</Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              loadingPosition="start"
            >
              创建
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  )
}
