import React, { useContext, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Typography,
  Avatar,
  Stack,
  Box,
  IconButton,
} from '@mui/material';
import { debounce } from 'lodash';
import randomMC from 'random-material-color';
import RefreshOutlinedIcon from '@icons/RefreshOutlined';
import { UserContext } from '@contexts/user/userReducer';
import { SET_USER } from '@contexts/user/UserActionTypes';
import { getContrastYIQ } from '@utils/helper';
import { useApolloClient } from '@apollo/client';
import { UPDATE_USER } from '@apis/UserApi';
import flowerIcons from '@flower-icons';

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 80,
  height: 80,
  border: `2px solid ${theme.palette.background.paper}`,
}));

export default (props) => {
  const {
    showBorder = true,
  } = props;
  
  const theme = useTheme();
  const client = useApolloClient();

  const {
    state: { user },
    dispatch: userDispatch,
  } = useContext(UserContext);

  const saveAvatar = useCallback(debounce((avatarTheme, avatar) => {
    client.mutate({
      mutation: UPDATE_USER,
      variables: {
        user: {
          avatarTheme,
          avatar: Number(avatar),
        }
      },
    })
  }, 1000), [])

  const handleRandomize = () => {
    const avatarTheme = randomMC.getColor();
    const avatar = Math.floor(Math.random() * flowerIcons.length);
    userDispatch({
      type: SET_USER,
      data: Object.assign({}, user, {
        avatarTheme,
        avatar,
      }),
    });

    saveAvatar(avatarTheme, avatar.toString());
  };

  const FlowerIcon = flowerIcons[user?.avatar || 0];

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={0.5}
      sx={{
        ...showBorder && {
          py: 1,
          px: 5,
          backgroundColor: theme.palette.background.neutral,
          borderRadius: '16px',
          border: '1px solid rgba(145, 158, 171, 0.08)',
        },
      }}
    >
      <ProfileAvatar sx={{ bgcolor: user.avatarTheme }}>
        <FlowerIcon
          sx={{
            color: getContrastYIQ(user.avatarTheme),
            "&.MuiSvgIcon-root": { fontSize: "64px" },
          }}
        />
      </ProfileAvatar>
      <Typography
        variant="body1"
        sx={{
          fontWeight: 700,
          color: user.avatarTheme,
        }}
      >
        {user.nickname}
      </Typography>
      <Box sx={{ pt: 0.5 }}>
        <IconButton
          color="inherit"
          aria-label="randomize"
          onClick={handleRandomize}
          size="small"
          sx={{ fontSize: '1rem' }}
        >
          <RefreshOutlinedIcon
            fontSize="inherit"
            sx={{ color: theme.palette.text.secondary }}
          />
        </IconButton>
      </Box>
    </Stack>
  );
};
