import React from 'react';
import { useTheme } from '@mui/material/styles';
import { produce } from 'immer';
import { getCardUrl, getImageUrl, resolveCardTagName, lowerCamelToKebab } from '@utils/helper';

// const DefaultPreview = (props) => {
//   const {
//     id,
//     title,
//     url,
//     description,
//   } = props;

//   const theme = useTheme();
//   const [urlNoWrap, setUrlNoWrap] = useState(true);

//   useEffect(() => {
//     setUrlNoWrap(true);
//   }, [id]);

//   return (
//     <>
//       <Typography variant="h4">{title}</Typography>
//       <Typography
//         variant="body2"
//         sx={{
//           color: theme.palette.text.secondary,
//           wordBreak: 'break-all',
//         }}
//         noWrap={urlNoWrap}
//         onClick={() => setUrlNoWrap(false)}
//       >
//         {url}
//       </Typography>
//       <Typography
//         variant="body2"
//         sx={{
//           color: theme.palette.text.primary,
//         }}
//         dangerouslySetInnerHTML={{
//           __html: description
//         }}
//       >
//       </Typography>
//     </>
//   )
// }

// 卡片组件属性
const getWcProps = (props, isDarkMode, srcUrl) => {
  const { extra, ...otherProps } = props;
  const wcProps = {
    ...otherProps,
    ...produce(extra, draftState => {
      const imageInExtra = [];
      for (let i in extra) {
        if (i.endsWith(':image')) {
          draftState[i.replace(':image', '')] = getImageUrl(draftState[i], srcUrl ?? props.url, 'preview');
          imageInExtra.push(i);
        }
      }
      for (let i of imageInExtra) {
        delete draftState[i];
      }
    })
  }

  const ret = {};
  for (let i in wcProps) {
    ret[`${lowerCamelToKebab(i)}`] = wcProps[i];
  }
  
  if (isDarkMode) {
    ret['dark'] = true;
  }

  return ret;
}

export default React.memo(({ data }) => {
  const theme = useTheme();

  if (!data) return;
  
  let image;

  if (data.image) {
    image = getImageUrl(data.image, data.srcUrl ?? data.url, 'preview');
  }

  const wcProps = {
    type: data.type,
    url: data.url,
    title: data.title,
    image,
    description: data.description,
  }

  if (data.extra) {
    wcProps.extra = JSON.parse(data.extra);
  } else if (data.meta?.extraObj) {
    wcProps.extra = data.meta.extraObj;
  }

  // const { state: bookmarkState } = useContext(BookmarkContext);
  // let cardName = data.type?.split(':')[0];
  // let cardData = bookmarkState.cards[cardName];

  // if (!cardData) {
  //   cardName = 'common';
  //   cardData = bookmarkState.cards['common'];

  //   // 兼容common模板未获取到的情况
  //   if (!cardData) {
  //     return <DefaultPreview {...props} />;
  //   }
  // }

  let cardName = 'common';
  let cardVersion = '9'; // 基础卡片版本

  if (data.card) {
    const card = data.card.split('@');
    cardName = card[0];
    cardVersion = card[1];
  }

  const WcTag = resolveCardTagName(cardName, cardVersion);
  const wcUrl = getCardUrl(cardName, cardVersion);

  // 加载卡片 WebComponent
  if (wcUrl && !customElements.get(WcTag)) {
    const src = document.createElement('script')
    src.type = 'module'
    src.src = wcUrl;
    document.body.appendChild(src);
  }

  if (wcUrl) {
    return <WcTag {...getWcProps(wcProps, theme.palette.mode === 'dark', data.srcUrl)} />;
  }
}, (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps));
