import { gql } from '@apollo/client';

// 获取登录用户信息
export const GET_USER = gql`
  query GetUser {
    user {
      email
      nickname
      avatar
      avatarTheme
      access
      createdAt
      invitationCode {
        code
      }
      settings {
        defaultView
        theme
        autoDarkTheme
      }
    }
    auth {
      type
    }
  }
`;


// 更新用户信息
export const UPDATE_USER = gql`
  mutation UserUpdate($user: UserUpdateInput!) {
    userUpdate(user: $user) {
      avatar
      avatarTheme
      nickname
    }
  }
`;
