import React from 'react';
import update from 'immutability-helper';
import bookmarkInitialState from './bookmarkInitialState';
import * as BookmarkActionTypes from './BookmarkActionTypes';
import { LOC_SHOW_BOOKMARK_PREVIEW } from '@constants/localStorage';

let snapshotState;

export const BookmarkReducer = (state = bookmarkInitialState, action) => {
  switch(action.type) {
    case BookmarkActionTypes.SHOW_ACTION:
      return {
        ...state,
        anchorEl: action.data.anchorEl,
        containerRef: action.data.containerRef,
      };
    case BookmarkActionTypes.HIDE_ACTION:
      return {
        ...state,
        anchorEl: null,
        containerRef: null,
      }
    case BookmarkActionTypes.SET_COLLECTION:
      return {
        ...state,
        collection: action.data,
      }
    case BookmarkActionTypes.SET_KEYWORDS:
      return {
        ...state,
        keywords: action.data,
      }
    case BookmarkActionTypes.SET_SELECT_INDEX:
      return {
        ...state,
        selectIndex: action.data,
      }
    case BookmarkActionTypes.SET_BOOKMARK_LIST: {
      return {
        ...state,
        bookmarkList: {
          edges: action.data.edges,
          pageInfo: action.data.pageInfo
        },
      }
    }
    case BookmarkActionTypes.RESET_BOOKMARK_LIST: {
      return {
        ...state,
        selectIndex: -1,
        bookmarkList: {
          edges: [],
          pageInfo: {
            afterCursor: null,
            hasNextPage: true,
          },
        }
      }
    }
    case BookmarkActionTypes.OPERATION_FETCH_BOOKMARK:
      return {
        ...state,
        operation: {
          type: BookmarkActionTypes.OPERATION_FETCH_BOOKMARK,
          params: action.data,
        },
      }
    case BookmarkActionTypes.REMOVE_SELECTED_BOOKMARK: {
      snapshotState = { ...state };
      
      return {
        ...state,
        selectIndex: -1,
        bookmarkList: {
          ...state.bookmarkList,
          edges: (!state.bookmarkList.pageInfo.hasNextPage && state.bookmarkList.edges.length === 2) ? [] : update(state.bookmarkList.edges, {$splice: [[state.selectIndex, 1]]}),
        }
      }
    }
    case BookmarkActionTypes.OPERATION_MOVE_BOOKMARK:
      return {
        ...state,
        operation: {
          type: BookmarkActionTypes.OPERATION_MOVE_BOOKMARK,
          params: action.data,
        }
      }
    case BookmarkActionTypes.UPDATE_SELECTED_BOOKMARK: {
      snapshotState = { ...state };
      return {
        ...state,
        bookmarkList: {
          ...state.bookmarkList,
          edges: update(
            state.bookmarkList.edges,
            {
              [state.selectIndex]: {
                $set: {
                  ...state.bookmarkList.edges[state.selectIndex],
                  collection: {
                    id: action.data.collectionId,
                    name: action.data.collectionName,
                  }
                }
              }
            }
          )
        }
      }
    }
    case BookmarkActionTypes.OPEN_PREVIEW_WINDOW: {
      const showPreviewWindow = true;
      const { record = true } = action.data || {};
      if (record) {
        localStorage[LOC_SHOW_BOOKMARK_PREVIEW] = showPreviewWindow;
      }
      return {
        ...state,
        showPreviewWindow,
      }
    }
    case BookmarkActionTypes.CLOSE_PREVIEW_WINDOW: {
      const showPreviewWindow = false;
      const { record = true } = action.data || {};
      if (record) {
        localStorage[LOC_SHOW_BOOKMARK_PREVIEW] = showPreviewWindow;
      }
      return {
        ...state,
        showPreviewWindow,
      }
    }
    case BookmarkActionTypes.TOGGLE_PREVIEW_WINDOW: {
      const showPreviewWindow = !state.showPreviewWindow;
      localStorage[LOC_SHOW_BOOKMARK_PREVIEW] = showPreviewWindow;
      return {
        ...state,
        showPreviewWindow,
      }
    }
    case BookmarkActionTypes.STAR_BOOKMARK: {
      snapshotState = { ...state };

      return {
        ...state,
        bookmarkList: {
          ...state.bookmarkList,
          edges: update(
            state.bookmarkList.edges,
            {
              [state.selectIndex]: {
                $set: {
                  ...state.bookmarkList.edges[state.selectIndex],
                  isStarred: true,
                }
              }
            }
          )
        }
      }
    }
    case BookmarkActionTypes.UNSTAR_BOOKMARK: {
      snapshotState = { ...state };

      return {
        ...state,
        bookmarkList: {
          ...state.bookmarkList,
          edges: update(
            state.bookmarkList.edges,
            {
              [state.selectIndex]: {
                $set: {
                  ...state.bookmarkList.edges[state.selectIndex],
                  isStarred: false,
                }
              }
            }
          )
        }
      }
    }
    case BookmarkActionTypes.SET_CARDS: {
      return {
        ...state,
        cards: action.data
      }
    }
    case BookmarkActionTypes.SET_BOOKMARK_NOTE:
      return {
        ...state,
        bookmarkList: {
          ...state.bookmarkList,
          edges: update(
            state.bookmarkList.edges,
            {
              [state.selectIndex]: {
                $set: {
                  ...state.bookmarkList.edges[state.selectIndex],
                  note: action.data,
                }
              }
            }
          )
        }
      }
    case BookmarkActionTypes.SET_PREVIEW_TAB:
      return {
        ...state,
        previewTab: action.data
      }
    case BookmarkActionTypes.ROLLBACK_OPERATION: {
      if (snapshotState) {
        const tmp = { ...snapshotState };
        snapshotState = null;
        return tmp;
      }
    }
    case BookmarkActionTypes.REMOVE_PENDING_PARSE_BOOKMARKS:
      const pendingParseBookmarks = [];
      for (let b of state.pendingParseBookmarks) {
        if (action.data.indexOf(b) === -1) {
          pendingParseBookmarks.push(b);
        }
      }
      return {
        ...state,
        pendingParseBookmarks,
      }
    case BookmarkActionTypes.UPDATE_BOOKMARKS: {
      const updateData = {};
      action.data.forEach(bookmark => {
        const index = state.bookmarkList.edges.findIndex(item => item.id === bookmark.id);
        if (index > -1) {
          if (!updateData[index]) {
            updateData[index] = {
              $set: {
                ...state.bookmarkList.edges[index],
                ...bookmark,
              }
            }
          } else {
            updateData[index] = {
              $set: {
                ...updateData[index].$set,
                ...bookmark,
              }
            }
          }
        }
      });

      console.log('[debug] BookmarkActionTypes.UPDATE_BOOKMARKS', updateData);

      return {
        ...state,
        bookmarkList: {
          ...state.bookmarkList,
          edges: update(
            state.bookmarkList.edges,
            updateData,
          )
        }
      }
    }
    case BookmarkActionTypes.ADD_BOOKMARK:
      return {
        ...state,
        bookmarkList: {
          ...state.bookmarkList,
          edges: update(state.bookmarkList.edges, { $unshift: [action.data] }),
        }
      }
    case BookmarkActionTypes.OPERATION_UPDATE_BOOKMARK:
      return {
        ...state,
        operation: {
          type: BookmarkActionTypes.OPERATION_UPDATE_BOOKMARK,
          params: action.data,
        }
      }
    case BookmarkActionTypes.UPDATE_BOOKMARK: {
      snapshotState = { ...state };
      const {
        id,
        ...updatedData
      } = action.data;

      const idx = state.bookmarkList.edges.findIndex(bookmark => bookmark.id === id);
      return {
        ...state,
        bookmarkList: {
          ...state.bookmarkList,
          edges: update(
            state.bookmarkList.edges,
            {
              [idx]: {
                $set: {
                  ...state.bookmarkList.edges[idx],
                  ...updatedData,
                }
              }
            }
          )
        }
      }
    }
    case BookmarkActionTypes.SET_PENDING_PROGRESS_BOOKMARKS: {
      const {
        pendingParseBookmarkIds = [],
        pendingUploadImageBookmarkIds = [],
      } = action.data;

      return {
        ...state,
        pendingParseBookmarkIds,
        pendingUploadImageBookmarkIds,
      }
    }
    default:
      return state;
  }
}

export const BookmarkContext = React.createContext(bookmarkInitialState);
