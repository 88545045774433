import React, { useEffect, useRef, useState, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import qs from 'qs';
import BookmarkProvider from '@contexts/bookmark/BookmarkProvider';
import { CollectionContext } from '@contexts/collection/collectionReducer';
import { CollectionListContext } from '@contexts/collectionList/collectionListReducer';
import * as CollectionActionTypes from '@contexts/collection/CollectionActionTypes';
import { GET_COLLECTION } from '@apis/CollectionApi';
import BookmarkList from './BookmarkList';

export default () => {
  const containerRef = useRef();
  const location = useLocation();
  const params = useParams();
  const { scope } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [collectionId, setCollectionId] = useState(params.collection);
  const [keywords, setKeywords] = useState([params.keywords, scope]);

  const { state: collectionState, dispatch: collectionDispatch } = useContext(CollectionContext);
  const { state: collectionListState } = useContext(CollectionListContext);

  const [getCollection] = useLazyQuery(GET_COLLECTION);
  
  useEffect(() => {
    setCollectionId(params.collection);
    setKeywords([params.keywords, scope]);
  }, [location]);

  const isSearchDefaultView = location.pathname === `/${collectionId}/q`;
  
  const isDefaultCollection = collectionId => ['inbox', 'all', 'trash', 'starred'].indexOf(collectionId) > -1;

  const getDefaultTitle = collectionId => {
    let title = '';
    switch (collectionId) {
      case 'inbox': title = '收集箱'; break;
      case 'all': title = '所有书签'; break;
      case 'trash': title = '回收站'; break;
      case 'starred': title = '已加星标'; break;
    }
  
    return title;
  }
  
  useEffect(() => {
    // if (!collectionListState.collectionList) return;

    const loaded = !(collectionState.collection?.id !== collectionId);

    if (isDefaultCollection(collectionId)) { // 默认收藏夹默认设置显示标题
      collectionDispatch({
        type: CollectionActionTypes.SET_COLLECTION,
        data: {
          ...collectionState.collection,
          id: collectionId,
          name: getDefaultTitle(collectionId),
          loaded,
        }
      });
    } else if (collectionListState.collectionList) { // 从收藏夹列表中获取标题
      let isInCollectionList = false;
      for (let collection of collectionListState.collectionList) {
        if (collection.id === collectionId) {
          isInCollectionList = true;
          const { id, name } = collection;
          collectionDispatch({
            type: CollectionActionTypes.SET_COLLECTION,
            data: {
              ...collectionState.collection,
              id,
              name,
              loaded,
            }
          });
          break;
        }
      }

      if (!isInCollectionList) {
        collectionDispatch({
          type: CollectionActionTypes.SET_COLLECTION,
          data: {
            id: collectionId,
            loaded,
          }
        });
      }
    }
  }, [collectionListState.collectionList, collectionId]);

  useEffect(() => {
    if (!collectionState.collection) return;
    
    getCollection({
      fetchPolicy: 'cache-and-network',
      variables: {
        id: collectionState.collection.id,
      },
      onCompleted: ({ collection: { id, name, sortByField, sortByDirection, view, role } }) => {
        collectionDispatch({
          type: CollectionActionTypes.SET_COLLECTION,
          data: {
            id,
            name,
            sortByField,
            sortByDirection,
            view,
            loaded: true,
            role,
          }
        });
      }
    });
  }, [collectionState.collection?.id])

  useEffect(() => {
    return () => {
      collectionDispatch({
        type: CollectionActionTypes.SET_COLLECTION,
        data: null
      });
    }
  }, [])

  return isSearchDefaultView ? (
    <div></div>
  ) : (
    <BookmarkProvider
      containerRef={containerRef}
      type={collectionId === 'trash' ? 'trash' : ''}
      pageType={keywords ? 'search' : 'list'}
      pageParams={{ collection: collectionId, keywords }}
    >
      <BookmarkList />
    </BookmarkProvider>
  );
}
