import { gql } from "@apollo/client";

// 添加收藏夹到快捷访问
export const ADD_PINNED_COLLECTION = gql`
  mutation pinnedCollectionAdd($collectionId: String!) {
    pinnedCollectionAdd(collectionId: $collectionId) {
      id
    }
  }
`;

// 移除快捷访问的收藏夹
export const REMOVE_PINNED_COLLECTION = gql`
  mutation pinnedCollectionRemove($collectionId: String!) {
    pinnedCollectionRemove(collectionId: $collectionId) {
      id
    }
  }
`;

// 获取快捷访问中收藏夹列表
export const GET_PINNED_COLLECTIONS = gql`
  query pinnedCollections {
    pinnedCollections {
      id
      collection {
        id
        name
      }
    }
  }
`;
