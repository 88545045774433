import React, { useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { GET_USER } from '@apis/UserApi';
import { LOC_DEFAULT_VIEW, LOC_THEME, LOC_AUTO_DARK_THEME } from '@constants/localStorage';
import { UserContext, UserReducer } from './userReducer';
import * as UserActionTypes from './UserActionTypes';
import initialState from './userInitialState';
import * as events from '@constants/events';
import { useLazyQuery } from '@apollo/client';
import { GET_BOOKMARK_TAGS } from '@apis/BookmarkTagApi';

const DEFAULT_USER_SETTINGS = {
  defaultView: '/inbox',
  autoDarkTheme: false,
  theme: 'LIGHT',
};

const UserProvider = (props) => {
  const [ state, dispatch ] = useReducer(UserReducer, initialState);
  const client = useApolloClient();
  const navigate = useNavigate();

  const [getBookmarkTags] = useLazyQuery(GET_BOOKMARK_TAGS, { fetchPolicy: 'network-only' });

  useEffect(() => {
    (async () => {
      const { data } = await client.query({
        query: GET_USER,
        variables: {},
      });

      const { settings: recordSettings, ...user} = data.user;

      const settings = recordSettings || DEFAULT_USER_SETTINGS;
      
      dispatch({ type: UserActionTypes.SET_USER, data: user });
      dispatch({
        type: UserActionTypes.SET_USER_SETTINGS,
        data: settings,
      });
      dispatch({
        type: UserActionTypes.SET_USER_AUTH,
        data: data.auth,
      });

      localStorage.setItem(LOC_DEFAULT_VIEW, settings.defaultView);
      localStorage.setItem(LOC_THEME, settings.theme);
      localStorage.setItem(LOC_AUTO_DARK_THEME, settings.autoDarkTheme);

      window.dispatchEvent(new CustomEvent(events.CHANGE_THEME, {
        detail: {
          autoDarkTheme: settings.autoDarkTheme,
          theme: settings.theme,
        }
      }));
      
      if (!user.access) {
        navigate('/coming-soon');
      }
    })();
  }, []);

  useEffect(() => {
    if (!state.operation) return;
    
    switch (state.operation.type) {
      case UserActionTypes.OPERATION_FETCH_BOOKMARK_TAGS: {
        getBookmarkTags({
          onCompleted: ({ bookmarkTags }) => {
            dispatch({ type: UserActionTypes.SET_BOOKMARK_TAGS, data: bookmarkTags });
          }
        })
        break;
      }
    }
  }, [state.operation]);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      { props.children }
    </UserContext.Provider>
  )
}

export default UserProvider;
