export const HOTKEYS_TOGGLE_LEFT_SIDEBAR = 'shift+B';
export const HOTKEYS_TOGGLE_RIGHT_SIDEBAR = 'shift+P';
export const HOTKEYS_OPEN_LINK = 'O';
export const HOTKEYS_INBOX = '1';
export const HOTKEYS_ALL = '2';
export const HOTKEYS_TRASH = '3';
export const HOTKEYS_PINNED_1 = '4';
export const HOTKEYS_PINNED_2 = '5';
export const HOTKEYS_PINNED_3 = '6';
export const HOTKEYS_STAR_BOOKMARK = 'shift+S';
export const HOTKEYS_EDIT_BOOKMARK = 'shift+M';
export const HOTKEYS_OPEN_NOTE = 'shift+N';
export const HOTKEYS_SEARCH = '/';
export const HOTKEYS_COPY_URL = 'shift+C';
export const HOTKEYS_COPY_AS_MD = 'shift+D';
export const HOTKEYS_COPY_AS_DESCMD = 'shift+E';
export const HOTKEYS_CREATE_BOOKMARK = 'shift+A';
export const HOTKEYS_OPEN_SETTINGS = 'shift+<';
export const HOTKEYS_EDIT_TAGS = 'shift+T';