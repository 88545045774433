// 窗口大小
export const LAYOUT_SIZE = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
}

// 布局类型
export const LAYOUT_TYPE = {
  TABLET: 'TABLET',
  MOBILE: 'MOBILE',
  MINI: 'MINI'
}
