import FlowerIcon1 from "./FlowerIcon1";
import FlowerIcon2 from "./FlowerIcon2";
import FlowerIcon3 from "./FlowerIcon3";
import FlowerIcon4 from "./FlowerIcon4";
import FlowerIcon5 from "./FlowerIcon5";
import FlowerIcon6 from "./FlowerIcon6";
import FlowerIcon7 from "./FlowerIcon7";
import FlowerIcon8 from "./FlowerIcon8";

export default [
  FlowerIcon1,
  FlowerIcon2,
  FlowerIcon3,
  FlowerIcon4,
  FlowerIcon5,
  FlowerIcon6,
  FlowerIcon7,
  FlowerIcon8,
];
