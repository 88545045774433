import { gql } from "@apollo/client";

// 添加备注
export const ADD_BOOKMARK_NOTE = gql`
  mutation bookmarkNoteAdd($bookmarkId: String!, $note: String) {
    bookmarkNoteAdd(bookmarkId: $bookmarkId, note: $note) {
      id
      note
    }
  }
`;
