
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function BookmarksOutlined(props) {
  return (
    <SvgIcon {...props}>
      <path d="M16 2a4.75 4.75 0 0 1 4.75 4.75v9.233a2.201 2.201 0 0 1-3.5 1.776v-2.062l.81.788a.7.7 0 0 0 1.19-.502V6.75A3.25 3.25 0 0 0 16 3.5h-4a3.24 3.24 0 0 0-2.345 1H8.5c-.242 0-.48.018-.713.053A4.75 4.75 0 0 1 12 2h4Z"/>
      <path fill-rule="evenodd" d="M3 10a4.75 4.75 0 0 1 4.75-4.75h4A4.75 4.75 0 0 1 16.5 10v9.233a2.201 2.201 0 0 1-3.735 1.578l-2.51-2.442a.725.725 0 0 0-1.01 0l-2.51 2.442A2.2 2.2 0 0 1 3 19.233V10Zm4.75-3.25A3.25 3.25 0 0 0 4.5 10v9.233a.7.7 0 0 0 1.19.502l2.51-2.441a2.224 2.224 0 0 1 3.1 0l2.51 2.441a.7.7 0 0 0 1.19-.502V10a3.25 3.25 0 0 0-3.25-3.25h-4Z" clip-rule="evenodd"/>
    </SvgIcon>
  );
}

export default BookmarksOutlined;
