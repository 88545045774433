import React, { useState, useRef, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, useMediaQuery, SwipeableDrawer, useScrollTrigger } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useHotkeys } from 'react-hotkeys-hook';
import { BookmarkContext } from '@contexts/bookmark/bookmarkReducer';
import { CLOSE_PREVIEW_WINDOW, SET_SELECT_INDEX } from '@contexts/bookmark/BookmarkActionTypes';
import { TOGGLE_PREVIEW_WINDOW } from '@contexts/bookmark/BookmarkActionTypes';
import { CollectionContext } from '@contexts/collection/collectionReducer';
import { HOTKEYS_TOGGLE_RIGHT_SIDEBAR } from '@constants/hotkeys';
import DynamicBookmarkList from '../DynamicBookmarkList/DynamicBookmarkList';
import TabletHeader from './components/TabletHeader';
import MobileHeader from './components/MobileHeader';
import BookmarkPreview from './components/BookmarkPreview';

const APPBAR_MOBILE = 48;
const APPBAR_DESKTOP = 96;

const StyleBox = styled(Box)(({ theme, showDetail = false }) => ({
  height: '100%',
  [theme.breakpoints.up('sm')]: {
    ...(showDetail && {
      marginRight: '360px',
    })
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  // height: `calc(100vh - ${APPBAR_MOBILE}px)`,
  height: "100%",
  flex: 1,
  paddingTop: APPBAR_MOBILE,
  [theme.breakpoints.up('sm')]: {
    paddingTop: APPBAR_DESKTOP,
    // height: `calc(100vh - ${APPBAR_DESKTOP}px)`
  },
}));

export default () => {
  const containerRef = useRef();
  const theme = useTheme();
  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const location = useLocation();
  const { pathname } = location;
  const [scrollY, setScrollY] = useState(0);
  const drawerContentRef = useRef();

  const { state: collectionState } = useContext(CollectionContext);
  const { state: bookmarkState, dispatch: bookmarkDispatch } = useContext(BookmarkContext);

  useHotkeys(HOTKEYS_TOGGLE_RIGHT_SIDEBAR, () => bookmarkDispatch({ type: TOGGLE_PREVIEW_WINDOW }));

  const drawerProps = matchUpSm ? {
    variant: 'persistent',
    anchor: 'right',
    sx: {
      width: 360,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: 360,
      },
    }
  } : {
    variant: 'temporary',
    anchor: 'bottom',
  }

  useEffect(() => {
    if (matchUpSm) {
      return;
    }

    // fix: 修复iOS浏览器上，关闭弹窗自动滑动到顶部的Bug
    if (bookmarkState.showPreviewWindow) {
      setScrollY(window.scrollY);
    } else {
      window.scrollTo(0, scrollY === 0 ? -1 : scrollY);
    }
  }, [bookmarkState.showPreviewWindow]);

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: drawerContentRef.current || window,
  });

  return (
    <ContentStyle>
      {
        matchUpSm ? <TabletHeader /> : <MobileHeader />
      }
      <StyleBox ref={containerRef} showDetail={bookmarkState.showPreviewWindow}>
        {
          (bookmarkState.bookmarkList.edges.length === 0 && !bookmarkState.bookmarkList.pageInfo.hasNextPage) ? (
            <Typography variant="caption" component="div" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 56, color: 'text.secondary' }}>
              暂无书签
            </Typography>
          ) : (collectionState.collection?.loaded ? <DynamicBookmarkList /> : null)
        }
      </StyleBox>
      <SwipeableDrawer
        PaperProps={{
          ref: drawerContentRef,
          sx: {
            borderLeft: matchUpSm ? `1px solid ${theme.palette.divider}` : 0,
            backgroundColor: matchUpSm ? theme.palette.background.drawer : 'transparent',
            ...matchUpSm ? {} : { maxHeight: `calc(100% - 56px)` },
          }
        }}
        {...drawerProps}
        open={bookmarkState.showPreviewWindow}
        onClose={() => {
          bookmarkDispatch({ type: CLOSE_PREVIEW_WINDOW });
          bookmarkDispatch({ type: SET_SELECT_INDEX, data: -1 });
        }}
      >
        <BookmarkPreview mobile={!matchUpSm} showScrollBorder={scrollTrigger} />
      </SwipeableDrawer>
    </ContentStyle>
  )
}