import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { useMutation } from "@apollo/client";
import { REMOVE_COLLECTION_MEMBER, DELETE_COLLECTION } from '@apis/CollectionApi';

const StyledFolderLabel = styled('strong')(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

export default (props) => {
  const {
    open,
    onClose,
    onSuccess,
    collection,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [removeCollectionMember] = useMutation(REMOVE_COLLECTION_MEMBER);
  const [deleteCollection] = useMutation(DELETE_COLLECTION);

  const handleCancel = () => {
    onClose();
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        删除 {collection.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          是否确定要将自己从<StyledFolderLabel>{collection.name}</StyledFolderLabel>中移除？移除后你将无法再访问该收藏夹。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>取消</Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={async () => {
            setIsSubmitting(true);

            // await removeCollectionMember({
            //   variables: {
            //     input: {
            //       collectionId: collection.id,
            //     }
            //   },
            // });
            
            await deleteCollection({
              variables: {
                collectionDeleteId: collection.id,
              },
            });

            setIsSubmitting(false);
            onSuccess();
          }}
          loading={isSubmitting}
        >
          移除
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
