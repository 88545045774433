import { alpha, useTheme, styled } from '@mui/material/styles';

export default function InputBase(theme) {
  return {
    MuiInputBase: {
      defaultProps: {
      },
      styleOverrides: {
        root: {
          // fontSize: theme.typography.body2.fontSize,
          // '&.Mui-focused': {
          //   input: {
          //     borderColor: theme.palette.primary.main,
          //   },
          // }
          '&.Mui-focused .MuiSelect-select': {
            borderColor: theme.palette.primary.main,
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
          },
        },
        input: ({ ownerState }) => ({
          // border: `1px solid ${theme.palette.border`,
          // fontSize: theme.typography.body2.fontSize,
          // borderRadius: theme.spacing(1),
          // transition: theme.transitions.create([
          //   'border-color',
          //   'background-color',
          //   'box-shadow',
          // ]),
          // ...(ownerState.focused && {
          //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          // })
        }),
      }
    }
  };
}
