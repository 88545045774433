import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function StarFilled(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10.286 3.847c.778-1.291 2.65-1.291 3.428 0l1.47 2.443a2 2 0 0 0 1.262.918l2.778.643c1.469.34 2.047 2.121 1.06 3.26l-1.87 2.153a2 2 0 0 0-.482 1.484l.246 2.84c.13 1.502-1.384 2.603-2.772 2.015L12.78 18.49a2 2 0 0 0-1.56 0l-2.626 1.113c-1.388.588-2.903-.513-2.772-2.015l.246-2.84a2 2 0 0 0-.482-1.484l-1.87-2.153c-.987-1.139-.409-2.92 1.06-3.26l2.778-.643a2 2 0 0 0 1.262-.918l1.47-2.443Z"/>
    </SvgIcon>
  );
}

export default StarFilled;
