import { gql } from '@apollo/client';

// 获取当前用户配置
export const GET_USER_SETTINGS = gql`
  query GetUserSettings {
    userSettings {
      defaultView
      theme
      autoDarkTheme
    }
  }
`;


// 更新用户配置
export const UPDATE_USER_SETTINGS = gql`
  mutation UserSettingsUpdate($userSettings: UserSettingsUpdateInput!) {
    userSettingsUpdate(userSettings: $userSettings) {
      defaultView
      theme
      autoDarkTheme
    }
  }
`;
