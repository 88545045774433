import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function StarOutlined(props) {
  return (
    <SvgIcon {...props}>
      <path fill-rule="evenodd" d="M9.644 3.46c1.069-1.775 3.643-1.775 4.712 0l1.47 2.444c.175.29.46.497.79.573l2.777.643c2.02.468 2.815 2.917 1.456 4.482l-1.869 2.153a1.25 1.25 0 0 0-.301.928l.247 2.84c.179 2.066-1.904 3.579-3.813 2.77l-2.625-1.112a1.25 1.25 0 0 0-.976 0l-2.625 1.112c-1.909.809-3.992-.704-3.812-2.77l.246-2.84a1.25 1.25 0 0 0-.301-.928l-1.87-2.153c-1.358-1.565-.563-4.014 1.457-4.482l2.778-.643a1.25 1.25 0 0 0 .789-.573l1.47-2.443Zm3.427.774a1.25 1.25 0 0 0-2.142 0l-1.47 2.443a2.75 2.75 0 0 1-1.736 1.261l-2.778.644a1.25 1.25 0 0 0-.662 2.037l1.87 2.153a2.75 2.75 0 0 1 .662 2.04l-.246 2.841a1.25 1.25 0 0 0 1.733 1.26l2.625-1.113a2.75 2.75 0 0 1 2.146 0l2.625 1.112a1.25 1.25 0 0 0 1.733-1.259l-.246-2.84a2.75 2.75 0 0 1 .663-2.041l1.869-2.153a1.25 1.25 0 0 0-.662-2.037l-2.778-.644a2.75 2.75 0 0 1-1.736-1.26l-1.47-2.444Z" clip-rule="evenodd"/>
    </SvgIcon>
  );
}

export default StarOutlined;
