import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function NoteOutlined(props) {
  return (
    <SvgIcon {...props}>
      <path d="M15,2.25 C17.8994949,2.25 20.25,4.60050506 20.25,7.5 L20.25,16.5 C20.25,19.3994949 17.8994949,21.75 15,21.75 L9,21.75 C6.10050506,21.75 3.75,19.3994949 3.75,16.5 L3.75,9.25 C3.77678513,7.8550455 10.2848376,2.25 11.7363384,2.25 L15,2.25 Z M15,3.75 L13.25,3.75 L13.2497996,5.5173366 L13.244298,5.74655429 C13.1147899,8.54122273 10.8075477,10.75 8,10.75 L5.25,10.75 L5.25,16.5 C5.25,18.5042592 6.82236105,20.1412737 8.80084143,20.244802 L9,20.25 L15,20.25 C17.0710678,20.25 18.75,18.5710678 18.75,16.5 L18.75,7.5 C18.75,5.42893219 17.0710678,3.75 15,3.75 Z M16,16.25 C16.4142136,16.25 16.75,16.5857864 16.75,17 C16.75,17.4142136 16.4142136,17.75 16,17.75 L8,17.75 C7.58578644,17.75 7.25,17.4142136 7.25,17 C7.25,16.5857864 7.58578644,16.25 8,16.25 L16,16.25 Z M12,13.25 C12.4142136,13.25 12.75,13.5857864 12.75,14 C12.75,14.4142136 12.4142136,14.75 12,14.75 L8,14.75 C7.58578644,14.75 7.25,14.4142136 7.25,14 C7.25,13.5857864 7.58578644,13.25 8,13.25 L12,13.25 Z M11.75,5.5 L11.75,3.75 L11.7363384,3.75 C11.5430173,3.75 10.0905071,4.76288642 8.57799032,6.03169188 L8.21444814,6.34073127 C7.36893965,7.06925696 6.58982617,7.80028494 6.02843479,8.39465203 C5.79030885,8.64676531 5.59632384,8.86907323 5.45420488,9.05218426 L5.37521924,9.15727212 C5.3488303,9.19368645 5.32691012,9.2261446 5.30918152,9.2510325 L8,9.25 C10.0052231,9.25 11.6534559,7.67210797 11.7453024,5.69449911 L11.75,5.5 Z" />
    </SvgIcon>
  );
}

export default NoteOutlined;
