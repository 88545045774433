import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function BookmarksFilled(props) {
  return (
    <SvgIcon {...props}>
      <path d="M11.75,5.25 C14.3734,5.25 16.5,7.37665 16.5,10 L16.5,19.2327 C16.5,20.057 16.0393,20.8122 15.3064,21.1895 C14.4658,21.6221 13.4429,21.4696 12.765,20.8106 L10.2542,18.3695 C10.1989,18.3158 10.1355,18.2713 10.0662,18.2376 C9.94524039,18.1788259 9.81197799,18.1556641 9.68131956,18.1681147 C9.5963412,18.1762124 9.51246433,18.1993741 9.43379,18.2376 C9.36451,18.2713 9.30106,18.3158 9.24582,18.3695 L6.73497,20.8106 C6.05714,21.4696 5.03419,21.6221 4.19363,21.1895 C3.46067,20.8122 3,20.057 3,19.2327 L3,10 C3,7.53900941 4.87155871,5.51513974 7.26901584,5.27405126 L7.52955559,5.25502472 L7.63946862,5.25126073 L11.75,5.25 Z" />
      <path d="M16,2 C18.6234,2 20.75,4.12665 20.75,6.75 L20.75,15.9827 C20.75,16.807 20.2893,17.5622 19.5564,17.9395 C19.0646348,18.1925783 18.5104785,18.2454092 17.9995064,18.1137299 L17.8042989,18.0524176 C17.8584102,18.0715525 17.9132151,18.0886098 17.9685993,18.1034728 C17.989311,17.9822126 18,17.8582579 18,17.7327 L18,8.5 C18,5.87665 15.8734,3.75 13.25,3.75 L9.25,3.75 C8.89959324,3.75 8.55804837,3.78794272 8.22908857,3.86122628 L8.23561533,3.85274658 C9.10420198,2.72590716 10.4673296,2 12,2 L16,2 Z" />
    </SvgIcon>
  );
}

export default BookmarksFilled;
