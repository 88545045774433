import React, { useReducer } from 'react';
import { Snackbar, IconButton, Slide, Grow } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarContext, SnackbarReducer } from './snackbarReducer';
import * as SnackbarActionTypes from './SnackbarActionTypes';
import initialState from './snackbarInitialState';

const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    // "& .MuiSnackbar-root": {
    //   bottom: theme.spacing(15),
    // },
  })
);

const SnackbarProvider = (props) => {
  const theme = useTheme();

  const [ state, dispatch ] = useReducer(SnackbarReducer, initialState);
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <SnackbarContext.Provider value={{ state, dispatch }}>
      { props.children }
      <StyledSnackbar
        open={state.open}
        autoHideDuration={2000}
        disableWindowBlurListener={true}
        TransitionComponent={matchDownSm ? Grow : Slide}
        onClose={() => { dispatch({ type: SnackbarActionTypes.HIDE_SNACKBAR }) }}
        message={state.message}
        action={(
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => { dispatch({ type: SnackbarActionTypes.HIDE_SNACKBAR }) }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        )}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider;
