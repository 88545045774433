import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function FlowerIcon1(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10.987417,3.57654208 C13.3721594,2.24637449 16.4173695,2.33410823 18.7688691,4.13573357 C21.5118652,6.23730797 20.7745006,10.2402752 17.9851979,12.9112608 C17.0516629,13.805197 15.9063825,14.5850004 14.5387746,15.3104853 L14.2513057,15.4575921 C12.8204385,16.1693728 9.65062606,17.5096712 9.3599751,17.7054131 C8.94059813,17.987847 8.52720271,18.3627625 8.08339339,18.8454398 L7.8252751,19.1339126 C7.736639,19.2354668 7.6439019,19.3440435 7.54255084,19.4645196 L7.10112609,19.9923863 L6.79219641,20.3478353 C6.50963332,20.6587074 6.30499767,20.830814 6.06023894,20.9415447 C5.49732263,21.1962122 5.00081149,20.9091934 4.79467658,20.4231691 L4.67526093,20.0892202 C3.18477936,15.5446526 6.2986193,6.19187143 10.987417,3.57654208 Z M17.8566016,5.32643424 C16.0017345,3.90530891 13.6012584,3.83615022 11.7181096,4.88653763 C8.34561626,6.76765543 5.79715214,13.0830851 5.79824275,17.3864008 C6.22944384,16.2579909 6.72559251,15.2584569 7.28646756,14.386443 C8.16306218,13.0235679 9.70779892,11.1808754 11.925829,8.84755713 C12.1160841,8.64741309 12.432565,8.63939646 12.6327091,8.82965148 C12.8328531,9.0199065 12.8408698,9.33638748 12.6506147,9.53653152 L12.01719,10.2092555 L11.3902844,10.8893697 C9.87933824,12.5482165 8.7908891,13.8960314 8.12751671,14.9274018 C7.5034147,15.897717 6.96021175,17.0481799 6.49914142,18.3789711 L6.69773217,18.1446275 L6.97920761,17.8301695 C7.49608848,17.2680212 7.99212142,16.8181602 8.52208208,16.4612526 C8.65080801,16.3745608 9.03905888,16.187679 9.55553012,15.9506335 L12.1418659,14.7867164 C12.6900418,14.5387703 13.1929646,14.3070285 13.5584149,14.126626 L13.8358405,13.9853877 C15.0906705,13.3197289 16.1259608,12.614816 16.9477643,11.8278719 C19.1603445,9.70914528 19.7065729,6.74380856 17.8566016,5.32643424 Z" />
    </SvgIcon>
  );
}

export default FlowerIcon1;
  