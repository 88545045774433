import React, { useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { UPDATE_COLLECTION, GET_COLLECTIONS } from '@apis/CollectionApi';
import { useEffect } from 'react';
import InputFormControl from '../InputFormControl';

const StyledDialogTitle = styled(DialogTitle)(
  ({ theme }) => `
    font-size: ${theme.typography.h4.fontSize};
  `,
);

export default (props) => {
  const {
    open,
    onClose,
    onSuccess,
    collection,
  } = props;

  const [updateCollection] = useMutation(UPDATE_COLLECTION);
  // const [getCollections] = useLazyQuery(GET_COLLECTIONS, { fetchPolicy: 'network-only' });

  const formik = useFormik({
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required('请输入名称').max(100, '名称不能超过100个字'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      await updateCollection({
        variables: {
          id: collection.id,
          input: values,
        },
      });
      // getCollections();  // 重新拉取一遍收藏夹
      // onClose();
      setSubmitting(false);
      onSuccess();
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleCancel = () => {
    onClose();
  }

  useEffect(() => {
    resetForm();
    formik.setValues({
      name: collection.name,
    })
  }, [collection]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="xs"
      fullWidth
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <StyledDialogTitle>
            修改 {collection.name}
            <IconButton
              aria-label="close"
              onClick={handleCancel}
              size="small"
              sx={{
                position: 'absolute',
                right: 14,
                top: 15,
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          <DialogContent>
            <InputFormControl
              fullWidth
              autoFocus
              margin="dense"
              type="text"
              // label="名称"
              placeholder="名称"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>取消</Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              loadingPosition="start"
            >
              保存
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  )
}
