import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function UnPinOutlined(props) {
  return (
    <SvgIcon {...props}>
      <path fill-rule="evenodd" d="M17.48 3.691h-.001l-.176-.176-.006.006a3.483 3.483 0 0 0-5.266.841l-.33.55-.421.702a.75.75 0 1 0 1.286.772l.421-.702.33-.55a1.983 1.983 0 0 1 3.102-.383v.001l.354.354 2.121 2.121.354.354a1.983 1.983 0 0 1-.382 3.102l-1.252.75a.75.75 0 1 0 .772 1.287l1.252-.751a3.483 3.483 0 0 0 .841-5.266l.006-.006-.177-.177-.353-.354-2.121-2.121-.354-.354ZM7.52 8.47a.75.75 0 1 0 .475-1.423L7.68 6.94a2.82 2.82 0 0 0-3.485 1.565l-.038.09a2.564 2.564 0 0 0 .543 2.823l.401.4.004.004 3.005 3.005-3.359 3.36a.75.75 0 1 0 1.06 1.06l3.36-3.359 3.005 3.005.002.002.402.403a2.564 2.564 0 0 0 2.823.543l.09-.038a2.82 2.82 0 0 0 1.565-3.485l-.105-.313a.75.75 0 0 0-1.423.474l.105.314a1.32 1.32 0 0 1-.733 1.631l-.09.039a1.065 1.065 0 0 1-1.171-.226l-.404-.404-.002-.002L9.704 14.3l-.003-.003-.003-.003-3.53-3.53-.003-.002-.404-.405a1.064 1.064 0 0 1-.226-1.171l.039-.09c.27-.631.98-.95 1.631-.733l.314.105Zm-.49-5.5a.75.75 0 1 0-1.06 1.06l14 14a.75.75 0 1 0 1.06-1.06l-14-14Z" clip-rule="evenodd"/>
    </SvgIcon>
  );
}

export default UnPinOutlined;
