import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery, SwipeableDrawer } from '@mui/material';
import SidebarContent from './SidebarContent';
import * as events from '@constants/events';

const WIDTH = 260;

const iOS =
  typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

Sidebar.propTypes = {
  open: PropTypes.bool,
  onToggle: PropTypes.func
};

export default function Sidebar({ open, onToggle, onClose }) {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const containerRef = useRef();

  const handleCloseSidebar = () => {
    onClose && onClose();
  }

  useEffect(() => {
    window.addEventListener(events.CLOSE_SIDEBAR, handleCloseSidebar);

    return () => {
      window.removeEventListener(events.CLOSE_SIDEBAR, handleCloseSidebar);
    }
  }, []);

  return (
    <Box
      // sx={{ width: matchUpMd ? WIDTH : 'auto' }}
      ref={containerRef}
    >
      <SwipeableDrawer
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={open}
        onClose={onToggle}
        onOpen={onToggle}
        ModalProps={{ keepMounted: true }}
        color="inherit"
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        sx={{
          // zIndex: 1202,
          width: open ? WIDTH : 'auto',
        }}
        PaperProps={{
          sx: {
            borderRight: `1px solid ${theme.palette.divider}`,
            width: WIDTH,
            backgroundColor: theme.palette.background.drawer,
            // background: 'radial-gradient(circle at 120% 100%,  rgb(247, 239, 242) 0%,  rgb(238, 243, 247) 50%)',
            // backdropFilter: 'blur(64px)',
            // WebkitBackdropFilter: 'blur(64px)',
            // borderRight: 'none',
            // [theme.breakpoints.up('md')]: {
            //   top: 64,
            // }
          }
        }}
      >
        <SidebarContent />
      </SwipeableDrawer>
    </Box>
  )
}
