import React, { useState, forwardRef, useContext, useEffect, isValidElement } from 'react';
import { produce } from 'immer';
import { useTheme, styled } from '@mui/material/styles';
import { List, Typography, Stack, Tooltip, Collapse } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MoreHorizOutlinedIcon from '@icons/MoreHorizOutlined';
import { CollectionListContext } from '@contexts/collectionList/collectionListReducer';
import { SHOW_ACTION_MENU } from '@contexts/collectionList/CollectionListActionTypes';
import LoadingPanel from '@components/LoadingPanel';
import NavMenuItem from './NavMenuItem';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const StyledToggleCollapseIcon = styled(ExpandLessIcon)(({ collapse }) => ({
  transform: collapse ? 'rotate(0eg)' : 'rotate(180deg)',
  transition: 'transform 0.4s',
}));

export default forwardRef(({ data, sortable = false, sx, collapsed, onExpand, onItemClick, onCollapsePanel, type = 'collection' }) => {
  const theme = useTheme();
  const { dispatch: collectionListDispatch } = useContext(CollectionListContext);
  const [menuData, setMenuData] = useState(data.children);

  useEffect(() => {
    setMenuData(data.children);
  }, [data.children]);

  // const ACTION_MENU = [
  //   {
  //     name: '创建收藏夹',
  //     icon: plusOutline,
  //     action: ACTION_ADD_COLLECTION,
  //   },
  // ];

  // const findItemByPath = (path) => {
  //   let res = menuData[path[0]];
  //   for (let i = 1; i < path.length; i++) {
  //     res = res.children[path[i]];
  //   }

  //   return res;
  // }

  const handleMove = (action, sourcePath, targetPath) => {
    // console.log('[handleMove]', action, sourcePath, targetPath);
    const newData = produce(menuData, draftState => {
      const sourceDeep = [...sourcePath];
      const targetDeep = [...targetPath];

      const sourceIndex = sourceDeep.pop();
      const targetIndex = targetDeep.pop();

      // if (sourceDeep.toString() === targetDeep.toString() && sourceIndex !== targetIndex) { // 同层级移动
        // console.log('[同层级移动]', targetDeep);
        let parentChildren = draftState;

        // console.log('[targetDeep]', targetDeep);
        for (let i = 0; i < targetDeep.length; i++) {
          parentChildren = parentChildren[targetDeep[i]].children;
        }

        for (let i = 0; i < parentChildren.length; i++) {
          parentChildren[i].hoverBefore = false;
          parentChildren[i].hoverAfter = false;
          parentChildren[i].hoverIn = false;
        }
  
        if (action === 'before') {
          parentChildren[targetIndex].hoverBefore = true;
        } else if (action === 'after') {
          parentChildren[targetIndex].hoverAfter = true;
        } else if (action === 'in') {
          parentChildren[targetIndex].hoverIn = true;
        }
      // }
    });

    // console.log('[newData]', newData);
    // const newData = produce(menuData, draftState => {

    //   const sourceDeep = [...sourcePath];
    //   const targetDeep = [...targetPath];

    //   const sourceIndex = sourceDeep.pop();
    //   const targetIndex = targetDeep.pop();

    //   const isRoot = sourceDeep.length === 0;

    //   if (sourceDeep.toString() === targetDeep.toString() && sourceIndex !== targetIndex) { // 同层级移动

    //     // 子节点集合
    //     let childrenItems = draftState;

    //     // 查找父节点
    //     if (!isRoot) {
    //       let parentItem = draftState[sourceDeep[0]];
    //       if (!isRoot) {
    //         for (let i = 1; i < sourceDeep.length; i++) {
    //           parentItem = parentItem.children[sourceDeep[i]];
    //         }
    //       }
    //       childrenItems = parentItem.children;
    //     }

    //     // 待移动的节点
    //     const moveItem = childrenItems[sourceIndex];

    //     if (sourceIndex < targetIndex) {
    //       for (let i = sourceIndex; i < targetIndex; i++) {
    //         childrenItems[i] = {
    //           ...childrenItems[i + 1],
    //           idx: i,
    //         }
    //       }
    //     } else {
    //       for (let i = sourceIndex; i > targetIndex; i--) {
    //         childrenItems[i] = {
    //           ...childrenItems[i - 1],
    //           idx: i,
    //         }
    //       }
    //     }

    //     childrenItems[targetIndex] = {
    //       ...moveItem,
    //       idx: targetIndex,
    //     }
    //   }
    // });
    // console.log('[newData]', newData);
    setMenuData(newData);
  }

  // console.log('[menuData]', menuData);

  const collapseActions = data.action?.filter(({ collapse }) => !!collapse);
  const actions = data.action?.filter(({ collapse }) => !collapse);
  
  return (
    <List
      disablePadding
      sx={{
        pb: 2,
        ...sx,
      }}
      subheader={
        data.name && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 0.5 }}
          >
            {
              isValidElement(data.name) ? data.name : (
                <Typography
                  display="block"
                  variant="body2"
                  sx={{
                    color: theme.palette.text.secondary,
                  }}
                >
                  {data.name}
                </Typography>
              )
            }
            <Stack direction="row" spacing={.5}>
              {
                (actions || []).map((actionItem) => (
                  <Tooltip title={actionItem.name}>
                    <IconButton
                      aria-label="more"
                      size="small"
                      // sx={{ fontSize: '1rem' }}
                      // disableRipple
                      onClick={(evt) => {
                        actionItem.action();
                      }}
                    >
                      <actionItem.icon sx={{ fontSize: 'inherit', color: theme.palette.text.secondary }} />
                    </IconButton>
                  </Tooltip>
                ))
              }
              {
                collapseActions && collapseActions.length > 0 && (
                  <IconButton
                    aria-label="more"
                    size="small"
                    disableRipple
                    onClick={(evt) => {
                      evt.stopPropagation();
                      collectionListDispatch({
                        type: SHOW_ACTION_MENU,
                        data: {
                          menu: collapseActions,
                          element: evt.currentTarget,
                        }
                      });
                    }}
                  >
                    <MoreHorizOutlinedIcon sx={{ fontSize: theme.typography.h4.fontSize, color: theme.palette.text.secondary }} />
                  </IconButton>
                )
              }
              <IconButton
                aria-label={collapsed ? 'expand' : 'collapse'}
                size="small"
                onClick={() => {
                  onCollapsePanel && onCollapsePanel();
                }}
              >
                <StyledToggleCollapseIcon collapse={!collapsed} sx={{ fontSize: 'inherit', color: theme.palette.text.secondary }} />
              </IconButton>
            </Stack>
          </Stack>
        )}
    >
      <Collapse in={!collapsed} timeout="auto">
        {
          !menuData
          ? <LoadingPanel height={72} size={16} />
          : menuData.length === 0 ? (
            <Typography variant="caption" component="div" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 56, color: 'text.secondary' }}>
              暂无收藏夹
            </Typography>
          ) : menuData?.map((i, index) => (
            <NavMenuItem
              type={type}
              key={i.id}
              sortable={sortable}
              data={{
                ...i,
                xpath: [index],
              }}
              level={1}
              onExpand={onExpand}
              onMove={handleMove}
              onItemClick={onItemClick}
              hoverBefore={i.hoverBefore}
              hoverAfter={i.hoverAfter}
              hoverIn={i.hoverIn}
            />
          ))
        }
      </Collapse>
    </List>
  )
});
