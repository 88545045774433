import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function FlowerIcon4(props) {
  return (
    <SvgIcon {...props}>
      <path d="M11.9771414,1.27137399 C8.44785229,1.27137399 5.85641241,4.06804339 5.24272701,7.90979329 C4.61630029,11.8313055 6.67229864,17.7178088 9.47797611,21.0496542 C10.3982164,22.1424735 11.3680273,22.771374 11.9988894,22.771374 C12.6237958,22.771374 13.564646,22.146549 14.4878101,21.0494447 C17.3006798,17.706581 19.3760508,11.8237394 18.7587752,7.91121798 C18.1534625,4.0745225 15.5194892,1.27137399 11.9771414,1.27137399 Z" />
    </SvgIcon>
  );
}

export default FlowerIcon4;
  