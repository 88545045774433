import React from 'react';
import collectionInitialState from './collectionInitialState';
import * as CollectionActionTypes from './CollectionActionTypes';

export const CollectionReducer = (state = collectionInitialState, action) => {
  switch(action.type) {
    case CollectionActionTypes.SET_COLLECTION:
      return {
        ...state,
        collection: action.data,
      }
    case CollectionActionTypes.UPDATE_COLLECTION:
      return {
        ...state,
        collection: {
          ...state.collection,
          ...action.data,
        }
      }
    default:
      return state;
  }
}

export const CollectionContext = React.createContext(collectionInitialState);
