import React from "react";
import { styled } from "@mui/material/styles";
import { Container, Stack, Typography, Box, Link, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Page from "@components/Page";
import DoneIcon from '@mui/icons-material/Done';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 360,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  // justifyContent: 'center',
  paddingTop: theme.spacing(12),
}));

const ComingSoon = () => {
  const theme = useTheme();

  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const handleRedirectHome = (evt) => {
    evt.preventDefault();

    localStorage.removeItem('token');
    location.replace('/logout?redirect=/');
  }

  return (
    <RootStyle title="登录 - 奇普书签">
      <Container maxWidh="sm">
        <ContentStyle>
          <Stack sx={{ mb: 6, alignItems: 'center' }}>
            <Link href="/" onClick={handleRedirectHome}>
              <img
                src="//assets.kiipu.com/img/logo.png?v=230723"
                style={{ width: matchUpSm ? 160 : 120, marginBottom: matchUpSm ? 64 : 32, zIndex: 1 }}
              />
            </Link>
          </Stack>
          <Stack spacing={2}>
            <Typography
              variant={matchUpSm ? "h3" : "h4"}
              sx={{ fontWeight: 'normal', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <DoneIcon sx={{ color: theme.palette.success.main, mr: 1 }} />
              你已加入候选名单！
            </Typography>
            <Typography variant="body" color="text.secondary" sx={{ textAlign: 'center' }}>很快，我们将会给你发送邀请邮件</Typography>
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default ComingSoon;
