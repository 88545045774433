import React, { useContext, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { useMutation } from "@apollo/client";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { PARTIAL_UPDATE_BOOKMARK } from '@apis/BookmarkApi';
import { useEffect } from 'react';
import InputFormControl from '@components/InputFormControl';

const StyledDialogTitle = styled(DialogTitle)(
  ({ theme }) => `
    font-size: ${theme.typography.h4.fontSize};
  `,
);

export default (props) => {
  const {
    open,
    onClose,
    onSuccess,
    data: bookmark,
  } = props;

  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [partialUpdateBookmark] = useMutation(PARTIAL_UPDATE_BOOKMARK);
  // const [getCollections] = useLazyQuery(GET_COLLECTIONS, { fetchPolicy: 'network-only' });

  const formik = useFormik({
    validateOnBlur: validateAfterSubmit,
    validationSchema: Yup.object().shape({
      title: Yup.string().trim().required('请输入标题').max(200, '标题不能超过200个字'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setValidateAfterSubmit(false);
      const { data: { bookmarkPartialUpdate: { bookmark: updatedBookmark } } } = await partialUpdateBookmark({
        variables: {
          id: bookmark.id,
          input: values,
        },
      });
      setSubmitting(false);
      onSuccess({
        title: updatedBookmark.title,
        srcTitle: updatedBookmark.srcTitle,
      });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleCancel = () => {
    onClose();
  }

  useEffect(() => {
    if (!bookmark) return;

    resetForm();
    setValidateAfterSubmit(false);
    formik.setValues({
      title: bookmark.title,
    })
  }, [bookmark]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="xs"
      fullWidth
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <StyledDialogTitle>
            修改基本信息
          </StyledDialogTitle>
          <DialogContent>
            <InputFormControl
              fullWidth
              autoFocus
              margin="dense"
              type="text"
              label="标题"
              placeholder="标题"
              {...getFieldProps('title')}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>取消</Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              loadingPosition="start"
              onClick={() => {
                setValidateAfterSubmit(true);
              }}
            >
              保存
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  )
}
