import React, { useReducer, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LayoutContext, LayoutReducer } from './layoutReducer';
import * as LayoutActionTypes from './LayoutActionTypes';
import initialState from './layoutInitialState';
import { LAYOUT_SIZE, LAYOUT_TYPE } from '@constants/common';
import { HOTKEYS_TOGGLE_LEFT_SIDEBAR } from '@constants/hotkeys';
import { useHotkeys } from 'react-hotkeys-hook';
import { TOGGLE_SIDEBAR } from '@contexts/layout/LayoutActionTypes';

export default function LayoutProvider(props) {
  const {
    type,
    children,
  } = props;

  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  let initialSize;
  if (matchDownSm) {
    initialSize = LAYOUT_SIZE.SMALL;
  } else if (matchDownMd) {
    initialSize = LAYOUT_SIZE.MEDIUM;
  } else {
    initialSize = LAYOUT_SIZE.LARGE;
  }

  const [ state, dispatch ] = useReducer(LayoutReducer, {
    ...initialState,
    showSidebar: initialSize === LAYOUT_SIZE.LARGE, // 大屏默认显示侧边栏
    mobile: matchDownSm,
    size: initialSize,
    type: type || LAYOUT_TYPE.TABLET,
  });

  useHotkeys(HOTKEYS_TOGGLE_LEFT_SIDEBAR, () => dispatch({ type: TOGGLE_SIDEBAR }));

  useEffect(() => {
    dispatch({ type: LayoutActionTypes.SET_MOBILE, data: matchDownSm });
  }, [matchDownSm]);

  useEffect(() => {
    if (matchDownSm) {
      dispatch({ type: LayoutActionTypes.SET_LAYOUT_SIZE, data: LAYOUT_SIZE.SMALL });
    } else if (matchDownMd) {
      dispatch({ type: LayoutActionTypes.SET_LAYOUT_SIZE, data: LAYOUT_SIZE.MEDIUM });
    } else {
      dispatch({ type: LayoutActionTypes.SET_LAYOUT_SIZE, data: LAYOUT_SIZE.LARGE });
    }
  }, [matchDownMd, matchDownSm])

  return (
    <LayoutContext.Provider value={{ state, dispatch }}>
      { children }
    </LayoutContext.Provider>
  )
}
