import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function FlowerIcon8(props) {
  return (
    <SvgIcon {...props}>
      <path d="M15.1829564,3.25 C12.3326469,3.25 9.51680295,4.00369355 7.71014952,5.47749883 C5.99265445,6.87857181 4.87976413,9.88642187 4.37777063,13.2128994 C4.29717477,13.7469707 4.05284253,14.1132154 3.60512209,14.4762157 L3.06031017,14.879511 C2.90628471,14.9946621 2.76348086,15.1048207 2.69828334,15.1659619 C2.18256354,15.6495962 2.08260337,16.2859557 2.54168933,16.8953634 C4.23365356,19.1413394 7.33404807,20.2596379 10.8809358,20.2596379 C14.2874531,20.2596379 17.5876136,18.9937446 19.8005611,16.4953615 C21.5879753,14.4773989 22.0548695,12.4322098 21.5702441,8.68109481 C21.4558236,7.79545401 20.7440941,7.66538901 19.8957525,7.87940671 C19.5203447,7.97411375 19.0945803,8.13568615 18.4867227,8.40109238 L17.474,8.854 L17.596936,8.65722588 L18.6695011,7.01440708 C19.0304127,6.45091236 19.1806496,6.17331295 19.306995,5.8507548 C19.7028022,4.8402648 18.9723776,4.14819525 17.7664322,3.71208743 C16.9807046,3.42794362 15.9456747,3.25 15.1829564,3.25 Z" />
    </SvgIcon>
  );
}

export default FlowerIcon8;
  