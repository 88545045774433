import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useFormik, Form, FormikProvider } from 'formik';
import { SHARE_COLLECTION, GET_COLLECTION_SHARE_LIST, REMOVE_COLLECTION_MEMBER, UPDATE_COLLECTION_MEMBER } from '@apis/CollectionApi';
import { SnackbarContext } from '@contexts/snackbar/snackbarReducer';
import InputFormControl from '@components/InputFormControl';
import CollectionMemberList from '@components/CollectionMemberList';
import * as Yup from 'yup';
import * as collectionRole from '@constants/collectionRole';

export default (props) => {
  const {
    onSuccess,
    collection,
  } = props;

  const navigate = useNavigate();
  const [shareCollection] = useMutation(SHARE_COLLECTION);
  const [removeCollectionMember] = useMutation(REMOVE_COLLECTION_MEMBER);
  const [updateCollectionMember] = useMutation(UPDATE_COLLECTION_MEMBER);
  const [getCollectionMembers] = useLazyQuery(GET_COLLECTION_SHARE_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      id: collection.id,
    },
  });

  const [members, setMembers] = useState(null);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('请输入正确的电子邮箱地址').required('请输入电子邮箱地址'),
    }),
    validateOnBlur: validateAfterSubmit,
    validateOnChange: validateAfterSubmit,
    onSubmit: async ({ email }, { setSubmitting }) => {
      await shareCollection({
        variables: {
          input: {
            email,
            collectionId: collection.id,
          }
        },
      })

      resetForm();
      setValidateAfterSubmit(false);
      
      fetchMembersData();
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setFieldValue } = formik;

  const fetchMembersData = () => {
    getCollectionMembers({
      onCompleted: ({ collection: { members } }) => {
        setMembers(members);
      },
    });
  }

  useEffect(() => {
    resetForm();
    setValidateAfterSubmit(false);
    setMembers(null);
    fetchMembersData();
  }, []);

  return (
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <Stack
            divider={<Divider />}
            spacing={2}
          >
            {
              collectionRole.VIEWER !== collection.role ? (
                <Stack
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  sx={{ px: 2.5 }}
                >
                  <InputFormControl
                    type="username"
                    placeholder="电子邮件地址"
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    {...getFieldProps('email')}
                  />
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={isSubmitting}
                    loadingPosition="start"
                    onClick={() => {
                      setValidateAfterSubmit(true);
                    }}
                  >
                    邀请
                  </LoadingButton>
                </Stack>
              ) : null
            }
            <Stack sx={{ px: 1.5 }}>
              {
                collection?.id ? (
                  <CollectionMemberList
                    data={members}
                    collection={collection}
                    onRemove={async (email, exist) => {
                      await removeCollectionMember({
                        variables: {
                          input: {
                            email,
                            collectionId: collection.id,
                          }
                        },
                      });

                      if (exist) {
                        onSuccess();
                        navigate('/inbox');
                      } else {
                        fetchMembersData();
                      }
                    }}
                    onUpdate={async (email, role) => {
                      await updateCollectionMember({
                        variables: {
                          input: {
                            email,
                            collectionId: collection.id,
                            role,
                          }
                        },
                      });

                      fetchMembersData();
                    }}
                  />
                ) : null
              }
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
  )
}