import React, { useContext, useEffect, useRef, useCallback } from "react";
import { Box, IconButton, Link, useMediaQuery, Stack, CircularProgress, Button, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { styled, useTheme, alpha } from "@mui/material/styles";
import { BookmarkContext } from "@contexts/bookmark/bookmarkReducer";
import { useHotkeys } from "react-hotkeys-hook";
import {
  CLOSE_PREVIEW_WINDOW,
  SET_PREVIEW_TAB,
  OPERATION_UPDATE_BOOKMARK,
} from "@contexts/bookmark/BookmarkActionTypes";
import SidebarRightOutlined from "@icons/SidebarRightOutlined";
import OpenInNewOutlinedIcon from "@icons/OpenInNewOutlined";
import KeycapTooltip from "@components/KeycapTooltip";
import {
  HOTKEYS_OPEN_LINK,
  HOTKEYS_TOGGLE_RIGHT_SIDEBAR,
  HOTKEYS_OPEN_NOTE,
} from "@constants/hotkeys";
import InboxOutlinedIcon from "@icons/InboxOutlined";
import BookmarksOutlinedIcon from "@icons/BookmarksOutlined";
import BookmarkOutlinedIcon from "@icons/BookmarkOutlined";
import StarOutlinedIcon from "@icons/StarOutlined";
import TrashOutlinedIcon from "@icons/TrashOutlined";
import { getFaviconUrlByHost } from "@utils/helper";
import BookmarkSummary from "../BookmarkSummary";
import BookmarkNote from "../BookmarkNote";
import BookmarkActionBar from "../BookmarkActionBar";
import BookmarkMetadata from "../BookmarkMetadata";

const TABS = {
  SUMMARY: 0,
  NOTE: 1,
}

const Header = styled((props) => <Box component="header" {...props} />)(({ theme, showBorder }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'sticky',
  top: 0,
  zIndex: 10,
  backgroundColor: theme.palette.background.drawer,
  height: theme.spacing(7),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  flexShrink: 0,
  borderBottom: `1px solid ${showBorder ? theme.palette.divider : 'transparent'}`,
}));

const StyledBookmarkIcon = styled("div")(({ theme }) => ({
  width: "20px",
  backgroundPosition: "0 center",
  backgroundSize: "20px auto",
  backgroundRepeat: "no-repeat",
  height: "20px",
}));

const StyledTabButton = styled(props => <Button component="div" {...props} />)(({ theme, selected }) => ({
  color: theme.palette.text.primary,
  backgroundColor: 'transparent',
  height: '28px',
  fontWeight: 400,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  transition: theme.transitions.create(['background-color']),
  ...(selected && {
    backgroundColor: theme.palette.action.selected,
    fontWeight: 700,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    }
  }),
}));

export default (props) => {
  const {
    showScrollBorder
  } = props;

  const theme = useTheme();
  const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  // const [tabValue, setTabValue] = useState(0);
  const { state: bookmarkState, dispatch: bookmarkDispatch } =
    useContext(BookmarkContext);

  const bookmarkData =
    bookmarkState.bookmarkList.edges[bookmarkState.selectIndex];

  const prevBookmarkId = useRef();

  // const handleTabChange = (evt, newValue) => {
  //   // setTabValue(newValue);
  //   bookmarkDispatch({ type: SET_PREVIEW_TAB, data: newValue });
  // };

  const handleTabButtonClick = (value) => {
    bookmarkDispatch({ type: SET_PREVIEW_TAB, data: value });
  };

  const redirectToSearch = () => {
    let collectionPrefix = `/collection`;
    if (
      ["inbox", "all", "trash", "starred"].indexOf(bookmarkState.collection) >
      -1
    ) {
      collectionPrefix = "";
    }

    // 如果当前搜索范围是所有书签，则跳转到所有书签的页面显示搜索结果
    return `${
      bookmarkState.keywords[1] === "all"
        ? "/all"
        : `${collectionPrefix}/${bookmarkState.collection}`
    }/q/${encodeURIComponent(`site:${bookmarkData.urlHost}`)}`;
  };

  const DefaultIcon = (props) => {
    const propsWithSx = {
      ...props,
      sx: { color: theme.palette.action.selected, fontSize: '8rem' },
    }
    switch (bookmarkState.collection) {
      case "inbox":
        return <InboxOutlinedIcon {...propsWithSx} />;
      case "all":
        return <BookmarksOutlinedIcon {...propsWithSx} />;
      case "starred":
        return <StarOutlinedIcon {...propsWithSx} />;
      case "trash":
        return <TrashOutlinedIcon {...propsWithSx} />;
      default:
        return <BookmarkOutlinedIcon {...propsWithSx} />;
    }
  };

  // 设置卡片主题
  const setSummaryPalette = (node) => {
    const PALETTE_COLORS = {
      '--kp-color-primary': theme.palette.primary.main,
      '--kp-color-primary-hover': theme.palette.primary.dark,
      '--kp-color-text-primary': theme.palette.text.primary,
      '--kp-color-text-secondary': theme.palette.text.secondary,
      '--kp-color-border': theme.palette.border,
      '--kp-color-background-paper': theme.palette.background.paper,
      '--kp-color-background-neutral': theme.palette.background.neutral,
    }

    for (let i in PALETTE_COLORS) {
      node.style.setProperty(i, PALETTE_COLORS[i]);
    }
  }

  useHotkeys(
    HOTKEYS_OPEN_LINK,
    () => {
      if (bookmarkData) {
        window.open(bookmarkData.url);
      }
    },
    [bookmarkData]
  );

  useHotkeys(
    HOTKEYS_OPEN_NOTE,
    () => {
      bookmarkDispatch({ type: SET_PREVIEW_TAB, data: 1 });
    },
    [bookmarkData]
  );

  useEffect(() => {
    if (bookmarkData && prevBookmarkId.current != bookmarkData.id) {
      prevBookmarkId.current = bookmarkData.id;
    }
  }, [bookmarkData]);

  const handleSummaryElementRef = useCallback((node) => {
    if (!node) return;
    setSummaryPalette(node);
  }, [theme.palette.mode]);
  const tabValue = bookmarkState.previewTab;

  useEffect(() => {
    if (!bookmarkData) return;
    if (bookmarkData.card && !bookmarkData.extra && !bookmarkData.meta?.extraObj) {
      bookmarkDispatch({ type: OPERATION_UPDATE_BOOKMARK, data: { id: bookmarkData.id } })
    }
  }, [bookmarkData]);

  const bookmarkDataExtra = (!bookmarkData?.extra && bookmarkData?.meta?.extraObj) ? JSON.stringify(bookmarkData.meta.extraObj) : bookmarkData?.extra;

  const showSummary = bookmarkData?.card ? !!bookmarkDataExtra : !!bookmarkData;

  return (
    <>
      <Header showBorder={!!bookmarkData && showScrollBorder}>
        {bookmarkData && (
          <>
            <RouterLink component={Link} to={redirectToSearch()}>
              <IconButton>
                <StyledBookmarkIcon
                  sx={{
                    backgroundImage: `url(${getFaviconUrlByHost(bookmarkData.urlHost)})`,
                  }}
                />
              </IconButton>
            </RouterLink>
            <Stack direction="row" alignItems="center" spacing={1} sx={{ ml: 2 }}>
              <StyledTabButton selected={tabValue === TABS.SUMMARY} onClick={handleTabButtonClick.bind(this, TABS.SUMMARY)}>摘要</StyledTabButton>
              <StyledTabButton selected={tabValue === TABS.NOTE} onClick={handleTabButtonClick.bind(this, TABS.NOTE)}>备注</StyledTabButton>
            </Stack>
          </>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center'}}>
          {bookmarkData && (
            <Link href={bookmarkData.url} rel="noopener" target="_blank">
              <KeycapTooltip text="打开" hotkeys={HOTKEYS_OPEN_LINK}>
                <IconButton color="inherit" aria-label="open" size="small">
                  <OpenInNewOutlinedIcon
                    sx={{ color: theme.palette.text.secondary }}
                  />
                </IconButton>
              </KeycapTooltip>
            </Link>
          )}
          <KeycapTooltip
            text="收起预览窗口"
            hotkeys={HOTKEYS_TOGGLE_RIGHT_SIDEBAR}
          >
            <IconButton
              color="inherit"
              aria-label="toggle preview window"
              size="small"
              onClick={() => bookmarkDispatch({ type: CLOSE_PREVIEW_WINDOW })}
            >
              <SidebarRightOutlined
                sx={{ color: theme.palette.text.secondary }}
              />
            </IconButton>
          </KeycapTooltip>
        </Stack>
      </Header>
      {bookmarkData ? (
        <Box sx={{ p: 2, pt: 0, flex: 1 }}>
          {
            tabValue === TABS.SUMMARY && (
              <>
                {
                  bookmarkData.inProgress && (
                    <Stack alignItems="center" justifyContent="center" spacing={1} direction="row" sx={{ pt: 1, pb: 2 }}>
                      <CircularProgress size={16} />
                      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>解析中...</Typography>
                    </Stack>
                  )
                }
                {
                  showSummary ? (
                    <Box ref={handleSummaryElementRef} sx={{ backgroundColor: theme.palette.background.paper, borderRadius: '12px', p: 2, border: '1px solid', borderColor: theme.palette.divider, width: '100%', overflowX: 'hidden' }}>
                      <BookmarkSummary data={bookmarkData} />
                    </Box>
                  ) : null
                }
                <BookmarkMetadata data={bookmarkData} />
              </>
            )
          }
          {
            tabValue === TABS.NOTE && (
              <BookmarkNote data={bookmarkData} />
            )
          }
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pb: "20%",
            }}
          >
            <DefaultIcon
              sx={{ color: theme.palette.grey[300], fontSize: "8rem" }}
            />
            {/* <Typography variant="body1" sx={{ color: theme.palette.text.secondary, pt: 2 }}>
                点击书签，预览书签摘要
              </Typography> */}
          </Box>
        </Box>
      )}
      {bookmarkState.selectIndex > -1 && <BookmarkActionBar />}
    </>
  );
};
