import React from 'react';
import { Tooltip } from '@mui/material';
import Keycap from '../Keycap';

export default (props) => {
  const {
    children,
    text,
    hotkeys,
  } = props;

  return (
    <Tooltip title={<Keycap text={text} hotkeys={hotkeys} />} enterDelay={700} disableInteractive={true}>
      { children }
    </Tooltip>
  )
}
