import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// import { produce } from 'immer';
import { Box, Stack, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import MoreHorizOutlinedIcon from '@icons/MoreHorizOutlined';
// import settingsOutline from '@iconify/icons-eva/settings-outline';
// import logOutOutline from '@iconify/icons-eva/log-out-outline';
// import { ACTION_ADD_SUB_COLLECTION, ACTION_DELETE_COLLECTION, ACTION_UPDATE_COLLECTION } from '@contexts/collectionList/CollectionListConstants';
// import Logo from '@components/Logo';
import CollectionList from '@components/CollectionList';
import { OPERATION_FETCH_COLLECTION } from '@contexts/collectionList/CollectionListActionTypes';
import { CollectionListContext } from '@contexts/collectionList/collectionListReducer';
import * as events from '@constants/events';
import { LayoutContext } from '@contexts/layout/layoutReducer';
import { LAYOUT_SIZE } from '@constants/common';
// import NavMenu from './NavMenu';
import ProfileActionBar from './ProfileActionBar';
import { HOTKEYS_OPEN_SETTINGS } from '@constants/hotkeys';
import { useHotkeys } from 'react-hotkeys-hook';

// const SETTINGS_MENU_DATA = {
//   children: [{
//     name: '更多',
//     action: [{
//       icon: MoreHorizOutlinedIcon,
//       menu: [
//         {
//           name: '设置',
//           // path: '/settings',
//           // icon: settingsOutline,
//         },
//         {
//           name: '退出',
//           // icon: logOutOutline,
//           onClick: () => {
//             localStorage.removeItem('token');
//             location.replace('/login');
//           }
//         }
//       ]
//     }],
//     icon: MoreHorizOutlinedIcon,
//   }],
// };

export default () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { dispatch: collectionListDispatch } = useContext(CollectionListContext);
  const { state: layoutState } = useContext(LayoutContext);

  const handleItemClick = ({ path }) => {
    navigate(path);

    if (layoutState.size === LAYOUT_SIZE.MEDIUM) {
      window.dispatchEvent(new CustomEvent(events.CLOSE_SIDEBAR)); // 关闭侧边栏
    }
  }

  useHotkeys(HOTKEYS_OPEN_SETTINGS, () => { navigate('/settings') }, []);

  return (
    <Stack
      sx={{ height: '100%' }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        minHeight: { xs: '48px', lg: '56px' },
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5)
      }}>
        <IconButton
          onClick={() => {
            collectionListDispatch({ type: OPERATION_FETCH_COLLECTION, data: { force: true } });
            navigate('/');
          }}
        >
          {/* <Logo viewBox="0 0 48 48" sx={{ fontSize: '24px' }} /> */}
          <picture style={{ width: 24, height: 24 }}>
            {/* <source media="(prefers-color-scheme: dark)" srcset="//assets.kiipu.com/img/icon_light.svg?v=240427" style={{ width: '100%', height: '100%' }} /> */}
            <img src="//assets.kiipu.com/img/icon.svg?v=240427" alt="奇普书签" title="奇普书签" style={{ width: '100%', height: '100%', filter: theme.palette.mode === 'dark' ? 'brightness(0.8) saturate(1.25)' : 'none' }} />
          </picture>
        </IconButton>
        {/* <Typography variant="h2" component="h2" sx={{ flexGrow: 1, color: theme.palette.secondary.dark }}>
          kiipu
        </Typography> */}
      </Box>
      <CollectionList onItemClick={handleItemClick} />
      {/* <NavMenu data={SETTINGS_MENU_DATA} sx={{ px: 2 }} /> */}
      <ProfileActionBar />
    </Stack>
  )
}
