import React, { useState, useContext, useEffect } from 'react';
import { InputBase } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { BookmarkContext } from '@contexts/bookmark/bookmarkReducer';
import { SET_BOOKMARK_NOTE } from '@contexts/bookmark/BookmarkActionTypes';
import useBookmark from '@pages/DynamicBookmarkList/useBookmark';

const StyledNoteInput = styled(InputBase)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1.5),
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  fontSize: theme.typography.body2.fontSize,
  '&.Mui-focused': {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.divider,
  },
}));

export default ({ data }) => {
  const { addBookmarkNote } = useBookmark();
  const { dispatch: bookmarkDispatch } = useContext(BookmarkContext);

  const [ value, setValue ] = useState(data.note);

  const handleChange = (evt) => {
    setValue(evt.target.value);
  }

  const handleSave = () => {
    addBookmarkNote(data.id, value)
    bookmarkDispatch({ type: SET_BOOKMARK_NOTE, data: value });
  }

  useEffect(() => {
    setValue(data.note);
  }, [data]);

  return (
    <StyledNoteInput
      placeholder="添加备注..."
      inputProps={{ 'aria-label': 'note' }}
      multiline={true}
      minRows={6}
      fullWidth={true}
      onChange={handleChange}
      onBlur={handleSave}
      value={value}
      className="focus:outline-none"
    />
  )
}