import React, { useContext, useRef } from 'react';
import { debounce } from 'lodash';
import qs from 'qs';
import { Box, ListItemButton, Typography, ListItemText, ListItemIcon, Collapse } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import { useDrag, useDrop } from 'react-dnd';
import { useMutation, useLazyQuery } from "@apollo/client";
import arrowIosDownwardOutline from '@iconify/icons-eva/arrow-ios-downward-outline';
import arrowIosUpwardOutline from '@iconify/icons-eva/arrow-ios-upward-outline';
import iconGlobe2Fill from '@iconify/icons-eva/globe-2-fill';
import useDeviceDetect from '@utils/hooks/useDeviceDetect';
import { SHOW_ACTION_MENU, SET_DRAG_HOVER_COLLECTION, OPERATION_FETCH_COLLECTION } from '@contexts/collectionList/CollectionListActionTypes';
import { CollectionListContext } from '@contexts/collectionList/collectionListReducer';
import { CollectionContext } from '@contexts/collection/collectionReducer';
import { MOVE_COLLECTION, GET_COLLECTIONS } from '@apis/CollectionApi';
import { getEmojiAndName } from '@utils/helper';
import Keycap from '@components/Keycap';
import * as collectionRole from '@constants/collectionRole';
import { SnackbarContext } from '@contexts/snackbar/snackbarReducer';
import { SHOW_SNACKBAR } from '@contexts/snackbar/SnackbarActionTypes';

const { isTouch } = useDeviceDetect();

const ListItemWrapper = styled(Box)(({ theme, isDragging, hoverBefore, hoverAfter, dragHighlight, level }) => ({
  transform: 'translate(0, 0)', // fix: 修复拖拽过程中元素背景色 https://github.com/react-dnd/react-dnd/issues/788
  paddingTop: theme.spacing(0.25),
  paddingBottom: theme.spacing(0.25),
  opacity: isDragging ? 0.5 : 1,
  position: 'relative',
  ...(dragHighlight && hoverBefore && {
    '&::before': {
      content: '""',
      display: 'block',
      height: '2px',
      width: `calc(100% - ${16 * (level - 1)}px)`,
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      top: '-1px',
      right: 0,
    },
  }),
  ...(dragHighlight && hoverAfter && {
    '&::after': {
      content: '""',
      display: 'block',
      height: '2px',
      width: `calc(100% - ${16 * (level - 1)}px)`,
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      bottom: '-1px',
      right: 0,
    }
  })
}))

const ListItemButtonStyle = styled(ListItemButton)(({ theme, dragHighlight, selected, hoverIn, hasChildren, level, hasAction }) => ({
  backgroundColor: 'transparent',
  paddingTop: 0,
  paddingBottom: 0,
  height: '32px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderRadius: theme.spacing(2),
  paddingLeft: `calc(${16 * (level - (hasChildren ? 1 : 0))}px + 4px)`,
  overflow: 'hidden',
  transition: theme.transitions.create(['background-color']),
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
  },
  '&:hover': {
    '&.Mui-selected': {
      backgroundColor: selected ? theme.palette.action.selected : theme.palette.action.hover,
    },
    '.MuiListItemIcon-root': {
      // visibility: 'visible',
      display: 'inline-flex',
    },
    ...hasAction && {
      '.collection-count': {
        display: 'none',
      }
    }
  },
  ...(dragHighlight && {
    // backgroundColor: theme.palette.action.hover,
    backgroundColor: 'transparent',
  }),
  ...(dragHighlight && hoverIn && {
    borderColor: theme.palette.primary.main,
  }),
}));

const ListItemIconStyle = styled(ListItemIcon)(({ theme }) => ({
  height: '100%',
  alignItems: 'center',
}));

const ListItemActionStyle = styled(ListItemIconStyle)(({ theme, selected }) => ({
  // visibility: (isTouch && selected) ? 'visible' : 'hidden',  // 触摸屏，默认选中的时候，显示操作项
  display: (isTouch && selected) ? 'inline-flex' : 'none',
}));

const ListItemTextStyle = styled(ListItemText)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  marginTop: 0,
  marginBottom: 0,
}));

const NavMenuItem = React.forwardRef(({ data, sortable, level = 1, sx, onExpand, onMove, onItemClick, hoverBefore, hoverAfter, hoverIn, type = 'collection' }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef();

  const { pathname } = location;
  const { scope } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { state, dispatch: collectionListDispatch } = useContext(CollectionListContext);
  const { state: collectionState } = useContext(CollectionContext);
  const [moveCollection] = useMutation(MOVE_COLLECTION);
  const [getCollectionsForce] = useLazyQuery(GET_COLLECTIONS, { fetchPolicy: 'network-only' });
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);

  const [bookmarkDragProps, bookmarkDrop] = useDrop({
    accept: 'Box',
    collect: (minoter) => ({
      isOver: minoter.isOver(),
      canDrop: minoter.canDrop(),
    }),
    canDrop: () => {
      if (pathname !== data.path // 不是当前所在收藏夹
        && data.canDrop // 收藏夹有权限
        && !(pathname === '/trash' && data.id === 'starred') // 不在回收站页面移动到星标
        && !(collectionState.collection.role === collectionRole.VIEWER && data.id !== 'starred') // 当前所在收藏夹角色不为可查看
      ) return true;
      
      return false;
    },
    drop: (dragItem, minoter) => {
      return {
        id: data.id,
        name: data.name,
      };
    },
  });

  function handleDragItemHover(dragItem, monitor) {
    // console.log('[drag]', state.dragHover.id);
    // if (state.dragHover.id && data.name !== state.dragHover.id) {
    //   console.log('[drag] 取消移动');
    //   return;
    // }

    // collectionListDispatch({ type: SET_DRAG_HOVER_COLLECTION, data: { id: data.name } });

    const dragItemPath = [...dragItem.xpath];
    const hoverItemPath = [...data.xpath];

    // 拖拽到本身元素不处理
    if (dragItemPath.toString() === hoverItemPath.toString()) {
      dragItem.targetId = null;
      return;
    }
    
    // const dragItemIdx = dragItemPath.pop();
    // const hoverItemIdx = hoverItemPath.pop();

    // if (dragItemPath.toString() !== hoverItemPath.toString()) {
    //   return;
    // }

    // console.log('====', dragItemPath, hoverItemPath);

    const hoverBoundingRect = ref.current?.getBoundingClientRect(); // 获取hover元素区域
    const hoverMiddleY = 16; // (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2; // hover元素中间位置
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    // console.log("[hoverClientY]", hoverMiddleY, hoverClientY);

    // console.log('[dragItem] 1', [...dragItem.xpath], [...data.xpath]);

    // console.log('[debug]', dragItemIdx, hoverItemIdx, hoverClientY, hoverMiddleY);

    let targetPosition;

    if (hoverClientY < 8) {
      targetPosition = 'before';
    } else if (hoverClientY > 24 && !data.open) { // 展开状态的收藏夹没有after
      targetPosition = 'after';
    } else {
      targetPosition = 'in';
    }

    if (!data.canSupportNest && targetPosition === 'in') {
      return;
    }

    // if (state.dragHover.id === data.id && state.dragHover.position === targetPosition) {
    //   console.log('[drag] 跟上次相同', data.id);
    //   return;
    // }

    // 跟上次Hover操作一致，则忽略
    if (targetPosition === dragItem.targetPosition && dragItem.targetId === data.id) {
      return;
    }

    dragItem.targetPosition = targetPosition;
    dragItem.targetId = data.id;
    dragItem.targetXpath = [...data.xpath];
    
    onMove(targetPosition, dragItem.xpath, data.xpath);

    // collectionListDispatch({ type: SET_DRAG_HOVER_COLLECTION, data: { position: targetPosition, id: data.id } });

    // if (dragItemIdx < hoverItemIdx && hoverClientY > hoverMiddleY) {
    //   onMove('after', dragItem.xpath, data.xpath);
      // dragItem.xpath = [...data.xpath];
      // dragItem.name = data.name;
      // dragItem.id = data.id;
    // } else if (dragItemIdx > hoverItemIdx && hoverClientY < hoverMiddleY) {
    //   onMove('before', dragItem.xpath, data.xpath);
      // dragItem.xpath = [...data.xpath];
      // dragItem.name = data.name;
      // dragItem.id = data.id;
    // }
  };

  const [collectProps, drop] = useDrop({
    accept: 'Collection',
    collect: (minoter) => ({
      isOver: minoter.isOver(),
      canDrop: minoter.canDrop(),
    }),
    hover: handleDragItemHover,
    // hover: (dragItem, monitor) => {
    //   if (state.dragHover.timer) {
    //     clearTimeout(state.dragHover.timer);
    //     collectionListDispatch({ type: SET_DRAG_HOVER_COLLECTION, data: { timer: null } });
    //   }
      
    //   const timer = setTimeout(handleDragItemHover.bind(this, dragItem, monitor), 1000);

    //   collectionListDispatch({ type: SET_DRAG_HOVER_COLLECTION, data: { timer, id: data.id } });
    // },
    // drop: (dragItem, minoter) => {
    //   console.log('[dragItem] 2', dragItem, data);
    //   return {
    //     id: dragItem.id,
    //     name: dragItem.name,
    //     moveTo: data.id,
    //     moveToName: data.name,
    //   };
    // },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'Collection',
    item: {
      xpath: data.xpath,
      original: {
        xpath: [...data.xpath],
        name: data.name,
        id: data.id,
      },
      name: data.name,
      id: data.id,
    },
    collect(monitor) {
      return { isDragging: monitor.isDragging() }
    },
    end: async (item, monitor) => {
      // const ret = monitor.getDropResult();
      const didDrop = monitor.didDrop();
      // console.log('[onMove] end didDrop', didDrop, item.targetId);
      if (!didDrop || !item.targetId) {
        return;
      }
      
      // const sourceDeep = [...item.original.xpath];
      // const targetDeep = [...item.targetXpath];

      // const sourceIndex = sourceDeep.pop();
      // const targetIndex = targetDeep.pop();

      try {
        await moveCollection({
          variables: {
            id: item.original.id,
            input: {
              targetId: item.targetId,
              position: item.targetPosition,
            }
          }
        });
      } catch(ex) {
        snackbarDispatch({ type: SHOW_SNACKBAR, data: '收藏夹移动失败' });
      }

      // getCollectionsForce(); // 获取一次收藏夹数据
      collectionListDispatch({ type: OPERATION_FETCH_COLLECTION, data: { force: true } });

      // console.log('[onMove] complete', data, item.xpath, item.original.xpath);
      // console.log('[onMove] complete 2', item.original.name, item.name, item.original.id, item.id);

      // const sourceDeep = [...item.original.xpath];
      // const targetDeep = [...item.xpath];

      // const sourceIndex = sourceDeep.pop();
      // const targetIndex = targetDeep.pop();

      // if (sourceDeep.toString() === targetDeep.toString()) { // 同层级顺序调整
      //   await moveCollection({
      //     variables: {
      //       id: item.original.id,
      //       input: {
      //         targetId: item.id,
      //         position: sourceIndex < targetIndex ? 'after' : 'before',
      //       }
      //     }
      //   });
      //   getCollectionsForce();
      // }
    },
  });

  const handleClick = (e) => {
    onExpand(data.id);
    e.stopPropagation();
  };

  const hasChildren = data.children && data.children.length > 0;

  sortable && drag(drop(ref));

  bookmarkDrop(ref);

  const [icon, name] = getEmojiAndName(data.name);
  
  let selected = false;
  if (type === 'collection' && data.path && pathname.startsWith(data.path.split('?')[0]) && scope !== 'all') {
    selected = true;
  } else if (type === 'tag' && data.path === pathname) {
    selected = true;
  }

  const renderItemAction = () => {
    if (!data.action) return null;

    if (Array.isArray(data.action)) {
      return data.action.map(actionItem => (
        <ListItemActionStyle
          selected={selected}
          onClick={(evt) => {
            evt.stopPropagation();
            collectionListDispatch({
              type: SHOW_ACTION_MENU,
              data: {
                menu: actionItem.menu,
                element: evt.currentTarget,
                id: data.id,
                name: data.name,
              }
            });
          }}
        >
          <actionItem.icon fontSize="small" sx={{ color: theme.palette.text.secondary }} />
        </ListItemActionStyle>
      ));
    }

    return (
      <ListItemActionStyle
        selected={selected}
        onClick={(evt) => {
          evt.stopPropagation();
          collectionListDispatch({
            type: data.action,
            data: data.id
          });
        }}
      >
        <data.actionIcon fontSize="small" sx={{ color: theme.palette.text.secondary }} />
      </ListItemActionStyle>
    )
  }

  const dragHighlightForCollection = collectProps.isOver && collectProps.canDrop;
  const dragHighlightForBookmark = bookmarkDragProps.isOver && bookmarkDragProps.canDrop;
  const dragHighlight = dragHighlightForCollection || dragHighlightForBookmark;

  const handleItemClick = () => {
    onItemClick ? onItemClick({ path: data.path, id: data.id }) : (data.path ? navigate(data.path) : console.log('select', data.id))
  }

  return (
    <Box sx={{ ...sx }}>
      <ListItemWrapper
        ref={ref}
        isDragging={isDragging}
        hoverBefore={hoverBefore}
        hoverAfter={hoverAfter}
        dragHighlight={dragHighlight}
        level={level}
      >
        <ListItemButtonStyle
          hoverIn={dragHighlightForCollection ? hoverIn : true}
          disableGutters
          dragHighlight={dragHighlight}
          hasChildren={hasChildren}
          level={level}
          selected={selected}
          hasAction={!!data.action}
          onClick={handleItemClick}
        >
          {
            hasChildren && (
              <ListItemIconStyle
                sx={{
                  paddingLeft: 0,
                  marginRight: 0,
                }}
              >
                {
                  data.open ?
                    <Icon icon={arrowIosUpwardOutline} width={16} height={16} onClick={handleClick} /> :
                    <Icon icon={arrowIosDownwardOutline} width={16} height={16} onClick={handleClick} />
                }
              </ListItemIconStyle>
            )
          }
          <ListItemIconStyle>
            {
              icon ? <div class="text-center" style={{ width: '20px' }}>{icon}</div> : <data.icon sx={{ fontSize: theme.typography.h4.fontSize, color: theme.palette.text.secondary }} />
            }
          </ListItemIconStyle>
          <ListItemTextStyle
            disableTypography={true}
            primary={<Typography noWrap variant="body2" sx={{ fontWeight: selected ? 700 : 400 }}>{name}</Typography>}
          />
          {
            data.publish ? (
              <ListItemIconStyle>
                <Icon icon={iconGlobe2Fill} width={12} height={12} />
              </ListItemIconStyle>
            ) : null
          }
          {
            data.count > 0 && (
              <Typography className="collection-count" variant="caption" sx={{ color: theme.palette.text.secondary, pr: 1.5, pl: 0.5, fontSize: '0.625rem' }}>
                { data.count }
              </Typography>
            )
          }
          {
            renderItemAction()
          }
          {
            data.hotkey && !isTouch && (
              <ListItemActionStyle selected={selected}>
                <Keycap hotkeys={data.hotkey} />
              </ListItemActionStyle>
            )
          }
        </ListItemButtonStyle>
      </ListItemWrapper>
      {
        hasChildren && !isDragging && (
          <Collapse in={data.open} timeout="auto" unmountOnExit>
            {
              data.children.map((i, index) => {
                return (
                  <NavMenuItem
                    type={type}
                    key={i.id}
                    sortable={sortable}
                    data={{
                      ...i,
                      xpath: [...data.xpath, index]
                    }}
                    level={level + 1}
                    onExpand={onExpand}
                    onMove={onMove}
                    onItemClick={onItemClick}
                    hoverBefore={i.hoverBefore}
                    hoverAfter={i.hoverAfter}
                    hoverIn={i.hoverIn}
                  />
                )
              })
            }
          </Collapse>
        )
      }
    </Box>
  )
});

export default NavMenuItem;
