import { LOC_COLLAPSE_SIDEBAR_COLLECTION, LOC_COLLAPSE_SIDEBAR_TAGS } from '@constants/localStorage';

export default {
  user: null,
  settings: null,
  auth: null,
  bookmarkTags: null,
  sidebarCollectionCollapsed: localStorage[LOC_COLLAPSE_SIDEBAR_COLLECTION] === 'true',
  sidebarTagsCollapsed: localStorage[LOC_COLLAPSE_SIDEBAR_TAGS] === 'true',
}
