import React, { useContext } from 'react';
import { useMutation } from "@apollo/client";
import { styled, useTheme } from '@mui/material/styles';
import { Menu, MenuItem, ListSubheader, Box } from '@mui/material';
import { CollectionContext } from '@contexts/collection/collectionReducer';
import { UPDATE_VIEW_COLLECTION } from '@apis/CollectionApi';
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import CheckIcon from '@mui/icons-material/Check';
import * as CollectionActionTypes from '@contexts/collection/CollectionActionTypes';
import { GET_COLLECTION } from '@apis/CollectionApi';
import { useApolloClient } from '@apollo/client';

const StyledMenu = styled((props) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))();

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  lineHeight: '32px',
  color: theme.palette.grey[600],
  fontWeight: 400,
}));

const StyledListItemIcon = styled(({ checked, ...props }) => (
  <Box {...props}>
    {
      checked && <CheckIcon style={{ marginRight: 0 }} />
    }
  </Box>
))(({ theme }) => ({
  minWidth: '24px',
  display: 'flex',
  alignItems: 'center'
}));

export default (props) => {
  const theme = useTheme();
  const { anchorEl, onClose } = props;

  const { state: collectionState, dispatch: collectionDispatch } = useContext(CollectionContext);
  const [updateCollectionView] = useMutation(UPDATE_VIEW_COLLECTION);
  const client = useApolloClient();

  const open = Boolean(anchorEl);

  const handleUpdateView = async (view) => {
    onClose();

    collectionDispatch({
      type: CollectionActionTypes.SET_COLLECTION,
      data: {
        ...collectionState.collection,
        view,
      }
    })
    
    await updateCollectionView({
      variables: {
        id: collectionState.collection.id,
        view,
      },
    })

    client.writeQuery({
      query: GET_COLLECTION,
      data: {
        collection: {
          view,
        },
      },
      variables: {
        id: collectionState.collection.id,
      }
    });
  }

  return collectionState.collection ? (
    <StyledMenu
        id="view-action-menu"
        MenuListProps={{
          'aria-labelledby': 'view-action-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        <StyledListSubheader>视图</StyledListSubheader>
        <MenuItem onClick={handleUpdateView.bind(this, 'LIST')}>
          <StyledListItemIcon checked={collectionState.collection.view === 'LIST'} />
          <ReorderOutlinedIcon style={{ marginRight: '8px' }} />列表
        </MenuItem>
        <MenuItem onClick={handleUpdateView.bind(this, 'CARDS')}>
          <StyledListItemIcon checked={collectionState.collection.view === 'CARDS'} />
          <GridViewOutlinedIcon style={{ marginRight: '8px' }} />卡片
        </MenuItem>
      </StyledMenu>
  ) : null
}
