import { LOC_SHOW_BOOKMARK_PREVIEW } from '@constants/localStorage';

export default {
  operation: null,
  anchorEl: null,
  containerRef: null,
  selectIndex: -1,
  collection: null,
  keywords: [null, undefined],
  bookmarkList: {
    edges: [],
    pageInfo: {
      afterCursor: null,
      hasNextPage: true,
    },
  },
  showPreviewWindow: localStorage[LOC_SHOW_BOOKMARK_PREVIEW] != 'false', // 默认打开书签预览窗口
  cards: {},
  previewTab: 0,
  pendingParseBookmarkIds: [], // 待解析的书签ID
  pendingUploadImageBookmarkIds: [], // 待上传图片的书签ID
}
