import emojiRegex from 'emoji-regex';
import { MD5 } from 'crypto-js';
import { URL_CARD_HOST } from '@constants/url';

// 获取字符串中emoji图标
const getEmojiAndName = (str) => {
  if (!str) {
    return [null, ''];
  }
  
  let icon;
  const regex = emojiRegex();
  let name = str.replace(regex, (emoji, pos) => {
    if (pos === 0) {
      icon = emoji;
      return '';
    }
    return emoji;
  });
  return [icon, name];
}

const isProduction = () => ['kiipu.com', 'beta.kiipu.com'].indexOf(location.hostname) > -1;

const getImageUrlHost = () => isProduction() || process.env.PRODUCTION ? '//img.kiipu.com' : '//img-dev.kiipu.com';

const getFaviconUrlHost = () => isProduction() || process.env.PRODUCTION ? '//favicon.kiipu.com' : '//favicon-dev.kiipu.com';

const getCardFilename = (name, version) => {
  return `${name}${!process.env.DEBUG_CARD ? `-v${version.replace(/0.0./, '')}` : ''}`;
}

const getCardUrl = (name, version) => {
  console.log('[process.env.DEBUG_CARD]', process.env.DEBUG_CARD);
  if (process.env.DEBUG_CARD) {
    return `http://localhost:9997/${getCardFilename(name, version)}.js?_t=${new Date().getTime()}`;
  }
  return `${URL_CARD_HOST}/${name}/${getCardFilename(name, version)}.js`;
}

const resolveCardTagName = (name, version) => {
  return `kp-${getCardFilename(name, version)}`;
}

const getContrastYIQ = (hexcolor) => {
  var r = parseInt(hexcolor.substring(1,3),16);
  var g = parseInt(hexcolor.substring(3,5),16);
  var b = parseInt(hexcolor.substring(5,7),16);
  var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 190) ? '#212B36' : '#FFFFFF';
}

const getReadableTime = (dateTime) => {
  // 用毫秒表示分钟、小时、天、周、月
  let minute = 1000 * 60;
  let hour = minute * 60;
  let day = hour * 24;
  let week = day * 7;
  let month = day * 30;

  // 获取当前时间并转换为时间戳，方便计算
	let timestamp_current = new Date().getTime();
 
	// 将传入的时间格式字符串解析为Date对象
	let _date = new Date(dateTime);
 
	// 将Date对象转换为时间戳，方便计算
	let timestamp_input = _date.getTime();
 
	// 计算当前时间与传入的时间之间相差的时间戳
	let timestamp_diff = timestamp_current - timestamp_input;
 
	// 计算时间差共有多少个分钟
	let minC = timestamp_diff / minute;
	// 计算时间差共有多少个小时
	let hourC = timestamp_diff / hour;
	// 计算时间差共有多少个天
	let dayC = timestamp_diff / day;
	// 计算时间差共有多少个周
	let weekC = timestamp_diff / week;
	// 计算时间差共有多少个月
	let monthC = timestamp_diff / month;
 
	/*if (monthC >= 1 && monthC < 4) {
		return parseInt(monthC) + '个月前';
	} else if (weekC >= 1 && weekC < 4) {
		return parseInt(weekC) + '周前';
	} else */if (dayC >= 1 && dayC < 7) {
		return parseInt(dayC) + '天前';
	} else if (hourC >= 1 && hourC < 24) {
		return parseInt(hourC) + '小时前';
	} else if (minC >= 10 && minC < 60) {
		return parseInt(minC) + '分钟前';
	} else if ((timestamp_diff >= 0) && (minC < 10)) {
		return '刚刚';
	} else {
		return ((new Date().getFullYear() != _date.getFullYear()) ? _date.getFullYear() + '年' : '') + (_date.getMonth() + 1) + '月' + _date.getDate() + '日';
	}
}

const getImageUrl = (imageUrl, url, clip) => {
  if (imageUrl.startsWith('upload:')) {
    return '<loading>';
  } else if (imageUrl.startsWith('fail:')) {
    return null;
  } else if (imageUrl.startsWith('//img.kiipu.com')) {
    return imageUrl;
  }
  
  let clipStr = '';
  if (clip) {
    clipStr = `-${clip}`;
  }
  return `${getImageUrlHost()}/${new URL(url).hostname}/${MD5(imageUrl.replace(/.*:?\/\//, ''))}${clipStr}`;
}

/**
 * 获取faviconUrl
 * 若图标是kiipu.com域下，则直接返回，否则根据url域名返回
 * @param {*} url 
 * @param {*} faviconUrl 
 * @returns 
 */
const getFaviconUrl = (url, faviconUrl) => {
  if (faviconUrl && isKiipuUrl(faviconUrl)) {
    return faviconUrl;
  }
  
  return `${process.env.CHROME_EXT ? 'http:' : ''}${getFaviconUrlHost()}/${(new URL(url)).hostname}`;
}

/**
 * 根据URL Host获取faviconUrl
 * @param {*} urlHost 
 * @param {*} faviconUrl 
 * @returns 
 */
const getFaviconUrlByHost = (urlHost) => {
  return `${process.env.CHROME_EXT ? 'http:' : ''}${getFaviconUrlHost()}/${urlHost}`;
}

const getIconUrl = (icon, faviconUrl, url) => {
  if (icon) {
    if (icon.startsWith('//')) {
      return `${process.env.CHROME_EXT ? 'http:' : ''}${icon}`;
    }

    if (icon.startsWith('http')) {
      return icon;
    }

    return `${process.env.CHROME_EXT ? 'http:' : ''}//assets.kiipu.com/icon/sites/${icon}.png`;
  }

  return getFaviconUrl(url, faviconUrl);
}

const getToken = () => new Promise((resolve, reject) => {
  if (process.env.CHROME_EXT) {
    chrome.cookies.get({
      url: process.env.WEB_HOST,
      name: 'token'
    }, cookies => {
      if (cookies && cookies.value) {
        resolve(cookies.value);
      } else {
        resolve();
      }
    })
  } else {
    resolve(localStorage.getItem('token'));
  }
});

const getChromeExtensionId = () => {
  return localStorage['_kp_chrome_extension_id'] || (window.location.host.startsWith('dev') ? 'kddenfladaegmjfjgmamhoofjonpllec' : 'fjhogephchakgfmjjgiendejbblfgmhn');
};

// 获取文本中的URL链接
const extractUrls = (val) => {
  const reg = /https?:\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
  return val.match(reg);
}

// 小驼峰转换为连字符
function lowerCamelToKebab(str) {
  return str.replace(/([A-Z])/g, '-$1').toLowerCase();
}

// // 获取书签状态Url
// function wrapBookmarkImage(url, path, imageUploadPath) {
//   if (imageUploadPath.includes(path)) {
//     return `<loading>`;
//   }

//   return url;
// }

/**
 * 获取搜索路由
 * @param {*} collection 收藏夹ID
 * @param {*} keywords 关键词
 * @param {*} fromAll 是否在所有书签下搜索
 * @returns 
 */
function getSearchRoute(collection, keywords, fromAll = true) {
  let collectionPrefix = '/collection';
  if (['inbox', 'all', 'trash', 'starred'].indexOf(collection) > -1) {
    collectionPrefix = '';
  }

  // 如果当前搜索范围是所有书签，则跳转到所有书签的页面显示搜索结果
  return `${fromAll ? '/all' : `${collectionPrefix}/${collection}`}/q/${encodeURIComponent(keywords)}`;
}

/**
 * 是否是kiipu.com域下的域名
 * @param {*} url 
 * @returns 
 */
function isKiipuUrl(url) {
  if (!url || (!url.startsWith('//') && !url.startsWith('http:'))) return false;
  let u = url.startsWith('//') ? `http:${url}` : url;
  return new URL(u).hostname.endsWith('kiipu.com');
}

function sendRequestToBrowserExtension(type, payload) {
  return new Promise((resolve, reject) => {
    try {
      chrome.runtime.sendMessage(getChromeExtensionId(), { type, payload }, function (data) {
        if (data?.data) {
          console.log('[AddBookmarkDialog] local parse result', data?.data);
          resolve(data?.data);
        } else {
          reject();
        }
      });
    } catch (ex) {
      reject();
    }
  })
}

export {
  getEmojiAndName,
  isProduction,
  getImageUrlHost,
  getFaviconUrlHost,
  getCardUrl,
  getContrastYIQ,
  getReadableTime,
  getImageUrl,
  getFaviconUrl,
  getFaviconUrlByHost,
  getToken,
  getChromeExtensionId,
  extractUrls,
  getIconUrl,
  resolveCardTagName,
  lowerCamelToKebab,
  // wrapBookmarkImage,
  getSearchRoute,
  sendRequestToBrowserExtension,
}
