import React, { useEffect, useState, forwardRef, useRef, useContext } from 'react';
import { Stack, Alert, Link } from '@mui/material';
// import { useMutation, useLazyQuery } from '@apollo/client';
import { Form, Formik } from 'formik';
import useBookmark from '@pages/DynamicBookmarkList/useBookmark';
import { extractUrls, sendRequestToBrowserExtension } from '@utils/helper';
import InputFormControl from '../InputFormControl';
// import { CLOUD_PARSER } from '@apis/ParserApi';
// import { UPDATE_BOOKMARK } from '@apis/BookmarkApi';
// import { GET_BOOKMARK_META } from '@apis/BookmarkMetaApi';
import useDeviceDetect from '@utils/hooks/useDeviceDetect';
import * as events from '@constants/events';
// import { BookmarkContext } from '@contexts/bookmark/bookmarkReducer';
// import * as BookmarkActionTypes  from '@contexts/bookmark/BookmarkActionTypes';
import ChromeIcon from '../../../../assets/sp-icons/chrome';
import { MSG_REQUEST_FETCH_URL } from '@shared-constants/message';

const { isTouch } = useDeviceDetect();

const SAVE_STAGE = {
  BASIC: 'BASIC',
  META: 'META',
  EXT_BASIC: 'EXT_BASIC',
  CLOUD_BASIC: 'CLOUD_BASIC',
  CLOUD_ADV: 'CLOUD_ADV',
}

export default forwardRef((props, ref) => {
  const {
    collectionId,
    open,
    onClose,
    onSuccess,
    onValuesChange,
    onSubmitting,
  } = props;

  // const [metadata, setMetadata] = useState();
  const [clipboardText, setClipboardText] = useState();
  const { /* addBookmark, addBookmarkFromMeta, cloudParserBookmarkAsync, */ createBookmark } = useBookmark();
  // const [updateBookmark] = useMutation(UPDATE_BOOKMARK);
  // const [getBookmarkMeta] = useLazyQuery(GET_BOOKMARK_META);
  // const { state: bookmarkState, dispatch: bookmarkDispatch } = useContext(BookmarkContext);

  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  
  const formikRef = useRef();

  /**
   * 同步上传图片
   */
  // const uploadImage = async (type, imageUrl, host) => {
  //   let uri;
  //   if (location.host === 'kiipu.com' || location.host === 'beta.kiipu.com') {
  //     uri = 'api.kiipu.com';
  //   } else {
  //     uri = 'dev-api.kiipu.com:3001';
  //   }
    
  //   const res = await fetch(`//${uri}/qiniu/upload?type=${type}&url=${encodeURIComponent(imageUrl)}&host=${host}`);
  //   console.log('[res]', res);
  // }

  /**
   * 异步上传图片
   */
  // const uploadImageAsync = async (type, imageUrl, host) => {
  //   let uri;
  //   if (location.host === 'kiipu.com' || location.host === 'beta.kiipu.com') {
  //     uri = 'api.kiipu.com';
  //   } else {
  //     uri = 'dev-api.kiipu.com:3001';
  //   }
    
  //   await fetch(`//${uri}/qiniu/upload-async?type=${type}&url=${encodeURIComponent(imageUrl)}&host=${host}`, { mode: 'no-cors' });
  // }

  const formikProps = {
    initialValues: {
      url: '',
    },
    validateOnBlur: validateAfterSubmit,
    validateOnChange: validateAfterSubmit,
    // validationSchema: Yup.object().shape({
    //   url: Yup.string().trim().url('请输入网址').nullable(),
    // }),
    validate: (values) => {
      const errors = {};

      const urls = extractUrls(values.url);
      if (!urls || urls.length === 0) {
        errors.url = '请输入正确的网址';
      }

      return errors;
    },
    onSubmit: async ({ url: text }, { setSubmitting, setFieldError }) => {
      // let customParser = false; // 是否有自定义解析器
      // let stage = SAVE_STAGE.BASIC; // 保存阶段
      // let card;
      let currentCollectionId = ['inbox', 'all'].indexOf(collectionId) === -1 ? collectionId : undefined;

      setValidateAfterSubmit(true);

      // 本地基础数据抓取
      let localParseData;

      const url = extractUrls(text)[0];

      try {
        localParseData = await sendRequestToBrowserExtension(MSG_REQUEST_FETCH_URL, { url, text: clipboardText });
        console.log('[AddBookmarkDialog] localParseData', localParseData);
      } catch (ex) {
        console.log('[AddBookmarkDialog] localParseData error');
      }

      const newBookmark = await createBookmark({
        collectionId: currentCollectionId,
        platform: `web@${$CONFIG.apps.home}`,
        url: text,
        data: localParseData,
      });

      setSubmitting(false);
      setValidateAfterSubmit(false);
      onSuccess(newBookmark);

      // 添加到待解析书签
      // bookmarkDispatch({
      //   type: BookmarkActionTypes.ADD_PENDING_PARSE_BOOKMARKS,
      //   data: [newBookmark.id],
      // })

      window.dispatchEvent(new CustomEvent(events.ADD_PENDING_PARSE_BOOKMARKS, {
        detail: [newBookmark.id]
      }));
    }
  };

  const handleCancel = () => {
    onClose();
  }

  // const handleParse = async () => {
  //   try {
  //     const data = await parseUrl(values.url, clipboardText);

  //     if (!data) {
  //       return;
  //     }

  //     if (data.metadata) {
  //       // setMetadata(data.metadata);
  //       // formik.setValues(Object.assign(formik.values, data.metadata));
  //     }
  //   } catch(ex) {

  //   }
  // }

  const readFromClipboard = async () => {
    try {
      const permission = await navigator.permissions.query({ name: 'clipboard-read' });
      if (permission.state === 'granted' || permission.state === 'prompt') {
        const text = await navigator.clipboard.readText();
        const urlMatches = /http[s]?:\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\(\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+/i.exec(text);
        if (urlMatches && urlMatches.length && urlMatches[0]) {
          setClipboardText(text);
          formikRef.current?.setValues(Object.assign(formikRef.current?.values, {
            url: text,
          }));
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  useEffect(() => {
    // setMetadata(null);
    if (open) {
      formikRef.current?.resetForm();
      readFromClipboard();
      console.log('[collectionId]', collectionId);
    }
  }, [open]);

  return (
    <Formik innerRef={(f) => { formikRef.current = f; ref.current = f; }} {...formikProps}>
      {
        ({ errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setFieldError }) => {
          onValuesChange(values);
          onSubmitting(isSubmitting);
          
          return (
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              {
                !isTouch ? (
                  <Alert severity="success" color="info" iconMapping={{ success: <ChromeIcon /> }} style={{ marginBottom: '8px' }}>
                    使用
                    <Link
                      href="https://chromewebstore.google.com/detail/%E5%A5%87%E6%99%AE%E4%B9%A6%E7%AD%BE/fjhogephchakgfmjjgiendejbblfgmhn?hl=en"
                      rel="noopener"
                      target="_blank"
                      style={{ padding: '0 4px' }}
                    >
                      Chrome插件
                    </Link>
                    获得更极致的收藏体验
                  </Alert>
                ) : null
              }
              <Stack spacing={2}>
                <InputFormControl
                  fullWidth
                  autoFocus
                  margin="dense"
                  type="text"
                  placeholder="https://"
                  {...getFieldProps('url')}
                  error={Boolean(touched.url && errors.url)}
                  helperText={touched.url && errors.url}
                />
                {/* {
                  metadata && (
                    <>
                      <InputFormControl
                        fullWidth
                        margin="dense"
                        type="text"
                        label="标题"
                        {...getFieldProps('title')}
                      />
                      <InputFormControl
                        fullWidth
                        margin="dense"
                        type="text"
                        label="简介"
                        {...getFieldProps('description')}
                      />
                      <InputFormControl
                        fullWidth
                        margin="dense"
                        type="text"
                        label="图标"
                        {...getFieldProps('faviconUrl')}
                      />
                    </>
                  )
                } */}
              </Stack>

            </Form>
          )
        }
      }
    </Formik>
  )
})
