export default function Divider(theme) {
  return {
    MuiDivider: {
      defaultProps: {
      },

      styleOverrides: {
        root: {
          borderColor: theme.palette.divider
        }
      }
    }
  };
}
