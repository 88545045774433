import React, { useEffect, useRef, useState, useCallback, useContext, useLayoutEffect } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { WindowScroller, List, InfiniteLoader, AutoSizer, CellMeasurer, CellMeasurerCache, Grid } from 'react-virtualized';
import LoadingPanel from '@components/LoadingPanel';
import { BookmarkContext } from '@contexts/bookmark/bookmarkReducer';
import { CollectionContext } from '@contexts/collection/collectionReducer';
import * as BookmarkActionTypes from '@contexts/bookmark/BookmarkActionTypes';
import useDeviceDetect from '@utils/hooks/useDeviceDetect';
import { LayoutContext } from '@contexts/layout/layoutReducer';
import { LAYOUT_TYPE } from '@constants/common';
import SimpleListItem from './components/SimpleListItem';
import SimpleGridItem from './components/SimpleGridItem';
import { produce } from 'immer';

const wrapBookmarkImage = (bookmark) => {
  if (!bookmark) return null;

  return produce(bookmark, (draft) => {
    if (draft.imageUploadPath && draft.imageUploadPath.length > 0) {
      if (draft.imageUploadPath.includes('image')) {
        draft.image = '<loading>';
      }
      
      if (draft.imageUploadPath.includes('faviconUrl')) {
        draft.faviconUrl = '<loading>';
      }
    }
  })
}

const cache = new CellMeasurerCache({
  fixedWidth: true,
  // fixedHeight: true,
  defaultHeight: 60,
  // minHeight: 56,
  // keyMapper: () => 1,
});

// window.cache = cache;

const calcGridCfg = (width, layoutType) => {
  let columnCount;
  let gridGap = 6;

  if (width > 574 && width < 762 /* 3 */) {
    columnCount = 3; // 保证iPhone 6（375 x 667）横屏显示3列
  } else if (width >= 375 && width <= 574) {
    columnCount = 2; // 保证iPhone 12（390 x 844）竖屏显示2列
  } else if (width < 375) {
    columnCount = layoutType === LAYOUT_TYPE.MINI ? 2 : 1;
  } else {
    gridGap = 8;
    columnCount = Math.round((width - gridGap * 2) / 300);
  }

  return {
    columnCount, gridGap
  }
}

export default function() {
  const { state: bookmarkState, dispatch: bookmarkDispatch } = useContext(BookmarkContext);
  const { state: collectionState } = useContext(CollectionContext);
  const { state: layoutState } = useContext(LayoutContext);

  const infiniteLoaderRef = useRef();
  const gridRef = useRef();
  const { isTouch } = useDeviceDetect();
  const theme = useTheme();
  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const [lastLoadingIndex, setLastLoadingIndex] = useState();
  const [view, setView] = useState(collectionState.collection?.view);

  const [contentWidth, setContentWidth] = useState(0); // 书签区域宽度
  const [gridGap, setGridGap] = useState(6); // 卡片布局卡片间距
  const [columnCount, setColumnCount] = useState(1);

  const prevItemCount = useRef(0);

  useEffect(() => {
    // bookmarkDispatch({ type: BookmarkActionTypes.OPERATION_INIT_BOOKMARK });
    handleCloseActionPopper();
    infiniteLoaderRef.current.resetLoadMoreRowsCache(true);
  }, [bookmarkState.collection, bookmarkState.keywords]);

  const isRowLoaded = ({ index }) => !bookmarkState.bookmarkList.pageInfo.hasNextPage || index < bookmarkState.bookmarkList.edges.length;

  const loadMoreRows = () => bookmarkDispatch({ type: BookmarkActionTypes.OPERATION_FETCH_BOOKMARK, data: { collectionId: bookmarkState.collection, keywords: bookmarkState.keywords } });

  const handleOpenMenu = useCallback((index, el) => {
    if (isTouch && matchDownMd) { // 移动端点击菜单，展示预览
      bookmarkDispatch({ type: BookmarkActionTypes.OPEN_PREVIEW_WINDOW, data: { record: false } });
      handleSelect(index);
    } else { 
      const elememtRect = el.getBoundingClientRect();
      const anchorEl = {
        getBoundingClientRect: () => elememtRect,
      };
      bookmarkDispatch({ type: BookmarkActionTypes.SHOW_ACTION, data: { anchorEl } });
      bookmarkDispatch({ type: BookmarkActionTypes.SET_SELECT_INDEX, data: index });
    }
  }, []);

  const handleSelect = (index) => {
    bookmarkDispatch({ type: BookmarkActionTypes.SET_SELECT_INDEX, data: index });
    bookmarkDispatch({ type: BookmarkActionTypes.SET_PREVIEW_TAB, data: 0 });
    bookmarkDispatch({ type: BookmarkActionTypes.HIDE_ACTION });
  }

  const showCollection = () => ['all', 'starred'].indexOf(bookmarkState.collection) > -1;

  const getItemCount = () => {
    const hasNextPage = bookmarkState.bookmarkList.pageInfo.hasNextPage;
    const count = bookmarkState.bookmarkList.edges?.length;

    if (view === 'CARDS') {
      if (hasNextPage) {
        return count % columnCount === 0 ? count + 1 : count + (columnCount + 1 - count % columnCount);
      }
      
      return count + (columnCount - (count - 1) % columnCount) % columnCount;
    }
    
    return hasNextPage ? count + 1 : count;
  }

  const itemCount = getItemCount();

  const Empty = () => (
    <Typography variant="caption" component="div" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 60, color: 'text.secondary' }}>
      {`书签 ${bookmarkState.bookmarkList.edges.length - 1}`}
    </Typography>
  )

  const rowRenderer = useCallback(({ index, isScrolling, key, parent, style }) => (
    <CellMeasurer
      cache={cache}
      columnIndex={0}
      rowIndex={index}
      {...{ key, parent }}
    >
      {({ measure, registerChild }) => {
        const bookmarkList = bookmarkState.bookmarkList.edges;
        const bookmarkData = wrapBookmarkImage(bookmarkList[index]);
        return (
          <Box key={index} ref={registerChild} style={style}>
            {
              !bookmarkData && (
                <LoadingPanel height={60} size={24} />
              )
            }
            {
              bookmarkData && bookmarkData.end && (
                <Empty />
              )
            }
            {
              bookmarkData && !bookmarkData.end && (
                <SimpleListItem
                  id={bookmarkData.id}
                  title={bookmarkData.title}
                  url={bookmarkData.url}
                  srcUrl={bookmarkData.srcUrl}
                  urlHost={bookmarkData.urlHost}
                  faviconUrl={bookmarkData.faviconUrl}
                  description={bookmarkData.description}
                  collection={(showCollection() || bookmarkState.keywords[1] === 'all') && bookmarkData.collection?.id !== 'inbox' && bookmarkData.collection}
                  onOpenMenu={handleOpenMenu.bind(this, index)}
                  isSelected={bookmarkState.selectIndex > -1 && bookmarkState.bookmarkList.edges[bookmarkState.selectIndex].id === bookmarkData.id}
                  isDetailOpened={bookmarkState.showPreviewWindow}
                  isStaredPage={bookmarkState.collection === 'starred'}
                  isStarred={bookmarkData.isStarred}
                  note={bookmarkData.note}
                  createdAt={bookmarkData.createdAt}
                  isSearchScopeAll={bookmarkState.keywords[1] === 'all'}
                  inProgress={bookmarkData.inProgress}
                  tags={bookmarkData.tags}
                  currentCollection={bookmarkState.collection}
                  onMoveTo={(collectionId, collectionName) => {
                    bookmarkDispatch({
                      type: BookmarkActionTypes.OPERATION_MOVE_BOOKMARK,
                      data: {
                        index,
                        id: bookmarkData.id,
                        collectionId,
                        collectionName
                      }
                    })
                  }}
                  onSelect={handleSelect.bind(this, index)}
                  onOpenNote={() => {
                    bookmarkDispatch({ type: BookmarkActionTypes.SET_SELECT_INDEX, data: index });
                    bookmarkDispatch({ type: BookmarkActionTypes.SET_PREVIEW_TAB, data: 1 });
                  }}
                  datePublished={bookmarkData.datePublished}
                />
              )
            }
          </Box>
        )
      }}
    </CellMeasurer>
  ), [handleOpenMenu, bookmarkState.bookmarkList.edges, bookmarkState.selectIndex, bookmarkState.showPreviewWindow]);

  const cellRenderer = ({ key, columnIndex, rowIndex, style, parent }) => {
    const bookmarkList = bookmarkState.bookmarkList.edges;
    const index = rowIndex * columnCount + columnIndex;
    const bookmarkData = wrapBookmarkImage(bookmarkList[index]);

    let content;

    if (index < bookmarkList.length && !bookmarkList[index]?.end) {
      content = (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
            ...style,
          }}
        >
          <SimpleGridItem
            id={bookmarkData.id}
            title={bookmarkData.title}
            url={bookmarkData.url}
            srcUrl={bookmarkData.srcUrl}
            urlHost={bookmarkData.urlHost}
            image={bookmarkData.image}
            faviconUrl={bookmarkData.faviconUrl}
            description={bookmarkData.description}
            collection={(showCollection() || bookmarkState.keywords[1] === 'all') && bookmarkData.collection?.id !== 'inbox' && bookmarkData.collection}
            onOpenMenu={handleOpenMenu.bind(this, index)}
            isSelected={bookmarkState.selectIndex > -1 && bookmarkState.bookmarkList.edges[bookmarkState.selectIndex].id === bookmarkData.id}
            isDetailOpened={bookmarkState.showPreviewWindow}
            isStaredPage={bookmarkState.collection === 'starred'}
            isStarred={bookmarkData.isStarred}
            note={bookmarkData.note}
            createdAt={bookmarkData.createdAt}
            isSearchScopeAll={bookmarkState.keywords[1] === 'all'}
            onMoveTo={(collectionId, collectionName) => {
              bookmarkDispatch({
                type: BookmarkActionTypes.OPERATION_MOVE_BOOKMARK,
                data: {
                  index,
                  id: bookmarkData.id,
                  collectionId,
                  collectionName
                }
              })
            }}
            onSelect={handleSelect.bind(this, index)}
            onOpenNote={() => {
              bookmarkDispatch({ type: BookmarkActionTypes.SET_SELECT_INDEX, data: index });
              bookmarkDispatch({ type: BookmarkActionTypes.SET_PREVIEW_TAB, data: 1 });
            }}
            gap={gridGap}
            inProgress={bookmarkData.inProgress}
            themeColor={bookmarkData.theme}
            icon={bookmarkData.icon}
            datePublished={bookmarkData.datePublished}
            type={bookmarkData.type}
          />
        </div>
      )
    } else if (columnIndex === 0 && bookmarkList[bookmarkList.length - 1]?.end) {
      content = (
        <div style={Object.assign({}, style, {
          width: contentWidth,
        })}>
          <Empty />
        </div>
      )
    } else if (columnIndex === 0) {
      setLastLoadingIndex(rowIndex);
      
      content = (
        <div style={Object.assign({}, style, {
          width: contentWidth,
        })}>
          <LoadingPanel height={60} size={24} />
        </div>
      )
    } else {
      content = <div style={style} />;
    }

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        {...{ key, parent }}
      >
        {content}
      </CellMeasurer>
    )
  }

  const handleResize = ({ width }) => {
    setContentWidth(width);

    const r = calcGridCfg(width, layoutState.type);
    
    setColumnCount(r.columnCount);
    setGridGap(r.gridGap);
    
    cache.clearAll();
    if (view === 'CARDS') {
      gridRef.current && gridRef.current.recomputeGridSize();
    }
  }

  const handleCloseActionPopper = () => {
    bookmarkDispatch({ type: BookmarkActionTypes.HIDE_ACTION });
  }

  // const handleWindowResize = () => {
  //   handleCloseActionPopper(); // 窗口大小变化，隐藏菜单，防止触发元素丢失导致菜单位置显示不正确
  // }

  useEffect(() => {
    // 数据加载完成，重置Loaidng行
    if (view === 'CARDS') {
      for (let i = 0; i < columnCount; i++) {
        cache.clear(lastLoadingIndex, i);
      }
    }

    // 数据重置，则刷新页面
    if (itemCount < prevItemCount.current) {
      reset();
    }

    prevItemCount.current = itemCount;
  }, [itemCount]);

  const reset = () => {
    cache.clearAll();
    if (view === 'CARDS') {
      gridRef.current && gridRef.current.recomputeGridSize();
    }
  }

  // useEffect(() => {
  //   // 搜索数据，重置缓存
  //   reset();
  // }, [bookmarkState.keywords])

  useEffect(() => {
    // 视图切换，重置缓存
    if (!collectionState.collection?.view) return;
    if (collectionState.collection?.view !== view) {
      setView(collectionState.collection?.view);
      cache.clearAll();
    }
  }, [collectionState.collection?.view])

  useEffect(() => {
    // 切换排序方式，重置缓存
    if (!collectionState.collection?.sortByField) return;
    // reset();
  }, [collectionState.collection?.sortByField, collectionState.collection?.sortByDirection])

  useEffect(() => {
    window.addEventListener('resize', handleCloseActionPopper, false);

    return () => {
      window.removeEventListener('resize', handleCloseActionPopper, false);
    }
  }, []);

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={itemCount}
      ref={infiniteLoaderRef}
    >
      {
        ({ onRowsRendered, registerChild }) => {
          const listProps = {
            ref: registerChild,
            rowCount: itemCount,
            deferredMeasurementCache: cache,
            rowHeight: cache.rowHeight,
            onRowsRendered,
            rowRenderer,
            onScroll: handleCloseActionPopper,
          }

          const gridProps = {
            ref: (grid) => {
              gridRef.current = grid;
              // window.grid = grid;
              registerChild(grid);
            },
            style: { padding: gridGap },
            // deferredMeasurementCache: cache,
            columnWidth: (contentWidth - gridGap * 2) / columnCount,
            columnCount,
            rowCount: Math.ceil(itemCount / columnCount),
            rowHeight: cache.rowHeight,
            cellRenderer: cellRenderer,
            onSectionRendered: ({ columnStartIndex, columnStopIndex, rowStartIndex, rowStopIndex }) => {
              onRowsRendered({
                startIndex: rowStartIndex * columnCount + columnStartIndex,
                stopIndex: rowStopIndex * columnCount + columnStopIndex,
              });
            }
          }

          if (!isTouch && matchUpSm && !process.env.CHROME_EXT) {
            return <AutoSizer onResize={handleResize}>
              {({ width, height, scrollTop }) => {
                if (!width) return null;

                if (view === 'LIST') {
                  return (
                    <List
                      {...listProps}
                      width={width}
                      height={height}
                      scrollTop={scrollTop}
                    />
                  )
                }

                if (view === 'CARDS') {
                  return (
                    <Grid
                      {...gridProps}
                      width={width}
                      height={height}
                      scrollTop={scrollTop}
                    />
                  )
                }
              }}
            </AutoSizer>
          }
          return (
            <WindowScroller onScroll={handleCloseActionPopper}>
              {({ height, isScrolling, scrollTop }) => 
                <AutoSizer disableHeight onResize={handleResize}>
                  {({ width }) => {
                    if (view === 'LIST') {
                      return (
                        <List
                          {...listProps}
                          width={width}
                          height={height}
                          autoHeight
                          scrollTop={scrollTop}
                        />
                      )
                    }

                    if (view === 'CARDS') {
                      return (
                        <Grid
                          {...gridProps}
                          width={width}
                          height={height}
                          autoHeight
                          scrollTop={scrollTop}
                        />
                      )
                    }
                  }}
                </AutoSizer>
              }
            </WindowScroller>  
          )
        }
      }
    </InfiniteLoader>
  )
};
