import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function OpenInNewOutlined(props) {
  return (
    <SvgIcon {...props}>
      <path fill-rule="evenodd" d="M14.25 3.5a.75.75 0 0 1 .75-.75h5.8a.95.95 0 0 1 .95.95v5.8a.75.75 0 0 1-1.5 0V5.31l-7.72 7.72a.75.75 0 1 1-1.06-1.06l7.72-7.72H15a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd"/>
      <path fill-rule="evenodd" d="M3.25 10.5A6.75 6.75 0 0 1 10 3.75h2a.75.75 0 0 1 0 1.5h-2a5.25 5.25 0 0 0-5.25 5.25v4c0 2.9 2.35 5.25 5.25 5.25h4c2.9 0 5.25-2.35 5.25-5.25v-2a.75.75 0 0 1 1.5 0v2A6.75 6.75 0 0 1 14 21.25h-4a6.75 6.75 0 0 1-6.75-6.75v-4Z" clip-rule="evenodd"/>
  </SvgIcon>
  );
}

export default OpenInNewOutlined;
