import React, { useState, useEffect }  from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, Alert, Link, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import InputFormControl from '@components/InputFormControl';
import { REGISTER } from '@apis/AuthApi';

const INVITATION_CODE_REGX = /KP-[23456789ABCDEFGHJKMNPQRSTUVWXYZ]{8}/; // 邀请码正则

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: 16,
  height: 48,
}));

export default (props) => {
  const {
    onSubmittingStatusChange,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [register, { data, loading, error }] = useMutation(REGISTER);
  const [errorMessage, setErrorMessage] = useState();
  const [showInvitationDialog, setShowInvitationDialog] = useState(false);

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('请输入正确的电子邮箱地址').required('请输入电子邮箱地址'),
    password: Yup.string().required('请输入密码')
      .min(6, '密码至少需要6个字符')
      .max(30, '密码长度不能超过30个字符')
      // .matches(/[\d]/, '密码至少包含一个数字')
      // .matches(/[a-z]/, '密码至少包含一个小写字母')
      // .matches(/[A-Z]/, '密码至少包含一个大写字母')
      // .matches(/[^\w]/, '密码至少包含一个特殊字符')
      .trim()
      .notOneOf([Yup.ref('email')], '密码不能与电子邮箱相同'),
    invitationCode: Yup.string().required('请输入邀请码').matches(INVITATION_CODE_REGX, '请输入正确的邀请码')
  });

  const defaultInvitationCode = (new URLSearchParams(location.search)).get('invitation'); // 获取URL参数中的邀请码

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      invitationCode: defaultInvitationCode,
    },
    validationSchema: RegisterSchema,
    validateOnBlur: validateAfterSubmit,
    validateOnChange: validateAfterSubmit,
    onSubmit: (values, { setSubmitting }) => {
      register({
        variables: {
          input: values,
        },
        onCompleted: ({ register: { user} }) => {
          setErrorMessage(null);
          if (user) {
            navigate('/login', { state: { from: 'signup', status: 'success', message: '恭喜你已经注册成功！请登录', email: user.email } });
          }
        },
        onError: (ex) => {
          setErrorMessage(ex.message);
          setSubmitting(false);
        }
      });
    }
  })

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  const handleCloseInvitationDialog = () => {
    setShowInvitationDialog(false);
  }

  useEffect(() => {
    onSubmittingStatusChange(isSubmitting);
  }, [isSubmitting]);

  useEffect(() => {
    // URL参数中的邀请码不正确，重定向到注册页
    if (defaultInvitationCode && !INVITATION_CODE_REGX.test(defaultInvitationCode)) {
      setFieldValue('invitationCode', '');
      navigate('/signup', { replace: true });
    }
  }, []);

  return (
    <>
      {
        errorMessage && (
          <Alert sx={{ mb: 2 }} severity="error">{ errorMessage }</Alert>
        )
      }
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <Stack>
            <Stack spacing={2}>
              <InputFormControl
                label="电子邮件地址"
                type="username"
                placeholder="电子邮件地址"
                autoComplete="username"
                disabled={isSubmitting}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                {...getFieldProps('email')}
              />

              <InputFormControl
                label="密码"
                type={showPassword ? 'text' : 'password'}
                placeholder="密码"
                disabled={isSubmitting}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                {...getFieldProps('password')}
              />

              <InputFormControl
                label="邀请码"
                type="invitationCode"
                placeholder="邀请码"
                autoComplete="invitationCode"
                disabled={!!defaultInvitationCode || isSubmitting}
                error={Boolean(touched.invitationCode && errors.invitationCode)}
                helperText={touched.invitationCode && errors.invitationCode}
                {...getFieldProps('invitationCode')}
              />
            </Stack>

            {
              !defaultInvitationCode && (
                <Stack direction="row" sx={{ mt: 1 }}>
                  <Link
                    variant="subtitle2"
                    onClick={() => {
                      setShowInvitationDialog(true);
                    }}
                  >
                    没有邀请码？
                  </Link>
                  <Box sx={{ flex: 1 }}></Box>
                </Stack>
              )
            }

            <StyledLoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              loadingPosition="start"
              sx={{ mt: 4 }}
              onClick={() => {
                setValidateAfterSubmit(true);
              }}
            >
              注册
            </StyledLoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      <Dialog
        open={showInvitationDialog}
        onClose={handleCloseInvitationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          如何获取邀请码
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            在内测期间，想要拿到邀请码的小伙伴们，请在首页申请加入候选名单，同时记得关注奇普书签在如下平台的账号获取最新动态。
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <Link target="_blank" href="https://www.xiaohongshu.com/user/profile/64c72e3a000000000e026e37?xhsshare=CopyLink&appuid=565bcd7082718c740a40c322&apptime=1711813128">小红书</Link>
              <Link target="_blank" href="https://okjk.co/RxhuTi">即刻</Link>
              <Link target="_blank" href="https://weibo.com/u/7858148641">微博</Link>
              <Link target="_blank" href="https://x.com/kiipuapp?s=21&t=l8dR1sHPjWiNfKhTo3YrrA">X(@KiipuApp)</Link>
              <Link target="_blank" href="https://b23.tv/DNJse3I">哔哩哔哩</Link>
              <Link target="_blank" href="https://v.douyin.com/iY1PYjpA/">抖音</Link>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInvitationDialog} autoFocus>
            确定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
