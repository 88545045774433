import React, { forwardRef } from 'react';
import { CircularProgress, Box } from '@mui/material';

export default forwardRef(({ height = 16, size = 72 }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height }}>
      <CircularProgress size={size} />
    </Box>
  )
});
