export default function Autocomplete(theme) {
  return {
    MuiAutocomplete: {
      defaultProps: {
      },
      styleOverrides: {
        paper: {
          boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          ...theme.palette.mode === 'dark' && {
            boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          },
        },
        listbox: {
          padding: '4px 0',
        },
        option: {
          fontSize: theme.typography.body2.fontSize,

          '& .MuiSvgIcon-root': {
            fontSize: 18,
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(1.5),
          },
        },
      },
    }
  };
}
