import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function AddCircleOutlined(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12,1.75 C6.33908131,1.75 1.75,6.33908131 1.75,12 C1.75,17.6609187 6.33908131,22.25 12,22.25 C17.6609187,22.25 22.25,17.6609187 22.25,12 C22.25,6.33908131 17.6609187,1.75 12,1.75 Z M12,3.25 C16.8324916,3.25 20.75,7.16750844 20.75,12 C20.75,16.8324916 16.8324916,20.75 12,20.75 C7.16750844,20.75 3.25,16.8324916 3.25,12 C3.25,7.16750844 7.16750844,3.25 12,3.25 Z" id="Oval"></path>
      <path d="M12,7.25 C12.4142136,7.25 12.75,7.58578644 12.75,8 L12.75,11.25 L16,11.25 C16.4142136,11.25 16.75,11.5857864 16.75,12 C16.75,12.4142136 16.4142136,12.75 16,12.75 L12.75,12.75 L12.75,16 C12.75,16.4142136 12.4142136,16.75 12,16.75 C11.5857864,16.75 11.25,16.4142136 11.25,16 L11.25,12.75 L8,12.75 C7.58578644,12.75 7.25,12.4142136 7.25,12 C7.25,11.5857864 7.58578644,11.25 8,11.25 L11.25,11.25 L11.25,8 C11.25,7.58578644 11.5857864,7.25 12,7.25 Z"></path>
    </SvgIcon>
  );
}

export default AddCircleOutlined;

