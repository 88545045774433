import React from 'react';
import snackbarInitialState from './snackbarInitialState';
import * as SnackbarActionTypes from './SnackbarActionTypes';

export const SnackbarReducer = (state = snackbarInitialState, action) => {
  switch(action.type) {
    case SnackbarActionTypes.SHOW_SNACKBAR:
      return {
        ...state,
        open: true,
        message: action.data,
      };
    case SnackbarActionTypes.HIDE_SNACKBAR:
      return {
        ...state,
        open: false,
      }
    default:
      return state;
  }
}

export const SnackbarContext = React.createContext(snackbarInitialState);
