import { gql } from "@apollo/client";

// 查询所有书签
export const GET_BOOKMARKS = gql`
  query Bookmarks($first: Float, $after: String, $collection: String, $keywords: String, $field: BookmarkSortByField!, $direction: SortByDirection!) {
    bookmarks(first: $first, orderBy: { field: $field, direction: $direction }, after: $after, collection: $collection, keywords: $keywords) {
      edges {
        node {
          id
          title
          srcTitle
          url
          srcUrl
          urlHost
          faviconUrl
          description
          image
          type
          extra
          card
          isStarred
          collection {
            id
            name
          }
          note
          createdAt
          updatedAt
          from
          parser
          creator {
            nickname
          }
          inProgress
          theme
          icon
          datePublished
          imageUploadPath
          tags
        }
      }
      pageInfo {
        afterCursor
      }
    }
  }
`;

// 查询所有书签（管理员）
export const GET_BOOKMARKS_FOR_ADMIN = gql`
  query bookmarksForAdmin($first: Float, $after: String, $keywords: String, $field: BookmarkSortByField!, $direction: SortByDirection!) {
    bookmarksForAdmin(first: $first, orderBy: { field: $field, direction: $direction }, after: $after, keywords: $keywords) {
      edges {
        node {
          id
          title
          srcTitle
          url
          srcUrl
          urlHost
          faviconUrl
          description
          image
          type
          extra
          card
          isStarred
          collection {
            id
            name
          }
          note
          createdAt
          updatedAt
          from
          parser
          creator {
            nickname
          }
          inProgress
          theme
          icon
          datePublished
          imageUploadPath
          tags
        }
      }
      pageInfo {
        afterCursor
      }
    }
  }
`;

// 删除书签
export const DELETE_BOOKMARK = gql`
  mutation($id: String!) {
    bookmarkDelete(id: $id) {
      bookmark {
        id
        title
      }
    }
  }
`;

// 恢复书签
export const RECORVERY_BOOKMARK = gql`
  mutation($id: String!) {
    bookmarkRecorvery(id: $id) {
      bookmark {
        id
        title
        collection {
          id
          name
        }
      }
    }
  }
`;

// 永久删除书签
export const FOREVER_DELETE_BOOKMARK = gql`
  mutation($id: String!) {
    bookmarkForeverDelete(id: $id) {
      bookmark {
        id
        title
      }
    }
  }
`;

// 移动书签
export const MOVE_BOOKMARK = gql`
  mutation($id: String!, $collectionId: String) {
    bookmarkMove(id: $id, collectionId: $collectionId) {
      bookmark {
        id
        collection {
          id
          name
        }
      }
    }
  }
`;

// 添加书签
// export const ADD_BOOKMARK = gql`
//   mutation($input: BookmarkCreateInput!) {
//     bookmarkCreate(input: $input) {
//       bookmark {
//         id
//         url
//         urlHost
//         title
//         description
//         faviconUrl
//         image
//         extra
//         collectionId
//         from
//         card
//         parser
//         text
//       }
//     }
//   }
// `;

// 从元信息添加书签
// export const ADD_BOOKMARK_FROM_META = gql`
//   mutation($metaId: String!, $input: BookmarkCreateFromMetaInput!) {
//     bookmarkCreateFromMeta(metaId: $metaId, input: $input) {
//       bookmark {
//         id
//         url
//         urlHost
//         title
//         description
//         faviconUrl
//         image
//         extra
//         collectionId
//         card
//         parser
//         text
//         from
//       }
//     }
//   }
// `;

// 获取已加星标列表
export const GET_STARRED_BOOKMARKS = gql`
  query StarredBookmarks($first: Float, $after: String, $keywords: String, $field: BookmarkSortByField!, $direction: SortByDirection!) {
    starredBookmarks(first: $first, orderBy: { field: $field, direction: $direction }, after: $after, keywords: $keywords) {
      edges {
        node {
          bookmark {
            id
            title
            srcTitle
            url
            faviconUrl
            description
            image
            type
            extra
            card
            collection {
              id
              name
            }
            note
            createdAt
            inProgress
            theme
            icon
            datePublished
            imageUploadPath
            tags
          }
        }
      }
      pageInfo {
        afterCursor
      }
    }
  }
`;

// 获取所有书签（前100条）
export const GET_BOOKMARK_ALL = gql`
  query BookmarkAll {
    bookmarkAll {
      id
      title
      url
      srcUrl
      urlHost
      faviconUrl
      description
      image
      type
      extra
      card
      isStarred
      collection {
        id
        name
      }
      note
      createdAt
      from
      parser
      creator {
        nickname
      }
    }
  }
`;

// 获取公开收藏夹书签列表
export const GET_PUBLIC_BOOKMARKS = gql`
  query PublicBookmarks($first: Float, $after: String, $collection: String, $keywords: String, $field: BookmarkSortByField!, $direction: SortByDirection!) {
    publicBookmarks(first: $first, orderBy: { field: $field, direction: $direction }, after: $after, collection: $collection, keywords: $keywords) {
      edges {
        node {
          id
          title
          url
          faviconUrl
          description
          image
          type
          extra
          card
          collection {
            id
            name
          }
          createdAt
          from
          parser
          creator {
            nickname
          }
        }
      }
      pageInfo {
        afterCursor
      }
    }
  }
`;

// export const UPDATE_BOOKMARK = gql`
//   mutation($id: String!, $input: BookmarkUpdateInput!) {
//     bookmarkUpdate(id: $id, input: $input) {
//       bookmark {
//         id
//         url
//         title
//         description
//         faviconUrl
//         image
//         type
//         extra
//         card
//         parser
//         from
//       }
//     }
//   }
// `;

export const PARTIAL_UPDATE_BOOKMARK = gql`
  mutation($id: String!, $input: BookmarkPartialUpdateInput!) {
    bookmarkPartialUpdate(id: $id, input: $input) {
      bookmark {
        id
        title
        srcTitle
      }
    }
  }
`;

// 云端解析更新书签
export const BOOKMARK_CLOUSE_PARSER_ASYNC = gql`
  mutation($input: BookmarkCloudParserAsyncInput!) {
    bookmarkCloudParserAsync(input: $input) {
      bookmarkParserRecordId
    }
  }
`;

// 云端解析更新书签
export const UPDATE_BOOKMARK_CLOUD_PARSER = gql`
  mutation($input: BookmarkCloudParserUpdateInput!) {
    bookmarkCloudParserUpdate(input: $input) {
      bookmark {
        id
        url
        title
        description
        faviconUrl
        image
        extra
        collectionId
        from
        card
        parser
        text
      }
    }
  }
`;

// 创建书签
export const CREATE_BOOKMARK = gql`
  mutation($input: BookmarkCreateInput!) {
    bookmarkCreate(input: $input) {
      bookmark {
        id
        title
        url
        urlHost
        faviconUrl
        description
        image
        type
        extra
        card
        isStarred
        collection {
          id
          name
        }
        note
        createdAt
        from
        parser
        creator {
          nickname
        }
        inProgress
        theme
        icon
        datePublished
        imageUploadPath
      }
    }
  }
`;

// 获取书签保存状态
export const GET_BOOKMARK_PROGRESS_STATUS = gql`
  query BookmarkProgressStatus($pendingParseBookmarkIds: [String!]!, $pendingUploadImageBookmarkIds: [String!]!) {
    bookmarkProgressStatus(pendingParseBookmarkIds: $pendingParseBookmarkIds, pendingUploadImageBookmarkIds: $pendingUploadImageBookmarkIds) {
      pendingParseBookmarkIds
      completeParseBookmarks {
          id
          inProgress
          imageUploadPath
          title
          url
          srcUrl
          urlHost
          image
          faviconUrl
          icon
          description
          parser
          card
          meta {
            extraObj
          }
          tags
      }
      pendingUploadImageBookmarkIds
      uploadImageBookmarks {
        id
        imageUploadPath
      }
    }
  }
`;

// 获取书签详情
export const GET_BOOKMARK = gql`
  query Bookmark($bookmarkId: String) {
    bookmark(id: $bookmarkId) {
      id
      title
      description
      extra
      meta {
        extraObj
      }
    }
  }
`

// 获取书签完整详情
export const GET_BOOKMARK_FOR_ADMIN = gql`
query bookmarkForAdmin($bookmarkId: String) {
  bookmarkForAdmin(id: $bookmarkId) {
    id
    title
    url
    srcUrl
    urlHost
    faviconUrl
    description
    image
    type
    card
    from
    parser
    text
    meta {
      id
      url
      title
      description
      faviconUrl
      image
      type
      card
      from
      parser
      extraObj
    }
  }
}
`

// 更新书签详情（管理员）
export const UPDATE_BOOKMARK_FOR_ADMIN = gql`
  mutation($id: String!, $input: BookmarkUpdateInput!) {
    bookmarkUpdateForAdmin(id: $id, input: $input) {
      bookmark {
        id
        url
        srcUrl
        urlHost
        title
        description
        faviconUrl
        image
        type
        extra
        card
        parser
        from
      }
    }
  }
`;

// 从元数据更新书签
export const UPDATE_BOOKMARK_FROM_META = gql`
  mutation($input: BookmarkUpdateFromMetaInput!) {
    bookmarkUpdateFromMeta(input: $input) {
      bookmark {
        id
        url
        srcUrl
        urlHost
        title
        description
        faviconUrl
        image
        type
        extra
        card
        parser
        from
      }
    }
  }
`;
