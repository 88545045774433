export const SHOW_ACTION_MENU = 'SHOW_ACTION_MENU';
export const HIDE_ACTION_MENU = 'HIDE_ACTION_MENU';
export const OPERATION_FETCH_COLLECTION = 'OPERATION_FETCH_COLLECTION';
export const SET_COLLECTION_LIST = 'SET_COLLECTION_LIST';
export const SET_EXPAND_COLLECTION = 'SET_EXPAND_COLLECTION';
export const ADD_EXPAND_COLLECTION = 'ADD_EXPAND_COLLECTION';
export const DELETE_EXPAND_COLLECTION = 'DELETE_EXPAND_COLLECTION';
export const SET_PINNED_COLLECTION_LIST = 'SET_PINNED_COLLECTION_LIST';
export const OPERATION_REMOVE_PINNED_COLLECTION = 'OPERATION_REMOVE_PINNED_COLLECTION';
export const OPERATION_CREATE_COLLECTION = 'OPERATION_CREATE_COLLECTION';
export const OPERATION_UPDATE_COLLECTION = 'OPERATION_UPDATE_COLLECTION';
export const OPERATION_DELETE_COLLECTION = 'OPERATION_DELETE_COLLECTION';
export const OPERATION_SHARE_COLLECTION = 'OPERATION_SHARE_COLLECTION';
export const OPERATION_QUIT_COLLECTION = 'OPERATION_QUIT_COLLECTION';
export const UPDATE_COLLECTION_COUNT = 'UPDATE_COLLECTION_COUNT';
export const ROLLBACK_OPERATION = 'ROLLBACK_OPERATION';
// export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
// export const SET_DRAG_HOVER_COLLECTION = 'SET_DRAG_HOVER_COLLECTION';