import React, { useContext } from 'react';
import { MenuItem, MenuList, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CollectionListContext } from '@contexts/collectionList/collectionListReducer';
import * as CollectionListActionTypes from '@contexts/collectionList/CollectionListActionTypes';

function CollectionActionMenuList({ onMenuItemClick }) {
  const theme = useTheme();
  const { state, dispatch } = useContext(CollectionListContext);

  function handleListKeyDown(evt) {
    if (evt.key === 'Tab') {
      evt.preventDefault();
      dispatch({ type: CollectionListActionTypes.HIDE_ACTION_MENU });
    } else if (evt.key === 'Escape') {
      dispatch({ type: CollectionListActionTypes.HIDE_ACTION_MENU });
    }
  }

  const menuListItems = [];

  function makeMenuListItems(menus) {
    for (const menu of menus) {
      const { name, icon: ActionIcon, role } = menu;
      menuListItems.push(
        <MenuItem
          dense
          onClick={onMenuItemClick.bind(this, menu)}
        >
          {
            ActionIcon && (
              <ListItemIcon>
                <ActionIcon />
              </ListItemIcon>
            )
          }
          <ListItemText
            primary={name}
            sx={{ color: role === 'destructive' ? theme.palette.error.main : theme.palette.text.primary }}
          />
        </MenuItem>
      )
    }
  }

  if (Array.isArray(state.menu?.[0])) {
    for (let i = 0; i < state.menu.length; i++) {
      makeMenuListItems(state.menu[i])
      if (i < state.menu.length - 1) {
        menuListItems.push(<Divider />)
      }
    }
  } else if (Array.isArray(state.menu)) {
    makeMenuListItems(state.menu);
  }

  return (
    <MenuList
      autoFocus
      onKeyDown={handleListKeyDown}
    >
      {
        menuListItems.map(MenuListItem => MenuListItem)
      }
    </MenuList>
  );
}

export default CollectionActionMenuList;
