import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function InboxOutlined(props) {
  return (
    <SvgIcon {...props}>
      <path fill-rule="evenodd" d="M4.423 5.985A3.918 3.918 0 0 1 8.281 2.75h7.438a3.92 3.92 0 0 1 3.858 3.235l1.591 8.987a5.346 5.346 0 0 1-5.264 6.278H8.096a5.346 5.346 0 0 1-5.264-6.278l1.591-8.987ZM8.281 4.25a2.418 2.418 0 0 0-2.38 1.996l-1.592 8.987a3.847 3.847 0 0 0 3.787 4.517h7.808a3.846 3.846 0 0 0 3.787-4.517L18.1 6.246a2.418 2.418 0 0 0-2.381-1.996H8.28Z" clip-rule="evenodd"/>
      <path fill-rule="evenodd" d="M3.25 14a.75.75 0 0 1 .75-.75h5a.75.75 0 0 1 0 1.5H4a.75.75 0 0 1-.75-.75Zm11 0a.75.75 0 0 1 .75-.75h5a.75.75 0 0 1 0 1.5h-5a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd"/>
      <path fill-rule="evenodd" d="M14.652 16.652A3.75 3.75 0 0 1 8.25 14a.75.75 0 0 1 1.5 0 2.25 2.25 0 0 0 4.5 0 .75.75 0 0 1 1.5 0 3.75 3.75 0 0 1-1.098 2.652Z" clip-rule="evenodd"/>
    </SvgIcon>
  );
}

export default InboxOutlined;
  