import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IconButton, InputBase, InputAdornment, Box, Typography, Link } from '@mui/material';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from 'formik';
import { HOTKEYS_SEARCH } from '@constants/hotkeys';
import Keycap from '@components/Keycap';
import CloseOutlinedIcon from '@icons/CloseOutlined';
import SearchOutlinedIcon from '@icons/SearchOutlined';

const SearchInput = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  position: 'relative',
  borderRadius: '9999px',
  backgroundColor: alpha(theme.palette.grey[400], 0.25),
  borderWidth: 0,
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  flex: 1,
  color: theme.palette.text.primary,
  // width: theme.spacing(expand ? 32 : 24),
  // transition : 'width .25s',
  '& .MuiInputBase-input': {
    // padding: theme.spacing(.7, 1, .7, 0),
    // vertical padding + font size from searchIcon
    height: '38px',
    padding: 0,
    paddingLeft: `calc(1em + ${theme.spacing(3.5)})`,
    transition: theme.transitions.create('width'),
    // width: '100%',
    backgroundColor: 'transparent', // fix: FlowBite
    '&:focus': { // fix: FlowBite
      outline: 0,
      boxShadow: 'none',
    },
    fontSize: '15px',
  },
  '&::-webkit-search-cancel-button': {
    /* Remove default */
    WebkitAppearance: 'none',
    /* Now your own custom styles */
    height: '10px',
    width: '10px',
    background: 'red',
    /* Will place small red box on the right of input (positioning carries over) */
  }
}));

export default (props) => {
  const {
    keywords,
    showTitle = true,
    index = true,
    onHeightChange,
  } = props;

  const theme = useTheme();
  const inputRef = useRef();
  const [inputFocus, setInputFocus] = useState(false);
  const [expandSearch, setExpandSearch] = useState(!!keywords);
  const navigate = useNavigate();
  const location = useLocation();
  
  const formik = useFormik({
    initialValues: {
      keywords,
    },
    onSubmit: ({ keywords: value }) => {
      // 移动端搜索后自动收起输入法
      // inputRef.current.blur();
      // onSearch(value, innerScope === 'all' && collection !== 'all');
      if (!value) {
        navigate('/search', { state: { autoFocus: false } })
      } else {
        navigate(`/all/q/${value}`)
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setValues, handleBlur } = formik;

  // 清除搜索内容
  const clearSearch = () => {
    if (!!values.keywords) {
      inputRef.current.focus(); // 清除后继续聚焦到输入框
    }
    setValues({ keywords: '' });
    // handleSubmit();
  }

  const InputEndAdornment = () => {
    if (values.keywords) {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={clearSearch}
            onMouseDown={evt => { evt.preventDefault() }}
            sx={{ fontSize: '0.75rem', padding: '3px', margin: '9px' }}
          >
            <CloseOutlinedIcon fontSize="inherit" />
          </IconButton>
        </InputAdornment>
      );
    } else if (!expandSearch) {
      return (
        <InputAdornment position="end">
          <Keycap
            hotkeys={HOTKEYS_SEARCH}
            style={{ padding: theme.spacing(1) }}
            onClick={() => { inputRef.current.focus() }}
          />
        </InputAdornment>
      )
    }

    return null;
  }

  useEffect(() => {
    onHeightChange && onHeightChange(inputFocus ? 48 : 96);
  }, [inputFocus]);

  useEffect(() => {
    setValues({
      keywords: keywords || '',
    });
    if (keywords) {
      setExpandSearch(true);
    }
  }, [keywords]);

  useEffect(() => {
    if (location.state?.autoFocus !== false && !keywords) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Box
      sx={{
        px: 2,
        ...index && {
          position: 'absolute', width: '100%', transition: theme.transitions.create('top'), top: inputFocus ? -48 : 0
        }
      }}
    >
      {
        showTitle && (
          <Box sx={{ height: 48, display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h4"
              component="h4"
              sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}
            >
              搜索
            </Typography>
          </Box>
        )
      }
      <Box sx={{ height: 48, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flex: 1 }}>
          <FormikProvider value={formik}>
            <Form
              autoComplete="off"
              onSubmit={(e) => {
                handleSubmit(e);
                inputRef.current.blur();
              }}
            >
              <SearchInput>
                <SearchIconWrapper>
                  <SearchOutlinedIcon sx={{ fontSize: '1rem' }} />
                </SearchIconWrapper>
                <StyledInputBase
                  // active={inputFocus || !!values.keywords || !!scope}
                  inputRef={inputRef}
                  placeholder='搜索'
                  inputProps={{ 'aria-label': '搜索', spellcheck: 'false' }}
                  type="text"
                  endAdornment={<InputEndAdornment />}
                  onFocus={() => setInputFocus(true)}
                  {...getFieldProps('keywords')}
                  onBlur={(e) => {
                    handleBlur(e);
                    setInputFocus(false)
                  }}
                />
              </SearchInput>
            </Form>
          </FormikProvider>
        </Box>
        {
          !index && (
            <Link
              onClick={() => {
                navigate('/search', { state: { autoFocus: false } })
              }}
              sx={{ textDecoration: 'none', ml: 2, fontSize: '15px', color: theme.palette.text.secondary, cursor: 'pointer' }}
            >
              取消
            </Link>
          )
        }
      </Box>
    </Box>
  )
}
