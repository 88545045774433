import React, { useReducer, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from "@apollo/client";
import { SnackbarContext } from '@contexts/snackbar/snackbarReducer';
import { GET_COLLECTIONS, DELETE_COLLECTION } from '@apis/CollectionApi';
import { ADD_PINNED_COLLECTION, REMOVE_PINNED_COLLECTION } from '@apis/PinnedCollectionApi';
import { SHOW_SNACKBAR } from '@contexts/snackbar/SnackbarActionTypes';
import AddCollectionDialog from '@components/AddCollectionDialog';
import UpdateCollectionDialog from '@components/UpdateCollectionDialog';
import ShareCollectionDialog from '@components/ShareCollectionDialog';
import DeleteCollectionDialog from '@components/DeleteCollectionDialog';
import QuitCollectionDialog from '@components/QuitCollectionDialog';
import CollectionActionMenuList from '@components/CollectionActionMenuList';
import ActionPopper from '@components/ActionPopper';
import { CollectionListContext, CollectionListReducer } from './collectionListReducer';
import initialState from './collectionListInitialState';
import * as CollectionListActionTypes from './CollectionListActionTypes';
import * as CollectionListConstants from './CollectionListConstants';

const getCollectionByPath = (pathname) => {
  if (pathname.startsWith('/collection')) {
    return pathname.replace('/collection/', '');
  }
  
  return pathname.slice(1);
}

const CollectionListProvider = (props) => {
  const navigate = useNavigate();
  const [ state, dispatch ] = useReducer(CollectionListReducer, initialState);

  const [addDialogShow, setAddDialogShow] = useState(false);
  const [updateDialogShow, setUpdateDialogShow] = useState(false);
  const [shareDialogShow, setShareDialogShow] = useState(false);
  const [deleteDialogShow, setDeleteDialogShow] = useState(false);
  const [quitDialogShow, setQuitDialogShow] = useState(false);
  const [selCollection, setSelCollection] = useState({});

  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);

  const [getCollections] = useLazyQuery(GET_COLLECTIONS);
  const [removePinnedCollection] = useMutation(REMOVE_PINNED_COLLECTION);
  const [deleteCollection] = useMutation(DELETE_COLLECTION);
  const [getCollectionsForce] = useLazyQuery(GET_COLLECTIONS, { fetchPolicy: 'network-only' });
  const [addPinnedCollection] = useMutation(ADD_PINNED_COLLECTION);

  const fetchCollectionList = (force) => {
    const params = {
      onCompleted: ({ collections, pinnedCollections }) => {
        dispatch({ type: CollectionListActionTypes.SET_COLLECTION_LIST, data: collections });
        dispatch({ type: CollectionListActionTypes.SET_PINNED_COLLECTION_LIST, data: pinnedCollections });
      }
    };

    if (force) {
      params.fetchPolicy = 'network-only';
    }

    getCollections(params);
  }

  useEffect(() => {
    if (!state.operation) return;

    switch (state.operation.type) {
      case CollectionListActionTypes.OPERATION_FETCH_COLLECTION: {
        fetchCollectionList(!!state.operation.params.force);
        break;
      }
      case CollectionListActionTypes.OPERATION_REMOVE_PINNED_COLLECTION: {
        removePinnedCollection({
          variables: {
            collectionId: state.operation.params
          },
          onCompleted: () => {
            fetchCollectionList(true);
          },
          onError: (err) => {
            console.log(err);
          }
        });
        break;
      }
      case CollectionListActionTypes.OPERATION_CREATE_COLLECTION: {
        setAddDialogShow(true);
        break;
      }
      case CollectionListActionTypes.OPERATION_UPDATE_COLLECTION: {
        setSelCollection(state.operation.params);
        setUpdateDialogShow(true);
        break;
      }
      case CollectionListActionTypes.OPERATION_DELETE_COLLECTION: {
        setSelCollection(state.operation.params);
        setDeleteDialogShow(true);
        break;
      }
      case CollectionListActionTypes.OPERATION_SHARE_COLLECTION: {
        setSelCollection(state.operation.params);
        setShareDialogShow(true);
        break;
      }
      case CollectionListActionTypes.OPERATION_QUIT_COLLECTION: {
        setSelCollection(state.operation.params);
        setQuitDialogShow(true);
        break;
      }
    }
  }, [state.operation]);

  async function doMenuAction(action) {
    switch (action) {
      case CollectionListConstants.ACTION_DELETE_COLLECTION: {
        setSelCollection({
          name: state.name,
          id: state.id
        });
        setDeleteDialogShow(true);
        break;
      }
      case CollectionListConstants.ACTION_ADD_SUB_COLLECTION: {
        setSelCollection({
          id: state.id
        });
        setAddDialogShow(true);
        break;
      }
      case CollectionListConstants.ACTION_UPDATE_COLLECTION: {
        setSelCollection({
          name: state.name,
          id: state.id
        });
        setUpdateDialogShow(true);
        break;
      }
      case CollectionListConstants.ACTION_SHARE_COLLECTION: {
        setSelCollection({
          name: state.name,
          id: state.id
        });
        setShareDialogShow(true);
        break;
      }
      case CollectionListConstants.ACTION_PIN_COLLECTION: {
        addPinnedCollection({
          variables: {
            collectionId: state.id
          },
          onCompleted: () => {
            fetchCollectionList(true);
          },
          onError: (err) => {
            console.log(err.graphQLErrors);
            let msg = '';
            switch (err.graphQLErrors[0].extensions.code) {
              case 'PINNED_COLLECTIONS_FULL': {
                msg = '添加失败，快捷访问最多只能添加3个收藏夹';
                break;
              }
              case 'PINNED_COLLECTION_EXISTED': {
                msg = '当前收藏夹已存在快捷访问';
                break;
              }
            }
            snackbarDispatch({
              type: SHOW_SNACKBAR,
              data: msg
            });
          }
        });
        break;
      }
      case CollectionListConstants.ACTION_QUIT_COLLECTION: {
        setSelCollection({
          name: state.name,
          id: state.id
        });
        setQuitDialogShow(true);
        break;
      }
    }
  }

  useEffect(() => {
    // 取消选中收藏夹记录
    if (!addDialogShow && !updateDialogShow && !shareDialogShow && !deleteDialogShow) {
      setSelCollection({});
    }
  }, [addDialogShow, updateDialogShow, shareDialogShow, deleteDialogShow])

  return (
    <CollectionListContext.Provider value={{ state, dispatch }}>
      { props.children }
      <ActionPopper
        anchorEl={state.element}
        // containerRef={props.containerRef}
        onClose={() => {
          dispatch({ type: CollectionListActionTypes.HIDE_ACTION_MENU });
        }}
        // popperOptions={{
        //   strategy: 'fixed',
        // }}
      >
        <CollectionActionMenuList
          onMenuItemClick={async ({ path, action }) => {
            dispatch({ type: CollectionListActionTypes.HIDE_ACTION_MENU });
            if (path) {
              navigate(path);
            } else if (action) {
              doMenuAction(action);
            }
          }}
        />
      </ActionPopper>
      <AddCollectionDialog
        parentId={selCollection.id}
        open={addDialogShow}
        onClose={() => { setAddDialogShow(false); }}
        onSuccess={({ collection }) => {
          setAddDialogShow(false);
          // snackbarDispatch({ type: SHOW_SNACKBAR, data: `已创建 ${collection.name}` });
          // 新建书签若有父菜单则展开
          if (collection.parent?.id) {
            dispatch({ type: CollectionListActionTypes.ADD_EXPAND_COLLECTION, data: collection.parent?.id });
          }
          fetchCollectionList(true);
        }}
      />
      <UpdateCollectionDialog
        collection={selCollection}
        open={updateDialogShow}
        onClose={() => { setUpdateDialogShow(false); }}
        onSuccess={() => {
          setUpdateDialogShow(false);
          fetchCollectionList(true);
        }}
      />
      {/* <PublicShareCollectionDialog
        collection={selCollection}
        open={shareDialogShow}
        onClose={() => { setShareDialogShow(false); }}
        onSuccess={() => {
          fetchCollectionList(true);
        }}
      /> */}
      <ShareCollectionDialog
        collection={selCollection}
        open={shareDialogShow}
        onClose={() => { setShareDialogShow(false); }}
        onSuccess={() => {
          // setShareDialogShow(false);
          fetchCollectionList(true);
        }}
      />
      <DeleteCollectionDialog
        collection={selCollection}
        open={deleteDialogShow}
        onClose={() => { setDeleteDialogShow(false); }}
        onSuccess={({ deletedIds = [] }) => {
          // snackbarDispatch({ type: SHOW_SNACKBAR, data: `已删除 ${collection.name} 及其所有子收藏夹` });
          setDeleteDialogShow(false);
          fetchCollectionList(true);
          
          if (deletedIds.includes(getCollectionByPath(location.pathname))) {
            navigate('/inbox');
          };
        }}
      />
      <QuitCollectionDialog
        collection={selCollection}
        open={quitDialogShow}
        onClose={() => { setQuitDialogShow(false); }}
        onSuccess={() => {
          // snackbarDispatch({ type: SHOW_SNACKBAR, data: `已删除 ${collection.name} 及其所有子收藏夹` });
          setQuitDialogShow(false);
          fetchCollectionList(true);
          
          navigate('/inbox');
        }}
      />
    </CollectionListContext.Provider>
  )
}

export default CollectionListProvider;
