import React, { useReducer, useContext, useState, useEffect } from 'react';
import initialState from './collectionInitialState';
import { CollectionContext, CollectionReducer } from './collectionReducer';

const CollectionProvider = (props) => {
  const [ state, dispatch ] = useReducer(CollectionReducer, initialState);

  return (
    <CollectionContext.Provider value={{ state, dispatch }}>
      { props.children }
    </CollectionContext.Provider>
  )
}

export default CollectionProvider;
