// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
export var paper = `#fff`;
export var primaryLight = `#e3f2fd`;
export var primary200 = `#90caf9`;
export var primaryMain = `#2196f3`;
export var primaryDark = `#1e88e5`;
export var primary800 = `#1565c0`;
export var secondaryLight = `#ede7f6`;
export var secondary200 = `#b39ddb`;
export var secondaryMain = `#673ab7`;
export var secondaryDark = `#5e35b1`;
export var secondary800 = `#4527a0`;
export var successLight = `#b9f6ca`;
export var success200 = `#69f0ae`;
export var successMain = `#00e676`;
export var successDark = `#00c853`;
export var errorLight = `#ef9a9a`;
export var errorMain = `#f44336`;
export var errorDark = `#c62828`;
export var orangeLight = `#fbe9e7`;
export var orangeMain = `#ffab91`;
export var orangeDark = `#d84315`;
export var warningLight = `#fff8e1`;
export var warningMain = `#ffe57f`;
export var warningDark = `#ffc107`;
export var grey50 = `#fafafa`;
export var grey100 = `#f5f5f5`;
export var grey200 = `#eee`;
export var grey300 = `#e0e0e0`;
export var grey500 = `#9e9e9e`;
export var grey600 = `#757575`;
export var grey700 = `#616161`;
export var grey900 = `#212121`;
export var darkPaper = `#111936`;
export var darkBackground = `#1a223f`;
export var darkLevel1 = `#29314f`;
export var darkLevel2 = `#212946`;
export var darkTextTitle = `#d7dcec`;
export var darkTextPrimary = `#bdc8f0`;
export var darkTextSecondary = `#8492c4`;
export var darkPrimaryLight = `#e3f2fd`;
export var darkPrimaryMain = `#2196f3`;
export var darkPrimaryDark = `#1e88e5`;
export var darkPrimary200 = `#90caf9`;
export var darkPrimary800 = `#1565c0`;
export var darkSecondaryLight = `#d1c4e9`;
export var darkSecondaryMain = `#7c4dff`;
export var darkSecondaryDark = `#651fff`;
export var darkSecondary200 = `#b39ddb`;
export var darkSecondary800 = `#6200ea`;
export default ___CSS_LOADER_EXPORT___;
