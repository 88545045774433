import React, { useContext, useState } from 'react';
import { Box, Stack, Typography, Chip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { useHotkeys } from 'react-hotkeys-hook';
import { BookmarkContext } from '@contexts/bookmark/bookmarkReducer';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import UpdateBookmarkTagsDialog from '@components/UpdateBookmarkTagsDialog';
import * as BookmarkActionTypes from '@contexts/bookmark/BookmarkActionTypes';
import * as collectionRole from '@constants/collectionRole';
import { CollectionContext } from '@contexts/collection/collectionReducer';
import { getSearchRoute } from '@utils/helper';
import { UserContext } from '@contexts/user/userReducer';
import { OPERATION_FETCH_BOOKMARK_TAGS } from '@contexts/user/UserActionTypes';
import KeycapTooltip from '@components/KeycapTooltip';
import { HOTKEYS_EDIT_TAGS } from '@constants/hotkeys';

const METADATA_TYPE_LIST = {
  'video': '视频',
  'article': '文章',
  'image': '图片',
  'music:song': '音乐',
}

const StyledMetadataItem = styled(({ to, action, children, ...props }) => {
  return to ? (
    <Stack component={RouterLink} to={to} direction="row" spacing={0.5} {...props}>
      {children}
    </Stack>
  ) : (
    <Stack component="div" direction="row" spacing={0.5} {...props}>
      {children}
    </Stack>
  )
})(({ theme, to, action }) => ({
  position: 'relative',
  minHeight: '36px',
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(0.5),
  borderRadius: '18px',
  '.metadata-link-nav': {
    visibility: 'hidden'
  },
  ...(!!to || !!action) && {
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      
      '.metadata-link-nav': {
        visibility: 'visible'
      }
    }
  }
}));

const StyledChip = styled(props => (
  <Chip size="small" variant="outlined" component="a" clickable {...props} />
))(({ theme }) => ({
  borderColor: theme.palette.border,
  maxWidth: 128,
}));

const StyledMetadataItemTitle = styled(props => (
  <Typography variant="body2" {...props} /> 
))(({ theme }) =>
  theme.unstable_sx({
    color: theme.palette.text.secondary,
    width: 100,
    flexShrink: 0,
    py: 1,
    lineHeight: '20px',
  })
);

const StyledMetadataItemValue = styled(props => (
  <Typography variant="body2" {...props} /> 
))(({ theme }) => 
  theme.unstable_sx({
    color: theme.palette.text.primary,
    flex: 1,
    borderRadius: '12px',
    py: 1,
    lineHeight: '20px',
    overflowWrap: 'anywhere',
  })
);

const StyledMetadataItemAction = styled(Box)(({ theme }) => 
  theme.unstable_sx({
    ml: 1,
    backgroundColor: 'background.paper',
    borderRadius: '12px',
    position: 'absolute',
    right: 6,
    top: '50%',
    marginTop: '-12px !important',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
  })
);

export default (props) => {
  const {
    data
  } = props;

  const theme = useTheme();
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const { state: bookmarkState, dispatch: bookmarkDispatch } = useContext(BookmarkContext);
  const { dispatch: userDispatch } = useContext(UserContext);
  const { state: collectionState } = useContext(CollectionContext);

  useHotkeys(
    HOTKEYS_EDIT_TAGS,
    () => {
      setShowUpdateDialog(true);
    },
    [data]
  );

  const redirectToSearch = (keywords) => {
    // 待搜索内容与当前页一致，则不跳转搜索
    if (bookmarkState.keywords[0] === keywords && !bookmarkState.keywords[1]) {
      return undefined;
    }

    return getSearchRoute(bookmarkState.collection, keywords, bookmarkState.keywords[1] === 'all');
  };

  const collectionRoute = () => {
    const bookmarkCollection = data.collection || { id: 'inbox' };

    if (bookmarkCollection.id === 'inbox') {
      return bookmarkState.collection !== 'inbox' ? '/inbox' : undefined;
    }
    
    return bookmarkCollection.id !== bookmarkState.collection ? `/collections/${bookmarkCollection.id}` : undefined;
  }

  const urlHost = data.urlHost ?? (data.srcUrl ? new URL(data.srcUrl).hostname : new URL(data.url).hostname);

  const metadata = [Object.keys(METADATA_TYPE_LIST).indexOf(data.type) > -1 && {
    title: '类型',
    value: METADATA_TYPE_LIST[data.type],
    route: redirectToSearch(`type:${data.type}`),
  }, {
    title: '站点',
    value: urlHost,
    route: redirectToSearch(`site:${urlHost}`),
  }, {
    title: '收藏夹',
    value: data.collection ? data.collection.name : '收集箱',
    route: collectionRoute(),
  }, data.srcTitle && {
    title: '原标题',
    value: data.srcTitle,
  }, {
    title: '创建时间',
    value: dayjs(data.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    route: redirectToSearch(`created:${dayjs(data.createdAt).format('YYYY-MM-DD')}`),
  }, data.createdAt !== data.updatedAt && {
    title: '更新时间',
    value: dayjs(data.updatedAt).format('YYYY-MM-DD HH:mm:ss')
  }, {
    title: '标签',
    value: (
      <Box sx={{ py: .75, flex: 1, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
        {
          (data.tags || []).map(tag => (
            <RouterLink to={getSearchRoute(bookmarkState.collection, `tag:${tag}`, bookmarkState.keywords[1] === 'all')}>
              <StyledChip label={tag} />
            </RouterLink>
          ))
        }
      </Box>
    ),
    action: () => {
      setShowUpdateDialog(true);
    }
  }].filter(item => item);

  const hasActionPermission = !collectionState.collection?.role || [collectionRole.COLLABORATOR, collectionRole.ADMIN].indexOf(collectionState.collection?.role) > -1;

  return (
    <>
      <Box sx={{ pt: 3 }}>
        <Stack>
          <Typography variant="caption" sx={{ color: theme.palette.text.secondary, pb: 1, pl: 1.5 }}>基本信息</Typography>
        </Stack>
        {
          metadata.map(({ title, value, route, action }) => (
            <StyledMetadataItem to={route} action={!!action && hasActionPermission}>
              <StyledMetadataItemTitle>{ title }</StyledMetadataItemTitle>
              {
                typeof value === 'string' ? (
                  <StyledMetadataItemValue>{ value }</StyledMetadataItemValue>
                ) : value
              }
              {
                !!route && (
                  <StyledMetadataItemAction className="metadata-link-nav">
                    <KeyboardArrowRightIcon sx={{ fontSize: '1.2rem' }} {...props} />
                  </StyledMetadataItemAction>
                )
              }
              {
                !!action && hasActionPermission && (
                  <StyledMetadataItemAction type="edit" className="metadata-link-nav" onClick={action} sx={{ cursor: 'pointer' }}>
                    <KeycapTooltip text="修改标签" hotkeys={HOTKEYS_EDIT_TAGS}>
                      <EditIcon sx={{ fontSize: '0.875rem' }} {...props} />
                    </KeycapTooltip>
                  </StyledMetadataItemAction>
                )
              }
            </StyledMetadataItem>
          ))
        }
      </Box>
      <UpdateBookmarkTagsDialog
        data={{
          id: data.id,
          tags: data.tags,
        }}
        open={showUpdateDialog}
        onClose={(evt, reason) => {
          if (!reason || reason === 'escapeKeyDown' ) { setShowUpdateDialog(false) }
        }}
        onSuccess={(values) => {
          setShowUpdateDialog(false);
          bookmarkDispatch({
            type: BookmarkActionTypes.UPDATE_BOOKMARK,
            data: {
              id: data.id,
              tags: values,
            }
          });
          userDispatch({ type: OPERATION_FETCH_BOOKMARK_TAGS });
        }}
      />
    </>
  )
}
