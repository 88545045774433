import { gql } from "@apollo/client";

// 获取卡片列表
export const GET_CARDS = gql`
  query Query {
    cards {
      id
      name
      description
      latestVersion {
        id
        version
        description
        createdAt
      }
    }
  }
`;

// 添加卡片
export const CREATE_CARD = gql`
  mutation CardCreate($input: CardCreateInput!) {
    cardCreate(input: $input) {
      card {
        id
      }
    }
  }
`;

// 更新卡片
export const UPDATE_CARD = gql`
  mutation CardUpdate($id: String!, $input: CardUpdateInput!) {
    cardUpdate(id: $id, input: $input) {
      card {
        id
      }
    }
  }
`;

// 发布卡片
export const PUBLISH_CARD = gql`
  mutation CardVersionCreate($input: CardVersionCreateInput!) {
    cardVersionCreate(input: $input) {
      id
      cardId
      version
      description
    }
  }
`;
