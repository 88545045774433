import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default (props) => {
  const theme = useTheme();

  return (
    <Box>
      {/* <Box>
        <Typography
          component="span"
          variant="h3"
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
          }}
        >
          集成
        </Typography>
      </Box> */}
    </Box>
  )
};
