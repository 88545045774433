import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Link as RouterLink } from "react-router-dom";
import { Container, Stack, Typography, Box, Link, useMediaQuery, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Logo from '@components/Logo';
import Page from '@components/Page';
import { LoadingButton } from '@mui/lab';
import GithubIcon from '@assets/auth-icons/Github';
import GoogleIcon from '@assets/auth-icons/Google';
import LoginForm from './components/LoginForm';
import SignupForm from './components/SignupForm';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 360,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  paddingTop: theme.spacing(8)
}));

const StyledAuthButton = styled(({ loading, color, icon, onClick, disabled, children }) => (
  <LoadingButton
    fullWidth
    size="large"
    variant="outlined"
    loading={loading}
    loadingPosition="start"
    sx={{ fontSize: 16, height: 48, borderColor: color, color, '&:hover': { backgroundColor: alpha(color, .08), borderColor: color } }}
    startIcon={icon}
    onClick={onClick}
    disabled={disabled}
  >
    { children }
  </LoadingButton>
))(({ theme }) => ({
}));

export default (props) => {
  const {
    type = 'login',
  } = props;

  const theme = useTheme();

  const [authGoogleLogin, setAuthGoogleLogin] = useState(false);
  const [authGithubLogin, setAuthGithubLogin] = useState(false);
  const [authLoginInProgress, setAuthLoginInProgress] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const title = type === 'signup' ? '注册' : '登录';
  const subtitle = type === 'signup' ? '已有账号？' : '还没有账号？';
  const redirectText = type === 'signup' ? '登录' : '注册';
  const redirectTo = type === 'signup' ? '/login' : '/signup';

  return (
    <RootStyle>
      <Container maxWidh="sm">
        <ContentStyle>
          <Stack sx={{ mb: 4, alignItems: 'center' }}>
            <Link href={'/'}>
              <picture>
                <img src={`//assets.kiipu.com/img/${theme.palette.mode === 'dark' ? 'logo_light.png?v=240427' : 'logo.png?v=240427'}`} alt="奇普书签" title="奇普书签" style={{ width: 160 }} />
              </picture>
            </Link>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', mt: 6 }}>
              <Typography variant="h2">
                { title }
              </Typography>
              <Stack direction="row" spacing={0}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>{ subtitle }</Typography>
                <Link component={RouterLink} to={redirectTo} sx={{ fontSize: theme.typography.body2 }}>{ redirectText }</Link>
              </Stack>
            </Box>
          </Stack>
          {
            type === 'signup' ? (
              <SignupForm
                onSubmittingStatusChange={(submitting) => {
                  setSubmitting(submitting);
                }}
              />
            ) : (
              <LoginForm
                onSubmittingStatusChange={(submitting) => {
                  setSubmitting(submitting);
                }}
              />
            )
          }
          <Divider sx={{ my: 4, fontSize: theme.typography.body2.fontSize, color: theme.palette.grey[400] }}>或</Divider>
          <Stack spacing={3}>
            <StyledAuthButton
              loading={authGoogleLogin}
              color={theme.palette.mode === 'dark' ? '#8ab4f8' : '#1A73E8'}
              icon={<GoogleIcon dark={theme.palette.mode === 'dark'} />}
              disabled={submitting}
              onClick={() => {
                if (authLoginInProgress) return;
                setAuthGoogleLogin(true);
                setAuthLoginInProgress(true);
                location.href = '//auth.kiipu.com/google';
              }}
            >
              使用 Google 登录
            </StyledAuthButton>
            <StyledAuthButton
              loading={authGithubLogin}
              color={theme.palette.mode === 'dark' ? '#FFF' : '#1F2328'}
              icon={<GithubIcon />}
              disabled={submitting}
              onClick={() => {
                if (authLoginInProgress) return;
                setAuthGithubLogin(true);
                setAuthLoginInProgress(true);
                location.href = '//auth.kiipu.com/github';
              }}
            >
              使用 Github 登录
            </StyledAuthButton>
          </Stack>
        </ContentStyle>
      </Container>
      {/* <Footer>
        <Stack direction="row" spacing={3} sx={{ justifyContent: 'center', fontSize: theme.typography.body2.fontSize }}>
          <Typography sx={{ color: theme.palette.grey[600], fontSize: theme.typography.body2.fontSize }}>©2024 Kiipu</Typography>
          <StyledLink target="_blank" href="/explore">发现</StyledLink>
          <StyledLink href="https://beian.miit.gov.cn/" target="_blank">京ICP备14010856号-2</StyledLink>
        </Stack>
      </Footer> */}
    </RootStyle>
  )
}
