import React from 'react';
import layoutInitialState from './layoutInitialState';
import * as LayoutActionTypes from './LayoutActionTypes';

export const LayoutReducer = (state = layoutInitialState, action) => {
  switch(action.type) {
    case LayoutActionTypes.SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: true,
      };
    case LayoutActionTypes.HIDE_SIDEBAR:
      return {
        ...state,
        showSidebar: false,
      }
    case LayoutActionTypes.TOGGLE_SIDEBAR:
      return {
        ...state,
        showSidebar: !state.showSidebar,
      }
    case LayoutActionTypes.SET_MOBILE:
      return {
        ...state,
        mobile: action.data,
      }
    case LayoutActionTypes.SET_LAYOUT_SIZE:
      return {
        ...state,
        size: action.data,
      }
    default:
      return state;
  }
}

export const LayoutContext = React.createContext(layoutInitialState);
