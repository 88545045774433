export const SHOW_ACTION = 'SHOW_ACTION';
export const HIDE_ACTION = 'HIDE_ACTION';
export const SET_COLLECTION = 'SET_COLLECTION';
export const SET_KEYWORDS = 'SET_KEYWORDS';
export const SET_SELECT_INDEX= 'SET_SELECT_INDEX';
export const SET_BOOKMARK_LIST = 'SET_BOOKMARK_LIST';
export const RESET_BOOKMARK_LIST = 'RESET_BOOKMARK_LIST';
export const APPEND_BOOKMARK_LIST = 'APPEND_BOOKMARK_LIST';
export const REMOVE_SELECTED_BOOKMARK = 'REMOVE_SELECTED_BOOKMARK';

export const OPERATION_FETCH_BOOKMARK = 'OPERATION_FETCH_BOOKMARK';
export const OPERATION_MOVE_BOOKMARK = 'OPERATION_MOVE_BOOKMARK';
export const UPDATE_SELECTED_BOOKMARK = 'UPDATE_SELECTED_BOOKMARK';
export const UPDATE_BOOKMARKS = 'UPDATE_BOOKMARKS';

export const OPEN_PREVIEW_WINDOW = 'OPEN_PREVIEW_WINDOW';
export const CLOSE_PREVIEW_WINDOW = 'CLOSE_PREVIEW_WINDOW';
export const TOGGLE_PREVIEW_WINDOW = 'TOGGLE_PREVIEW_WINDOW';

export const STAR_BOOKMARK = 'STAR_BOOKMARK';
export const UNSTAR_BOOKMARK = 'UNSTAR_BOOKMARK';

export const SET_BOOKMARK_NOTE = 'SET_BOOKMARK_NOTE';

export const SET_CARDS = 'SET_CARDS';

export const SET_PREVIEW_TAB = 'SET_PREVIEW_TAB';

export const ROLLBACK_OPERATION = 'ROLLBACK_OPERATION';

// 移除待解析的书签
export const REMOVE_PENDING_PARSE_BOOKMARKS = 'REMOVE_PENDING_PARSE_BOOKMARKS';

// 添加新书签到列表
export const ADD_BOOKMARK = 'ADD_BOOKMARK';

export const OPERATION_UPDATE_BOOKMARK = 'OPERATION_UPDATE_BOOKMARK';

// 更新书签详情
export const UPDATE_BOOKMARK = 'UPDATE_BOOKMARK';

export const SET_PENDING_PROGRESS_BOOKMARKS = 'SET_PENDING_PROGRESS_BOOKMARKS';
export const ADD_PENDING_PROGRESS_BOOKMARKS = 'ADD_PENDING_PROGRESS_BOOKMARKS';
