import React, { forwardRef } from 'react';
import { Box, Stack, Typography, Link, IconButton, Divider, useMediaQuery, Tooltip, CircularProgress } from '@mui/material';
import { styled, useTheme, alpha, lighten, darken } from '@mui/material/styles';
import MoreHorizOutlinedIcon from '@icons/MoreHorizOutlined';
import OpenInNewOutlinedIcon from '@icons/OpenInNewOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import useDeviceDetect from '@utils/hooks/useDeviceDetect';
import StarOutlinedIcon from '@icons/StarOutlined';
import StarFilledIcon from '@icons/StarFilled';
import NoteOutlinedIcon from '@icons/NoteOutlined';
import { getReadableTime, getFaviconUrl } from '@utils/helper';
import { getSearchRoute } from '@utils/helper';

const getDividerColor = (mode, color) => {
  return mode === 'light' ? lighten(alpha(color, 1), 0.32) : darken(alpha(color, 1), 0.24);
}

const StyledFavicon = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: '3.5px',
  left: 0,
  width: '16px',

  // '&::after': {
  //   display: 'block',
  //   content: '""',
  //   position: 'absolute',
  //   top: 0,
  //   left: 0,
  //   width: '100%',
  //   height: '100%',
  //   backgroundColor: '#eee',
  // }
}))

const { isTouch } = useDeviceDetect();

const StyledItemLink = styled('a')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 2,
  fontSize: 0,
  color: 'transparent',
  WebkitTapHighlightColor: 'transparent', // iOS上禁止Hover的样式
}));

const StarTagIcon = ({ sx, className, showStarTag, showUnstarTag }) => {
  const theme = useTheme();
  const props = {
    sx: {
      fontSize: '1rem',
      color: theme.palette.text.secondary,
      position: 'relative',
      top: 2.5,
      ml: .5,
      ...sx
    },
    className,
  }

  return (
    <>
      {
        showUnstarTag && <StarOutlinedIcon {...props} />
      }
      {
        !showUnstarTag && showStarTag && <StarFilledIcon {...props} />
      }
    </>
  )
}

const SimpleListItem = forwardRef((props) => {
  const {
    id,
    title,
    url,
    srcUrl,
    urlHost,
    faviconUrl,
    collection,
    onOpenMenu,
    onMoveTo,
    onSelect,
    isSelected,
    isDetailOpened,
    isStaredPage = false,
    isStarred,
    note,
    tags = [],
    currentCollection,
    onOpenNote,
    createdAt,
    isSearchScopeAll, // 是否在所有书签搜索状态
    inProgress,
    ...otherProps
  } = props;

  const theme = useTheme();
  // const [isOpen, setIsOpen] = useState(false);
  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  // const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  // const { state: bookmarkState, dispatch: bookmarkDispatch } = useContext(BookmarkContext);
  const [collected, drager, dragPreview] = useDrag({
    type: 'Box',
    item: { id },
    collect(monitor) {
      return { opacity: monitor.isDragging() ? 0.4 : 1 }
    },
    end: (_, minoter) => {
      const ret = minoter.getDropResult();
      if (ret) {
        onMoveTo(ret.id, ret.name);
      }
    },
  });

  const OpenButton = () => (
    <Link
      href={url}
      rel="noopener"
      target="_blank"
    >
      <Tooltip title="打开">
        <IconButton size="md">
          <OpenInNewOutlinedIcon />
        </IconButton>
      </Tooltip>
    </Link>
  );

  const MenuButton = () => (
    <IconButton
      size="md"
      onClick={(evt) => {
        onOpenMenu(evt.currentTarget);
        evt.stopPropagation();
      }}
    >
      <MoreHorizOutlinedIcon />
    </IconButton>
  );

  const ACTION_BUTTONS = (isDetailOpened && matchUpSm && !isTouch) ? [
    <OpenButton />,
    <MenuButton />,
  ] : [
    <MenuButton />,
  ];

  const showUnstarTag = (isStaredPage && isStarred === false && !isSearchScopeAll); // 在已加星标页，且项目取消了星标，且不在所有书签搜索页
  const showStarTag = (!isStaredPage && isStarred) // 不是收藏夹页 & 书签加星
    || (isStaredPage && !isSearchScopeAll) // 收藏夹页 & 不在所有书签搜索状态
    || (isStaredPage && isSearchScopeAll && isStarred); // 收藏夹页 & 在所有书签搜索状态 & 书签加星

  return (
    <Box
      sx={{
        pl: 2,
        [theme.breakpoints.up('sm')]: {
          pl: 3,
        },
        ...isSelected && {
          backgroundColor: theme.palette.background.neutral,
        },
        ...!isTouch && {
          '&:hover': {
            backgroundColor: theme.palette.background.neutral,
            '& .action': {
              display: 'flex',
            }
          }
        }
      }}
    >
      <Stack ref={drager} direction="row" {...collected}>
        <Stack sx={{ flexGrow: 1, overflow: 'hidden', justifyContent: 'center', height: '59px' }}>
          <Typography
            variant="h6"
            noWrap
            sx={{
              // backgroundImage: `url(${process.env.CHROME_EXT ? 'http:' : ''}//favicon1.kiipu.com/${(new URL(url)).host})`,
              // backgroundPosition: 'left center',
              // backgroundSize: '16px auto',
              // backgroundRepeat: 'no-repeat',
              pl: 3,
              lineHeight: '23px',
              position: 'relative',
              fontSize: 15,
              fontWeight: 500,
            }}
          >
            <StyledFavicon
              crossOrigin='anonymous'
              src={getFaviconUrl(srcUrl ?? url, faviconUrl)}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAANlBMVEUAAADu7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u7u60JhWoAAAAEXRSTlMA9wok0cmZierpV+ucmzJYIy+iF/gAAACbSURBVDjLhdNXEsMgDARQCdHdsve/bCZlSDzY2vcNSKjIT+g5NdWWcg8ys00x6GZyForiRMvplUfEJJoMe8OFto/wFZeqfeNH3IifPApulXeCilv6CrLBsYoEhUODdLi6ZLiyJLiSLHAtonApPUBD0CTpN2mheKllJc0SI+0mA8NGjg0tHfvByOLQ1ZuXdzWZhSOnqlpTPv5uPwEBTTNXp3FQXgAAAABJRU5ErkJggg==";
              }}
            />
            {title}
            <StarTagIcon showStarTag={showStarTag} showUnstarTag={showUnstarTag} />
          </Typography>
          <Stack direction="row" alignItems="center" sx={{ pl: 3, mt: .5 }}>
            {
              inProgress && <CircularProgress size={8} sx={{ color: theme.palette.text.secondary, mr: .6 }} />
            }
            <Stack
              direction="row"
              spacing={1}
              divider={<Box sx={{ width: '4px', height: '4px', backgroundColor: theme.palette.border, flexShrink: 0, borderRadius: 9999 }} />}
              sx={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                flex: 1,
              }}
            >
              {
                collection && (
                  <Link component={RouterLink} variant="caption" noWrap={true} color={theme.palette.text.secondary} to={`/collection/${collection.id}`} underline="hover" sx={{ zIndex: 3 }}>
                    { collection.name }
                  </Link>
                )
              }
              {
                urlHost && (
                  <Typography variant="caption" noWrap={true} color={theme.palette.text.secondary}>
                    {urlHost.startsWith('www.') ? urlHost.slice(4) : urlHost}
                  </Typography>
                )
              }
              <Typography variant="caption" noWrap={true} color={theme.palette.text.secondary}>
                {getReadableTime(createdAt)}
              </Typography>
              {
                tags.length > 0 && (
                  <Stack direction="row" spacing={1}>
                    {
                      tags.map(tag => (
                        <Link component={RouterLink} variant="caption" noWrap={true} color={theme.palette.text.secondary} to={getSearchRoute(currentCollection, `tag:${tag}`, isSearchScopeAll)} underline="hover" sx={{ zIndex: 3 }}>
                          #{ tag }
                        </Link>
                      ))
                    }
                  </Stack>
                )
              }
              {
                !!note && (
                  <IconButton aria-label="note" sx={{ zIndex: 3, fontSize: '1rem', p: .5 }} onClick={onOpenNote}>
                    <NoteOutlinedIcon sx={{ fontSize: theme.typography.caption, color: theme.palette.text.secondary }} />
                  </IconButton>
                )
              }
            </Stack>
          </Stack>
        </Stack>
        <Stack
          className="action"
          direction="row"
          spacing={.5}
          sx={{
            alignItems: 'center',
            display: (isSelected && isDetailOpened || isTouch) ? 'flex' : 'none',
            zIndex: 3,
            pr: 1,
            [theme.breakpoints.up('sm')]: {
              pr: 3,
            },
          }}
        >
          {
            ACTION_BUTTONS.map(btn => btn)
          }
        </Stack>
        <StyledItemLink
          href={url}
          rel="noopener"
          target="_blank"
          onClick={(evt) => {
            if (process.env.CHROME_EXT && !!chrome.runtime) {
              evt.preventDefault();
              evt.stopPropagation();
              chrome.runtime.sendMessage({
                type: 'KP_MSG_OPEN_URL',
                payload: { url, target: 'self' }
              });
            } else if (isDetailOpened) {
              evt.preventDefault();
              evt.stopPropagation();
              onSelect();
              if (evt.detail === 2) {
                window.open(url);
              }
            }
          }}
        >
          { title }
        </StyledItemLink>
      </Stack>
      <Divider sx={{ borderColor: getDividerColor(theme.palette.mode, theme.palette.divider) }} />
    </Box>
  )
});

export default SimpleListItem;
