export default {
  // containerRef: null,
  // menuHook: {
  //   onItemClick: () => null,
  // }
  menu: [],
  element: null,
  id: null,
  name: '',
  operation: null,
  collectionList: null,
  pinnedCollectionList:null,
  expandCollection: [],
  // dragHover: {},
}
