import React, { useState, useContext } from 'react';
import { MenuList, MenuItem, Typography, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ActionPopper from '@components/ActionPopper';
import { useApolloClient } from '@apollo/client';
// import { LOGOUT } from '@apis/AuthApi';
import { useMutation } from '@apollo/client';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { UserContext } from '@contexts/user/userReducer';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SHOW_SNACKBAR } from '@contexts/snackbar/SnackbarActionTypes';
import { SnackbarContext } from '@contexts/snackbar/snackbarReducer';
import * as events from '@constants/events';
import { LayoutContext } from '@contexts/layout/layoutReducer';
import { LAYOUT_SIZE } from '@constants/common';
import { UPDATE_USER_SETTINGS } from '@apis/UserSettingsApi';
import { LOC_THEME, LOC_AUTO_DARK_THEME } from '@constants/localStorage';
import { SET_USER_SETTINGS } from '@contexts/user/UserActionTypes';

export default (props) => {
  const { anchorEl, onClose } = props;
  const navigate = useNavigate();
  const client = useApolloClient();
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const theme = useTheme();

  const { state: userState, dispatch: userDispatch } = useContext(UserContext);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);
  const { state: layoutState } = useContext(LayoutContext);

  const [updateUserSettings] = useMutation(UPDATE_USER_SETTINGS);
  
  // const handleGoTrash = () => {
  //   navigate('/trash');
  //   onClose();
  // }

  const handleGoSettings = async () => {
    navigate('/settings');
    onClose();

    if (layoutState.size === LAYOUT_SIZE.MEDIUM) {
      window.dispatchEvent(new CustomEvent(events.CLOSE_SIDEBAR)); // 关闭侧边栏
    }
  }
  
  const handleLogout = async () => {
    // const { data } = await client.mutate({
    //   mutation: LOGOUT,
    // })
    // if (data.logout) {
    //   localStorage.removeItem('token');
    //   location.replace('/login');
    // }
    localStorage.removeItem('token');
    location.replace('/logout');
  }

  const handleShowInviteDialog = () => {
    setShowInviteDialog(true);
    onClose();
  }

  const handleHideInviteDialog = () => {
    setShowInviteDialog(false);
  }

  const handleToggleDarkTheme = async (isDark) => {
    const { data: { userSettingsUpdate: { autoDarkTheme, theme } } } = await updateUserSettings({
      variables: {
        userSettings: {
          theme: isDark ? 'LIGHT' : 'DARK',
          autoDarkTheme: false,
        }
      }
    });

    userDispatch({ type: SET_USER_SETTINGS, data: { autoDarkTheme, theme } });

    localStorage.setItem(LOC_AUTO_DARK_THEME, false);
    localStorage.setItem(LOC_THEME, theme);

    window.dispatchEvent(new CustomEvent(events.CHANGE_THEME, {
      detail: {
        autoDarkTheme,
        theme,
      }
    }));
    
    onClose();
  }

  return (
    <>
      <ActionPopper
        anchorEl={anchorEl}
        containerRef={props.containerRef}
        onClose={onClose}
        // popperOptions={{
        //   strategy: 'fixed',
        // }}
      >
        <MenuList>
          {/* <MenuItem onClick={handleGoTrash} disableRipple>
            <Typography variant="body2">
              回收站
            </Typography>
          </MenuItem> */}
          <MenuItem onClick={handleGoSettings} disableRipple>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>设置</ListItemText>
            <Typography variant="caption" color="text.secondary">
              ⇧,
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleToggleDarkTheme.bind(this, theme.palette.mode === 'dark')} disableRipple>
            <Typography variant="body2">
              深色主题：{ theme.palette.mode === 'light' ? '关闭' : '开启' }
            </Typography>
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem onClick={handleShowInviteDialog} disableRipple>
            <Typography variant="body2">
              邀请码（{ userState?.user?.invitationCode?.length || 0 }）
            </Typography>
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem onClick={handleLogout} disableRipple>
            <Typography variant="body2">
              退出登录
            </Typography>
          </MenuItem>
        </MenuList>
      </ActionPopper>
      <Dialog
        open={showInviteDialog}
        onClose={handleHideInviteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          邀请码
        </DialogTitle>
        <DialogContent sx={{ width: '360px' }}>
          {
            userState?.user?.invitationCode?.length === 0 ? (
              <DialogContentText id="alert-dialog-description">
                当前没有邀请码
              </DialogContentText>
            ) : (
              <>
                <DialogContentText id="alert-dialog-description">
                  当前有{ userState?.user?.invitationCode?.length }枚邀请码，欢迎邀请你的朋友来使用
                </DialogContentText>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  {
                    userState?.user?.invitationCode?.map(({ code }, idx) => (
                      <>
                        <ListItem
                          secondaryAction={
                            <CopyToClipboard
                              text={`https://kiipu.com/signup?invitation=${code}`}
                              onCopy={() => {
                                snackbarDispatch({ type: SHOW_SNACKBAR, data: '已复制邀请链接' });
                              }}
                            >
                              <IconButton edge="end" aria-label="delete">
                                <ContentCopyIcon sx={{ fontSize: 16 }} />
                              </IconButton>
                            </CopyToClipboard>
                          }
                        >
                          <ListItemIcon sx={{ mr: 2 }}>
                            <LoyaltyOutlinedIcon sx={{ fontSize: 16 }} />
                          </ListItemIcon>
                          <ListItemText primary={code} />
                        </ListItem>
                        <Divider component="li" />
                      </>
                    ))
                  }
                </List>
              </>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHideInviteDialog} autoFocus>
            确定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
