import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SnackbarProvider from '@contexts/snackbar/SnackbarProvider';
import LayoutProvider from '@contexts/layout/LayoutProvider';
import UserProvider from '@contexts/user/UserProvider'; 
import CollectionListProvider from '@contexts/collectionList/CollectionListProvider';
import CollectionProvider from '@contexts/collection/CollectionProvider';
import MainLayout from './layouts/MainLayout';
import MobileLayout from './layouts/MobileLayout';
import SettingsPage from './pages/Settings';
import BookmarkList from './pages/BookmarkList';
import SharePage from './pages/Share';
import ExplorePage from './pages/Explore';
import SettingsProfilePage from './pages/Settings/Profile';
import SettingsInterfacePage from './pages/Settings/Interface';
import SettingsIntegrationsPage from './pages/Settings/Integrations';
import SettingsExportPage from './pages/Settings/Export';
import ComingSoon from './pages/ComingSoon';
import SearchPage from './pages/Search';
import LoginAndSignupPage from './pages/LoginAndSignup/LoginAndSignup';
import { LOC_DEFAULT_VIEW } from '@constants/localStorage';
import { LAYOUT_TYPE } from '@constants/common';

function LayourWrapper({ isMobile }) {
  return (
    <LayoutProvider type={isMobile ? LAYOUT_TYPE.MOBILE : LAYOUT_TYPE.TABLET}>
      <SnackbarProvider>
        <UserProvider>
          <CollectionListProvider>
            <CollectionProvider>
              { isMobile ? <MobileLayout /> : <MainLayout /> }
            </CollectionProvider>
          </CollectionListProvider>
        </UserProvider>
      </SnackbarProvider>
    </LayoutProvider>
  )
}

export default function Router() {
  const theme = useTheme();
  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return useRoutes([
    {
      path: '/',
      element: <LayourWrapper isMobile={!matchUpSm} />,
      children: [
        { path: '/', element: <Navigate to={localStorage.getItem(LOC_DEFAULT_VIEW) || $CONFIG.interface?.defaultView || '/inbox'} replace={true} /> },
        { path: '/:collection', element: <BookmarkList /> },
        { path: '/:collection/q/:keywords', element: <BookmarkList /> },
        { path: '/:collection/q', element: <BookmarkList /> },
        { path: '/collection/:collection', element: <BookmarkList /> },
        { path: '/collection/:collection/q', element: <BookmarkList /> },
        { path: '/collection/:collection/q/:keywords', element: <BookmarkList /> },
        { path: '/explore', element: <ExplorePage /> },
        { path: '/search', element: <SearchPage /> },
        {
          path: '/settings',
          element: <SettingsPage />,
          children: [{
            path: '',
            element: <Navigate to="profile" replace={true} />,
          }, {
            path: 'profile',
            element: <SettingsProfilePage />,
            index: true,
          }, {
            path: 'interface',
            element: <SettingsInterfacePage />,
          }, {
            path: 'integrations',
            element: <SettingsIntegrationsPage />,
          }, {
            path: 'export',
            element: <SettingsExportPage />,
          }],
        },
        // { path: '/share', element: <SharePage /> },
      ]
    },
    {
      path: '/login',
      element: <LoginAndSignupPage type="login" />
    },
    {
      path: '/signup',
      element: <LoginAndSignupPage type="signup" />
    },
    {
      path: '/coming-soon',
      element: (
        <UserProvider>
          <ComingSoon />
        </UserProvider>
      ),
    }
  ]);
}
