import React, { useState, createRef } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddBookmarkForm from '../AddBookmarkForm';

export default (props) => {
  const {
    collectionId,
    open,
    onClose,
    onSuccess,
  } = props;

  const formRef = createRef();

  const handleCancel = () => {
    onClose();
  }

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState();
  
  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="xs"
      fullWidth
    >

      <DialogTitle>
        添加书签
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          size="small"
          sx={{
            position: 'absolute',
            right: 14,
            top: 15,
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <AddBookmarkForm
          ref={formRef}
          open={open}
          collectionId={collectionId}
          onSubmitting={(submiting) => setIsSubmitting(submiting)}
          onValuesChange={(values) => { setValue(values?.url); }}
          onSuccess={onSuccess}
        />
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleParse}>解析</Button> */}
        <Box sx={{ flexGrow: 1 }} />
        <Button onClick={handleCancel}>取消</Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={!value}
          onClick={() => {
            formRef.current.handleSubmit();
          }}
        >
          添加
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
