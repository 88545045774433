import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function BookmarkOutlined(props) {
  return (
    <SvgIcon {...props}>
      <path fill-rule="evenodd" d="M4.25 7A4.75 4.75 0 0 1 9 2.25h6A4.75 4.75 0 0 1 19.75 7v11.925a2.438 2.438 0 0 1-4.162 1.724l-2.925-2.925a.94.94 0 0 0-1.326 0l-2.925 2.925a2.438 2.438 0 0 1-4.162-1.724V7ZM9 3.75A3.25 3.25 0 0 0 5.75 7v11.925a.938.938 0 0 0 1.601.663l2.925-2.925a2.438 2.438 0 0 1 3.448 0l2.925 2.925a.938.938 0 0 0 1.601-.663V7A3.25 3.25 0 0 0 15 3.75H9Z" clip-rule="evenodd"/>
    </SvgIcon>
  );
}

export default BookmarkOutlined;
