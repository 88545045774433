import { gql } from "@apollo/client";

// 退出登录
export const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

// 更新密码
export const UPDATE_AUTH_PASSWORD = gql`
  mutation UserPasswordUpdate($oldPassword: String, $newPassword: String!) {
    authPasswordUpdate(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

// 登录
export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      token
    }
  }
`;

// 注册
export const REGISTER = gql`
  mutation Register($input: RegisterInput!) {
    register(input: $input) {
      user {
        email
      }
    }
  }
`;

// 获取授权信息
export const AUTH = gql`
  query Auth {
    auth {
      type
    }
  }
`;
