import React, { useEffect, useReducer, useContext, useState, useRef } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isEqual } from 'lodash';
import { SHOW_SNACKBAR } from '@contexts/snackbar/SnackbarActionTypes';
import { SnackbarContext } from '@contexts/snackbar/snackbarReducer';
import ActionPopper from '@components/ActionPopper';
import BookmarkActionMenuList from '@components/BookmarkActionMenuList';
import { useApolloClient } from '@apollo/client';
import { GET_COLLECTION } from '@apis/CollectionApi';
import { CollectionContext } from '@contexts/collection/collectionReducer';
import { CollectionListContext } from '@contexts/collectionList/collectionListReducer';
import * as CollectionActionTypes from '@contexts/collection/CollectionActionTypes';
import { BookmarkContext, BookmarkReducer } from './bookmarkReducer';
import initialState from './bookmarkInitialState';
import * as BookmarkActionTypes from './BookmarkActionTypes';
import * as CollectionListActionTypes from '@contexts/collectionList/CollectionListActionTypes';
import useBookmark from '../../pages/DynamicBookmarkList/useBookmark';
import { GET_BOOKMARK_PROGRESS_STATUS } from '@apis/BookmarkApi';
import * as events from '@constants/events';
import { LayoutContext } from '@contexts/layout/layoutReducer';
import { LAYOUT_SIZE } from '@constants/common';
import { LOC_SHOW_BOOKMARK_PREVIEW } from '@constants/localStorage';
import BookmarkUpdateDialog from '../../pages/BookmarkList/components/BookmarkUpdateDialog';

// const INIT_SELECT = { show: false, index: -1 };

const PAGE_TYPE = {
  LIST: 'list',
  SEARCH: 'search',
};

const PARSE_STATUS_POLL_INTERVAL = 3000; // 解析状态轮询时间
let isPolling = false;

const BookmarkProvider = (props) => {
  const {
    containerRef,
    type,
    pageType = PAGE_TYPE.LIST,
    pageParams = {},
  } = props;

  const theme = useTheme();
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);
  const { state: collectionState, dispatch: collectionDispatch } = useContext(CollectionContext);
  const { dispatch: collectionListDispatch } = useContext(CollectionListContext);
  const { state: layoutState, dispatch: layoutDispatch } = useContext(LayoutContext);
  const [showUpdateMetadataDialog, setShowUpdateMetadataDialog] = useState(false);
  const collectionRef = useRef();
  const [pollingTimer, setPollingTimer] = useState(null);

  const [pollCount, setPollCount] = useState(0);

  const [ state, dispatch ] = useReducer(BookmarkReducer, {
    ...initialState,
    selectIndex: -1,
    collection: pageParams.collection,
    keywords: pageParams.keywords,
    ...layoutState.size === LAYOUT_SIZE.SMALL ? { showPreviewWindow: false } : {} // 移动端窗口默认隐藏侧边栏
  });
  const {
    data,
    deleteBookmark,
    recorveryBookmark,
    foreverDeleteBookmark,
    moveBookmark,
    resetBookmark,
    fetchBookmark,
    getCards,
    starBookmark,
    getBookmark,
  } = useBookmark();

  const client = useApolloClient();

  // 抽取正在解析 或 图片上传的书签Id
  const extractProgressBookmarkIds = (bookmarkList) => {
    const pendingParseBookmarkIds = [];
    const pendingUploadImageBookmarkIds = [];
    
    for (let i of bookmarkList) {
      if (i.inProgress) {
        pendingParseBookmarkIds.push(i.id);
      }
      
      if (Array.isArray(i.imageUploadPath) && i.imageUploadPath.length > 0) {
        pendingUploadImageBookmarkIds.push(i.id);
      }
    }

    return {
      pendingParseBookmarkIds,
      pendingUploadImageBookmarkIds,
    }
  }

  const pollingCheckBookmark = async () => {
    if (!isPolling) {
      console.log('[debug] pollingCheckBookmark 检查过程中，已停止轮询 1');
      return;
    }

    if (state.pendingParseBookmarkIds.length === 0 && state.pendingUploadImageBookmarkIds.length === 0) {
      console.log('[debug] pollingCheckBookmark 检查过程中，已停止轮询 2');
      return;
    }

    console.log('[debug] pollingCheckBookmark 启动', `${new Date().getMinutes()}:${new Date().getSeconds()}`, state.pendingParseBookmarkIds, state.pendingUploadImageBookmarkIds);

    const { data: { bookmarkProgressStatus: { pendingParseBookmarkIds, completeParseBookmarks, pendingUploadImageBookmarkIds, uploadImageBookmarks } } } = await client.query({
      query: GET_BOOKMARK_PROGRESS_STATUS,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        pendingParseBookmarkIds: state.pendingParseBookmarkIds,
        pendingUploadImageBookmarkIds: state.pendingUploadImageBookmarkIds
      },
    });

    if (!isPolling) {
      console.log('[debug] pollingCheckBookmark 检查过程中，已停止轮询 3');
      return;
    }
    
    let willUpdateBookmarks = []; // 需要更新的书签信息

    if (completeParseBookmarks.length > 0) {
      willUpdateBookmarks = willUpdateBookmarks.concat(completeParseBookmarks);
    }

    for (let i of uploadImageBookmarks) {
      const bookmark = state.bookmarkList.edges.find(({ id }) => id === i.id);
      // 判断bookmark是否存在，避免切换页面的时候没有找到bookmark报错
      if (bookmark && !isEqual(bookmark.imageUploadPath, i.imageUploadPath)) {
        willUpdateBookmarks = willUpdateBookmarks.concat(i);
      }
    }

    // console.log('[debug] willUpdateBookmarks', willUpdateBookmarks, completeParseBookmarks);

    if (willUpdateBookmarks.length > 0) {
      dispatch({
        type: BookmarkActionTypes.UPDATE_BOOKMARKS,
        data: willUpdateBookmarks,
      });
    }

    if (!isEqual(pendingParseBookmarkIds, state.pendingParseBookmarkIds) || !isEqual(pendingUploadImageBookmarkIds, state.pendingUploadImageBookmarkIds)) {
      dispatch({
        type: BookmarkActionTypes.SET_PENDING_PROGRESS_BOOKMARKS,
        data: {
          pendingParseBookmarkIds,
          pendingUploadImageBookmarkIds,
        },
      });
    }

    setPollCount(pollCount + 1);
  }

  useEffect(() => {
    if (pollCount === 0) return;
    console.log('[debug] 是否需要轮询: %s, 轮询次数：%s', isPolling, pollCount);
    if (isPolling) {
      const timer = setTimeout(() => {
        pollingCheckBookmark();
      }, PARSE_STATUS_POLL_INTERVAL);

      setPollingTimer(timer);
    }
  }, [pollCount]);

  const startPolling = (from) => {
    console.log('[debug] 启动轮询：来源', from);
    if (!isPolling) {
      isPolling = true;
      pollingCheckBookmark();
      console.log('[debug] 启动轮询：准备', isPolling);
    }
  }

  const stopPolling = (from) => {
    console.log('[debug] 停止轮询：来源', from, pollingTimer);
    isPolling = false;

    if (pollingTimer) {
      clearTimeout(pollingTimer);
      setPollingTimer(null);
    }
  }

  useEffect(() => {
    // if (pageType === PAGE_TYPE.LIST && state.collection === pageParams.collection) {
    //   return;
    // }

    // if (pageType === PAGE_TYPE.SEARCH && state.collection === pageParams.collection && state.keywords === pageParams.keywords) {
    //   return;
    // }

    if (!collectionState.collection?.id || !((state.collection === pageParams.collection && state.keywords[0] === pageParams.keywords[0] && state.keywords[1] === pageParams.keywords[1]))) {
      resetBookmark();
      // collectionDispatch({ type: CollectionActionTypes.SET_COLLECTION, data: { id: pageParams.collection } });
      dispatch({ type: BookmarkActionTypes.RESET_BOOKMARK_LIST });
      dispatch({ type: BookmarkActionTypes.SET_COLLECTION, data: pageParams.collection });
      dispatch({ type: BookmarkActionTypes.SET_KEYWORDS, data: pageParams.keywords });
    }
  }, [pageType, pageParams, collectionState.collection?.id]);

  useEffect(() => {
    if (!data) return;

    const edges = [...state.bookmarkList.edges, ...data.edges];
    const { afterCursor } = data.pageInfo;
    let hasNextPage = true;

    if (data.edges.length === 0) {

    }

    if ((edges.length > 0 && !data.pageInfo.afterCursor) // 书签总数大于0 & 没有下一页
      || data.edges.length < 20 // 当前页书签小于20条
    ) {
      hasNextPage = false;
      if (edges.length > 0) { // 书签总数不为0，则加一条空记录
        edges.push({ end: true });
      }
    }

    dispatch({
      type: BookmarkActionTypes.SET_BOOKMARK_LIST,
      data: {
        edges,
        pageInfo: {
          afterCursor,
          hasNextPage,
        },
      },
    });
  }, [data]);

  useEffect(() => {
    if (!state.operation) return;
    switch (state.operation.type) {
      case BookmarkActionTypes.OPERATION_MOVE_BOOKMARK: {
        const { index, id, collectionId, collectionName } = state.operation.params;
        const operateBookmark = state.bookmarkList.edges[index];
        dispatch({ type: BookmarkActionTypes.SET_SELECT_INDEX, data: index });
        if (state.collection === 'trash') { // 当前在回收站页面
          // 回收站（删除前在收集箱） -> 收集箱
          // 回收站（删除前在收藏夹） -> 相同收藏夹
          // 回收站（删除前在收藏夹） -> 收集箱
          // 回收站（删除前在收藏夹） -> 不同收藏夹
          dispatch({ type: BookmarkActionTypes.REMOVE_SELECTED_BOOKMARK });
          collectionListDispatch({
            type: CollectionListActionTypes.UPDATE_COLLECTION_COUNT,
            data: [
              { id: collectionId, updateField: 'count', value: 1 },
              { id: 'all', updateField: 'count', value: 1 }
            ],
          });

          (async () => {
            // const restoredBookmark = await recorveryBookmark(id);
            // if (
            //   (!restoredBookmark.collection && collectionId === 'inbox')
            //   || (restoredBookmark.collection && restoredBookmark.collection.id === collectionId)) {
            //   snackbarDispatch({ type: SHOW_SNACKBAR, data: `已移至 ${restoredBookmark.collection?.name || '收集箱'}` });
            // } else {
            //   const movedBookmark = await moveBookmark(id, collectionId);
            //   snackbarDispatch({ type: SHOW_SNACKBAR, data: `已移至 ${movedBookmark.collection?.name || '收集箱'}` });
            // }
            try {
              const movedBookmark = await moveBookmark(id, collectionId);
              snackbarDispatch({ type: SHOW_SNACKBAR, data: `已移至 ${movedBookmark.collection?.name || '收集箱'}` });
            } catch(ex) {
              snackbarDispatch({ type: SHOW_SNACKBAR, data: '书签操作失败' });
              dispatch({ type: BookmarkActionTypes.ROLLBACK_OPERATION });
              collectionListDispatch({ type: CollectionListActionTypes.ROLLBACK_OPERATION });
            }
          })();
        } else if (collectionId === 'trash') { // 移动到回收站
          dispatch({ type: BookmarkActionTypes.REMOVE_SELECTED_BOOKMARK });
          collectionListDispatch({
            type: CollectionListActionTypes.UPDATE_COLLECTION_COUNT,
            data: [
              { id: operateBookmark.collection?.id || 'inbox', updateField: 'count', value: -1 },
              { id: 'all', updateField: 'count', value: -1 },
              { id: 'starred', updateField: 'count', value: operateBookmark.isStarred || operateBookmark.isStarred === undefined ? -1 : 0 },
            ],
          });

          (async () => {
            try {
              await deleteBookmark(id);
              snackbarDispatch({ type: SHOW_SNACKBAR, data: '已移至 回收站' });
            } catch(ex) {
              snackbarDispatch({ type: SHOW_SNACKBAR, data: '书签删除失败' });
              dispatch({ type: BookmarkActionTypes.ROLLBACK_OPERATION });
              collectionListDispatch({ type: CollectionListActionTypes.ROLLBACK_OPERATION });
            }
          })();
        } else if (collectionId === 'starred') { // 移动到“已加星标”
          if (operateBookmark.isStarred) {
            return;
          }

          dispatch({ type: BookmarkActionTypes.STAR_BOOKMARK });
          collectionListDispatch({
            type: CollectionListActionTypes.UPDATE_COLLECTION_COUNT,
            data: [{ id: 'starred', updateField: 'count', value: 1 }],
          });

          (async () => {
            try {
              await starBookmark(id);
            } catch(ex) {
              // if (ex.graphQLErrors[0].extensions.code === 'STARRED_BOOKMARK_EXISTED') {
              //   dispatch({ type: BookmarkActionTypes.STAR_BOOKMARK });
              // }
              snackbarDispatch({ type: SHOW_SNACKBAR, data: '书签添加星标失败' });
              dispatch({ type: BookmarkActionTypes.ROLLBACK_OPERATION });
              collectionListDispatch({ type: CollectionListActionTypes.ROLLBACK_OPERATION });
            }
          })()
        } else {
          if (state.keywords[1] === 'all') { // 若当前在收藏夹处在搜索所有书签页面，操作书签即更新
            dispatch({ type: BookmarkActionTypes.UPDATE_SELECTED_BOOKMARK, data: { collectionId, collectionName } });
          } else {
            // 当前页面不是“全部书签”/“已加星标” 且 移动到的书签夹不为当前书签夹，才删除当前操作的书签
            if (['all', 'starred'].indexOf(state.collection) === -1 && state.collection !== collectionId) {
              dispatch({ type: BookmarkActionTypes.REMOVE_SELECTED_BOOKMARK });
            }
            // 当前页面是“全部书签”/“已加星标”，则需要更新书签所属收藏夹
            if (['all', 'starred'].indexOf(state.collection) > -1) {
              dispatch({ type: BookmarkActionTypes.UPDATE_SELECTED_BOOKMARK, data: { collectionId, collectionName } });
              // listRef.current.forceUpdate();
              // listRef.current.forceUpdateGrid();
            }
          }

          // 更新收藏夹书签数
          collectionListDispatch({
            type: CollectionListActionTypes.UPDATE_COLLECTION_COUNT,
            data: [
              { id: operateBookmark.collection?.id || 'inbox', updateField: 'count', value: -1 },
              { id: collectionId, updateField: 'count', value: 1 }
            ],
          });

          (async () => {
            try {
              const movedBookmark = await moveBookmark(id, collectionId);
              snackbarDispatch({ type: SHOW_SNACKBAR, data: `已移至 ${movedBookmark.collection?.name || '收集箱'}` });
            } catch(ex) {
              snackbarDispatch({ type: SHOW_SNACKBAR, data: '书签操作失败' });
              dispatch({ type: BookmarkActionTypes.ROLLBACK_OPERATION });
            }
          })();
        }
        break;
      }
      case BookmarkActionTypes.OPERATION_FETCH_BOOKMARK: {
        const { collectionId, keywords: [keywords, scope] } = state.operation.params;
        if (!state.bookmarkList.pageInfo.hasNextPage) {
          return;
        }

        const fetchBookmarakParams = {
          collectionId: scope || collectionId,
          keywords,
          afterCursor: state.bookmarkList.pageInfo?.afterCursor,
        }
        
        fetchBookmark({
          ...fetchBookmarakParams,
          orderBy: {
            field: collectionState.collection.sortByField,
            direction: collectionState.collection.sortByDirection
          },
        });
        
        break;
      }
      case BookmarkActionTypes.OPERATION_UPDATE_BOOKMARK: {
        const { id } = state.operation.params;
        (async () => {
          const bookmark = await getBookmark(id);
          if (bookmark) {
            dispatch({ type: BookmarkActionTypes.UPDATE_BOOKMARK, data: bookmark });
          }
        })();
      }
    }
  }, [state.operation]);

  // 本地书签列表变化，重置待解析书签列表
  useEffect(() => {
    // console.log('[debug] useEffect 数据', state.bookmarkList.edges, collectionState.collection?.view);
    // stopPolling(2);
    if (state.bookmarkList.edges.length > 0) {
      console.log('[debug] useEffect 数据', state.bookmarkList.edges.length);

      // 从书签列表中取出待解析和待上传图片的书签
      const { pendingParseBookmarkIds, pendingUploadImageBookmarkIds } = extractProgressBookmarkIds(state.bookmarkList.edges[state.bookmarkList.edges.length - 1]?.end
        ? state.bookmarkList.edges.slice(0, state.bookmarkList.edges.length - 1)
        : state.bookmarkList.edges
      );

      console.log('[debug] useEffect 数据', '待轮询检查内容一致 检查', pendingParseBookmarkIds, state.pendingParseBookmarkIds);

      if (isEqual(pendingParseBookmarkIds, state.pendingParseBookmarkIds) && isEqual(pendingUploadImageBookmarkIds, state.pendingUploadImageBookmarkIds)) {
        console.log('[debug] useEffect 数据', '待轮询检查内容一致');
        return;
      }

      dispatch({
        type: BookmarkActionTypes.SET_PENDING_PROGRESS_BOOKMARKS,
        data: { pendingParseBookmarkIds, pendingUploadImageBookmarkIds },
      });
  
      // // 书签数据变化时，若有解析中的书签和待上传图片的书签，则启动轮询
      // if (pendingParseBookmarkIds.length > 0 || pendingUploadImageBookmarkIds.length > 0) {
      //   if (!isPolling) {
      //     console.log('[debug] 书签变化：启动轮询', pendingParseBookmarkIds, pendingUploadImageBookmarkIds);
      //     startPolling(1);
      //     isPolling = true;
      //   }
      // }
    }
  }, [state.bookmarkList.edges]);

  useEffect(() => {
    console.log('[debug] useEffect 检查队列', state.pendingParseBookmarkIds,state.pendingUploadImageBookmarkIds, isPolling);

    if (state.pendingParseBookmarkIds.length === 0 && state.pendingUploadImageBookmarkIds.length === 0) {
      if (isPolling) {
        stopPolling(1);
        isPolling = false;
      }
    } else {
      if (!isPolling) {
        startPolling(2);
      }
    }
  }, [state.pendingParseBookmarkIds, state.pendingUploadImageBookmarkIds])

  // useEffect(() => {
    // // 获取卡片版本信息
    // (async () => {
    //   const cards = await getCards();
    //   const cardsMap = {};
    //   for (let { name, imageInExtra, latestVersion } of cards) {
    //     if (latestVersion?.version) {
    //       cardsMap[name] = {
    //         version: latestVersion.version,
    //         imageInExtra: (imageInExtra && imageInExtra.split(',')) || [],
    //       }
    //     }
    //   }
    //   dispatch({ type: BookmarkActionTypes.SET_CARDS, data: cardsMap });
    // })();
  // }, []);

  // const handleAddPendingParseBookmarks = (evt) => {
  //   dispatch({
  //     type: BookmarkActionTypes.ADD_PENDING_PARSE_BOOKMARKS,
  //     data: evt.detail,
  //   })
  // }

  const handleUpdateMetadata = () => {
    setShowUpdateMetadataDialog(true);
  }

  // 响应屏幕大小变化
  useEffect(() => {
    // 小屏以及预览窗口是关闭的情况下
    if (layoutState.size === LAYOUT_SIZE.SMALL || localStorage[LOC_SHOW_BOOKMARK_PREVIEW] === 'false') {
      dispatch({ type: BookmarkActionTypes.CLOSE_PREVIEW_WINDOW, data: { record: false } });
    } else {
      dispatch({ type: BookmarkActionTypes.OPEN_PREVIEW_WINDOW, data: { record: false } });
    }
  }, [layoutState.size]);

  useEffect(() => {
    // 当前收藏夹变化，暂存一份到Ref中，用于在addEventListener回调中使用
    collectionRef.current = collectionState?.collection;
  }, [collectionState?.collection]);

  const handleAddBookmark = (evt) => {
    const needAppendToBookmarkList = (!evt.detail.collection && ['inbox', 'all'].indexOf(collectionRef.current?.id) > -1) || (evt.detail.collection?.id === collectionRef.current?.id)
    if (needAppendToBookmarkList) {
      dispatch({ type: BookmarkActionTypes.ADD_BOOKMARK, data: evt.detail });
    }
  }

  useEffect(() => {
    window.addEventListener(events.ADD_BOOKMARK, handleAddBookmark);
    // window.addEventListener(events.ADD_PENDING_PARSE_BOOKMARKS, handleAddPendingParseBookmarks);

    return () => {
      window.removeEventListener(events.ADD_BOOKMARK, handleAddBookmark);
      // window.removeEventListener(events.ADD_PENDING_PARSE_BOOKMARKS, handleAddPendingParseBookmarks);
    }
  }, []);

  useEffect(() => {
    window.addEventListener(events.UPDATE_BOOKMARK_METADATA, handleUpdateMetadata);

    return () => {
      window.removeEventListener(events.UPDATE_BOOKMARK_METADATA, handleUpdateMetadata);
    }
  }, [showUpdateMetadataDialog]);

  const currentSelected = state.bookmarkList.edges[state.selectIndex];

  /**
   * 移除选中的书签
   */
  //   const removeSelectedBookmark = () => {
  //     if (!state.bookmarkList.pageInfo.hasNextPage && state.bookmarkList.edges.length === 2) {
  //     dispatch({
  //       type: BookmarkActionTypes.SET_BOOKMARK_LIST,
  //       data: {
  //         edges: [],
  //       }
  //     });
  //     } else {
  //     dispatch({
  //       type: BookmarkActionTypes.SET_BOOKMARK_LIST,
  //       data: {
  //         edges: update(state.bookmarkList.edges, {$splice: [[state.selectIndex, 1]]}),
  //       },
  //     });
  //     }
  //   // if (!hasNextPage.current && listDataRef.current.length === 2) {
  //   //   listDataRef.current = [];
  //   //   setEmpty(true);
  //   // } else {
  //     // listDataRef.current = update(listDataRef.current, {$splice: [[bookmarkState.selectIndex, 1]]});
  //   // }
  // }

  return (
    <BookmarkContext.Provider value={{ state, dispatch }}>
      { props.children }
      <ActionPopper
        anchorEl={state.anchorEl}
        containerRef={containerRef}
        onClose={() => {
          dispatch({ type: BookmarkActionTypes.HIDE_ACTION });
        }}
      >
        <BookmarkActionMenuList
          open={Boolean(state.anchorEl)}
          type={type}
          onDeleteBookmark={deleteBookmark}
          onRecorveryBookmark={recorveryBookmark}
          onForeverDeleteBookmark={foreverDeleteBookmark}
        />
      </ActionPopper>
      <BookmarkUpdateDialog
        data={currentSelected}
        open={showUpdateMetadataDialog}
        onClose={(evt, reason) => { if (!reason) { setShowUpdateMetadataDialog(false) } }}
        onSuccess={(values) => {
          dispatch({
            type: BookmarkActionTypes.UPDATE_BOOKMARKS,
            data: [{
              id: currentSelected.id,
              ...values,
            }]
          });
          setShowUpdateMetadataDialog(false);
        }}
      />
    </BookmarkContext.Provider>
  )
}

export default BookmarkProvider;
