import React, { useContext } from 'react';
import { Avatar, List, ListItem, Typography, ListItemAvatar, ListItemText, IconButton, Select, MenuItem, InputBase, FormControl, Box } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { getContrastYIQ } from '@utils/helper';
import flowerIcons from '@flower-icons';
import CloseIcon from '@mui/icons-material/Close';
import LoadingPanel from '@components/LoadingPanel';
import TrashOutlinedIcon from '@icons/TrashOutlined';
import * as collectionRole from '@constants/collectionRole';
import { UserContext } from '@contexts/user/userReducer';

const StyledListItem = styled(ListItem)(({ theme, selected, hoverIn, hasChildren, level }) => ({
  backgroundColor: 'transparent',
  padding: '0 8px',
  height: theme.spacing(6),
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderRadius: theme.spacing(6),
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: selected ? theme.palette.action.selected : theme.palette.action.hover,
  },
}));

const StyledListItemAvatar = styled(ListItemAvatar)(() => ({
  minWidth: '32px',
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.text.secondary,
}));

const MEMBER_ROLE = {
  'VIEWER': '可以查看',
  'COLLABORATOR': '可以编辑和邀请',
  'ADMIN': '所有者',
}

export default (props) => {
  const {
    data,
    collection,
    onRemove,
    onUpdate,
  } = props;

  const theme = useTheme();

  const { state: { user } } = useContext(UserContext);

  if (!data) {
    return <LoadingPanel height={72} size={16} />;
  }
  
  return (
    <>
      <Typography variant="body2" sx={{ px: 1 }}>共 { data.length } 人</Typography>
      <List>
        {
          data.map((member, index) => {
            const FlowerIcon = flowerIcons[member.avatar || 0];

            return (
              <StyledListItem
                disablePadding
                secondaryAction={
                  <>
                    {
                      (member.role === collectionRole.ADMIN || index === 0 || collection.role === collectionRole.VIEWER) ? (
                        <Box
                          sx={{
                            display: 'inline-block',
                            mx: 1,
                          }}
                        >
                          <Typography
                            fontSize={theme.typography.body2.fontSize}
                            color={theme.palette.text.secondary}
                          >
                            {MEMBER_ROLE[member.role]}
                          </Typography>
                        </Box>
                      ) : (
                        <StyledSelect
                          value={member.role}
                          onChange={evt => {
                            onUpdate(member.email, evt.target.value);
                          }}
                          label="Age"
                          input={<InputBase />}
                        >
                          <MenuItem value="COLLABORATOR">可以编辑和邀请</MenuItem>
                          <MenuItem value="VIEWER">可以查看</MenuItem>
                        </StyledSelect>
                      )
                    }
                    {
                      // 管理员不能删除 & 自己（index为0）不能操作删除
                      (member.role !== collectionRole.ADMIN && collection.role !== collectionRole.VIEWER && index > 0) ? (
                        <IconButton
                          color="inherit"
                          aria-label="remove"
                          edge="end"
                          onClick={onRemove.bind(this, member.email, index === 0)}
                        >
                          <TrashOutlinedIcon
                            sx={{
                              color: theme.palette.text.secondary,
                              fontSize: theme.typography.h4.fontSize
                            }}
                          />
                        </IconButton>
                      ) : null
                    }
                  </>
                }
              >
                <StyledListItemAvatar>
                  <ProfileAvatar
                    sx={{ bgcolor: member.avatarTheme }}
                  >
                    <FlowerIcon
                      sx={{
                        fontSize: theme.typography.body1.fontSize,
                        color: getContrastYIQ(member.avatarTheme)
                      }}
                    />
                  </ProfileAvatar>
                </StyledListItemAvatar>
                <ListItemText primary={user?.email === member.email ? '我' : member.nickname} secondary={member.email} />
              </StyledListItem>
            )
          })
        }
      </List>
    </>
  )
}