import React, { useState, useContext, useRef } from 'react';
import { AppBar, Toolbar, Avatar, IconButton, Typography, Box, Tooltip } from '@mui/material';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { Button } from '@mui/material';
import MoreHorizOutlinedIcon from '@icons/MoreHorizOutlined';
import { UserContext } from '@contexts/user/userReducer';
import { getContrastYIQ } from '@utils/helper';
// import ProfileDialog from '@components/ProfileDialog';
import flowerIcons from '@flower-icons';
import ProfileActionMenu from './ProfileActionMenu';
import SettingsMenu from './SettingsMenu';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.drawer,
  top: 'auto',
  bottom: 0
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  borderTop: `1px solid ${theme.palette.divider}`
  // boxShadow: `inset 0 1px 0 ${alpha(theme.palette.divider, .16)}`
}));

const Footer = styled((props) => <Box component="footer" {...props} />)(({ theme, mobile }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'sticky',
  bottom: 0,
  zIndex: 10,
  backgroundColor: mobile ? theme.palette.background.paper : theme.palette.background.drawer,
  height: theme.spacing(7),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  flexShrink: 0,
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
  border: `2px solid ${theme.palette.background.paper}`,
}));

function ProfileActionBar() {
  const theme = useTheme();
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  // const [profileDialogVisible, setProfileDialogVisible] = useState(false);
  const { state: { user }, dispatch: userDispatch } = useContext(UserContext);
  
  const FlowerIcon = flowerIcons[user?.avatar || 0];

  const containerRef = useRef();

  return (
    <>
      <Footer>
        {
          user && (
            <Button
              startIcon={<ProfileAvatar sx={{ bgcolor: user.avatarTheme }}><FlowerIcon fontSize="small" sx={{ color: getContrastYIQ(user.avatarTheme) }} /></ProfileAvatar>}
              onClick={(evt) => {
                setAccountAnchorEl(evt.currentTarget);
                // setProfileDialogVisible(true);
              }}
              sx={{
                px: 2,
                marginLeft: '-8px',
                '&:hover': { backgroundColor: theme.palette.action.hover }
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: 700, color: user.avatarTheme }}
              >
                { user.nickname }
              </Typography>
            </Button>
          )
        }
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          color="inherit"
          aria-label="settings"
          size="small"
          sx={{ marginRight: '-5px' }}
          onClick={(evt) => { setSettingsAnchorEl(evt.currentTarget); }}
        >
          <MoreHorizOutlinedIcon sx={{ color: theme.palette.text.secondary }} />
        </IconButton>
      </Footer>
      <ProfileActionMenu
        anchorEl={accountAnchorEl}
        onClose={() => { setAccountAnchorEl(null); }}
      />
      <SettingsMenu
        anchorEl={settingsAnchorEl}
        containerRef={containerRef}
        onClose={() => { setSettingsAnchorEl(null); }}
      />
      {/* <ProfileDialog
        open={profileDialogVisible}
        onClose={() => {
          setProfileDialogVisible(false);
        }}
      /> */}
    </>
  )
}

export default ProfileActionBar;
