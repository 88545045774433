import React, { useContext, useEffect } from 'react';
import { Outlet, matchPath, useLocation } from 'react-router-dom';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { LayoutContext } from '@contexts/layout/layoutReducer';
import * as LayoutActionTypes from '@contexts/layout/LayoutActionTypes';
import CollectionListProvider from '@contexts/collectionList/CollectionListProvider';
import CollectionProvider from '@contexts/collection/CollectionProvider';
import Sidebar from './Sidebar';

const DRAWER_WIDTH = 260;
const APPBAR_MOBILE = 48;
const APPBAR_DESKTOP = 56;

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,
}));

const MainStyle = styled('main')(({ theme, open }) => ({
  // flexGrow: 1,
  flex: 1,
  display: 'flex',
  overflow: 'auto',
  width: '100%',
  // ...(!open && {
  //   transition: theme.transitions.create('margin', {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen
  //   }),
  //   [theme.breakpoints.up('md')]: {
  //     marginLeft: -DRAWER_WIDTH,
  //     width: `calc(100% - ${DRAWER_WIDTH}px)`
  //   },
  // }),
  // ...(open && {
  //   transition: theme.transitions.create('margin', {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen
  //   }),
  //   marginLeft: 0,
  //   [theme.breakpoints.up('md')]: {
  //     width: `calc(100% - ${DRAWER_WIDTH}px)`
  //   },
  // }),
}));

export default () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { state, dispatch } = useContext(LayoutContext);

  useEffect(() => {
    if (!matchDownMd) {
      dispatch({ type: LayoutActionTypes.SHOW_SIDEBAR });
    }
    // dispatch({ type: !matchDownMd ? LayoutActionTypes.SHOW_SIDEBAR : LayoutActionTypes.HIDE_SIDEBAR });
  }, [matchDownMd]);

  return (
    <RootStyle>
      <Sidebar
        open={state.showSidebar}
        onToggle={() => dispatch({ type: LayoutActionTypes.TOGGLE_SIDEBAR }) }
        onClose={() => dispatch({ type: LayoutActionTypes.HIDE_SIDEBAR }) }
      />
      <MainStyle theme={theme} open={state.showSidebar}>
        <Outlet />
      </MainStyle>
    </RootStyle>
  )
}