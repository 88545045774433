import React, { useContext } from 'react';
import { NavLink as RouterLink, Outlet, useLocation } from 'react-router-dom';
import { Typography, Box, Stack, Link, useScrollTrigger } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SimpleAppBar from '@components/SimpleAppBar';
import { LayoutContext } from '@contexts/layout/layoutReducer';
import Interface from './Interface/Interface';
import Profile from './Profile/Profile';

const PAGES = [
  { name: '账号', path: '/settings/profile' },
  { name: '界面', path: '/settings/interface' },
  { name: '集成', path: '/settings/integrations' },
  { name: '导出', path: '/settings/export' }, 
]

export default () => {
  const location = useLocation();
  const { pathname } = location;
  const theme = useTheme();

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window,
  });
  
  const { state: layoutState, dispatch: layoutDispatch } = useContext(LayoutContext);

  const title = PAGES.find(({ path }) => pathname.startsWith(path))?.name || '设置';

  return layoutState.mobile ? (
    <Box>
      <SimpleAppBar title="我的" showBorder={scrollTrigger} />
      <Stack spacing={1.5} sx={{ flex: 1, px: 2, pt: 6, pb: 3 }}>
        <Profile />
        <Interface hideDescription={true} showTitle={true} />
      </Stack>
    </Box>
  ) : (
    <Box sx={{
      width: '100%',
      p: 6,
      [theme.breakpoints.up('sm')]: {
        p: 7,
      }
    }}>
      <SimpleAppBar title={title} showBorder={scrollTrigger} />
      <Box sx={{ py: 3, display: 'flex', maxWidth: '840px', margin: '0 auto' }}>
        <Stack component="aside" direction="column" sx={{ width: '120px' }}>
          {
            PAGES.map((page, index) => (
              <Link
                component={RouterLink}
                to={page.path}
                sx={{ textDecoration: 'none', mb: 1, '&:hover': { textDecoration: 'none' } }}
              >
                <Typography
                  component="span"
                  variant="body2"
                  sx={{
                    color: theme.palette.text.secondary,
                    ...pathname === page.path && {
                      color: theme.palette.primary.main,
                      fontWeight: theme.typography.fontWeightMedium,
                    },
                  }}
                >
                  { page.name }
                </Typography>
              </Link>
            ))
          }
        </Stack>
        <Box sx={{ flex: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
};
