import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Menu, MenuItem, ListItemText, Divider, Typography } from '@mui/material';
import { CollectionListContext } from '@contexts/collectionList/collectionListReducer';
import { CollectionContext } from '@contexts/collection/collectionReducer';
import * as CollectionListActionTypes from '@contexts/collectionList/CollectionListActionTypes';
import * as collectionRole from '@constants/collectionRole';

const StyledMenu = styled((props) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))();

export default (props) => {
  const theme = useTheme();
  const { anchorEl, onClose } = props;

  const { state: collectionState } = useContext(CollectionContext);
  const { dispatch: collectionListDispatch } = useContext(CollectionListContext);

  const open = Boolean(anchorEl);
  
  return (
    <>
      <StyledMenu
        id="title-action-menu"
        MenuListProps={{
          'aria-labelledby': 'title-action-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        <MenuItem
          disableRipple
          onClick={() => {
            collectionListDispatch({
              type: CollectionListActionTypes.OPERATION_UPDATE_COLLECTION,
              data: collectionState.collection,
            }); 
            onClose();
          }}
        >
          <ListItemText>修改名称</ListItemText>
        </MenuItem>
        {
          collectionState.collection?.role !== collectionRole.VIEWER ? (
            <MenuItem
              disableRipple
              onClick={() => {
                collectionListDispatch({
                  type: CollectionListActionTypes.OPERATION_SHARE_COLLECTION,
                  data: collectionState.collection,
                });
                onClose();
              }}
            >
              <ListItemText>分享</ListItemText>
            </MenuItem>
          ) : null
        }
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            if (collectionState.collection?.role === collectionRole.VIEWER || collectionState.collection?.role === collectionRole.COLLABORATOR) {
              collectionListDispatch({
                type: CollectionListActionTypes.OPERATION_QUIT_COLLECTION,
                data: collectionState.collection,
              });
            } else {
              collectionListDispatch({
                type: CollectionListActionTypes.OPERATION_DELETE_COLLECTION,
                data: collectionState.collection,
              });
            }
            onClose();
          }}
          disableRipple
          sx={{ color: theme.palette.error.main }}
        >
          删除
        </MenuItem>
      </StyledMenu>
    </>
  )
}
