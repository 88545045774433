import React, { useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  GET_BOOKMARKS,
  DELETE_BOOKMARK,
  RECORVERY_BOOKMARK,
  FOREVER_DELETE_BOOKMARK,
  MOVE_BOOKMARK,
  GET_STARRED_BOOKMARKS,
  BOOKMARK_CLOUSE_PARSER_ASYNC,
  CREATE_BOOKMARK,
  GET_BOOKMARK,
} from '@apis/BookmarkApi';
import { ADD_STARRED_BOOKMARK, REMOVE_STARRED_BOOKMARK } from '@apis/StarredBookmarkApi';
import { ADD_BOOKMARK_NOTE } from '@apis/BookmarkNoteApi';
import { GET_CARDS } from '@apis/CardApi';

const INIT_DATA = {
  edges: [],
  pageInfo: {
    afterCursor: null,
    hasNextPage: true,
  },
};

const ORDER_BY = {
  CREATED_DESC: { field: 'UPDATED_AT', direction: 'DESC' },
  CREATED_ASC: { field: 'UPDATED_AT', direction: 'ASC' },
}

const useBookmark = () => {
  const client = useApolloClient();
  const subscribeRef = useRef(); // 请求订阅
  const fetching = useRef(false);
  // const [empty, setEmpty] = useState(false);
  const [data, setData] = useState(null);
  const pageId = useRef(0);
  
  const fetchBookmark = ({ collectionId, keywords, afterCursor: after, orderBy = ORDER_BY.CREATED_ASC }) => {
    const t = pageId.current;

    if (fetching.current) {
      console.log('[loadMoreRows] request cancel!');
      return;
    }
    fetching.current = true;

    // console.log('[loadMoreRows] init >>', collectionId, afterCursor.current, `(${t})`);

    // setEmpty(false);

    const params = {
      after,
      collection: collectionId,
      keywords,
      ...orderBy,
    }

    let query;

    switch(collectionId) {
      case 'trash': {
        query = GET_BOOKMARKS;
        orderBy = { field: 'UPDATED_AT', direction: 'DESC' };
        break;
      }
      case 'starred': {
        query = GET_STARRED_BOOKMARKS;
        break;
      }
      default: {
        query = GET_BOOKMARKS;
        break;
      }
    }

    subscribeRef.current = client.watchQuery({
      query,
      fetchPolicy: 'network-only',
      variables: {
        first: 20,
        ...params,
      },
    }).subscribe({
      next: ({ data }) => {
        // 成功获取数据后，取消订阅，避免重复触发
        subscribeRef.current.unsubscribe();
  
        fetching.current = false;

        const isStarred = collectionId === 'starred';
        if (t !== pageId.current || collectionId !== params.collection) return;
        const { edges, pageInfo } = isStarred ? data.starredBookmarks : data.bookmarks;

        setData({
          edges: edges.map(({ node }) => isStarred ? node.bookmark : node),
          pageInfo: {
            afterCursor: pageInfo.afterCursor,
            hasNextPage: !!pageInfo.afterCursor,
          }
        });
      },
      error: err => {
        fetching.current = false;
        console.log(err);
      }
    });
  }

  const resetBookmark = () => {
    if (subscribeRef.current) {
      subscribeRef.current.unsubscribe();
    }

    pageId.current = +pageId.current + 1;
    fetching.current = false;
  }

  const deleteBookmark = async (id) => {
    const { data: { bookmarkDelete: { bookmark } } } = await client.mutate({
      mutation: DELETE_BOOKMARK,
      variables: {
        id,
      },
    });
    return bookmark;
  }

  const recorveryBookmark = async (id) => {
    const { data: { bookmarkRecorvery: { bookmark } } } = await client.mutate({
      mutation: RECORVERY_BOOKMARK,
      variables: {
        id,
      },
    });
    return bookmark;
  }

  const foreverDeleteBookmark = async (id) => {
    const { data: { bookmarkForeverDelete: { bookmark } } } = await client.mutate({
      mutation: FOREVER_DELETE_BOOKMARK,
      variables: {
        id,
      },
    });
    return bookmark;
  }

  const moveBookmark = async (id, collectionId) => {
    const { data: { bookmarkMove: { bookmark } } } = await client.mutate({
      mutation: MOVE_BOOKMARK,
      variables: {
        id,
        collectionId: collectionId === 'inbox' ? undefined : collectionId,
      },
    });
    return bookmark;
  }

  const createBookmark = async (input) => {
    const { data: { bookmarkCreate: { bookmark } } } = await client.mutate({
      mutation: CREATE_BOOKMARK,
      variables: {
        input,
      },
    });
    return bookmark;
  }

  const cloudParserBookmarkAsync = async (input) => {
    const { data: { bookmarkCloudParserAsync: { bookmarkParserRecordId } } } = await client.mutate({
      mutation: BOOKMARK_CLOUSE_PARSER_ASYNC,
      variables: {
        input,
      },
    });
    return bookmarkParserRecordId;
  }

  // 添加星标
  const starBookmark = async (bookmarkId) => {
    const { data } = await client.mutate({
      mutation: ADD_STARRED_BOOKMARK,
      variables: { bookmarkId },
    });
    return data.starredBookmarkAdd;
  }

    // 移除星标
    const unstarBookmark = async (bookmarkId) => {
      const { data } = await client.mutate({
        mutation: REMOVE_STARRED_BOOKMARK,
        variables: { bookmarkId },
      });
      return data.starredBookmarkAdd;
    }

  const getCards = async () => {
    const { data } = await client.query({
      query: GET_CARDS,
      variables: {},
    });
    return data.cards;
  }

  // 添加备注
  const addBookmarkNote = async (bookmarkId, note) => {
    const { data } = await client.mutate({
      mutation: ADD_BOOKMARK_NOTE,
      variables: { bookmarkId, note },
    });
    return data.starredBookmarkAdd;
  }

  // 获取书签详情
  const getBookmark = async (bookmarkId) => {
    const { data: { bookmark } } = await client.query({
      query: GET_BOOKMARK,
      variables: { bookmarkId },
    });

    return bookmark;
  }

  return {
    fetchBookmark,
    resetBookmark,
    data,
    deleteBookmark,
    recorveryBookmark,
    foreverDeleteBookmark,
    moveBookmark,
    createBookmark,

    starBookmark,
    unstarBookmark,

    getCards,

    addBookmarkNote,

    cloudParserBookmarkAsync,
    getBookmark,
  }
}

export default useBookmark;
