import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function TrashOutlined(props) {
  return (
    <SvgIcon {...props}>
      <path fill-rule="evenodd" d="M3 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 5.25Zm2.202 2.002a.75.75 0 0 1 .796.7l.617 9.512v.01A2.887 2.887 0 0 0 9.5 20.25h5a2.887 2.887 0 0 0 2.885-2.776v-.01l.617-9.512a.75.75 0 1 1 1.496.097l-.614 9.493A4.387 4.387 0 0 1 14.5 21.75h-5a4.387 4.387 0 0 1-4.384-4.208l-.614-9.494a.75.75 0 0 1 .7-.796Z" clip-rule="evenodd"/>
      <path fill-rule="evenodd" d="M12 3.75c-1.353 0-2.196.621-2.871 1.66L7.87 4.59c.881-1.352 2.15-2.34 4.129-2.34 1.979 0 3.248.988 4.129 2.34l-1.258.82c-.674-1.04-1.517-1.66-2.87-1.66Z" clip-rule="evenodd"/>
  </SvgIcon>
  );
}

export default TrashOutlined;
