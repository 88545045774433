import React from 'react';
import { twMerge } from 'tailwind-merge';

const theme = {
  size: {
    xs: 'px-3 py-1.5 text-xs',
    sm: 'px-3 py-1.5 text-sm',
    md: 'px-5 py-2.5 text-sm',
    lg: 'px-5 py-3 text-base',
  },
  color: {
    primary: 'text-primary hover:bg-primary/5',
  }
}

export default (props) => {
  const {
    color = 'primary',
    size = 'md',
    startIcon,
    children,
    className,
    ...otherProps
  } = props;

  return (
    <button
      type="button"
      className={twMerge(
        'font-medium text-center inline-flex items-center rounded-full transition-all ease-in duration-200',
        theme.size[size],
        theme.color[color],
        className,
      )}
      {...otherProps}
    >
      {
        startIcon && React.cloneElement(startIcon, {
          className: 'mr-1.5',
          color: 'current',
          fontSize: size,
        })
      }
      { children }
    </button>
  )
};
