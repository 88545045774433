import { merge } from 'lodash';
import Paper from './Paper';
import Lists from './Lists';
import CssBaseline from './CssBaseline';
import Toolbar from './Toolbar';
import Button from './Button';
import AppBar from './AppBar';
import Menu from './Menu';
import DialogTitle from './DialogTitle';
import Tooltip from './Tooltip';
import DialogActions from './DialogActions';
import SnackbarContent from './SnackbarContent';
import InputBase from './InputBase';
import SvgIcon from './SvgIcon';
import MenuItem from './MenuItem';
import ListItemText from './ListItemText';
import IconButton from './IconButton';
import UseMediaQuery from './UseMediaQuery';
import Link from './Link';
import Snackbar from './Snackbar';
import Divider from './Divider';
import Select from './Select';
import Switch from './Switch';
import Autocomplete from './Autocomplete';

export default function ComponentsOverrides(theme) {
  return merge(
    Paper(theme),
    Lists(theme),
    CssBaseline(theme),
    Toolbar(theme),
    Button(theme),
    AppBar(theme),
    Menu(theme),
    DialogTitle(theme),
    Tooltip(theme),
    DialogActions(theme),
    SnackbarContent(theme),
    InputBase(theme),
    SvgIcon(theme),
    MenuItem(theme),
    ListItemText(theme),
    IconButton(theme),
    UseMediaQuery(theme),
    Link(theme),
    Snackbar(theme),
    Divider(theme),
    Select(theme),
    Switch(theme),
    Autocomplete(theme),
  );
}
