import React, { useEffect, useContext } from 'react';
import { Button, Switch, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLazyQuery, useMutation } from "@apollo/client";
import { useFormik, Form, FormikProvider } from 'formik';
import { GET_COLLECTION, SET_COLLECTION_PUBLISH } from '@apis/CollectionApi';
import LoadingPanel from '@components/LoadingPanel';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SHOW_SNACKBAR } from '@contexts/snackbar/SnackbarActionTypes';
import { SnackbarContext } from '@contexts/snackbar/snackbarReducer';
import InputFormControl from '@components/InputFormControl';
import * as collectionRole from '@constants/collectionRole';

export default (props) => {
  const {
    open,
    onClose,
    onSuccess,
    collection,
  } = props;

  const theme = useTheme();

  const [setCollectionPublish] = useMutation(SET_COLLECTION_PUBLISH);
  const [getCollection] = useLazyQuery(GET_COLLECTION, { fetchPolicy: 'network-only' });
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);

  const formik = useFormik({
    initialValues: {
      publish: false,
      shareUrl: '',
    },
    onSubmit: async ({ publish }, { setValues }) => {
      try {
        await setCollectionPublish({
          variables: {
            id: collection.id,
            publish,
          },
        })
        onSuccess();
      } catch(ex) {
        setFieldValue('publish', !publish);
        snackbarDispatch({ type: SHOW_SNACKBAR, data: '发布失败' });
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setFieldValue } = formik;

  useEffect(() => {
    resetForm();
    if (collection?.id) {
      getCollection({
        variables: {
          id: collection.id,
        },
        onCompleted: ({ collection: { publish, id, role } }) => {
          formik.setValues({
            publish,
            shareUrl: `${location.origin}/s/${id}`,
            hasAuth: [collectionRole.ADMIN, collectionRole.COLLABORATOR].indexOf(role) > -1
          })
        }
      })
    }
  }, []);

  return (
    <FormikProvider value={formik}>
      {
        !values.shareUrl ? (
          <LoadingPanel height={72} size={16} />
        ) : (
          <div style={{ padding: '0 20px' }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="publish"
                    checked={values.publish}
                    disabled={!values.hasAuth}
                    sx={{ '& .MuiSwitch-thumb': { boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px' }}}
                    onChange={(event, checked) => {
                      setFieldValue('publish', checked ? true : false);
                      handleSubmit();
                    }}
                  />
                }
                label={<Typography variant="body2" sx={{ color: theme.palette.text.primary }}>开启后，任何人将可以通过链接访问</Typography>}
              />
            </FormGroup>
            {
              values.publish ? (
                <Stack
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  sx={{ pt: 2 }}
                >
                  <InputFormControl
                    type="text"
                    placeholder="分享链接"
                    readOnly={true}
                    {...getFieldProps('shareUrl')}
                  />
                  <CopyToClipboard
                    text={values.shareUrl}
                    onCopy={() => {
                      snackbarDispatch({ type: SHOW_SNACKBAR, data: '已复制链接' });
                    }}
                  >
                    <Button variant="contained" style={{ flexShrink: 0 }}>复制链接</Button>
                  </CopyToClipboard>
                </Stack>
              ) : null
            }
          </div>
        )
      }
    </FormikProvider>
  )
}
