import React, { useState, useContext } from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { useHotkeys } from 'react-hotkeys-hook';
import { BookmarkContext } from '@contexts/bookmark/bookmarkReducer';
import { CollectionListContext } from '@contexts/collectionList/collectionListReducer';
import * as BookmarkActionTypes from '@contexts/bookmark/BookmarkActionTypes';
import * as CollectionListActionTypes from '@contexts/collectionList/CollectionListActionTypes';
import { LayoutContext } from '@contexts/layout/layoutReducer';
import { HOTKEYS_STAR_BOOKMARK, HOTKEYS_EDIT_BOOKMARK } from '@constants/hotkeys';
import StarOutlinedIcon from '@icons/StarOutlined';
import StarFilledIcon from '@icons/StarFilled';
import EditSquareOutlinedIcon from '@icons/EditSquareOutlined';
import MoreHorizOutlinedIcon from '@icons/MoreHorizOutlined';
import KeycapTooltip from '@components/KeycapTooltip';
import useBookmark from '@pages/DynamicBookmarkList/useBookmark';
import BookmarkActionMenu from '../BookmarkActionMenu';

const FooterWrap = styled(Box)(({ theme, mobile }) => ({
  backgroundColor: mobile ? theme.palette.background.paper : theme.palette.background.drawer,
  paddingBottom: ['calc(env(safe-area-inset-bottom) - 8px)', 'calc(constant(safe-area-inset-bottom) - 8px)'],
  position: 'sticky',
  bottom: 0,
  zIndex: 10,
  flexShrink: 0,
}));

const Footer = styled((props) => <Box component="footer" {...props} />)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: theme.spacing(7),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

function BookmarkActionBar() {
  const theme = useTheme();

  const { state: bookmarkState, dispatch: bookmarkDispatch } = useContext(BookmarkContext);
  const { dispatch: collectionListDispatch } = useContext(CollectionListContext);
  const { state: layoutState, dispatch: layoutDispatch } = useContext(LayoutContext);

  const bookmarkData = bookmarkState.bookmarkList.edges[bookmarkState.selectIndex];

  const { starBookmark, unstarBookmark } = useBookmark();

  const [lock, setLock] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const isStarred = bookmarkData.isStarred || bookmarkData.isStarred === undefined;

  async function handleStarBookmarkToggle() {
    if (lock) return;
    setLock(true);

    try {
      collectionListDispatch({
        type: CollectionListActionTypes.UPDATE_COLLECTION_COUNT,
        data: [
          { id: 'starred', updateField: 'count', value: isStarred ? -1 : 1 },
        ],
      });

      if (isStarred) {
        bookmarkDispatch({ type: BookmarkActionTypes.UNSTAR_BOOKMARK });
        await unstarBookmark(bookmarkData.id);
      } else {
        bookmarkDispatch({ type: BookmarkActionTypes.STAR_BOOKMARK });
        await starBookmark(bookmarkData.id);
      }
    } catch(ex) {
      snackbarDispatch({ type: SHOW_SNACKBAR, data: `书签${isStarred ? '移除' : '添加'}星标失败` });
      dispatch({ type: BookmarkActionTypes.ROLLBACK_OPERATION });
      collectionListDispatch({ type: CollectionListActionTypes.ROLLBACK_OPERATION });
    }
    setLock(false);
  }

  useHotkeys(HOTKEYS_STAR_BOOKMARK, handleStarBookmarkToggle, [bookmarkData, lock]);

  return (
    <>
      <FooterWrap mobile={layoutState.mobile}>
        <Footer>
          <KeycapTooltip text={ isStarred ? '移除星标' : '添加星标' } hotkeys={HOTKEYS_STAR_BOOKMARK}>
            <IconButton
              color="inherit"
              aria-label={ isStarred ? 'unstar' : 'star' }
              size="small"
              sx={{ marginLeft: '-5px' }}
              onClick={handleStarBookmarkToggle}
            >
              {
                isStarred ? (
                  <StarFilledIcon sx={{ color: theme.palette.text.secondary }} />
                ) : (
                  <StarOutlinedIcon sx={{ color: theme.palette.text.secondary }} />
                )
              }
            </IconButton>
          </KeycapTooltip>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" spacing={0.5}>
            {/* <KeycapTooltip text="编辑" hotkeys={HOTKEYS_EDIT_BOOKMARK}>
              <IconButton
                color="inherit"
                aria-label="edit"
                size="small"
              >
                <EditSquareOutlinedIcon sx={{ color: theme.palette.text.secondary }} />
              </IconButton>
            </KeycapTooltip> */}
            <IconButton
              color="inherit"
              aria-label="more"
              id="bookmark-action-menu-button"
              size="small"
              sx={{ marginRight: '-5px' }}
              onClick={(evt) => {
                setAnchorEl(evt.currentTarget);
              }}
            >
              <MoreHorizOutlinedIcon sx={{ color: theme.palette.text.secondary }} />
            </IconButton>
          </Stack>
        </Footer>
      </FooterWrap>
      <BookmarkActionMenu
        anchorEl={anchorEl}
        onClose={() => { setAnchorEl(null); }}
      />
    </>
  )
}

export default BookmarkActionBar;
