import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function SearchFilled(props) {
  return (
    <SvgIcon {...props}>
      <path d="M18.5303301,17.4696699 L21.5303301,20.4696699 C21.8232233,20.7625631 21.8232233,21.2374369 21.5303301,21.5303301 C21.2374369,21.8232233 20.7625631,21.8232233 20.4696699,21.5303301 L17.4696699,18.5303301 C17.1767767,18.2374369 17.1767767,17.7625631 17.4696699,17.4696699 C17.7625631,17.1767767 18.2374369,17.1767767 18.5303301,17.4696699 Z M11,2.25 C15.8324916,2.25 19.75,6.16750844 19.75,11 C19.75,15.8324916 15.8324916,19.75 11,19.75 C6.16750844,19.75 2.25,15.8324916 2.25,11 C2.25,6.16750844 6.16750844,2.25 11,2.25 Z M10.9919943,4.25400285 C9.51270227,4.25400285 8.09293875,4.66989958 6.8673697,5.44167164 C6.44403286,5.70825763 6.28949246,6.3206277 6.76081565,6.72194974 C6.90849793,6.84769817 7.05265511,6.90082574 7.25482216,6.94684963 L7.30478954,6.95700353 C8.33839984,6.20057525 9.61304578,5.75400285 10.9919943,5.75400285 C12.3709529,5.75400285 13.6456074,6.20058177 14.6792217,6.95700353 L14.8439977,6.91785871 C14.9891373,6.87666988 15.1050271,6.82254849 15.2231729,6.72194974 C15.6944961,6.3206277 15.5399557,5.70825763 15.1166189,5.44167164 C13.8910498,4.66989958 12.4712863,4.25400285 10.9919943,4.25400285 Z" />
    </SvgIcon>
  );
}

export default SearchFilled;
