import React, { useEffect, useState, useRef } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Stack, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation } from "@apollo/client";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { UPDATE_AUTH_PASSWORD } from '@apis/AuthApi';
import InputFormControl from '@components/InputFormControl';

export default (props) => {
  const {
    open,
    hasPassword = false,
    onClose,
    onSuccess,
  } = props;

  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [updateAuthPassword] = useMutation(UPDATE_AUTH_PASSWORD);
  const [errorMessage, setErrorMessage] = useState();
  const passwordInputRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      password: hasPassword && Yup.string().required('请输入当前密码'),
      newPassword: Yup.string().min(6, '新密码至少需要6个字符').required('请输入新密码'),
      confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], '两次输入的新密码不匹配').required('请确认新密码'),
    }),
    validateOnBlur: validateAfterSubmit,
    validateOnChange: validateAfterSubmit,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setErrorMessage(null);

      try {
        await updateAuthPassword({
          variables: {
            oldPassword: hasPassword ? values.password : undefined,
            newPassword: values.newPassword,
          },
        });

        onSuccess();
      } catch(ex) {
        const error = ex.graphQLErrors[0];
        if (error.extensions.code === 'OLD_PASSWORD_INCORRECT_ERROR') {
          passwordInputRef.current && passwordInputRef.current.focus();
          setTimeout(() => {
            setErrors({
              password: error.message,
            });
          }, 0);
        } else {
          setErrorMessage(ex.message);
        }
      }
      setSubmitting(false);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleCancel = () => {
    onClose();
  }

  useEffect(() => {
    resetForm();
    setErrorMessage(null);
  }, [open]);

  return (
    <Dialog
      onClose={(evt, reason) => {
        if (reason && reason === "backdropClick") 
        return;
        onClose();
      }}
      open={open}
      maxWidth="xs"
      fullWidth
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>
            {hasPassword ? '修改' : '创建'}密码
            <IconButton
              aria-label="close"
              onClick={handleCancel}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              {
                errorMessage && (
                  <Alert sx={{ mb: 2 }} severity="error">{ errorMessage }</Alert>
                )
              }
              {
                hasPassword ? (
                  <InputFormControl
                    autoFocus
                    inputRef={passwordInputRef}
                    label="当前密码"
                    fullWidth
                    type="password"
                    placeholder="当前密码"
                    {...getFieldProps('password')}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                ) : null
              }
              <InputFormControl
                autoFocus={!hasPassword}
                label="新密码"
                fullWidth
                type="password"
                placeholder="新密码"
                {...getFieldProps('newPassword')}
                error={Boolean(touched.newPassword && errors.newPassword)}
                helperText={touched.newPassword && errors.newPassword}
              />
              <InputFormControl
                label="再次输入新密码"
                fullWidth
                type="password"
                placeholder="再次输入新密码"
                {...getFieldProps('confirmPassword')}
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                helperText={touched.confirmPassword && errors.confirmPassword}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>取消</Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              loadingPosition="start"
              onClick={() => {
                setValidateAfterSubmit(true);
              }}
            >
              保存
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  )
}
