import React, { useContext, useEffect } from 'react';
import { Typography, Box, Stack, Divider, FormControl, FormHelperText, InputBase, Select, MenuItem, Button, Switch, Checkbox, FormControlLabel } from '@mui/material';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { useFormik, Form, FormikProvider, Field } from 'formik';
import * as Yup from 'yup';
import { UserContext } from '@contexts/user/userReducer';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_SETTINGS } from '@apis/UserSettingsApi';
import CheckIcon from '@mui/icons-material/Check';
import { LOC_DEFAULT_VIEW, LOC_THEME, LOC_AUTO_DARK_THEME } from '@constants/localStorage';
import { SET_USER_SETTINGS } from '@contexts/user/UserActionTypes';
import * as events from '@constants/events';

const THEME_LIST = [
  { value: 'LIGHT', label: '浅色' },
  { value: 'DARK', label: '深色' },
];

const DEFAULT_VIEW_LIST = [
  { value: '/inbox', label: '收集箱' },
  { value: '/all', label: '所有书签' },
  { value: '/starred', label: '已加星标' },
];

const StyledOutlinedInput = styled(InputBase)(({ theme, error }) => ({
  '& .MuiInputBase-input': {
    borderRadius: theme.spacing(1),
    position: 'relative',
    border: '1px solid',
    fontSize: 14,
    padding: '4px 12px',
    height: 24,
    minWidth: '144px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    borderColor: error ? theme.palette.error.main : theme.palette.default,
    '&:focus': {
      boxShadow: `${alpha(error ? theme.palette.error.main : theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
    },
  },
}));

const FormItem = (props) => {
  const {
    label,
    description,
    children,
    edge,
  } = props;

  const theme = useTheme();

  return (
    <Stack sx={{ px: 2 }}>
      <Stack direction="row" sx={{ alignItems: 'center', mb: 2, height: 34 }}>
        <Stack sx={{ flex: 1 }}>
          <Typography
            component="label"
            variant="body2"
            sx={{
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            { label }
          </Typography>
          {
            description && (
              <Typography
                component="label"
                variant="body2"
                sx={{
                  color: theme.palette.text.secondary,
                  mt: .5,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  overflow: 'hidden',
                }}
              >
                { description }
              </Typography>
            )
          }
        </Stack>
        <Box>
          { children }
        </Box>
      </Stack>
      {
        edge !== 'end' && <Divider />
      }
    </Stack>
  )
}

const StyledListItemIcon = styled(({ checked, ...props }) => (
  <Box {...props}>
    {
      checked && <CheckIcon style={{ marginRight: 0 }} />
    }
  </Box>
))(({ theme }) => ({
  minWidth: '24px',
  display: 'flex',
  alignItems: 'center'
}));

export default (props) => {
  const {
    hideDescription = false,
    showTitle = false,
  } = props;

  const theme = useTheme();
  const { state: userState, dispatch: userDispatch } = useContext(UserContext);

  if (!userState.settings) return;
  
  const [updateUserSettings] = useMutation(UPDATE_USER_SETTINGS);

  const formik = useFormik({
    initialValues: {
      defaultView: userState.settings?.defaultView || '/inbox',
      theme: userState.settings?.theme || 'LIGHT',
      autoDarkTheme: userState.settings?.autoDarkTheme || false,
    },
    validationSchema: Yup.object().shape({
    }),
    // onSubmit: async (values: { theme, defaultView }, { setSubmitting }) => {
    // }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setValues } = formik;

  const dispatchThemeChangeEvent = ({ autoDarkTheme, theme }) => {
    window.dispatchEvent(new CustomEvent(events.CHANGE_THEME, {
      detail: {
        autoDarkTheme,
        theme,
      }
    }));
  }

  const handleChangeDefaultView = async (evt) => {
    const { value } = evt.target;
    setValues({ ...values, defaultView: value });

    const { data } = await updateUserSettings({
      variables: {
        userSettings: {
          defaultView: value,
        }
      }
    });

    userDispatch({ type: SET_USER_SETTINGS, data: data.userSettingsUpdate });

    localStorage.setItem(LOC_DEFAULT_VIEW, data.userSettingsUpdate.defaultView);
  }

  const handleChangeTheme = async (evt) => {
    const { value } = evt.target;
    setValues({ ...values, theme: value });

    const { data } = await updateUserSettings({
      variables: {
        userSettings: {
          theme: value,
        }
      }
    });

    userDispatch({ type: SET_USER_SETTINGS, data: data.userSettingsUpdate });

    localStorage.setItem(LOC_THEME, data.userSettingsUpdate.theme);

    dispatchThemeChangeEvent(data.userSettingsUpdate);
  }

  const handleChangeAutoDarkTheme = async (evt, checked) => {
    setValues({ ...values, autoDarkTheme: evt.target.checked });

    const { data } = await updateUserSettings({
      variables: {
        userSettings: {
          autoDarkTheme: checked,
        }
      }
    });

    userDispatch({ type: SET_USER_SETTINGS, data: data.userSettingsUpdate });

    localStorage.setItem(LOC_AUTO_DARK_THEME, data.userSettingsUpdate.autoDarkTheme);

    dispatchThemeChangeEvent(data.userSettingsUpdate);
  }

  useEffect(() => {
    const { theme, autoDarkTheme } = userState.settings;
    setValues({ ...values, theme, autoDarkTheme });
  }, [userState.settings]);

  return (
    <Box>
      {/* <Box>
        <Typography
          component="span"
          variant="h3"
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
          }}
        >
          界面
        </Typography>
      </Box> */}
      {
        showTitle ? (
          <Box sx={{ px: 2, py: 1 }}>
            <Typography
              component="span"
              variant="body2"
              color="text.secondary"
            >
              界面
            </Typography>
          </Box>
        ) : null
      }
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
        >
          <Stack
            component="section"
            spacing={2}
            sx={{
              borderRadius: '16px',
              border: `1px solid ${theme.palette.divider}`,
              pt: 2,
            }}
          >
            <FormItem label="主题" description={hideDescription ? null : '选择个性化主题'}>
              <Field name="theme">
                {({ field }) => (
                  <FormControl>
                    <Select
                      size="small"
                      input={<StyledOutlinedInput />}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            width: 190,
                          },
                        },
                      }}
                      renderValue={(value) => THEME_LIST.find((view) => view.value === value)?.label}
                      value={field.value}
                      onChange={handleChangeTheme}
                    >
                      {
                        THEME_LIST.map(({ value, label }) => (
                          <MenuItem value={value}>
                            <StyledListItemIcon checked={THEME_LIST.findIndex((view) => getFieldProps('theme').value === value) > -1} />
                            { label }
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                )}
              </Field>
            </FormItem>
            <FormItem label="自动深色主题" description={hideDescription ? null : '跟随系统主题自动切换到深色主题'}>
              <Field name="autoDarkTheme">
                {({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={handleChangeAutoDarkTheme}
                        name={field.name}
                      />
                    }
                  />
                )}
              </Field>
            </FormItem>
            <FormItem label="默认首页" edge="end" description={hideDescription ? null : '登录后默认打开的收藏夹'}>
              <Field name="defaultView">
                {({ field }) => (
                  <FormControl>
                    <Select
                      size="small"
                      input={<StyledOutlinedInput />}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            width: 190,
                          },
                        },
                      }}
                      renderValue={(value) => DEFAULT_VIEW_LIST.find((view) => view.value === value)?.label}
                      value={field.value}
                      onChange={handleChangeDefaultView}
                    >
                      {
                        DEFAULT_VIEW_LIST.map(({ value, label }) => (
                          <MenuItem value={value}>
                            <StyledListItemIcon checked={DEFAULT_VIEW_LIST.findIndex((view) => getFieldProps('defaultView').value === value) > -1} />
                            { label }
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                )}
              </Field>
            </FormItem>
          </Stack>
        </Form>
      </FormikProvider>
    </Box>
  )
};
