import React, { useContext, useState } from 'react';
import { ListItemText, MenuItem, ListItemIcon, MenuList, Divider, Typography } from '@mui/material';
import { NestedMenuItem } from 'mui-nested-menu';
import { styled, useTheme } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import { SHOW_SNACKBAR } from '@contexts/snackbar/SnackbarActionTypes';
import { SnackbarContext } from '@contexts/snackbar/snackbarReducer';
import infoOutline from '@iconify/icons-eva/info-outline';
import trashOutline from '@iconify/icons-eva/trash-outline';
import flip2Outline from '@iconify/icons-eva/flip-2-outline';
import { BookmarkContext } from '@contexts/bookmark/bookmarkReducer';
import { CollectionContext } from '@contexts/collection/collectionReducer';
import { CollectionListContext } from '@contexts/collectionList/collectionListReducer';
import * as BookmarkActionTypes from '@contexts/bookmark/BookmarkActionTypes';
import * as CollectionListActionTypes from '@contexts/collectionList/CollectionListActionTypes';
import * as collectionRole from '@constants/collectionRole';
import copy from 'copy-to-clipboard';
import { useHotkeys } from 'react-hotkeys-hook';
import { HOTKEYS_STAR_BOOKMARK, HOTKEYS_TOGGLE_RIGHT_SIDEBAR, HOTKEYS_COPY_URL, HOTKEYS_COPY_AS_MD, HOTKEYS_COPY_AS_DESCMD } from '@constants/hotkeys';
import useBookmark from '@pages/DynamicBookmarkList/useBookmark';
import * as events from '@constants/events';

// 书签操作
export const ACTION = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  RECOVERY: 'RECOVERY',
  FOREVER_DELETE: 'FOREVER_DELETE',
  MOVE: 'MOVE',
  STAR: 'STAR',
  COPY_URL: 'COPY_URL',
  COPY_AS_MD: 'COPY_AS_MD',
  COPY_AS_DESC_MD: 'COPY_AS_DESC_MD',
  UPDATE_METADATA: 'UPDATE_METADATA',
}

const ACTION_MENU_TYPE = {
  NORMAL: 'NORMAL',
  NORMAL_WITHOUT_PREVIEW: 'NORMAL_WITHOUT_PREVIEW',
  IN_TRASH: 'IN_TRASH',
}

// 书签操作菜单
const getActionMenu = (type, { role, isStarred }) => {
  const baseMenu = [{
    name: '预览',
    icon: infoOutline,
    action: ACTION.EDIT,
    hotkeys: '⇧P',
  }];

  const operateMenu = [{
    name: `${isStarred ? '移除' : '添加'}星标`,
    action: ACTION.STAR,
    hotkeys: '⇧S',
  }, {
    name: '复制链接',
    action: ACTION.COPY_URL,
    hotkeys: '⇧C',
  }, {
    name: '复制为',
    children: [{
      name: 'Markdown',
      action: ACTION.COPY_AS_MD,
      hotkeys: '⇧D',
    }, {
      name: '带描述的Markdown',
      action: ACTION.COPY_AS_DESC_MD,
      hotkeys: '⇧E',
    }]
  }];

  const updateMetadataMenu = [{
    name: '修改基本信息',
    action: ACTION.UPDATE_METADATA,
  }];

  if (type === ACTION_MENU_TYPE.NORMAL) {
    return [
      baseMenu,
      operateMenu,
      updateMetadataMenu,
      role !== collectionRole.VIEWER ? [
        {
          name: '删除',
          icon: trashOutline,
          action: ACTION.DELETE,
          role: 'destructive',
        }
      ] : null,
    ].filter(m => m);
  }

  if (type === ACTION_MENU_TYPE.NORMAL_WITHOUT_PREVIEW) {
    return [
      operateMenu,
      updateMetadataMenu,
      role !== collectionRole.VIEWER ? [
        {
          name: '删除',
          icon: trashOutline,
          action: ACTION.DELETE,
          role: 'destructive',
        }
      ] : null,
    ].filter(m => m);
  }

  if (type === ACTION_MENU_TYPE.IN_TRASH) {
    return [
      [
        {
          name: '恢复',
          icon: flip2Outline,
          action: ACTION.RECOVERY,
        }, {
          name: '永久删除',
          icon: trashOutline,
          action: ACTION.FOREVER_DELETE,
          role: 'destructive',
        }
      ]
    ];
  }
}

const StyledMenuItem = styled((props) => (
  <MenuItem
    {...props}
  />
))(({ theme }) => ({
  minHeight: theme.spacing(4),
  height: theme.spacing(4),
}));

const StyledNestedMenuItem = styled((props) => (
  <NestedMenuItem
    MenuProps={{
      anchorOrigin:{
        vertical: 'top',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      }
    }}
    {...props}
  />
))(({ theme }) => ({
  minHeight: theme.spacing(4),
  height: theme.spacing(4),
  paddingLeft: '16px',
  '& .MuiTypography-root': {
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: theme.typography.body2.fontSize,
  }
}));

export default ((props) => {
  const {
    open,
    type,
    onDeleteBookmark,
    onRecorveryBookmark,
    onForeverDeleteBookmark,
  } = props;

  const theme = useTheme();
  const { state, dispatch } = useContext(BookmarkContext);
  const { state: collectionState, dispatch: collectionDispatch } = useContext(CollectionContext);
  const { dispatch: collectionListDispatch } = useContext(CollectionListContext);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);
  const [reqLock, setReqLock] = useState(false);
  const { starBookmark, unstarBookmark } = useBookmark();

  function handleListKeyDown(evt) {
    if (evt.key === 'Tab') {
      evt.preventDefault();
      dispatch({ type: BookmarkActionTypes.HIDE_ACTION });
    } else if (evt.key === 'Escape') {
      dispatch({ type: BookmarkActionTypes.HIDE_ACTION });
    }
  }

  const getMenuItemData = () => {
    const bookmark = state.bookmarkList.edges[state.selectIndex];
    const actionParams = {
      role: collectionState.collection.role,
      isStarred: (collectionState.collection.id === 'starred' && typeof bookmark.isStarred === 'undefined') ? true : bookmark.isStarred,
    }
    if (type === 'trash') {
      return getActionMenu(ACTION_MENU_TYPE.IN_TRASH, actionParams);
    } else {
      if (state.showPreviewWindow) {
        return getActionMenu(ACTION_MENU_TYPE.NORMAL_WITHOUT_PREVIEW, actionParams);
      } else {
        return getActionMenu(ACTION_MENU_TYPE.NORMAL, actionParams);
      }
    }
  }

  const handleMenuItemClick = async (action) => {
    const operateBookmark = state.bookmarkList.edges[state.selectIndex];
    const operateId = operateBookmark.id;
    dispatch({ type: BookmarkActionTypes.HIDE_ACTION });
    switch (action) {
      case ACTION.EDIT: {
        dispatch({ type: BookmarkActionTypes.OPEN_PREVIEW_WINDOW });
        break;
      }
      case ACTION.DELETE: {
        dispatch({ type: BookmarkActionTypes.REMOVE_SELECTED_BOOKMARK });
        collectionListDispatch({
          type: CollectionListActionTypes.UPDATE_COLLECTION_COUNT,
          data: [
            { id: operateBookmark.collection?.id || 'inbox', updateField: 'count', value: -1 }, // 当前收藏夹书签数-1
            { id: 'all', updateField: 'count', value: -1 }, // 所有书签数-1
            { id: 'starred', updateField: 'count', value: operateBookmark.isStarred || operateBookmark.isStarred === undefined ? -1 : 0 }, // 星标书签数-1
          ],
        });

        try {
          await onDeleteBookmark(operateId);
          snackbarDispatch({ type: SHOW_SNACKBAR, data: '已移至 回收站' });
        } catch(ex) {
          snackbarDispatch({ type: SHOW_SNACKBAR, data: '书签删除失败' });
          dispatch({ type: BookmarkActionTypes.ROLLBACK_OPERATION });
          collectionListDispatch({ type: CollectionListActionTypes.ROLLBACK_OPERATION });
        }
        break;
      }
      case ACTION.RECOVERY: {
        dispatch({ type: BookmarkActionTypes.REMOVE_SELECTED_BOOKMARK });
        collectionListDispatch({
          type: CollectionListActionTypes.UPDATE_COLLECTION_COUNT,
          data: [
            { id: operateBookmark.collection?.id || 'inbox', updateField: 'count', value: 1 }, // 书签数所在收藏夹书数+1
            { id: 'all', updateField: 'count', value: 1 }, // 所有书签数+1
          ],
        });

        try {
          const bookmark = await onRecorveryBookmark(operateId);
          snackbarDispatch({ type: SHOW_SNACKBAR, data: `已恢复至 ${bookmark.collection?.name || '收集箱'}` });
        } catch(ex) {
          snackbarDispatch({ type: SHOW_SNACKBAR, data: '书签恢复失败' });
          dispatch({ type: BookmarkActionTypes.ROLLBACK_OPERATION });
          collectionListDispatch({ type: CollectionListActionTypes.ROLLBACK_OPERATION });
        }
        break;
      }
      case ACTION.FOREVER_DELETE: {
        dispatch({ type: BookmarkActionTypes.REMOVE_SELECTED_BOOKMARK });
        try {
          await onForeverDeleteBookmark(operateId);
          snackbarDispatch({ type: SHOW_SNACKBAR, data: '已永久删除' });
        } catch(ex) {
          snackbarDispatch({ type: SHOW_SNACKBAR, data: '书签删除失败' });
          dispatch({ type: BookmarkActionTypes.ROLLBACK_OPERATION });
        }
        break;
      }
      case ACTION.STAR: {
        if (reqLock) return;
        setReqLock(true);
        
        const isStarred = operateBookmark.isStarred || operateBookmark.isStarred === undefined;

        try {
          collectionListDispatch({
            type: CollectionListActionTypes.UPDATE_COLLECTION_COUNT,
            data: [
              { id: 'starred', updateField: 'count', value: isStarred ? -1 : 1 }, // 更新星标书签数
            ],
          });

          if (isStarred) {
            dispatch({ type: BookmarkActionTypes.UNSTAR_BOOKMARK });
            await unstarBookmark(operateId);
          } else {
            dispatch({ type: BookmarkActionTypes.STAR_BOOKMARK });
            await starBookmark(operateId);
          }
        } catch(ex) {
          snackbarDispatch({ type: SHOW_SNACKBAR, data: `书签${isStarred ? '移除' : '添加'}星标失败` });
          dispatch({ type: BookmarkActionTypes.ROLLBACK_OPERATION });
          collectionListDispatch({ type: CollectionListActionTypes.ROLLBACK_OPERATION });
        }
        setReqLock(false);
        break;
      }
      case ACTION.COPY_URL: {
        copy(operateBookmark.url);
        snackbarDispatch({ type: SHOW_SNACKBAR, data: '已复制链接' });
        break;
      }
      case ACTION.COPY_AS_MD: {
        copy(`[${operateBookmark.title}](${operateBookmark.url})`);
        snackbarDispatch({ type: SHOW_SNACKBAR, data: '已复制为Markdown' });
        break;
      }
      case ACTION.COPY_AS_DESC_MD: {
        copy(`[${operateBookmark.title}](${operateBookmark.url})${operateBookmark.description ? `\r\n${operateBookmark.description}` : ''}${operateBookmark.image ? `\r\n\r\n![](${operateBookmark.image})` : ''}`);
        snackbarDispatch({ type: SHOW_SNACKBAR, data: '已复制为带描述的Markdown' });
        break;
      }
      case ACTION.UPDATE_METADATA: {
        window.dispatchEvent(new CustomEvent(events.UPDATE_BOOKMARK_METADATA));
        break;
      }
    }
  }

  const MENU_ITEM_DATA = getMenuItemData();

  useHotkeys([HOTKEYS_STAR_BOOKMARK, HOTKEYS_TOGGLE_RIGHT_SIDEBAR, HOTKEYS_COPY_URL, HOTKEYS_COPY_AS_MD, HOTKEYS_COPY_AS_DESCMD], () => {
    dispatch({ type: BookmarkActionTypes.HIDE_ACTION });
  }, []);

  return (
    <MenuList
      autoFocus
      onKeyDown={handleListKeyDown}
    >
      {
        MENU_ITEM_DATA.map((d, idx) => {
          const ret = [
            d.map(({ name, icon, action, role, children, hotkeys }) => {
              if (children) {
                return (
                  <StyledNestedMenuItem
                    label={name}
                    parentMenuOpen={open}
                  >
                    {
                      children.map(({ name, action, hotkeys }) => (
                        <StyledMenuItem onClick={handleMenuItemClick.bind(this, action)}>
                          <ListItemText primary={name} sx={{ color: role === 'destructive' ? theme.palette.error.main : theme.palette.text.primary }} />
                          {
                            hotkeys ? (
                              <Typography variant="caption" color="text.secondary">
                                { hotkeys }
                              </Typography>
                            ) : null
                          }
                        </StyledMenuItem>
                      ))
                    }
                  </StyledNestedMenuItem>
                )
              }

              return (
                <StyledMenuItem onClick={handleMenuItemClick.bind(this, action)}>
                  {/* <ListItemIcon>
                    <Icon icon={icon} />
                  </ListItemIcon> */}
                  <ListItemText primary={name} sx={{ color: role === 'destructive' ? theme.palette.error.main : theme.palette.text.primary }} />
                  {
                    hotkeys ? (
                      <Typography variant="body2" color="text.secondary">
                        { hotkeys }
                      </Typography>
                    ) : null
                  }
                </StyledMenuItem>
              );
            })
          ];
          
          if (idx < MENU_ITEM_DATA.length - 1) {
            return ret.concat(<Divider sx={{ my: 0.5 }} />);
          }

          return ret;
        })
      }
    </MenuList>
  )
})
