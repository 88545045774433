import React from 'react';
import { ClickAwayListener, Popper, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ActionPopper = ({ anchorEl, onClose, containerRef, children, ...popperProps }) => {
  const theme = useTheme();
  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      style={{ zIndex: 1201 }}
      placement="bottom-start"
      role={undefined}
      container={containerRef?.current}
      // transition
      {...popperProps}
      // disablePortal
      // keepMounted
    >
      {/* {({ TransitionProps, placement }) => ( */}
        {/* <Grow
          // {...TransitionProps}
          style={{ transformOrigin: 'right top' }}
        > */}
        <ClickAwayListener onClickAway={onClose}>
          <Paper
            sx={{
              borderRadius: '6px',
              minWidth: '220px',
              color: theme.palette.text.primary,
              boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
              ...theme.palette.mode === 'dark' && {
                boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
              },
              '& .MuiList-root': {
                padding: '4px 0',
              },
              '& .MuiMenuItem-root+.MuiDivider-root': {
                margin: '4px 0',
              }
            }}
          >
            {children}
          </Paper>
          </ClickAwayListener>
        {/* </Grow> */}
      {/* )} */}
    </Popper>
  )
}

export default ActionPopper;
