import React, { useState, useRef, useEffect, useContext } from 'react';
import { Autocomplete, InputBase, Chip, Paper, Box, FormControl, FormHelperText } from '@mui/material';
import { styled, alpha, useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { GET_BOOKMARK_TAGS } from '@apis/BookmarkTagApi';
import { useLazyQuery } from '@apollo/client';
import { UserContext } from '@contexts/user/userReducer';
import { OPERATION_FETCH_BOOKMARK_TAGS } from '@contexts/user/UserActionTypes';

const OPTION_PREFIX_NEW = 'N$';

const StyledPaper = styled(Paper)(({ theme, error, isFocused }) => ({
  display: 'flex', 
  flexWrap: 'wrap',
  alignItems: 'center',
  borderRadius: theme.spacing(1),
  border: '1px solid',
  padding: '5px 8px',
  margin: '2px 0',
  transition: theme.transitions.create([
    'border-color',
    'background-color',
    'box-shadow',
  ]),
  borderColor: error ? theme.palette.error.main : theme.palette.border,
  ...isFocused && {
    boxShadow: `${alpha(error ? theme.palette.error.main : theme.palette.primary.main, 0.25)} 0 0 0 2px`,
    borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
  }
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  minWidth: '2px',
  width: 'auto',
  flexGrow: 1,
  padding: '5px 4px',
  '& input': {
    padding: '0',
    height: '1.4375em',
    textOverflow: 'ellipsis',
    fontSize: '14px',
  },
}));

const HiddenSpan = styled('span')({
  position: 'absolute',
  top: '-9999px',
  left: '-9999px',
  whiteSpace: 'pre',
  fontSize: '1rem', // 确保与输入框字体大小一致
});

const StyledListItemIcon = styled(({ checked, ...props }) => (
  <Box {...props}>
    {
      checked && <CheckIcon style={{ marginRight: 0 }} />
    }
  </Box>
))(({ theme }) => ({
  minWidth: '24px',
  display: 'flex',
  alignItems: 'center'
}));

export default function BookmarkTagSelect(props) {
  const {
    value,
    error,
    helperText,
    onChange,
  } = props;

  const [selectedValues, setSelectedValues] = useState(value || []);
  const [inputValue, setInputValue] = useState('');
  const [inputWidth, setInputWidth] = useState(2);
  const [isFocused, setIsFocused] = useState(false);
  const hiddenSpanRef = useRef(null);
  const [options, setOptions] = useState([]);
  const inputRef = useRef(null);
  const theme = useTheme();
  const { state: userState, dispatch: userDispatch } = useContext(UserContext);
  const handleChange = (event, newValue, reason) => {
    let finalValue;
    if (reason === 'selectOption' && newValue[newValue.length - 1]?.startsWith(OPTION_PREFIX_NEW)) {
      const newOption = newValue[newValue.length - 1].slice(OPTION_PREFIX_NEW.length);
      setOptions([...options, newOption]);
      finalValue = [...selectedValues, newOption];
    } else {
      finalValue = newValue;
    }

    setSelectedValues(finalValue);
    onChange({
      target: {
        name: 'tags',
        value: finalValue
      }
    });
  };

  const filterOptions = (options, { inputValue }) => {
    const filtered = options.filter(
      (option) => option.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
    )
    
    const isExisting = options.some(
      (option) => option.toLowerCase() === inputValue.toLowerCase()
    );

    if (inputValue !== '' && !isExisting) {
      filtered.push(`${OPTION_PREFIX_NEW}${inputValue}`);
    }

    return filtered;
  };

  useEffect(() => {
    userDispatch({ type: OPERATION_FETCH_BOOKMARK_TAGS });
  }, []);

  useEffect(() => {
    setOptions(userState.bookmarkTags.map(({ name }) => name));
  }, [userState.bookmarkTags]);

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 200)
    }
  }, []);

  useEffect(() => {
    if (hiddenSpanRef.current) {
      const width = hiddenSpanRef.current.offsetWidth;
      setInputWidth(Math.max(2, width + 4)); // 加4是为了给一些额外空间
    }
  }, [inputValue]);

  return (
    <FormControl fullWidth>
      <Autocomplete
        multiple
        autoHighlight
        openOnFocus
        options={options}
        value={selectedValues}
        onChange={handleChange}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <StyledPaper ref={params.InputProps.ref} isFocused={isFocused} error={!!error}>
            {params.InputProps.startAdornment}
            <StyledInputBase
              {...params.inputProps}
              placeholder={selectedValues.length === 0 ? '标签' : ''}
              style={{ width: `${inputWidth}px` }}
              onFocus={(event) => {
                setIsFocused(true);
                if (params.inputProps.onFocus) {
                  params.inputProps.onFocus(event);
                }
              }}
              onBlur={(event) => {
                setIsFocused(false);
                if (params.inputProps.onBlur) {
                  params.inputProps.onBlur(event);
                }
              }}
              inputRef={inputRef}
            />
          </StyledPaper>
        )}
        renderOption={(props, option, state, ownerState) => {
          return (
            <li {...props}>
              {option.startsWith(OPTION_PREFIX_NEW) ? (
                <>
                  <StyledListItemIcon checked={state.selected} />
                    创建<Box component="span" sx={{ color: theme.palette.primary.main, ml: .5 }}>{option.slice(OPTION_PREFIX_NEW.length)}</Box>
                </>
              ) : (
                <>
                  <StyledListItemIcon checked={state.selected} />{option}
                </>
              )}
            </li>
          )
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              size="small"
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              key={option}
            />
          ))
        }
        filterOptions={filterOptions}
        disableCloseOnSelect
        freeSolo
        sx={{
          '& .MuiAutocomplete-paper': {
            backgroundColor: 'red',
            boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          }
        }}
      />
      <HiddenSpan ref={hiddenSpanRef}>{inputValue}</HiddenSpan>
      {helperText && (
        <FormHelperText error={error} sx={{ fontSize: 14, ml: 0 }}>
          { helperText }
        </FormHelperText>
      )}
    </FormControl>
  );
}