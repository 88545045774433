import React from 'react';
import { Typography, Box, Stack } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import useDeviceDetect from '@utils/hooks/useDeviceDetect';

const KEY_MAP = {
  'shift': '⇧',
  'alt': '⌥',
  'ctrl': '^',
  'meta': '⌘'
}

export default (props) => {
  const theme = useTheme();
  const { isTouch } = useDeviceDetect();

  const {
    text,
    hotkeys,
    color,
    style,
    onClick,
  } = props;

  const hotkeysElem = hotkeys.split('+').map(key => (
    <Box sx={{ p: 1, border: 1, width: theme.spacing(2), height: theme.spacing(2), display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 1, borderColor: theme.palette.border, backgroundColor: alpha(theme.palette.background.paper, 0.9) }}> 
      <Typography
        variant="caption"
        sx={{ fontSize: '0.625rem', color: theme.palette.text.secondary }}
      >
        {KEY_MAP[key] || key}
      </Typography>
    </Box>
  ))

  return (
    <Box style={style} sx={{ display: 'flex' }} onClick={onClick}>
      {
        text && <Typography noWrap sx={{ fontSize: 13 }}>{text}</Typography>
      }
      {
        !isTouch && (
          <Stack direction="row" spacing={.5} style={text && { marginLeft: theme.spacing(1) }}>
            {hotkeysElem}
          </Stack>
        )
      }
    </Box>
  )
}