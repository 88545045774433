import React, { useEffect, useState, useRef } from 'react';
import { IconButton, InputBase, InputAdornment, Stack, ToggleButtonGroup, ToggleButton, ClickAwayListener } from '@mui/material';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from 'formik';
import { useHotkeys } from 'react-hotkeys-hook';
import { HOTKEYS_SEARCH } from '@constants/hotkeys';
import Keycap from '@components/Keycap';
import CloseOutlinedIcon from '@icons/CloseOutlined';
import SearchOutlinedIcon from '@icons/SearchOutlined';

const SearchInput = styled('div')(({ theme, inputFocus }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  // borderRadius: theme.spacing(1),
  borderRadius: '9999px',
  backgroundColor: theme.palette.background.neutral,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.divider,
  ...inputFocus && {
    backgroundColor: theme.palette.action.hover,
    borderColor: theme.palette.border,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    borderColor: theme.palette.border,
  },
  // width: '100%',
  // [theme.breakpoints.up('sm')]: {
  //   marginLeft: theme.spacing(3),
  //   width: 'auto',
  // },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
}));

const StyledInputBase = styled(InputBase)(({ theme, expand }) => ({
  flex: 1,
  color: theme.palette.text.primary,
  width: theme.spacing(expand ? 32 : 24),
  transition : 'width .25s',
  '& .MuiInputBase-input': {
    // padding: theme.spacing(.7, 1, .7, 0),
    // vertical padding + font size from searchIcon
    height: '32.5px',
    padding: 0,
    paddingLeft: `calc(1em + ${theme.spacing(3.5)})`,
    transition: theme.transitions.create('width'),
    // width: '100%',
    backgroundColor: 'transparent', // fix: FlowBite
    '&:focus': { // fix: FlowBite
      outline: 0,
      boxShadow: 'none',
    },
    fontSize: '14px',
  },
  '&::-webkit-search-cancel-button': {
    /* Remove default */
    WebkitAppearance: 'none',
    /* Now your own custom styles */
    height: '10px',
    width: '10px',
    background: 'red',
    /* Will place small red box on the right of input (positioning carries over) */
  }
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => {
  return {
    '& .MuiToggleButtonGroup-grouped': {
      borderColor: alpha(theme.palette.grey[400], 0.25),
      lineHeight: 'normal',
      padding: '3px 7px',
      background: theme.palette.background.paper,
      '&.Mui-selected': {
        color: theme.palette.background.paper,
        borderColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.main
        }
      },
      '&:not(:first-of-type)': {
        borderTopRightRadius: '999px',
        borderBottomRightRadius: '999px',
      },
      '&:first-of-type': {
        borderTopLeftRadius: '999px',
        borderBottomLeftRadius: '999px',
      },
    },
  }
});

export default (props) => {
  const {
    title,
    collection,
    keywords,
    onSearch,
    scope,
  } = props;

  const theme = useTheme();
  const inputRef = useRef();
  const [inputFocus, setInputFocus] = useState(false);
  const [innerScope, setInnerScope] = useState(scope);
  const [expandSearch, setExpandSearch] = useState(!!keywords);
  
  const formik = useFormik({
    initialValues: {
      keywords,
    },
    onSubmit: ({ keywords: value }) => {
      // 移动端搜索后自动收起输入法
      // inputRef.current.blur();
      onSearch(value, innerScope === 'all' && collection !== 'all');
    }
  });

  const handleInputKeyDown = (evt) => {
    if (evt.keyCode === 27) {
      if (!values.keywords) { // 当前文字为空的情况下，直接复原搜索框
        setExpandSearch(false);
        inputRef.current.blur();
      } else {
        clearSearch();
      }
    } else if (evt.keyCode === 9 && collection !== 'all') { // Tab切换搜索范围（除了所有收藏夹)
      evt.preventDefault();
      if (innerScope === 'all') {
        changeScope(collection);
      } else {
        changeScope('all');
      }
    }
  }

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setValues, handleBlur } = formik;

  // 清除搜索内容
  const clearSearch = () => {
    if (!!values.keywords) {
      inputRef.current.focus(); // 清除后继续聚焦到输入框
    }
    setValues({ keywords: '' });
    handleSubmit();
  }

  const InputEndAdornment = () => {
    if (values.keywords) {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={clearSearch}
            onMouseDown={evt => { evt.preventDefault() }}
            sx={{ fontSize: '0.75rem', padding: '3px', margin: '9px' }}
          >
            <CloseOutlinedIcon fontSize="inherit" />
          </IconButton>
        </InputAdornment>
      );
    } else if (!expandSearch) {
      return (
        <InputAdornment position="end">
          <Keycap
            hotkeys={HOTKEYS_SEARCH}
            style={{ padding: theme.spacing(1) }}
            onClick={() => { inputRef.current.focus() }}
          />
        </InputAdornment>
      )
    }

    return null;
  }

  // 修改搜索范围
  const changeScope = (val) => {
    setInnerScope(val);
    inputRef.current.focus();
    if (!!values.keywords) {
      onSearch(encodeURIComponent(values.keywords), val === 'all' && collection !== 'all');
    }
  }

  useEffect(() => {
    if (inputFocus) {
      setExpandSearch(true);
    }
  }, [inputFocus]);

  useHotkeys(HOTKEYS_SEARCH, (evt) => {
    evt.preventDefault();
    inputRef.current.focus();
    // inputRef.current.select();
  }, []);

  useEffect(() => {
    setValues({
      keywords: keywords || '',
    });
    if (keywords) {
      setExpandSearch(true);
    }
  }, [keywords]);

  useEffect(() => {
    if (keywords && scope === 'all') {
      setInnerScope('all');
    } else {
      setInnerScope(collection); // 重置默认搜索范围
    }

    // 切换收藏夹，重置搜索关键词
    if (collection && !keywords) {
      setValues({ keywords: '' });
    }
  }, [collection, keywords, scope]);

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        onSubmit={(e) => {
          handleSubmit(e);
          inputRef.current.blur();
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            if (expandSearch) {
              if (!values.keywords) {
                setExpandSearch(false);
              }
            }
          }}
        >
          <Stack direction="row">
            <SearchInput inputFocus={inputFocus}>
              <SearchIconWrapper>
                <SearchOutlinedIcon sx={{ fontSize: '1rem' }} />
              </SearchIconWrapper>
              <StyledInputBase
                // active={inputFocus || !!values.keywords || !!scope}
                expand={expandSearch}
                inputRef={inputRef}
                placeholder={expandSearch ? `在 ${innerScope === 'all' ? '所有书签' : title} 中搜索` : '搜索'}
                inputProps={{ 'aria-label': '搜索', spellcheck: 'false' }}
                autoFocus={location.pathname === `/${collection}/q`}
                type="text"
                endAdornment={<InputEndAdornment />}
                onFocus={() => setInputFocus(true)}
                onKeyDown={handleInputKeyDown}
                {...getFieldProps('keywords')}
                onBlur={(e) => {
                  handleBlur(e);
                  setInputFocus(false)
                }}
              />
              {
                collection !== 'all' ? (
                  <StyledToggleButtonGroup
                    exclusive
                    size="small"
                    value={innerScope}
                    aria-label="Search from"
                    onChange={(evt, newAlignment) => {
                      if (newAlignment !== null) {
                        changeScope(newAlignment);
                      }
                    }}
                    onKeyDown={(evt) => {
                      evt.preventDefault();
                      changeScope(innerScope === 'all' ? collection : 'all');
                    }}
                    style={{ display: expandSearch ? 'inline-flex' : 'none', marginRight: 5.5 }}
                  >
                    <ToggleButton value={collection}>{title}</ToggleButton>
                    <ToggleButton value='all'>所有书签</ToggleButton>
                  </StyledToggleButtonGroup>
                ) : null
              }
            </SearchInput>
          </Stack>
        </ClickAwayListener>
      </Form>
    </FormikProvider>
  )
}
