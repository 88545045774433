import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function UserFilled(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12,2.25 C14.209139,2.25 16,4.040861 16,6.25 C16,8.459139 14.209139,10.25 12,10.25 C9.790861,10.25 8,8.459139 8,6.25 C8,4.040861 9.790861,2.25 12,2.25 Z M12,11.5 C16.6585231,11.5 20.5,14.4740466 20.5,18.25 C20.5,22.5637085 3.5,22.5637085 3.5,18.25 C3.5,14.4740466 7.34147687,11.5 12,11.5 Z" />
    </SvgIcon>
  );
}

export default UserFilled;
