import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function MoreHorizOutlined(props) {
  return (
    <SvgIcon {...props}>
      <path fill-rule="evenodd" d="M12 13.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm-6.5 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm13 .002a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" clip-rule="evenodd"/>
    </SvgIcon>
  );
}

export default MoreHorizOutlined;
