import React, { createRef, useState } from 'react';
import { Typography, Stack, SwipeableDrawer, Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import AddBookmarkForm from '../AddBookmarkForm';

export default (props) => {
  const {
    collectionId,
    open = false,
    onClose,
    onOpen,
    onSuccess,
  } = props;

  const formRef = createRef();
  const theme = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState();

  return (
    <SwipeableDrawer 
      // container={window}
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      // swipeAreaWidth={56}
      ModalProps={{
        keepMounted: false,
      }}
      sx={{
        '& .MuiPaper-root': {
          maxHeight: `calc(100% - 48px)`,
          // overflow: 'visible',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          paddingBottom: 'env(safe-area-inset-bottom)',
        }
      }}
    >
      <header
        style={{
          height: '48px',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          px: 2,
          borderBottom: `1px solid ${theme.palette.divider}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction="row">
          <Button onClick={onClose} sx={{ fontSize: theme.typography.body1.fontSize }}>取消</Button>
        </Stack>
        <Typography variant="h5" component="h5" sx={{ flexGrow: 1, textAlign: 'center' }}>
          添加书签
        </Typography>
        <Stack direction="row">
          <LoadingButton
            sx={{ fontSize: theme.typography.body1.fontSize }}
            loading={isSubmitting}
            disabled={!value}
            onClick={() => {
              formRef.current.handleSubmit();
            }}
          >
          完成
        </LoadingButton>
        </Stack>
      </header>
      <Box sx={{ p: 2 }}>
        <AddBookmarkForm
          ref={formRef}
          open={open}
          collectionId={collectionId}
          onSubmitting={(submiting) => setIsSubmitting(submiting)}
          onValuesChange={(values) => setValue(values?.url)}
          onSuccess={onSuccess}
        />
      </Box>
    </SwipeableDrawer>
  )
}
