import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider, GlobalStyles } from '@mui/material';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
  from,
  useQuery,
  gql
} from "@apollo/client";
import { onError } from "apollo-link-error";
import { setContext } from '@apollo/client/link/context';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import StandaloneDivider from '@components/StandaloneDivider';
import Router from './routes';
// import ThemeConfig from './theme';
import themes from '../../themes';
import { LOC_THEME, LOC_AUTO_DARK_THEME } from '@constants/localStorage';
import * as events from '@constants/events';

const customGlobalStyles = <GlobalStyles styles={{
  'html': {
    fontSize: '16px',
  },
  'body > div:first-child': {
    height: '100%',
  },
  ':focus-visible': {
    outline: 0,
  },
  '[multiple]:focus,[type=date]:focus,[type=datetime-local]:focus,[type=email]:focus,[type=month]:focus,[type=number]:focus,[type=password]:focus,[type=search]:focus,[type=tel]:focus,[type=text]:focus,[type=time]:focus,[type=url]:focus,[type=week]:focus,select:focus,textarea:focus': {
    boxShadow: 'none',
  }
}} />;

const getGraphqlUri = () => {
  let uri;
  if (process.env.API_HOST) {
    uri = process.env.API_HOST;
  } else {
    if (location.host.endsWith('kiipu.com')) {
      uri = 'api.kiipu.com';
    } else {
      uri = 'dev-api.kiipu.com:3001';
    }
  }
  return `//${uri}/graphql`;
}

const httpLink = createHttpLink({
  uri: getGraphqlUri(),
  credentials: 'include',
  useGETForQueries: true,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log('[graphQLErrors]', graphQLErrors);
  // if (graphQLErrors) {
  //   for (let err of graphQLErrors) {
  //     switch (err.extensions.code) {
  //       case 'UNAUTHENTICATED':
  //         localStorage.removeItem('token');
  //         location.replace('/login');
  //         return;
  //     }
  //   }
  // }

  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error.
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }

  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );

      if (message.includes('Access denied!')) {
        localStorage.removeItem('token');
        location.replace('/logout');
      } else {
        // console.log("dispatch");
        // snackbarStore.dispatch.snackbar.handleOpen(message);
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  const mockToken = localStorage.getItem('mockToken');
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${mockToken || token}` : "",
    }
  }));

  return forward(operation);
});

const client = new ApolloClient({
  link: from([
    errorLink,
    authMiddleware,
    httpLink,
  ]),
  cache: new InMemoryCache(),
  // queryDeduplication: false,
});

export default function App() {
  let autoDarkTheme = false;

  if (typeof localStorage.getItem(LOC_AUTO_DARK_THEME) === 'string') {
    autoDarkTheme = localStorage.getItem(LOC_AUTO_DARK_THEME) === 'true';
  } else {
    autoDarkTheme = $CONFIG.interface?.autoDarkTheme;
  }

  const [theme, setTheme] = useState({
    theme: localStorage.getItem(LOC_THEME) || $CONFIG.interface?.theme || 'LIGHT',
    autoDarkTheme,
  });

  const handleChangeTheme = (value) => {
    setTheme(value.detail);
  }

  useEffect(() => {
    window.addEventListener(events.CHANGE_THEME, handleChangeTheme);

    return () => {
      window.removeEventListener(events.CHANGE_THEME, handleChangeTheme);
    }
  }, []);
  
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes({ ...theme })}>
        <CssBaseline />
        { customGlobalStyles }
        <StandaloneDivider />
        <ApolloProvider client={client}>
          <DndProvider backend={HTML5Backend}>
            <Router />
          </DndProvider>
        </ApolloProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
