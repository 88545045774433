import React, { useEffect, useState, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Box, BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { styled, useTheme } from '@mui/material/styles';
import { OPERATION_FETCH_COLLECTION } from '@contexts/collectionList/CollectionListActionTypes';
import { CollectionListContext } from '@contexts/collectionList/collectionListReducer';
import BookmarksFilledIcon from '@icons/BookmarksFilled';
import BookmarksOutlinedIcon from '@icons/BookmarksOutlined';
import SearchFilledIcon from '@icons/SearchFilled';
import SearchOutlinedIcon from '@icons/SearchOutlined';
import CompassFilledIcon from '@icons/CompassFilled';
import CompassOutlinedIcon from '@icons/CompassOutlined';
import UserFilledIcon from '@icons/UserFilled';
import UserOutlinedIcon from '@icons/UserOutlined';
import AddBookmarkDrawer from '@components/AddBookmarkDrawer';
import { SHOW_SNACKBAR } from '@contexts/snackbar/SnackbarActionTypes';
import { SnackbarContext } from '@contexts/snackbar/snackbarReducer';
import * as events from '@constants/events';

const MOBILE_PAGE_TAB = {
  BOOKMARK: 'BOOKMARK',
  SEARCH: 'SEARCH',
  EXPLORE: 'EXPLORE',
  PROFILE: 'PROFILE',
}

const Footer = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  borderRadius: 0,
  borderTop: `1px solid ${theme.palette.divider}`,
  paddingBottom: ['calc(constant(safe-area-inset-bottom) - 8px)', 'calc(env(safe-area-inset-bottom) - 8px)'],
}));

const StyledBottomNavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
  transition: 'none',
}));

export default function MobileLayout() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = React.useState(0);
  const [searchPath, setSearchPath] = useState('/search');
  const [bookmarkPath, setBookmarkPath] = useState('/inbox');
  const [addBookmarkDrawerVisible, setAddBookmarkDrawerVisible] = useState(false);
  const ref = React.useRef(null);
  const [scrollY, setScrollY] = useState(0);

  const { pathname } = location;
  const { collection: collectionId } = useParams();

  const { dispatch: collectionListDispatch } = useContext(CollectionListContext);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);

  const handleNew = () => {
    setScrollY(window.scrollY);
    setAddBookmarkDrawerVisible(true);
  }
  
  useEffect(() => {
    collectionListDispatch({ type: OPERATION_FETCH_COLLECTION, data: {} });
  }, []);

  useEffect(() => {
    ref.current.ownerDocument.body.scrollTop = 0;
  }, [value]);
  
  useEffect(() => {
    const collectionRegx = /(\/collection\/.+|inbox|all|starred|trash)(\/q\/.+|)$/;
    if (pathname.startsWith('/search')) {
      setSearchPath('/search');
      setValue(MOBILE_PAGE_TAB.SEARCH);
    } else if (pathname.startsWith('/explore')) {
      setValue(MOBILE_PAGE_TAB.EXPLORE);
    } else if (pathname.startsWith('/settings')) {
      setValue(MOBILE_PAGE_TAB.PROFILE);
    } else if (collectionRegx.test(pathname)) {
      const match = pathname.match(collectionRegx);
      if (match[2]) {
        setSearchPath(pathname);
        setValue(MOBILE_PAGE_TAB.SEARCH);
      } else {
        setBookmarkPath(pathname);
        setValue(MOBILE_PAGE_TAB.BOOKMARK);
      }
    }
  }, [pathname]);

  return (
    <>
      <Box sx={{ pb: 7 }} ref={ref}>
        <Outlet />
        <Footer elevation={0}>
          <BottomNavigation
            showLabels={false}
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              switch (newValue) {
                case MOBILE_PAGE_TAB.BOOKMARK: navigate(bookmarkPath, { replace: true }); break;
                case MOBILE_PAGE_TAB.SEARCH: navigate(searchPath, { replace: true }); break;
                case MOBILE_PAGE_TAB.EXPLORE: navigate('/explore', { replace: true }); break;
                case MOBILE_PAGE_TAB.PROFILE: navigate('/settings', { replace: true }); break;
              }
            }}
          >
            <StyledBottomNavigationAction
              sx={{ px: 0, minWidth: 'auto' }}
              value={MOBILE_PAGE_TAB.BOOKMARK}
              icon={value === MOBILE_PAGE_TAB.BOOKMARK ? <BookmarksFilledIcon /> : <BookmarksOutlinedIcon />}
              onDoubleClick={() => {
                window.dispatchEvent(new CustomEvent(events.SHOW_COLLECTION_LIST)); // 双击打开书签夹列表
              }}
            />
            <StyledBottomNavigationAction
              sx={{ px: 0, minWidth: 'auto' }}
              value={MOBILE_PAGE_TAB.SEARCH}
              icon={value === MOBILE_PAGE_TAB.SEARCH ? <SearchFilledIcon /> : <SearchOutlinedIcon />}
            />
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              <Fab
                elevation={0}
                color="primary"
                aria-label="add"
                style={{ backgroundColor: '#fBA300', bottom: theme.spacing(1) }}
                onClick={handleNew}
              >
                <AddIcon sx={{ fontSize: theme.typography.h2.fontSize }} />
              </Fab>
            </Box>
            <StyledBottomNavigationAction
              sx={{ px: 0, minWidth: 'auto' }}
              value={MOBILE_PAGE_TAB.EXPLORE}
              icon={value === MOBILE_PAGE_TAB.EXPLORE ? <CompassFilledIcon /> : <CompassOutlinedIcon />}
            />
            <StyledBottomNavigationAction
              sx={{ px: 0, minWidth: 'auto' }}
              value={MOBILE_PAGE_TAB.PROFILE}
              icon={value === MOBILE_PAGE_TAB.PROFILE ? < UserFilledIcon /> : <UserOutlinedIcon />}
            />
          </BottomNavigation>
        </Footer>
      </Box>
      <AddBookmarkDrawer
        collectionId={collectionId}
        open={addBookmarkDrawerVisible}
        onOpen={() => { setAddBookmarkDrawerVisible(true) }}
        onClose={() => {
          setAddBookmarkDrawerVisible(false);
          window.scrollTo({
            top: scrollY,
            left: 0,
            behavior: 'smooth',
          })
        }}
        onSuccess={(bookmark) => {
          setAddBookmarkDrawerVisible(false);
          window.dispatchEvent(new CustomEvent(events.ADD_BOOKMARK, {
            detail: bookmark
          }));
          // resetBookmark();
          // bookmarkDispatch({ type: BookmarkActionTypes.RESET_BOOKMARK_LIST });
          snackbarDispatch({ type: SHOW_SNACKBAR, data: `已添加书签` });
        }}
      />
    </>
  );
}
