import React from 'react';
import { twMerge } from 'tailwind-merge';

const theme = {
  size: {
    xs: 'text-[1.125rem]',
    sm: 'text-[1.25rem]',
    md: 'text-[1.5rem]',
    lg: 'text-[1.75rem]',
  },
  color: {
    current: 'fill-current',
    primary: 'fill-iconPrimary',
  }
}

export default (props) => {
  const {
    children,
    fontSize = 'md',
    color = 'primary',
    style,
    className,
    viewBox="0 0 24 24",
    ...otherProps
  } = props;

  return (
    <svg
      viewBox={viewBox}
      focusable={false}
      className={twMerge('inline-block',
        theme.size[fontSize],
        theme.color[color],
        className
      )}
      style={Object.assign({
        width: '1em',
        height: '1em',
      }, style)}
      {...otherProps}
    >
      {children}
    </svg>
  )
}
