import React, { useContext, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { MenuList, Menu, MenuItem, ListItemText, Divider, Typography, Link } from '@mui/material';
import ActionPopper from '@components/ActionPopper';
import copy from 'copy-to-clipboard';
import { BookmarkContext } from '@contexts/bookmark/bookmarkReducer';
import { useHotkeys } from 'react-hotkeys-hook';
import { HOTKEYS_COPY_URL, HOTKEYS_COPY_AS_MD, HOTKEYS_COPY_AS_DESCMD } from '@constants/hotkeys';
import { NestedMenuItem } from 'mui-nested-menu';
import { SHOW_SNACKBAR } from '@contexts/snackbar/SnackbarActionTypes';
import { SnackbarContext } from '@contexts/snackbar/snackbarReducer';
import useBookmark from '@pages/DynamicBookmarkList/useBookmark';
import * as BookmarkActionTypes from '@contexts/bookmark/BookmarkActionTypes';
import { CollectionListContext } from '@contexts/collectionList/collectionListReducer';
import * as CollectionListActionTypes from '@contexts/collectionList/CollectionListActionTypes';
import { LayoutContext } from '@contexts/layout/layoutReducer';
import * as events from '@constants/events';

const StyledMenu = styled((props) => (
  <Menu
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    {...props}
  />
))();

const StyledMenuItem = styled((props) => (
  <MenuItem
    {...props}
  />
))(({ theme }) => ({
  minHeight: theme.spacing(4),
  height: theme.spacing(4),
}));

const StyledNestedMenuItem = styled((props) => (
  <NestedMenuItem
    MenuProps={{
      anchorOrigin:{
        vertical: 'top',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      }
    }}
    primaryTypographyProps={{
      variant: 'body2',
    }}
    {...props}
  />
))(({ theme }) => ({
  minHeight: theme.spacing(4),
  height: theme.spacing(4),
  paddingLeft: '16px',
  '& .MuiTypography-root': {
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: theme.typography.body2.fontSize,
  }
}));

export default (props) => {
  const theme = useTheme();
  const { anchorEl, onClose } = props;

  const open = Boolean(anchorEl);

  const { state: bookmarkState, dispatch: bookmarkDispatch } = useContext(BookmarkContext);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);
  const { cloudParserBookmarkAsync, deleteBookmark } = useBookmark();
  const { dispatch: collectionListDispatch } = useContext(CollectionListContext);
  const { state: layoutState, dispatch: layoutDispatch } = useContext(LayoutContext);

  const [showEditDialog, setShowEditDialog] = useState(false);

  const bookmarkData = bookmarkState.bookmarkList.edges[bookmarkState.selectIndex];

  // 复制URL
  const handleCpoyUrl = () => {
    copy(bookmarkData.url);
    snackbarDispatch({ type: SHOW_SNACKBAR, data: '已复制链接' });
    onClose && onClose();
  }

  // 复制为Markdown
  const handleCpoyAsMD = () => {
    copy(`[${bookmarkData.title}](${bookmarkData.url})`);
    snackbarDispatch({ type: SHOW_SNACKBAR, data: '已复制为Markdown' });
    onClose && onClose();
  }

  // 复制为带描述的Markdown
  const handleCpoyAsDescMD = () => {
    copy(`[${bookmarkData.title}](${bookmarkData.url})${bookmarkData.description ? `\r\n${bookmarkData.description}` : ''}${bookmarkData.image ? `\r\n\r\n![](${bookmarkData.image})` : ''}`);
    snackbarDispatch({ type: SHOW_SNACKBAR, data: '已复制为带描述的Markdown' });
    onClose && onClose();
  }

  const handleUpdate = async () => {
    onClose && onClose();
    window.dispatchEvent(new CustomEvent(events.UPDATE_BOOKMARK_METADATA));
  }

  // 重新解析
  const handleReParse = async () => {
    bookmarkDispatch({
      type: BookmarkActionTypes.UPDATE_BOOKMARKS,
      data: [{
        id: bookmarkData.id,
        inProgress: true,
      }]
    });
    snackbarDispatch({ type: SHOW_SNACKBAR, data: '重新解析中' });
    onClose && onClose();
    await cloudParserBookmarkAsync({
      id: bookmarkData.id,
      platform: `web@${$CONFIG.apps.home}`,
      from: 'RE_CLOUD_ADV',
    });
    // bookmarkDispatch({
    //   type: BookmarkActionTypes.ADD_PENDING_PARSE_BOOKMARKS,
    //   data: [bookmarkData.id],
    // })
  }

  const handleDelete = async () => {
    // 移动视图删除时，需要关闭预览弹窗
    if (layoutState.mobile) {
      bookmarkDispatch({ type: BookmarkActionTypes.CLOSE_PREVIEW_WINDOW, data: { record: false } });
    }
    onClose && onClose();
    bookmarkDispatch({ type: BookmarkActionTypes.REMOVE_SELECTED_BOOKMARK });
    collectionListDispatch({
      type: CollectionListActionTypes.UPDATE_COLLECTION_COUNT,
      data: [
        { id: bookmarkData.collection?.id || 'inbox', updateField: 'count', value: -1 }, // 当前收藏夹书签数-1
        { id: 'all', updateField: 'count', value: -1 }, // 所有书签数-1
        { id: 'starred', updateField: 'count', value: bookmarkData.isStarred || bookmarkData.isStarred === undefined ? -1 : 0 }, // 星标书签数-1
      ],
    });

    try {
      await deleteBookmark(bookmarkData.id);
      snackbarDispatch({ type: SHOW_SNACKBAR, data: '已移至 回收站' });
    } catch(ex) {
      snackbarDispatch({ type: SHOW_SNACKBAR, data: '书签删除失败' });
      bookmarkDispatch({ type: BookmarkActionTypes.ROLLBACK_OPERATION });
      collectionListDispatch({ type: CollectionListActionTypes.ROLLBACK_OPERATION });
    }
  }

  useHotkeys(HOTKEYS_COPY_URL, () => {
    handleCpoyUrl();
  }, [bookmarkData]);

  useHotkeys(HOTKEYS_COPY_AS_MD, () => {
    handleCpoyAsMD();
  }, [bookmarkData]);

  useHotkeys(HOTKEYS_COPY_AS_DESCMD, () => {
    handleCpoyAsDescMD();
  }, [bookmarkData]);
  
  return (
    <>
      <ActionPopper
        anchorEl={anchorEl}
        containerRef={props.containerRef}
        onClose={onClose}
        // popperOptions={{
        //   strategy: 'fixed',
        // }}
      >
        <MenuList>
          <StyledMenuItem
            component={Link}
            href={bookmarkData.url}
            target="_blank"
            onClick={() => { onClose && onClose(); }}
          >
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>打开</ListItemText>
            <Typography variant="caption" color="text.secondary">
              O
            </Typography>
          </StyledMenuItem>
          <StyledMenuItem onClick={handleCpoyUrl}>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>复制链接</ListItemText>
            <Typography variant="caption" color="text.secondary">
              ⇧C
            </Typography>
          </StyledMenuItem>
          <StyledNestedMenuItem
            label="复制为"
            parentMenuOpen={open}
          >
            <StyledMenuItem onClick={handleCpoyAsMD}>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>Markdown</ListItemText>
              <Typography variant="caption" color="text.secondary">
                ⇧D
              </Typography>
            </StyledMenuItem>
            <StyledMenuItem onClick={handleCpoyAsDescMD}>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>带描述的Markdown</ListItemText>
              <Typography variant="caption" color="text.secondary">
                ⇧E
              </Typography>
            </StyledMenuItem>
          </StyledNestedMenuItem>
          <Divider sx={{ my: 0.5 }} />
          {
            !bookmarkData.meta && (
              <StyledMenuItem onClick={handleReParse}>
                <ListItemText primaryTypographyProps={{ variant: 'body2' }}>重新解析</ListItemText>
              </StyledMenuItem>
            )
          }
          <StyledMenuItem onClick={handleUpdate}>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>修改基本信息</ListItemText>
          </StyledMenuItem>
          <Divider sx={{ my: 0.5 }} />
          <StyledMenuItem onClick={handleDelete}>
            <ListItemText primaryTypographyProps={{ variant: 'body2', color: theme.palette.error.main }}>删除</ListItemText>
          </StyledMenuItem>
        </MenuList>
      </ActionPopper>
    </>
  )
}
