import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled, useTheme } from '@mui/material/styles';
import { useMutation } from "@apollo/client";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { ADD_BOOKMARK_TAGS } from '@apis/BookmarkTagApi';
import { useEffect } from 'react';
import BookmarkTagSelect from '../BookmarkTagSelect';

const StyledDialogTitle = styled(DialogTitle)(
  ({ theme }) => `
    font-size: ${theme.typography.h4.fontSize};
  `,
);

export default (props) => {
  const {
    open,
    onClose,
    onSuccess,
    data: bookmark,
  } = props;

  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState();
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [addBookmarkTags] = useMutation(ADD_BOOKMARK_TAGS);

  const formik = useFormik({
    initialValues: {
      tags: [],
    },
    validationSchema: Yup.object().shape({
      tags: Yup.array().max(20, '标签不能超过20个')
    }),
    validateOnBlur: validateAfterSubmit,
    validateOnChange: validateAfterSubmit,
    onSubmit: async (values, { setSubmitting }) => {
      setValidateAfterSubmit(false);
      addBookmarkTags({
        variables: {
          bookmarkId: bookmark.id,
          input: values,
        },
        onCompleted: ({ bookmarkTagsAdd: { tags: bookmarkTags } }) => {
          setErrorMessage(null);
          setSubmitting(false);
          onSuccess(bookmarkTags);
        },
        onError: (ex) => {
          setErrorMessage(ex.message);
          setSubmitting(false);
        }
      });
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleCancel = () => {
    onClose();
  }

  useEffect(() => {
    if (!bookmark) return;

    resetForm();
    setValidateAfterSubmit(false);
    formik.setValues({
      tags: bookmark.tags,
    });
    setErrorMessage(false);
  }, [bookmark]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="xs"
      fullWidth
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <StyledDialogTitle>
            修改标签
          </StyledDialogTitle>
          <DialogContent>
            {
              errorMessage && (
                <Alert sx={{ mb: 2 }} severity="error">{ errorMessage }</Alert>
              )
            }
            <BookmarkTagSelect
              {...getFieldProps('tags')}
              error={Boolean(touched.tags && errors.tags)}
              helperText={touched.tags && errors.tags}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>取消</Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              loadingPosition="start"
              onClick={() => {
                setValidateAfterSubmit(true);
              }}
            >
              保存
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  )
}
