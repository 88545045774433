import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function SidebarLeftOutlined(props) {
  return (
    <SvgIcon {...props}>
      <path fill-rule="evenodd" d="M4 9a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5h-2.5A.75.75 0 0 1 4 9Zm0 2.75a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5h-2.5a.75.75 0 0 1-.75-.75Zm0 2.75a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5h-2.5A.75.75 0 0 1 4 14.5Z" clip-rule="evenodd"/>
      <path fill-rule="evenodd" d="M1.25 10A6.75 6.75 0 0 1 8 3.25h8A6.75 6.75 0 0 1 22.75 10v5A6.75 6.75 0 0 1 16 21.75H8A6.75 6.75 0 0 1 1.25 15v-5Zm9.5 10.25H16c2.9 0 5.25-2.35 5.25-5.25v-5c0-2.9-2.35-5.25-5.25-5.25h-5.25v15.5Zm-1.5-15.5v15.5H8A5.25 5.25 0 0 1 2.75 15v-5C2.75 7.1 5.1 4.75 8 4.75h1.25Z" clip-rule="evenodd"/>
  </SvgIcon>
  );
}

export default SidebarLeftOutlined;
